import {FetchStatus, normalize} from '@software/reactcommons';
import {AdministrationState} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    AddIncidentTicketEntryPayload,
    createIncidentActionAndSaga,
    createNewStickyActionAndSaga,
    deleteIncidentUploadFileActionAndSaga,
    deleteStickyActionAndSaga,
    DeviceStatusMessagePayload,
    editStickyActionAndSaga,
    loadAllStickiesActionAndSaga,
    deactivateAccountsActionAndSaga,
    disableAccountsActionAndSaga,
    enableAccountsActionAndSaga,
    exportAccountsActionAndSaga, loadAccountLoginAuditLogsActionAndSaga,
    loadAccountProfilesActionAndSaga,
    loadAccountsActionAndSaga,
    loadIncidentProblemsActionAndSaga,
    loadOpeningHoursForLocationActionAndSaga,
    loadQrCodesActionAndSaga,
    loadTerminalsActionAndSaga,
    saveOpeningHoursForLocationsActionAndSaga,
    SetIncidentFileUploadProgressPayload,
    TemporarilyDisabledState,
    uploadIncidentFilesAction
} from './actions';
import {setSelectedCustomerIdAction} from '../user/actions';


export const initialAdministrationState: AdministrationState = {
    openingHours: {
        selectedLocationId: -1,
        elements: {}
    },
    terminals: {
        elements: {}
    },
    qrCodes: {
        elements: {}
    },
    incidents: {
        problems: {},
        uploads: {}
    },
    stickies: {
        elements: {},
        previewPath: '',
        stickyPosition: {},
        screenDimension: {},
        selectedItems: []
    },
    accounts: {
        elements: {},
        fetchStatus: FetchStatus.Default,
        visible: [],
        numberOfElements: 0,
        orderBy: [],
        page: 0,
        pageSize: 25,
        search: ''
    },
    profiles: {
        elements: []
    },
    loginAudit: {
        elements: []
    },
    fetchStatus: {
        openingHours: {
            load: FetchStatus.Default,
            save: FetchStatus.Default
        },
        terminals: {
            load: FetchStatus.Default
        },
        qrCodes: {
            load: FetchStatus.Default
        },
        incidents: {
            load: FetchStatus.Default,
            create: FetchStatus.Default
        },
        stickies: {
            load: FetchStatus.Default,
            delete: FetchStatus.Default,
            save: FetchStatus.Default,
            update: {}
        },
        accounts: {
            // Set fetch status initially to active to display loader on page mount
            load: FetchStatus.Active,
            disable: FetchStatus.Default,
            reEnable: FetchStatus.Default,
            deactivate: FetchStatus.Default,
            profiles: FetchStatus.Default,
            loginAudit: FetchStatus.Default,
            export: FetchStatus.Default
        }
    }
};

const administration = createSlice({
    name: 'administration',
    initialState: initialAdministrationState,
    reducers: {
        setIncidentFileUploadProgress: (state, action: PayloadAction<SetIncidentFileUploadProgressPayload>) => {
            state.incidents.uploads[action.payload.id]!.progress = action.payload.progress;
        },
        resetIncidentFileUploads: state => {
            state.incidents.uploads = {};
        },
        terminalStatusUpdate: (state, action: PayloadAction<DeviceStatusMessagePayload>) => {
            const terminal = state.terminals.elements[action.payload.deviceId];
            if (terminal) {
                terminal.isOnline = action.payload.isOnline;
            }
        },
        addIncidentTicketNote: (state, action: PayloadAction<AddIncidentTicketEntryPayload>) => {
            const terminal = state.terminals.elements[action.payload.deviceId];
            if (terminal) {
                terminal.ticketEntries = [...(terminal.ticketEntries || [])];
                terminal.ticketEntries.push(action.payload.ticketEntry);
            }
        },
        setAccountPage: (state, action: PayloadAction<number>) => {
            state.accounts.page = action.payload;
        },
        resetAccounts: (state) => {
            state.accounts = {...initialAdministrationState.accounts};
            state.fetchStatus.accounts.load = FetchStatus.Active;
        },
        removeAccountsFromState: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach(it => {
                delete state.accounts.elements[it];
            });
            state.accounts.visible = state.accounts.visible.filter(it => !action.payload.includes(it));
        },
        setAccountsDisabledState: (state, action: PayloadAction<{ opaqueIds: string[]; state: TemporarilyDisabledState; }>) => {
            action.payload.opaqueIds.forEach(it => {
                state.accounts.elements[it].temporarilyDisabledState = action.payload.state;
            });
        },
        setSelectedItems: (state, action: PayloadAction<string[]>) => {
            state.stickies.selectedItems = action.payload;
        },
        deleteStickyFromState: (state, action: PayloadAction<number>) => {
            delete state.stickies.elements[action.payload];
        }
    },
    extraReducers: builder => builder.addCase(loadOpeningHoursForLocationActionAndSaga.startAction, (state, action) => {
        state.openingHours.selectedLocationId = action.payload.id;
        state.fetchStatus.openingHours.load = FetchStatus.Active;
    }).addCase(loadOpeningHoursForLocationActionAndSaga.errorAction, (state, action) => {
        state.openingHours.selectedLocationId = -1;
        state.fetchStatus.openingHours.load = FetchStatus.Error;
    }).addCase(loadOpeningHoursForLocationActionAndSaga.successAction, (state, action) => {
        state.openingHours.elements[action.payload.locationId] = action.payload;
        state.fetchStatus.openingHours.load = FetchStatus.Success;
    }).addCase(loadOpeningHoursForLocationActionAndSaga.resetAction, (state) => {
        state.fetchStatus.openingHours.load = FetchStatus.Default;
    }).addCase(saveOpeningHoursForLocationsActionAndSaga.startAction, (state) => {
        state.fetchStatus.openingHours.save = FetchStatus.Active;
    }).addCase(saveOpeningHoursForLocationsActionAndSaga.errorAction, (state) => {
        state.fetchStatus.openingHours.save = FetchStatus.Error;
    }).addCase(saveOpeningHoursForLocationsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.openingHours.save = FetchStatus.Success;
        state.openingHours.elements = {
            ...state.openingHours.elements,
            ...normalize(action.payload, undefined, 'locationId')
        }
    }).addCase(saveOpeningHoursForLocationsActionAndSaga.resetAction, state => {
        state.fetchStatus.openingHours.save = FetchStatus.Default;
    }).addCase(loadTerminalsActionAndSaga.startAction, state => {
        state.fetchStatus.terminals.load = FetchStatus.Active;
    }).addCase(loadTerminalsActionAndSaga.errorAction, state => {
        state.fetchStatus.terminals.load = FetchStatus.Error;
    }).addCase(loadTerminalsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.terminals.load = FetchStatus.Success;
        state.terminals.elements = normalize(action.payload.elements, undefined, 'deviceId')
    }).addCase(loadTerminalsActionAndSaga.resetAction, (state) => {
        state.fetchStatus.terminals.load = FetchStatus.Default;
    }).addCase(loadIncidentProblemsActionAndSaga.startAction, state => {
        state.fetchStatus.incidents.load = FetchStatus.Default;
    }).addCase(loadIncidentProblemsActionAndSaga.errorAction, state => {
        state.fetchStatus.incidents.load = FetchStatus.Error;
    }).addCase(loadIncidentProblemsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.incidents.load = FetchStatus.Success;
        state.incidents.problems = normalize(action.payload.elements);
    }).addCase(loadIncidentProblemsActionAndSaga.resetAction, state => {
        state.fetchStatus.incidents.load = FetchStatus.Default;
    }).addCase(uploadIncidentFilesAction.action, (state, action) => {
        state.incidents.uploads = {
            ...state.incidents.uploads,
            ...normalize(action.payload.files)
        }
    }).addCase(uploadIncidentFilesAction.successAction, (state, action) => {
        state.incidents.uploads[action.payload.id]!.finished = true;
        state.incidents.uploads[action.payload.id]!.filename = action.payload.fileName;
    }).addCase(deleteIncidentUploadFileActionAndSaga.action, (state, action) => {
        delete state.incidents.uploads[action.payload.file.id];
    }).addCase(createIncidentActionAndSaga.startAction, state => {
        state.fetchStatus.incidents.create = FetchStatus.Active;
    }).addCase(createIncidentActionAndSaga.errorAction, state => {
        state.fetchStatus.incidents.create = FetchStatus.Error;
    }).addCase(createIncidentActionAndSaga.successAction, state => {
        state.fetchStatus.incidents.create = FetchStatus.Success;
    }).addCase(createIncidentActionAndSaga.resetAction, state => {
        state.fetchStatus.incidents.create = FetchStatus.Default;
    }).addCase(loadQrCodesActionAndSaga.startAction, state => {
        state.fetchStatus.qrCodes.load = FetchStatus.Active;
    }).addCase(loadQrCodesActionAndSaga.errorAction, state => {
        state.fetchStatus.qrCodes.load = FetchStatus.Error;
    }).addCase(loadQrCodesActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.qrCodes.load = FetchStatus.Success;
        state.qrCodes.elements = normalize(action.payload.elements, undefined, 'deviceId')
    }).addCase(loadQrCodesActionAndSaga.resetAction, (state) => {
        state.fetchStatus.qrCodes.load = FetchStatus.Default;
    }).addCase(loadAccountsActionAndSaga.startAction, state => {
        state.fetchStatus.accounts.load = FetchStatus.Active;
    }).addCase(loadAccountsActionAndSaga.errorAction, state => {
        state.fetchStatus.accounts.load = FetchStatus.Error;
    }).addCase(loadAccountsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.accounts.load = FetchStatus.Success;
        state.accounts.elements = {...state.accounts.elements, ...normalize(action.payload.accounts, undefined, 'opaqueId')};
        state.accounts.numberOfElements = action.payload.totalCount;
        state.accounts.visible = [...state.accounts.visible, ...action.payload.accounts.map(it => it.opaqueId).filter(it => !state.accounts.visible.includes(it))];
    }).addCase(loadAccountsActionAndSaga.resetAction, (state) => {
        state.fetchStatus.accounts.load = FetchStatus.Default;
    }).addCase(disableAccountsActionAndSaga.startAction, state => {
        state.fetchStatus.accounts.disable = FetchStatus.Active;
    }).addCase(disableAccountsActionAndSaga.errorAction, state => {
        state.fetchStatus.accounts.disable = FetchStatus.Error;
    }).addCase(disableAccountsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.accounts.disable = FetchStatus.Success;
    }).addCase(disableAccountsActionAndSaga.resetAction, (state) => {
        state.fetchStatus.accounts.disable = FetchStatus.Default;
    }).addCase(deactivateAccountsActionAndSaga.startAction, state => {
        state.fetchStatus.accounts.deactivate = FetchStatus.Active;
    }).addCase(deactivateAccountsActionAndSaga.errorAction, state => {
        state.fetchStatus.accounts.deactivate = FetchStatus.Error;
    }).addCase(deactivateAccountsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.accounts.deactivate = FetchStatus.Success;
    }).addCase(deactivateAccountsActionAndSaga.resetAction, (state) => {
        state.fetchStatus.accounts.deactivate = FetchStatus.Default;
    }).addCase(enableAccountsActionAndSaga.startAction, state => {
        state.fetchStatus.accounts.reEnable = FetchStatus.Active;
    }).addCase(enableAccountsActionAndSaga.errorAction, state => {
        state.fetchStatus.accounts.reEnable = FetchStatus.Error;
    }).addCase(enableAccountsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.accounts.reEnable = FetchStatus.Success;
    }).addCase(enableAccountsActionAndSaga.resetAction, (state) => {
        state.fetchStatus.accounts.reEnable = FetchStatus.Default;
    }).addCase(loadAccountProfilesActionAndSaga.startAction, state => {
        state.fetchStatus.accounts.profiles = FetchStatus.Active;
    }).addCase(loadAccountProfilesActionAndSaga.errorAction, state => {
        state.fetchStatus.accounts.profiles = FetchStatus.Error;
    }).addCase(loadAccountProfilesActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.accounts.profiles = FetchStatus.Success;
        state.profiles.elements = action.payload.profiles;
    }).addCase(loadAccountProfilesActionAndSaga.resetAction, (state) => {
        state.fetchStatus.accounts.profiles = FetchStatus.Default;
    }).addCase(exportAccountsActionAndSaga.startAction, state => {
        state.fetchStatus.accounts.export = FetchStatus.Active;
    }).addCase(exportAccountsActionAndSaga.errorAction, state => {
        state.fetchStatus.accounts.export = FetchStatus.Error;
    }).addCase(exportAccountsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.accounts.export = FetchStatus.Success;
    }).addCase(exportAccountsActionAndSaga.resetAction, (state) => {
        state.fetchStatus.accounts.export = FetchStatus.Default;
    }).addCase(loadAccountLoginAuditLogsActionAndSaga.startAction, state => {
        state.fetchStatus.accounts.loginAudit = FetchStatus.Active;
        // Reset the elements on every new load
        state.loginAudit.elements = [];
    }).addCase(loadAccountLoginAuditLogsActionAndSaga.errorAction, state => {
        state.fetchStatus.accounts.loginAudit = FetchStatus.Error;
    }).addCase(loadAccountLoginAuditLogsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus.accounts.loginAudit = FetchStatus.Success;
        state.loginAudit.elements = action.payload.entries;
    }).addCase(loadAccountLoginAuditLogsActionAndSaga.resetAction, (state) => {
        state.fetchStatus.accounts.loginAudit = FetchStatus.Default;
    }).addCase(setSelectedCustomerIdAction.startAction, (state, action) => {
        return {...initialAdministrationState};
    })

        .addCase(loadAllStickiesActionAndSaga.startAction, state => {
            state.fetchStatus.stickies.load = FetchStatus.Active;
        }).addCase(loadAllStickiesActionAndSaga.errorAction, state => {
            state.fetchStatus.stickies.load = FetchStatus.Error;
        }).addCase(loadAllStickiesActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.stickies.load = FetchStatus.Success;
            state.stickies.elements = normalize(action.payload.elements);
            state.stickies.previewPath = action.payload.screenPreviewPath;
            state.stickies.stickyPosition = action.payload.position;
            state.stickies.screenDimension = action.payload.screenDimension;
        }).addCase(loadAllStickiesActionAndSaga.resetAction, (state) => {
            state.fetchStatus.stickies.load = FetchStatus.Default;
        })
        .addCase(deleteStickyActionAndSaga.startAction, (state) => {
            state.fetchStatus.stickies.delete = FetchStatus.Active;
        }).addCase(deleteStickyActionAndSaga.errorAction, state => {
            state.fetchStatus.stickies.delete = FetchStatus.Error;
        }).addCase(deleteStickyActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.stickies.delete = FetchStatus.Success;
        }).addCase(deleteStickyActionAndSaga.resetAction, (state) => {
            state.fetchStatus.stickies.delete = FetchStatus.Default;
        })
        .addCase(createNewStickyActionAndSaga.startAction, state => {
            state.fetchStatus.stickies.save = FetchStatus.Active;
        }).addCase(createNewStickyActionAndSaga.errorAction, state => {
            state.fetchStatus.stickies.save = FetchStatus.Error;
        }).addCase(createNewStickyActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.stickies.save = FetchStatus.Success;
            state.stickies.createdSticky = action.payload;
            state.stickies.elements[action.payload.id] = action.payload;
        }).addCase(createNewStickyActionAndSaga.resetAction, (state) => {
            state.fetchStatus.stickies.save = FetchStatus.Default;
        })
        .addCase(editStickyActionAndSaga.startAction, (state, action) => {
            state.fetchStatus.stickies.update[action.payload.id] = FetchStatus.Active;
        }).addCase(editStickyActionAndSaga.errorAction, (state, action) => {
            state.fetchStatus.stickies.update[action.payload.id] = FetchStatus.Error;
        }).addCase(editStickyActionAndSaga.successAction, (state, action) => {
            state.fetchStatus.stickies.update[action.payload.id] = FetchStatus.Success;
            state.stickies.elements[action.payload.id] = action.payload;
            state.stickies.updatedSticky = action.payload;
        }).addCase(editStickyActionAndSaga.resetAction, (state, action) => {
            state.fetchStatus.stickies.update[action.payload.id] = FetchStatus.Default;
        })
});

export const {
    setIncidentFileUploadProgress,
    resetIncidentFileUploads,
    terminalStatusUpdate,
    addIncidentTicketNote,
    setAccountPage,
    resetAccounts,
    removeAccountsFromState,
    setAccountsDisabledState,
    setSelectedItems,
    deleteStickyFromState
} = administration.actions;

export default administration.reducer;