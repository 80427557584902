import {
    call,
    take,
    put,
    select,
    takeEvery,
    takeLatest,
    cancelled,
    PutEffect,
    SelectEffect,
    CallEffect, CancelledEffect, ChannelTakeEffect, ForkEffect
} from 'redux-saga/effects'
import {EventChannel, TakeableChannel} from 'redux-saga';
import {Route} from '../../api/Api';
import {
    VideoCategory,
    VideoCreatedTag,
    VideoTag,
    Video,
    AllowedVideoType
} from '../reducer/videos/types';
import {
    deleteVideoAction,
    DeleteVideoActionPayload, loadStatisticsAction,
    saveUploadedVideoAction,
    setVideoEnabledAction,
    SetVideoEnabledActionPayload,
    uploadVideoAction,
} from '../reducer/videos/actions';
import {
    saveEditedVideoAction
} from '../reducer/admin/actions';
import {replaceLink} from '../../helper/Helper';
import {AppState, SearchRequest} from '../../types/Types';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    deleteRequest,
    postRequest,
    getRequest,
    putRequest,
    logger,
    spawnSagas,
    Request,
    RequestResponse, createUploadFileChannel, UploadEventChannel
} from '@software/reactcommons';
import {CancelAllSagasViaForcedTabLogoutActionKey, jwtSelector, logoutAction} from '@software/reactcommons-security';
import {deleteVideoFromStateAction, setUploadVideoProgress, updateVideoSuccess} from '../reducer/videos/videos';

export function* deleteVideo(action: PayloadAction<DeleteVideoActionPayload>): Generator<SelectEffect | PutEffect<any> | CallEffect<RequestResponse<any>> | CancelledEffect, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        yield put(deleteVideoAction.startAction(action.payload));
        request = deleteRequest(Route.DeleteVideo(action.payload.videoID), {jwt});
        yield call(request.request);
        yield put(deleteVideoAction.successAction(action.payload.videoID));
        yield put(deleteVideoFromStateAction(action.payload.videoID));
    } catch (e: any) {
        logger.error(e);
        yield put(deleteVideoAction.errorAction({
            ...action.payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* deleteVideoSaga(): Generator<any, any, any> {
    yield takeEvery(deleteVideoAction.actionKey, deleteVideo);
}

export function* updateVideoActiveState(action: PayloadAction<SetVideoEnabledActionPayload>): Generator<SelectEffect | CallEffect<RequestResponse<any>> | CancelledEffect | PutEffect<any>, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        yield put(setVideoEnabledAction.startAction(action.payload));
        request = putRequest(Route.EnableVideo(action.payload.videoID, action.payload.enabled), {jwt});
        yield call(request.request);
        yield put(setVideoEnabledAction.successAction({
            id: action.payload.videoID,
            enabled: action.payload.enabled
        }));
    } catch (e: any) {
        logger.error(e);
        yield put(setVideoEnabledAction.errorAction({
            ...action.payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* updateVideoActiveStateSaga(): Generator<any, any, any> {
    yield takeEvery(setVideoEnabledAction.actionKey, updateVideoActiveState);
}

export function* uploadVideo(action: PayloadAction<{ file: File }>): Generator<SelectEffect | PutEffect<any> | CallEffect<EventChannel<any>> | ChannelTakeEffect<any>, any, any> {
    try {
        const jwt = yield select(jwtSelector);
        if (jwt && (Object as any).values(AllowedVideoType).includes(action.payload.file.type)) {
            yield put(uploadVideoAction.startAction(action.payload));
            // Only upload file if type is valid
            const channel: TakeableChannel<UploadEventChannel<Response>> = yield call(createUploadFileChannel, Route.UploadVideo, action.payload.file, jwt);
            while (true) {
                const {
                    progress = 0,
                    err,
                    success,
                    response
                } = yield take<UploadEventChannel<Response>>(channel);
                if (err) {
                    yield put(uploadVideoAction.errorAction({
                        ...action.payload,
                        message: err.message
                    }));
                    break;
                }
                if (success) {
                    if (response) {
                        yield put(uploadVideoAction.successAction(response));
                    } else {
                        yield put(uploadVideoAction.errorAction({
                            ...action.payload,
                            message: ''
                        }));
                    }
                    break;
                }
                yield put(setUploadVideoProgress(progress));
            }
        }
    } catch (e: any) {
        logger.error(e);
        yield put(uploadVideoAction.errorAction({
            ...action.payload,
            message: e.message
        }));
    }
}

export function* uploadVideoSaga(): Generator<any, any, any> {
    yield takeEvery(uploadVideoAction.actionKey, uploadVideo);
}

export function* saveUploadedVideo(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt = yield select(jwtSelector);
        const {
            videoFileName, previewImageFileName, title, description, author,
            userGroupIDs, categoryIDs, tagIDs, newTags, tagsByTagType,
            wishIds
        } = yield select((state: AppState) => ({
            videoFileName: state.videos.upload.previewVideoPath?.fileName || null,
            previewImageFileName: state.videos.upload.previewImagePath?.fileName || null,
            title: state.videos.upload.title,
            // Replace links with markdown language
            description: replaceLink(state.videos.upload.description),
            author: state.videos.upload.author,
            userGroupIDs: state.user.info.groups.map((group) => group.id),
            categoryIDs: state.videos.upload.categories.map((category: VideoCategory) => category.id),
            tagIDs: state.videos.upload.tags.map((tag: VideoTag) => tag.id),
            newTags: state.videos.upload.createdTags.map((tag: VideoCreatedTag) => tag.name),
            tagsByTagType: state.videos.upload.selectedTagsByTagType,
            wishIds: state.videos.upload.wishIds
        }));
        if (jwt && title?.trim() && description?.trim() && author?.trim() && videoFileName && previewImageFileName && categoryIDs.length) {

            const mergedTags: number[] = [...tagIDs];
            Object.keys(tagsByTagType).forEach((key: string) => {
                tagsByTagType[key].forEach((tag: VideoTag) => {
                    if (!mergedTags.includes(tag.id)) {
                        mergedTags.push(tag.id);
                    }
                });
            });

            yield put(saveUploadedVideoAction.startAction({}));

            request = postRequest(Route.SaveUploadedVideo, {
                body: {
                    videoFileName,
                    previewImageFileName,
                    title,
                    description,
                    author,
                    userGroupIDs,
                    categoryIDs,
                    tagIDs: mergedTags,
                    newTags,
                    userViewRestrictionIDs: [],
                    enabled: true,
                    userGroupViewRestrictionIDs: [],
                    wishIds
                },
                jwt
            });
            const {response}: RequestResponse<Video> = yield call(request.request);
            yield put(saveUploadedVideoAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(saveUploadedVideoAction.errorAction({
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* saveUploadedVideoSaga(): Generator<ForkEffect<never>> {
    yield takeLatest(saveUploadedVideoAction.actionKey, saveUploadedVideo);
}

export function* saveEditedVideo(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt = yield select(jwtSelector);
        const {
            title, description, author, userGroupIDs, categoryIDs, tagIDs, newTags, videoID
        } = yield select((state: AppState) => ({
            videoID: state.admin.editedVideo.videoID,
            title: state.admin.editedVideo.title,
            description: replaceLink(state.admin.editedVideo.description),
            author: state.admin.editedVideo.author,
            userGroupIDs: state.user.info.groups.map((group) => group.id),
            categoryIDs: state.admin.editedVideo.categories.map((category: VideoCategory) => category.id),
            tagIDs: state.admin.editedVideo.tags.map((tag: VideoTag) => tag.id),
            newTags: state.admin.editedVideo.createdTags.map((tag: VideoCreatedTag) => tag.name)
        }));
        if (jwt && videoID && title?.trim() && description?.trim() && author?.trim() && categoryIDs.length) {
            yield put(saveEditedVideoAction.startAction({}));
            request = putRequest(Route.UpdateVideo, {
                body: {
                    videoID,
                    title,
                    description,
                    author,
                    userGroupIDs,
                    categoryIDs,
                    tagIDs,
                    newTags,
                    userViewRestrictionIDs: [],
                    userGroupViewRestrictionIDs: []
                },
                jwt
            })
            const {response}: RequestResponse<Video> = yield call(request.request);
            yield put(saveEditedVideoAction.successAction());
            yield put(updateVideoSuccess(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(saveEditedVideoAction.errorAction({
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* saveEditedVideoSaga(): Generator<any, any, any> {
    yield takeLatest(saveEditedVideoAction.actionKey, saveEditedVideo);
}


export function* loadStatistic(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        const {pageSize, page, sorting, search} = yield select((state: AppState) => ({
            pageSize: state.videos.statistics.videos.pageSize,
            page: state.videos.statistics.videos.page,
            sorting: state.videos.statistics.videos.orderBy,
            search: state.videos.statistics.videos.search
        }));
        if (jwt?.trim()) {
            yield put(loadStatisticsAction.startAction({}));
            const params: SearchRequest = {}
            if (pageSize && pageSize > -1 && page !== undefined) {
                params.limit = pageSize;
                params.offset = pageSize * page;
            }
            if (sorting) {
                params.sorting = JSON.stringify(sorting);
            }
            if (search) {
                params.searchText = search
            }
            request = getRequest(Route.LoadStatistic, {params, jwt});
            yield put(loadStatisticsAction.successAction((yield call(request.request)).response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadStatisticsAction.errorAction({
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}


export function* loadStatisticSaga() {
    yield takeLatest([loadStatisticsAction.actionKey, 'videos/setAdminVideoColumnSortOrder', 'videos/removeAdminVideoColumnSortOrder',
        'videos/setAdminVideoPage', 'videos/setAdminVideoPageSize'], loadStatistic);
}

function* VideoAdminSaga(): Generator<any, any, any> {
    yield call(spawnSagas([
        {generator: deleteVideoSaga},
        {generator: updateVideoActiveStateSaga},
        {generator: uploadVideoSaga},
        {generator: saveUploadedVideoSaga},
        {generator: saveEditedVideoSaga},
        {generator: loadStatisticSaga},
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}

export default VideoAdminSaga;