import {
    call,
    CallEffect,
    cancelled,
    CancelledEffect, delay,
    fork,
    ForkEffect,
    put,
    PutEffect,
    race,
    select,
    SelectEffect, take, takeEvery,
    takeLatest
} from 'redux-saga/effects';
import {AppState} from '../../types/Types';
import {AvailableSitesResponse, ErrorCodes} from '../reducer/user/types';
import {
    authenticateViaLoginTokenAction,
    EmailTokenPayload,
    EnableUserLogoutOnInactivityActionKey,
    getEmailViaLoginTokenActionAndSaga,
    getLanguageFromCookie, InvalidateOIDCAuthorizationCodeActionAndSaga,
    invalidatePasswordLessLoginTokenActionAndSaga,
    invalidateResetPasswordTokenActionAndSaga,
    loadAccountActionAndSaga,
    loadAvailableLoginMethodsActionAndSaga,
    LoadAvailableSitesActionKey,
    loadCustomerListActionAndSaga,
    LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga,
    loadPersonAccountActionAndSaga,
    LoadResolvedHierarchyNodeHierarchyActionAndSaga,
    loadSelectedCustomerIdActionAndSaga,
    loadUserAccessActionAndSaga,
    loadUserInfoActionAndSaga, RegisterUserActivityActionKey, RegisterUserActivityInOtherTabActionKey,
    requestLoginTokenActionAndSaga,
    requestPasswordMailActionAndSaga,
    resetPasswordActionAndSaga,
    setPasswordViaRegistrationTokenActionAndSaga,
    setSelectedCustomerIdAction,
    SetSelectedCustomerIdPayload,
    UpdatePasswordActionKey,
    UpdatePasswordActionPayload,
    UpdatePersonalDataActionKey,
    UpdatePersonalDataActionPayload,
    validateLoginTokenAction,
    ValidateOIDCLoginActionAndSaga,
    validateRegistrationTokenActionAndSaga,
    validateResetPasswordTokenActionAndSaga
} from '../reducer/user/actions';
import {CheckOnlineStateRoutes, Route} from '../../api/Api';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    AuthenticationError,
    BadRequestError,
    getRequest, HttpError,
    Language,
    logger,
    postRequest,
    putRequest,
    Request,
    RequestResponse, SetBrowserTabActiveStatePayload,
    setLanguage,
    setShowAppLoader,
    spawnSagas
} from '@software/reactcommons';
import {
    AuthenticationResponse,
    BaseUserSaga, CancelAllSagasViaForcedTabLogoutActionKey,
    fetchAuthenticateSuccess,
    JWT,
    jwtSelector,
    logoutAction,
    preparedSelector,
    refreshToken, SecurityErrorCode,
    setUserPrepared,
} from '@software/reactcommons-security';
import {setDeepLinkToken, setRedirect, setShowInactivityLogoutModal} from '../reducer/application/application';
import {
    fetchLoadAvailableSites,
    fetchLoadAvailableSitesError,
    fetchLoadAvailableSitesSuccess,
    fetchUpdatePassword,
    fetchUpdatePasswordError,
    fetchUpdatePasswordSuccess,
    fetchUpdatePersonalData,
    fetchUpdatePersonalDataError,
    fetchUpdatePersonalDataSuccess,
    setCustomerLogoPath, setLastActivityTimestamp,
    setPasswordLessValidateTokenError,
    setUpdatePasswordError, setUserBlocked,
    setUserFullName
} from '../reducer/user/user';
import {DateTime, Settings} from 'luxon';
import {jwtDecode} from 'jwt-decode';
import {hideAppLoader} from './ApplicationSaga';
import {enableOnlineCheck, requestWebSocketConnectionAction} from '../reducer/application/actions';
import {QualitizeOnlineWebSocketUrl} from '../../constants/Constants';
import {manualRefreshToken, startRefreshTokenSaga} from '@software/reactcommons-security/dist/redux/saga/BaseUserSaga';
import {clearLoginInformation} from '../reducer/login/login';
import {inactivityLogoutDurationInMsSelector} from '../reducer/user/selectors';
import {StatusCodes} from 'http-status-codes';

export const emailSelector = (state: AppState) => state.user.info.email;

export function* updateAccount(action: PayloadAction<UpdatePersonalDataActionPayload>): Generator<SelectEffect | PutEffect<any> | CallEffect<RequestResponse<any>> | CancelledEffect | ForkEffect<any>, any, any> {
    let postReq: Request<any> | undefined = undefined;
    let putReq: Request<any> | undefined = undefined;
    try {
        const jwt = yield select(jwtSelector);
        const email = yield select(emailSelector);
        if (jwt?.trim() && action.payload.lastName?.trim() && action.payload.email?.trim()) {
            yield put(fetchUpdatePersonalData());
            // Before changing the personal data, check whether the email has changed and tell the auth service
            // If this fails, no personal data will be changed in the master data service.
            if (email.trim() !== action.payload.email.trim()) {
                postReq = postRequest(Route.UpdateAccount, {body: {email: action.payload.email}, jwt});
                // Tell the authentication service that a new email should be associated with this account
                yield call(postReq.request);
                // Get a new jwt including the new email
                yield fork(refreshToken, Route.RefreshToken);
            }
            putReq = putRequest(Route.UpdateAccountLegacy, {body: action.payload, jwt})
            const updateLegacy: RequestResponse<UpdatePersonalDataActionPayload> = yield call(putReq.request);
            yield put(fetchUpdatePersonalDataSuccess(updateLegacy.response));
        }
    } catch (e) {
        logger.error(e);
        yield put(fetchUpdatePersonalDataError());
    } finally {
        if (yield cancelled()) {
            postReq?.abort();
            putReq?.abort();
        }
    }
}

export function* updateAccountSaga(): Generator<any, any, any> {
    yield takeLatest(UpdatePersonalDataActionKey, updateAccount);
}

export function* updatePassword(action: PayloadAction<UpdatePasswordActionPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt = yield select(jwtSelector);
        const email = yield select(emailSelector);
        if (jwt?.trim() && action.payload.password.trim() === action.payload.passwordConfirm.trim()) {
            yield put(fetchUpdatePassword());
            request = postRequest(Route.UpdateAccount, {
                body: {
                    email,
                    oldPassword: action.payload.oldPassword,
                    newPassword: action.payload.password
                },
                jwt
            })
            yield call(request.request);
            yield put(fetchUpdatePasswordSuccess());
        }
    } catch (e) {
        logger.error(e);
        if (e instanceof BadRequestError) {
            yield put(fetchUpdatePasswordError(e.message));
        } else {
            yield put(fetchUpdatePasswordError());
        }
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* updatePasswordSaga(): Generator<ForkEffect, any, void> {
    yield takeLatest(UpdatePasswordActionKey, updatePassword);
}

export function* setPasswordUpdateErrorGenerator(request: any, error: any): Generator<PutEffect, void, void> {
    if (error instanceof BadRequestError) {
        yield put(setUpdatePasswordError(error.message));
    } else {
        yield put(setUpdatePasswordError());
    }
}

export const redirectSelector = (state: AppState) => state.application.redirect;

export function* loadAvailableSites(respectRedirect: boolean): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        const redirect = yield select(redirectSelector);
        yield put(fetchLoadAvailableSites());
        request = getRequest(Route.LoadSites, {
            jwt,
            withRetry: true
        });
        const {response, headers}: RequestResponse<AvailableSitesResponse> = yield call(request.request);
        const language = headers.get('Content-Language') as Language;
        if ([Language.English, Language.German].includes(language)) {
            yield put(setLanguage(language));
        }
        yield put(fetchLoadAvailableSitesSuccess(response.pages));
        if (response.customerLogoPath) {
            yield put(setCustomerLogoPath(response.customerLogoPath));
        }
        yield put(setUserFullName(response.fullName));
        // Only respect redirect if no redirect is in the state
        if (respectRedirect && response.redirect?.trim() && !redirect) {
            yield put(setRedirect(response.redirect));
        }
    } catch (e) {
        logger.error(e);
        yield put(fetchLoadAvailableSitesError());
        // Check if error code is either unauthorized of forbidden, then force logout, otherwise navigate to login page
        // (probably one service is not reachable)
        if (e instanceof AuthenticationError || (e instanceof HttpError && e.statusCode === StatusCodes.FORBIDDEN)) {
            yield put(logoutAction.action());
        }
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadAvailableSitesSaga() {
    yield takeLatest(LoadAvailableSitesActionKey, loadAvailableSites, false);
}

export function changeDateTimeLocale(action: PayloadAction<Language>) {
    Settings.defaultLocale = action.payload;
}

export function* changeDateTimeLocaleSaga() {
    yield takeLatest('application/setLanguage', changeDateTimeLocale);
}

export function* changeCustomer({payload}: PayloadAction<SetSelectedCustomerIdPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        yield put(setShowAppLoader(true));
        yield put(setSelectedCustomerIdAction.startAction(payload));
        request = postRequest(Route.User.ChangeCustomer, {body: payload, jwt});
        yield call(request.request);
        // Refresh the current jwt because customer id will be stored in the jwt
        yield call(manualRefreshToken, Route.RefreshToken);
        // Load resolved hierarchy after customer switch
        yield put(LoadResolvedHierarchyNodeHierarchyActionAndSaga.action());
        yield call(loadAvailableSites, false);
        // Load user access
        yield put(loadUserAccessActionAndSaga.action());
        yield put(setSelectedCustomerIdAction.successAction(payload));
        yield put(setUserPrepared());
    } catch (e: any) {
        logger.error(e);
        yield put(setSelectedCustomerIdAction.errorAction({
            ...payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
        yield put(setShowAppLoader(false));
    }
}

export function* changeCustomerSaga() {
    yield takeLatest(setSelectedCustomerIdAction.actionKey, changeCustomer);
}

export const internalUserSelector = (state: AppState) => state.user.info.internal;
export const rolesSelector = (state: AppState) => state.user.info.roles;

export function* authenticateViaLoginToken({payload}: PayloadAction<EmailTokenPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        yield put(authenticateViaLoginTokenAction.startAction(payload));
        request = postRequest(Route.Authenticate.AuthenticateViaLoginToken, {body: payload});
        const requestResponse: RequestResponse<AuthenticationResponse> = yield call(request.request);
        yield put(authenticateViaLoginTokenAction.successAction());
        const expiresAt = DateTime.now().valueOf() + requestResponse.response.expiresInSeconds * 1000;
        const decoded: JWT = jwtDecode(requestResponse.response.accessToken);
        yield put(fetchAuthenticateSuccess({
            ...requestResponse.response,
            roles: decoded.roles,
            internal: decoded.accountType === 'internal',
            expiresAt
        }));
        yield call(postLoginGenerator);
        yield fork(startRefreshTokenSaga, Route.RefreshToken);
    } catch (e: any) {
        logger.error(e);
        yield put(authenticateViaLoginTokenAction.errorAction({
            ...payload,
            message: e.message
        }));
        yield call(postLoginErrorGenerator, e);
    } finally {
        const prepared = yield select(preparedSelector);
        if (!prepared) {
            yield put(setUserPrepared());
        }
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* authenticateViaLoginTokenSaga() {
    yield takeLatest(authenticateViaLoginTokenAction.actionKey, authenticateViaLoginToken);
}

/**
 * Default export, spawns all user generator functions. Uses spawn to avoid complete saga crash if an error occures.
 */
export function* validateLoginToken({payload}: PayloadAction<EmailTokenPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        yield put(validateLoginTokenAction.startAction(payload));
        request = postRequest(Route.Authenticate.ValidateLoginToken, {body: payload});
        yield call(request.request);
        yield put(validateLoginTokenAction.successAction());
    } catch (e: any) {
        logger.error(e);
        if (e instanceof AuthenticationError) {
            yield put(setPasswordLessValidateTokenError(e.errorCode));
        }
        yield put(validateLoginTokenAction.errorAction({
            ...payload,
            message: e.message
        }));
    } finally {
        const prepared = yield select(preparedSelector);
        if (!prepared) {
            yield put(setUserPrepared());
        }
        if (yield cancelled()) {
            request?.abort();
        }
        yield call(hideAppLoader);
    }
}

export function* validateLoginTokenSaga() {
    yield takeLatest(validateLoginTokenAction.actionKey, validateLoginToken);
}

export function* postLoginGenerator(): Generator<SelectEffect | CallEffect | CancelledEffect | PutEffect<any> | ForkEffect, any, any> {
    let request: Request<any> | undefined = undefined;
    // Call the authentication service with the entered credentials which are stored in the payload of the
    try {
        yield put(setShowAppLoader(true));
        request = postRequest(Route.ClearLegacyAccount, {});
        yield call(request.request);
        yield call(postRefreshGenerator, true, true);
        // Reset the deep link token
        yield put(setDeepLinkToken());
        // Clear the stored web database state because login was successful
        yield put(clearLoginInformation());
    } catch (e) {
        logger.error('Cannot load user data from backend!', e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
        // No hiding of app loader, will be caught in other part of the app
    }
}

export function* postRefreshGenerator(respectRedirect: boolean = false, refreshForInternalUser: boolean = false): Generator<any, any, any> {
    yield put(setShowAppLoader(true));
    // Check whether the logged-in user is an internal user or not
    const internal = yield select(internalUserSelector);
    // Check language because it could have been updated via backend
    yield put(getLanguageFromCookie());
    // After login, the redirect from backend should be respected
    // After page reload the redirect from backend should be ignored because user reloaded on specific sub page
    yield call(loadAvailableSites, respectRedirect);

    if (internal && refreshForInternalUser) {
        // Refresh the current jwt because customer id will be stored in the jwt
        yield call(manualRefreshToken, Route.RefreshToken);
    }
    // Load available login methods

    // For non-internal users load the account data
    if (!internal) {
        yield put(loadAccountActionAndSaga.action({}));
    }
    yield put(loadUserAccessActionAndSaga.action());
    // Load data source group hierarchy access
    yield put(LoadResolvedHierarchyNodeHierarchyActionAndSaga.action());

    if (internal) {
        yield put(loadSelectedCustomerIdActionAndSaga.action());
        yield put(loadCustomerListActionAndSaga.action());
    } else {
        // For non-internal user load the available login methods which can be used to display password
        // change page
        // Use jwt to get email of user because user information is probably not available yet
        yield put(loadPersonAccountActionAndSaga.action());
    }

    yield put(requestWebSocketConnectionAction({url: QualitizeOnlineWebSocketUrl}));
    yield put(enableOnlineCheck(CheckOnlineStateRoutes.QualitizeOnline));

    // Do not hide the app loader on finish, must be called in other part, e.g. other generator which calls this generator
}

export function* postLoginErrorGenerator(error: HttpError<SecurityErrorCode, any, any>) {
    if (error.errorCode === ErrorCodes.UNAUTHORIZED_ACCOUNT_DISABLED_TOO_MANY_FAILED_LOGIN_ATTEMPTS) {
        yield put(setUserBlocked(true));
    }
}

export const ExtendedBaseUserSaga = BaseUserSaga({
    additionalGenerator: {
        authenticate: {
            success: [postLoginGenerator],
            error: [postLoginErrorGenerator]
        },
        refreshToken: {
            success: [postRefreshGenerator]
        }
    },
    refreshTokenRoute: Route.RefreshToken,
    authenticationRoute: Route.Authenticate.ViaPassword,
    logoutRoutes: [Route.Logout, Route.ClearLegacyAccount],
});

export function* inactivityLogoutAfterInactiveBrowser(action: PayloadAction<SetBrowserTabActiveStatePayload>) {
    const durationInMillis: number = yield select(inactivityLogoutDurationInMsSelector);
    const tabId: string = yield select((state: AppState) => state.application.window.browserTab.id);
    if (durationInMillis > 0 && action.payload.active && tabId === action.payload.id) {
        const lastUserActivity: number = yield select((state: AppState) => state.user.lastActivity);
        // Check if the inactivity duration has been passed, then logout the user
        if (new Date().getTime() - lastUserActivity > durationInMillis) {
            yield put(logoutAction.action());
        }
    }
}

export function* InactivityLogoutAfterInactiveBrowserSaga() {
    yield takeEvery('application/setBrowserTabActiveState', inactivityLogoutAfterInactiveBrowser);
}

export function* inactivityModal(offset: number) {
    // Display the dialog with the hint
    yield put(setShowInactivityLogoutModal(true));
    // Restart a race for the offset and check if user has manually closed the dialog
    const {offsetDelay} = yield race({
        offsetDelay: delay(offset),
        offsetUserActivity: take('application/setShowInactivityLogoutModal')
    });
    if (offsetDelay) {
        // Offset delay has won the race, so force logout
        // Trigger the logout action in the main tab
        const mainTab: boolean = yield select((state: AppState) => state.application.window.browserTab.main);
        if (mainTab) {
            yield put(logoutAction.action());
        }
    } else {
        // Update the state to the last user activity
        yield put(setLastActivityTimestamp(new Date().getTime()));
    }
}

export function* inactivityLogout() {
    let initialDelay = 0;
    while (true) {
        const durationInMillis: number = yield select(inactivityLogoutDurationInMsSelector);
        if (durationInMillis > 0) {
            // The used delay is the duration in millis minus the maximum of one minute and a minimum of 25% of the complete duration
            // After that period an inactivity dialog should be shown to the user
            const offset = Math.min(60000, durationInMillis / 4);
            const usedDelay = durationInMillis - offset - initialDelay;
            const {raceDelay, browserTabBecameActive}: {
                raceDelay: number;
                browserTabBecameActive: PayloadAction<SetBrowserTabActiveStatePayload>;
            } = yield race({
                raceDelay: delay(usedDelay > 0 ? usedDelay : 0),
                userActivity: take(RegisterUserActivityActionKey),
                userActivityInOtherTab: take(RegisterUserActivityInOtherTabActionKey),
                browserTabBecameActive: take('application/setBrowserTabActiveState')
            });
            initialDelay = 0;
            if (browserTabBecameActive) {
                // Check if the inactivity duration has been passed, then logout the user
                const lastUserActivity: number = yield select((state: AppState) => state.user.lastActivity);
                const delta = new Date().getTime() - lastUserActivity;
                // Check if delta is already greater than the complete duration in millis, than instant logout of the user
                if (delta >= durationInMillis) {
                    yield put(logoutAction.action());
                    break;
                } else if (delta >= (durationInMillis - offset)) {
                    // Delta is already greater than the used delay, so display the dialog and start a race for the remaining
                    yield call(inactivityModal, offset - (delta - usedDelay));
                } else {
                    // Restart the saga with a new initial delay offset
                    initialDelay = delta;
                }
            } else if (!raceDelay && !browserTabBecameActive) {
                // Update the state to the last user activity
                yield put(setLastActivityTimestamp(new Date().getTime()));
            } else if (raceDelay) {
                yield call(inactivityModal, offset);
            }
        } else {
            // Cannot handle the duration string, stop the inactivity check
            break;
        }
    }
}

export function* InactivityLogoutSaga() {
    yield takeLatest(EnableUserLogoutOnInactivityActionKey, inactivityLogout)
}

/**
 * Default export, spawns all user generator functions. Uses spawn to avoid complete saga crash if an error occures.
 */
const UserSaga = function* (): Generator<any, void, void> {
    yield call(spawnSagas([
        {generator: ExtendedBaseUserSaga},
        {generator: updateAccountSaga},
        {generator: updatePasswordSaga},
        {generator: loadAvailableSitesSaga},
        {generator: changeDateTimeLocaleSaga},
        {generator: changeCustomerSaga},
        {generator: validateLoginTokenSaga},
        {generator: loadCustomerListActionAndSaga.saga},
        {generator: loadUserAccessActionAndSaga.saga},
        {generator: loadSelectedCustomerIdActionAndSaga.saga},
        {generator: loadUserInfoActionAndSaga.saga},
        {generator: loadAccountActionAndSaga.saga},
        {generator: validateResetPasswordTokenActionAndSaga.saga},
        {generator: invalidateResetPasswordTokenActionAndSaga.saga},
        {generator: requestLoginTokenActionAndSaga.saga},
        {generator: invalidatePasswordLessLoginTokenActionAndSaga.saga},
        {generator: loadAvailableLoginMethodsActionAndSaga.saga},
        {generator: authenticateViaLoginTokenSaga},
        {generator: setPasswordViaRegistrationTokenActionAndSaga.saga},
        {generator: validateRegistrationTokenActionAndSaga.saga},
        {generator: resetPasswordActionAndSaga.saga},
        {generator: requestPasswordMailActionAndSaga.saga},
        {generator: getEmailViaLoginTokenActionAndSaga.saga},
        {generator: loadPersonAccountActionAndSaga.saga},
        {generator: LoadResolvedHierarchyNodeHierarchyActionAndSaga.saga},
        {generator: LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga.saga},
        {generator: ValidateOIDCLoginActionAndSaga.saga},
        {generator: InvalidateOIDCAuthorizationCodeActionAndSaga.saga},
        {generator: InactivityLogoutSaga},
        {generator: InactivityLogoutAfterInactiveBrowserSaga},
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}

export default UserSaga;