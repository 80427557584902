import {denormalize, logger, wait} from '@software/reactcommons';
import {Store} from 'redux';
import {
    RequestWebSocketConnection,
    requestWebSocketConnectionAction,
    RequestWebSocketConnectionActionPayload,
    SendWebSocketMessage,
    SendWebSocketMessageActionPayload,
    webSocketConnectionClosed,
    webSocketConnectionOpened,
    WebSocketMessage
} from '../reducer/application/actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {AppState} from '../../types/Types';
import log from 'loglevel';
import {logoutAction, refreshTokenAction} from '@software/reactcommons-security';
import {setSelectedCustomerIdAction} from '../reducer/user/actions';

/**
 * Redux middleware which connects the web app via web socket to the server and informs the app about messages which have been sent via web socket from the server to the app.
 *
 * Inspired by {@link https://exec64.co.uk/blog/websockets_with_redux/}
 */

const webSocketMiddleware = ((): any => {

    let sockets: Record<string, WebSocket> = {};
    let retries = 0;

    window.onbeforeunload = () => {
        // Try to close the sockets on window closing
        denormalize(sockets).forEach(it => it.close());
    }

    /**
     * Method which will be called when the web socket connection is opened.
     */
    const onOpen = (url: string, store: Store<AppState>) => (e: Event) => {
        // Authenticate the connection
        retries = 0;
        //
        store.dispatch(webSocketConnectionOpened(url));
    };

    /**
     * Method which will be called when the web socket connection is closed.
     *
     * @param url The url to which the connection should be re-opened.
     * @param store The redux store.
     */
    const onClose = (url: string, store: Store<AppState>) => async () => {
        // Tell the store we've disconnected
        store.dispatch(webSocketConnectionClosed(url));
        // If there are less than 100 retries, request another connection
        if (retries < 10 && Boolean(store.getState().user.info.jwt)) {
            delete sockets[url];
            logger.debug(`Waiting ${Math.pow(2, retries)}s to retry open web socket connection`);
            await wait(1000 * Math.pow(2, retries));
            retries++;
            store.dispatch(requestWebSocketConnectionAction({
                url
            }));
        }
    };

    /**
     * Method which will be called when a message has been received via web socket.
     *
     * @param store The redux store.
     */
    const onMessage = (store: Store<AppState>) => (evt: MessageEvent) => {
        // Parse the message into JSON Object and tell the store, that a message has been received via web socket.
        try {
            const message: WebSocketMessage = JSON.parse(evt.data);
            // Enrich message with user
            message.user = store.getState().user;
            // Convert message to redux action
            store.dispatch({type: message.type.toString(), payload: message});
        } catch (e) {
            logger.error('Could not parse WebSocket message!', e);
        }
    };

    // Define the middleware.
    return (store: Store<AppState>) => (next: (action: PayloadAction<any>) => void) => (action: PayloadAction<RequestWebSocketConnectionActionPayload> | PayloadAction<SendWebSocketMessageActionPayload> | any) => {
        // Switch the action types and check, if it is relevant for the web socket middleware
        switch (action.type) {
            case RequestWebSocketConnection: {
                const {payload} = (action as PayloadAction<RequestWebSocketConnectionActionPayload>);
                if (!sockets[payload.url]) {
                    const socket = new WebSocket(payload.url);
                    socket.onmessage = onMessage(store);
                    socket.onopen = onOpen(payload.url, store);
                    socket.onerror = log.error;
                    socket.onclose = onClose(payload.url, store);
                    sockets[payload.url] = socket;
                }
                break;
            }
            case SendWebSocketMessage:
                // Send message to all open sockets.
                const {payload} = (action as PayloadAction<SendWebSocketMessageActionPayload>);
                if (payload.url?.trim()) {
                    const socket = sockets[payload.url.trim()];
                    if (socket?.readyState === 1) {
                        socket.send(JSON.stringify(payload.body));
                    } else {
                        log.error(`Could not send message via socket because socket for url is not ready.`);
                    }
                } else {
                    denormalize(sockets).filter(it => it.readyState === 1).forEach(socket => socket.send(JSON.stringify(payload.body)));
                }
                break;
            case refreshTokenAction.successKey:
            case refreshTokenAction.errorKey:
            case logoutAction.actionKey:
            case setSelectedCustomerIdAction.successKey:
            case setSelectedCustomerIdAction.errorKey:
                // On refresh of access token or on logout, close the web socket connections. If refresh token action
                // was successful, the connection will be automatically restored.
                denormalize<WebSocket>(sockets).forEach(it => it.close());
                return next(action);
            default:
                return next(action);
        }

    }

})();

export default webSocketMiddleware;
