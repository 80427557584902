import React from 'react';
import {Menu as CommonMenu, MenuLink, MobileBreakpoint, useLocalization} from '@software/reactcommons';
import {useSelector} from 'react-redux';
import {AppState} from '../../../types/Types';
import {getMenuElementForRoute} from '../../routing/routesToIcons';
import {Messages} from '../../../localization/localization';
import Routes, {RouteType} from '../../../constants/routes';
import {useMediaQuery} from '@mui/material';
import {Theme} from '@mui/material/styles';

const availableRoutes: string[] = Object.keys(Routes).map(it => {
    const value = (Routes as any)[it] as RouteType;
    if (typeof value === 'function') {
        return value('') as string;
    }
    return value as string;
});

/**
 * Menu component. Wrapper component around the menu component of reactcommons library.
 * It automatically filters the basic routes from the state to generate the menu elements.
 * On mobile devices, the administration route is filtered since these links will be handled in the user menu.
 *
 * @component
 * @category Menu
 *
 * @returns The rendered menu component.
 */
const Menu: React.FC = () => {

    const messages = useLocalization<Messages>();
    const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down(MobileBreakpoint));
    const availableSites = useSelector((state: AppState) => state.user.availableSites);

    return (
        <CommonMenu
            links={availableSites.filter(it => !isMobile || !it.route.startsWith((Routes.AdministrationBase as (path: string) => string)(''))).map<MenuLink>(it => ({
                link: it.route,
                menuElement: getMenuElementForRoute(it.route, messages)
            }))} availableRoutes={availableRoutes}/>);
}

export default Menu;