import {baseMessages, BaseMessages, DragAreaTextElement, Language} from '@software/reactcommons';
import {GlobalStrings} from 'react-localization';
import {LocationSetupStatus, WeekDay} from '../redux/reducer/administration/types';
import {ReportScopeType, ReportTimeScopeType, ReportTimeSpanType} from '../redux/reducer/reports/types';
import {ToDoInterval} from '../redux/reducer/operations/types';
import {ArchiveVisualizationType} from '../types/operations/operationsTypes';
import {Sentiment} from '../types/charts/chartTypes';
import {ComparisonGrouping, PeriodGrouping, RankingOrder} from '../redux/reducer/dashboard/types';
import {RankingType} from '../components/sites/feedback/dashboard/elements/types';
import {
    LandingPageIconThemeType,
    LandingPageLayoutBackgroundType,
    LandingPageLayoutComplexButtonContentType,
    LandingPageLayoutComplexButtonHoverType,
    LandingPageLayoutRowType,
    LandingPageLayoutTextButtonConfigurationTab,
    LandingPageLayoutTextButtonHoverType,
    LandingPageLinkType,
    LandingPageReleaseStateType
} from '../redux/reducer/landingPages/types';
import {
    DefaultLandingPageLocalization, LandingPageAppStoreType,
    LandingPageConfigType,
    LandingPageFontWeightClass,
    LandingPageIcon,
    LandingPageMessages,
    LandingPageTextSizeType
} from '@software/landingpageui';
import {
    AvailableLoginAuditTimeSpan,
    PublicLoginMethodType, TemporarilyDisabledReasonType,
    TemporarilyDisabledStateType
} from '../redux/reducer/administration/actions';
import {LoginPasswordRuleType} from '@software/reactcommons-security';

export interface InputLabel {
    label: string;
    placeholder: string;
}

interface IftaMessage {
    label: string;
    placeholder: string;
}

interface DescriptionElement {
    name: string;
    description: string;
}

export interface Messages extends BaseMessages, LandingPageMessages {
    app: {
        title: string;
        offline: string;
    };
    general: {
        close: string;
        open: string;
        cancel: string;
        select: string;
        save: string;
        download: string;
        delete: string;
        next: string;
        back: string;
    };
    connection: {
        offline: string;
        online: string;
        notifications: {
            online: string;
            offline: string;
            backendOffline: string;
        };
    };
    languages: {
        at: string;
        ba: string;
        be: string;
        bg: string;
        ch: string;
        cs: string;
        de: string;
        da: string;
        fi: string;
        fr: string;
        gb: string;
        el: string;
        hr: string;
        hu: string;
        is: string;
        it: string;
        lt: string;
        lb: string;
        lv: string;
        mk: string;
        nl: string;
        no: string;
        pl: string;
        ro: string;
        ru: string;
        sv: string;
        sl: string;
        sk: string;
    };
    sort: {
        pageSize: string;
        all: string;
        search: string;
        rowCount: string;
    };
    legal: {
        legalNotice: {
            title: string;
        };
        privacy: {
            title: string;
        };
    };
    forms: {
        input: {
            number: {
                invalid: string;
            };
        };
        noOptions: string;
    };
    login: {
        slider: {
            welcome: {
                title: string;
            };
        };
        title: string;
        caption: string;
        buttons: {
            continue: string;
        };
        password: {
            title: string;
        };
        blocked: {
            title: string;
        };
        passwordLess: {
            title: string;
            help: {
                title: string;
                button: string;
                explanation: string;
                close: string;
                howItWorks: {
                    title: string;
                    description: string;
                };
                doNotClose: string;
            };
            request: string;
            success: {
                message: string;
                submitLogin: string;
            };
            invalidate: {
                success: string;
            };
            alreadyActiveToken: {
                button: string;
                dialog: {
                    title: string;
                    content: string;
                    submit: string;
                };
            };
            backToLogin: string;
            error: {
                tokenExpired: string;
                invalidBrowser: string;
                tooManyTokens: string;
                alreadyTokenActive: string;
                unknown: string;
            };
        };
        methods: {
            divider: string;
        };
        loggedIn: {
            title: string;
            keepLoggedIn: string;
            reLogin: string;
        };
        oidc: {
            error: string;
            accessDeniedError: string;
            backToLogin: string;
        };
        inactivityLogout: {
            dialog: {
                title: string;
                description: string;
                submit: string;
                cancel: string;
            };
            menu: {
                description: string;
                durationInMinutesAndSeconds: string;
                durationInMinutes: string;
                durationInSeconds: string;
            };
        };
    };
    passwordReset: {
        button: string;
        title: string;
        description: string;
        success: string;
        invalidate: {
            success: string;
        };
        error: {
            expired: string;
            invalidBrowser: string;
        };
    };
    register: {
        button: string;
        title: string;
        subtitle: string;
        description: string;
        success: string;
        expired: string;
    };
    user: {
        logout: string;
        hierarchy: {
            search: InputLabel;
        };
        login: {
            title: string;
            success: string;
            keepLoggedIn: string;
            errors: {
                invalidInput: string;
                missingInput: string;
                invalidMail: string;
            };
            forgot: {
                title: string;
                button: string;
                caption: string;
                description: string;
                toLogin: string;
                error: string;
                success: string;
            };
        };
        username: {
            label: string;
            placeholder: string;
        };
        password: {
            label: string;
            placeholder: string;
        };
    };
    settings: {
        items: {
            video: string;
            masterData: string;
            accounts: string;
            terminals: string;
        };
        account: {
            personalData: {
                title: string;
                subLine: string;
                gender: {
                    label: InputLabel;
                    male: string;
                    female: string;
                };
                firstName: InputLabel;
                lastName: InputLabel;
                email: InputLabel;
                emailDomain: InputLabel;
                phone: InputLabel;
                submit: string;
                update: {
                    success: string;
                    error: string;
                }
            };
            password: {
                title: string;
                subLine: string;
                old: InputLabel;
                new: InputLabel;
                confirm: InputLabel;
                set: {
                    password: InputLabel;
                    confirm: InputLabel;
                };
                submit: string;
                update: {
                    success: string;
                    error: {
                        mismatch: string;
                        empty: string;
                        same: string;
                        general: string
                    };
                };
            };
        };
        videos: {
            title: string;
            subLine: string;
            highlight: {
                success: string;
                error: string;
            };
            statistics: {
                headline: string;
                overview: string;
                thumbnail: string;
                title: string;
                authorCreator: string;
                author: string;
                creator: string;
                reactions: string;
                views: string;
                dates: string;
                created: string;
                updated: string;
                actions: string;
                totalViews: string;
            };
        };
    };
    administration: {
        locationSelection: {
            button: string;
        },
        openingHours: {
            locationSelection: string;
            table: {
                header: {
                    weekDay: string;
                    start: string;
                    end: string;
                    dayOfRest: string;
                    changeMultiple: string;
                    changeSingle: string;
                    changeAll: string;
                };
                alwaysOpen: string;
                weekDays: {
                    [WeekDay.None]: string;
                    [WeekDay.Monday]: string;
                    [WeekDay.Tuesday]: string;
                    [WeekDay.Wednesday]: string;
                    [WeekDay.Thursday]: string;
                    [WeekDay.Friday]: string;
                    [WeekDay.Saturday]: string;
                    [WeekDay.Sunday]: string;
                };
            };
            dialog: {
                title: string;
                search: InputLabel;
                subTitle: string;
            };
            actions: {
                save: string;
                saveMultiple: string;
                saveSingle: string;
                cancel: string;
                openDialog: {
                    desktop: string;
                    mobile: string;
                };
            };
            notifications: {
                success: string;
                error: string;
            };
        };
        locations: {
            state: {
                [LocationSetupStatus.NO_TABLET]: string;
                [LocationSetupStatus.SETUP]: string;
                [LocationSetupStatus.ACTIVE]: string;
                [LocationSetupStatus.INACTIVE]: string;
                [LocationSetupStatus.TEMP_CLOSED]: string;
                [LocationSetupStatus.TERMINATED]: string;
                [LocationSetupStatus.TEARDOWN]: string;
            }
        };
        terminals: {
            state: {
                all: string;
                offline: string;
                online: string;
            };
            table: {
                header: {
                    location: string;
                    terminalLocation: string;
                    pinCode: string;
                    connectionMethod: string;
                    id: string;
                    terminalState: string;
                    locationState: string;
                };
                actions: {
                    support: string;
                    supportStatus: string;
                }
            };
            filter: {
                showAllLocations: string;
                onlineStates: string;
                noResults: string;
            };
            notifications: {
                online: string;
            };
            dialogs: {
                incident: {
                    title: string;
                    inputs: {
                        contactPerson: InputLabel;
                        contactEmail: InputLabel;
                        contactPhone: InputLabel;
                        comment: InputLabel;
                        problems: InputLabel;
                    };
                    attachments: {
                        title: string;
                        description: string;
                        upload: {
                            title: string;
                            acceptedFileTypes: string;
                            illegalFileType: string;
                        },
                    };
                    actions: {
                        submit: string;
                        cancel: string;
                    };
                    messages: {
                        success: string;
                        error: string;
                    };
                };
                ticket: {
                    title: string;
                    add: string;
                    hide: string;
                    problems: string;
                    attachments: string;
                    notifications: {
                        success: string;
                        error: string;
                    };
                };
            };
        };
        stickyEditor: {
          pageTitle: string;
          zoomIn: string;
          zoomOut: string;
          stepper: {
              design: string;
              timeframe: string;
              location: string;
          };
          designStep: {
              description: string;
              colorInput: string;
              textInputLabel: string;
              textInputPlaceholder: string;
              textInputDescription: string;
          };
          colors: {
              yellow: string;
              pink: string;
              white: string;
          };
          timeframeStep: {
              description: string;
              startDate: string;
              endDate: string;
              endDateLabel: string;
              checkboxLabel: string;
          };
          locationStep: {
              descriptionGeneral: string;
              descriptionSpecific: string;
              descriptionOneLocation: string;
              selectAll: string;
              searchPlaceholder: string;
              noLocationFound: string;
          };
          archiveTitle: string;
          allLocations: string;
          undefinedEndDate: string;
          chipActive: string;
          chipInactive: string;
          archiveItems : {
              regionalManagedPlaceholder: string;
              regionalManagedTooltip: string;
              and: string;
              remainingCount: string;
              remainingLocationsTooltip: string;
          },
          actionMenu: {
              editText: string;
              deactivateSticky: string;
              reactivateSticky: string;
              deleteSticky: string;
          };
          popup: {
              editTextTitle: string;
              submitChanges: string;
              saveMessage: string;
              deleteMessage: string;
          },
            notifications: {
                SaveSuccess: string;
                SaveError: string;
                DeleteSuccess: string;
                DeleteError: string;
                EditSuccess: string;
                EditError: string;
            },
        };
        accounts: {
            management: {
                title: string;
                topBar: {
                    search: {
                        placeholder: string;
                    };
                    stateFilter: {
                        active: string;
                        temporarilyDisabled: string;
                        all: string;
                    };
                    timeFilter: {
                        title: string;
                        description: string;
                        selectDate: string;
                        createdFrom: string;
                        createdUntil: string;
                        resetDate: string;
                        submit: string;
                        submitMobile: string;
                        cancel: string;
                        cancelMobile: string;
                    };
                    export: {
                        button: string;
                        buttonSelection: string;
                        notifications: {
                            success: string;
                            error: string;
                        };
                    };
                };
                state: Record<TemporarilyDisabledStateType, string>;
                disabledReason: Record<TemporarilyDisabledReasonType, string>;
                reEnableDate: string;
                table: {
                    header: {
                        username: string;
                        authenticationConfiguration: string;
                        roleConfiguration: string;
                        loginMethodConfiguration: string;
                        securityRuleConfiguration: string;
                        createdAt: string;
                        disabledState: string;
                        actions: string;
                    };
                    footer: {
                        rows: string;
                    };
                    loginMethods: {
                        title: string;
                        methods: Record<PublicLoginMethodType, string>;
                        none: string;
                        failureState: string;
                    };
                    securityRules: {
                        title: string;
                        fromPreset: string;
                        none: string;
                    };
                    roles: {
                        title: string;
                        explicit: string;
                        fromPresets: string;
                        none: string;
                    };
                    authenticationConfiguration: {
                        explicit: string;
                        none: string;
                        tooltip: string;
                        dialog: {
                            title: string;
                        };
                    };
                    actions: {
                        disable: {
                            title: string;
                            mobileTitle: string;
                            description: string;
                            submit: string;
                            notifications: {
                                success: string;
                                error: string;
                            };
                            reason: InputLabel;
                            reEnableDescription: string;
                            noReEnableDate: string;
                            resetReEnableDate: string;
                        };
                        enable: {
                            title: string;
                            mobileTitle: string;
                            description: string;
                            submit: string;
                            submitMobile: string;
                            notifications: {
                                success: string;
                                error: string;
                            };
                        };
                        deactivate: {
                            title: string;
                            mobileTitle: string;
                            description: string;
                            submit: string;
                            notifications: {
                                success: string;
                                error: string;
                            };
                        };
                    };
                };
                passwordRule: Record<LoginPasswordRuleType, string>;
                defaultPasswordRule: string;
                dictionaryPasswordRule: {
                    extendDefault: {
                        withCustomPasswords: string;
                        withoutCustomPasswords: string;
                    };
                    custom: {
                        withCustomPasswords: string;
                        withoutCustomPasswords: string;
                    };
                };
                profiles: {
                    title: string;
                    emptyState: string;
                };
                loginAudit: {
                    title: string;
                    emptyState: string;
                    topBar: {
                        timeSpan: {
                            type: Record<AvailableLoginAuditTimeSpan, string>;
                        };
                    };
                    download: {
                        button: string;
                        filename: string;
                    };
                    table: {
                        header: {
                            timestamp: string;
                            username: string;
                            loginActivity: string;
                            loginMethod: string;
                            reason: string;
                        };
                        footer: {
                            singular: string;
                            plural: string;
                        };
                        loginActivity: {
                            type: {
                                success: string;
                                failure: string;
                            };
                        };
                    };
                };
            };
        };
    };
    menu: {
        user: {
            profile: string;
            password: string;
            setPassword: string;
            logout: string;
        };
        feedback: {
            title: string;
            reports: string;
        };
        administration: {
            openingHours: string;
            legacyStickies: string;
            legacyDepartments: string;
            terminals: string;
            qrCodes: string;
            accounts: string;
            accountProfiles: string;
            accountLoginAuditLog: string;
        };
        dashboard: string;
        toDos: string;
        landingPages: string;
        operations: string;
        videos: string;
        departments: string;
        stickyEditor: string;
        openingHours: string;
        settings: string;
        customers: {
            select: InputLabel;
        };
    };
    calendar: {
        week: {
            short: string;
        };
        time: {
            description: string;
        };
    };
    icons: {
        description: string;
    };
    threshold: {
        description: string;
    };
    switch: {
        active: string;
        inactive: string;
    };
    chart: {
        empty: string;
        tooFewRespondents: string;
    };
    dashboard: {
        load: {
            active: string;
            chart: {
                error: string;
            };
        };
        period: {
            clock: string;
            predefined: string;
            customDefined: string;
            select: {
                description: string;
                lastDay: string;
                week: string;
                month: string;
                calendarWeek: string;
                total: string;
                custom: string;
                today: string;
                day: string;
                quarter: string;
                yearToDate: string;
                lastYear: string;
            };
            comparison: {
                title: string;
                description: string;
                none: string;
                automatic: string;
                previousDay: string;
                previousWeek: string;
                previousMonth: string;
                previousQuarter: string;
                previousYear: string;
                previousTotal: string;
                day: string;
                week: string;
                month: string;
                quarter: string;
                year: string;
                yearToDate: string;
                custom: string;
                customDay: string;
                total: string;
            }
        };
        element: {
            noValue: string;
            numberOfRespondents: string;
            notApplicableCount: string;
            question: string;
            actions: {
                export: {
                    download: {
                        notifications: {
                            success: string;
                            error: string;
                        };
                        started: string;
                        navigate: string;
                        description: string;
                        triggerDownload: string;
                    };
                    mail: {
                        notifications: {
                            success: string;
                            error: string;
                        };
                    };
                };
            };
            table: {
                expand: {
                    showAll: string;
                    hideAll: string;
                };
            };
        };
        config: {
            element: {
                name: string;
                lastSync: string
            };
            feedbackCollectionPoint: string;
            questions: string;
            chart: {
                description: string;
                type: {
                    barChart: {
                        description: string;
                        horizontal: string;
                        stacked: string;
                        type: string;
                    };
                    lineChart: {
                        description: string;
                        interpolation: string;
                        filter: {
                            periodGrouping: {
                                name: string;
                                elements: Record<PeriodGrouping, string>;
                            };
                        };
                    };
                    gauge: {
                        description: string;
                        type: string;
                        customerIndex: string;
                        nps: string;
                    };
                    heatMap: {
                        description: string;
                    };
                    stat: {
                        description: string;
                        type: string;
                        number: string;
                        average: string;
                        percentage: string;
                        nps: string;
                    };
                    text: {
                        description: string;
                        filter: {
                            rating: {
                                name: string;
                                elements: Record<Sentiment, string>;
                            };
                            category: {
                                name: string;
                            };
                            search: {
                                input: InputLabel;
                            };
                        };
                        detail: {
                            show: string;
                            hide: string;
                            empty: string;
                            showOriginal: string;
                            hideOriginal: string;
                        };
                    };
                    image: {
                        three60: {
                            preparing: string;
                        }
                    };
                    table: {
                        includeOnlyFinishedFeedback: string;
                        empty: string;
                        search: {
                            placeholder: string
                        };
                    };
                };
            };
        };
        settings: {
            filter: {
                more: string;
                active: string;
            };
            global: {
                reload: {
                    desktop: string;
                    mobile: string;
                    last: string;
                };
                filter: {
                    placeholder: string;
                    desktop: string;
                    mobile: string;
                    apply: {
                        desktop: string;
                        mobile: string
                    };
                    tooltip: {
                        title: string;
                        numberOfAdditionalElements: string;
                    };
                    reset: string;
                };
                top: {
                    desktop: string;
                    mobile: string;
                    apply: {
                        desktop: string;
                        mobile: string;
                    }
                };
                period: {
                    desktop: string;
                    mobile: string;
                };
                comparisonPeriod: {
                    desktop: string;
                    mobile: string;
                };
                live: string;
            }
        }
    };
    feedback: {
        overview: {
            dashboards: {
                headline: string;
                more: string;
                open: string;
                all: string;
                lastDays: {
                    singular: string;
                    plural: string;
                }
            }
        };
        sidebar: {
            dashboards: {
                more: string;
                less: string;
            };
        };
        locationComparison: {
            map: {
                headline: string;
                detail: string;
            };
            podium: {
                headline: string;
            };
            ranking: Record<RankingType, DescriptionElement>;
            rankingOrder: {
                elements: Record<RankingOrder, string>;
                title: string;
            };
            grouping: {
                title: string;
                elements: Record<ComparisonGrouping, string>;
            };
            evaluationType: string;
            rank: {
                title: string;
                detail: {
                    open: string;
                };
                table: {
                    header: {
                        rank: string;
                        location: string;
                        value: string;
                        respondents: string;
                        difference: string;
                    };
                };
            };
            calculation: {
                base: string;
            },
            question: {
                shortName: string,
            },
            period: {
                description: string;
                comparison: string;
            };
            promotedDemoted: {
                promoted: {
                    title: string;
                    none: string;
                };
                demoted: {
                    title: string;
                    none: string;
                };
            };
        };
    };
    videos: {
        title: string;
        categories: {
            headline: string;
            sidebar: {
                headline: string;
                expand: string;
                collapse: string;
                allCategories: {
                    headline: string;
                    categories: string
                };
                playlists: {
                    headline: string;
                };
            };
            filter: {
                apply: string;
                delete: string;
            };
        };
        dashboard: {
            title: string;
            welcome: {
                description: string;
            };
            tags: {
                highlights: string;
            };
            search: {
                headline: string;
                placeholder: string;
            };
            sections: {
                mostSeen: string;
                latest: string;
                popular: string;
            };
            categories: {
                headline: string;
            };
            wishes: {
                headline: string;
                button: string;
                all: string;
            };
            allVideos: string;
            empty: string;
            back: string;
            admin: {
                settings: {
                    title: string;
                    edit: string;
                    deactivate: {
                        title: string;
                        headline: string;
                        content: string;
                        cancel: string;
                        submit: string;
                        success: string;
                        error: string;
                    };
                    activate: {
                        title: string;
                        headline: string;
                        content: string;
                        cancel: string;
                        submit: string;
                        success: string;
                        error: string;
                    };
                    delete: {
                        title: string;
                        headline: string;
                        content: string;
                        cancel: string;
                        submit: string;
                        success: string;
                        error: string;
                    };
                };
            };
            upload: {
                link: string;
                title: string;
                headline: string;
                caption: string;
                cancel: string;
                dragAndDrop: {
                    title: string;
                    subTitle: string;
                    illegalFileType: string;
                    illegalFileTypeDescription: string;
                    here: string;
                };
                dragOver: string;
                tags: string;
                wishes: string;
                categories: {
                    title: string;
                    noOption: string;
                    create: string;
                };
                create: string;
                video: {
                    title: string;
                    description: string;
                    creator: string;
                    acceptedFileTypes: string;
                    upload: {
                        active: string;
                        preparing: {
                            title: string;
                            subLine: string
                        };
                        save: {
                            title: string;
                            active: string;
                            success: string;
                            error: string;
                            link: string;
                        };
                    };
                    edit: {
                        save: string;
                        cancel: string;
                        success: string
                    };
                };
            };
            video: {
                creator: string;
                length: string;
                category: string;
                categories: string;
                description: {
                    title: string;
                    more: string;
                    less: string;
                };
                backToDashboard: string;
                deactivated: string;
            };
            loading: {
                title: string;
            };
            highlights: {
                title: string;
                author: string;
            };
        };
        wishList: {
            link: string;
            headline: string;
            popular: string;
            latest: string;
            finished: string;
            wish: {
                creator: string;
                more: string;
                less: string;
                create: {
                    headline: string;
                    headlineMobile: string;
                    title: InputLabel;
                    description: InputLabel;
                    explanation: string;
                    submit: string;
                    submitMobile: string;
                    cancel: string;
                    button: string;
                    success: string;
                    errors: {
                        missing: string;
                        server: string;
                    };
                };
                finished: {
                    link: string;
                    author: string;
                };
            };
        };
        video: {
            views: string;
            creator: string;
            actions: {
                create: string;
            };
            reactions: {
                reactor: {
                    mobile: {
                        single: string;
                        singular: string;
                        plural: string;
                        empty: string;
                    };
                    heart: {
                        single: string;
                        singular: string;
                        plural: string;
                        empty: string;
                    };
                    like: {
                        single: string;
                        singular: string;
                        plural: string;
                        empty: string;
                    };
                    clap: {
                        single: string;
                        singular: string;
                        plural: string;
                        empty: string;
                    };
                    you: string;
                };
            };
            comments: {
                numberOfComments: string;
                me: string;
                delete: {
                    button: string;
                    title: string;
                    content: string;
                    submit: string;
                    cancel: string;
                    error: string;
                    success: string;
                    deleted: string;
                    deletedByCreator: string;
                    deletedByAdmin: string;
                };
                input: {
                    placeholder: string;
                    submit: string;
                    error: string;
                };
                children: {
                    show: string;
                    hide: string;
                };
                answers: {
                    button: string;
                    cancel: string;
                };
                time: {
                    justNow: string;
                    minutes: {
                        singular: string;
                        plural: string;
                    };
                    hours: {
                        singular: string;
                        plural: string;
                    };
                    days: {
                        singular: string;
                        plural: string;
                    };
                    weeks: {
                        singular: string;
                        plural: string;
                    };
                    months: {
                        singular: string;
                        plural: string;
                    };
                    years: {
                        singular: string;
                        plural: string;
                    };
                };
            };
            player: {
                settings: {
                    quality: string;
                    speed: string;
                };
            };
            highlight: {
                remove: {
                    description: string;
                    success: string;
                    error: string;
                };
                add: {
                    description: string;
                    success: string;
                    error: string;
                };
            };
        };
        playlists: {
            manage: {
                headline: string;
                image: {
                    headline: {
                        default: string;
                        active: string;
                        illegalFileType: string;
                    };
                    subLine: {
                        default: string;
                        active: string;
                        illegalFileType: string;
                    };
                    acceptedFileTypes: string;
                };
                title: IftaMessage;
                subtitle: IftaMessage;
                description: IftaMessage;
                visibility: IftaMessage;
                curate: IftaMessage;
                tags: IftaMessage;
                sections: {
                    add: string;
                    title: IftaMessage;
                    video: {
                        add: string;
                    };
                };
                videos: {
                    manage: {
                        headline: string;
                        description: string;
                        descriptionWithoutSectionTitle: string;
                        preview: string;
                        title: string;
                        author: string;
                        date: string;
                        selected: string;
                        cancel: string;
                        submit: string;
                        search: string;
                    };
                };
                actions: {
                    delete: string;
                    back: string;
                    update: string;
                    create: string;
                };
                update: {
                    error: string;
                    success: string;
                };
                menu: {
                    edit: string;
                    curate: string;
                    uncurate: string;
                    delete: string;
                    deactivate: string;
                    activate: string;
                };
                highlight: {
                    success: string;
                    error: string;
                };
                unhighlight: {
                    success: string;
                    error: string;
                };
                enable: {
                    success: string;
                    error: string;
                    headline: string;
                    content: string;
                    cancel: string;
                    submit: string;
                };
                disable: {
                    success: string;
                    error: string;
                    headline: string;
                    content: string;
                    cancel: string;
                    submit: string;
                };
                delete: {
                    success: string;
                    error: string;
                    headline: string;
                    content: string;
                    cancel: string;
                    submit: string;
                };
            };
            create: {
                headline: string;
                error: string;
                success: string;
            };
            overview: {
                headline: string;
                subLine: string;
                numberOfVideos: string;
                numberOfMinutes: string;
            };
            detail: {
                title: string;
                playlist: string;
            };
            highlights: {
                title: string;
                mobileTitle: string;
                allPlaylists: string;
            };
        };
    };
    operations: {
        sidebar: {
            overview: string;
            actions: {
                addToDo: string;
            };
            moreToDos: {
                singular: string;
                plural: string;
            };
            nextToDos: {
                title: string;
                showMore: string;
                showLess: string;
            };
            reviewToDos: {
                title: string;
            };
            reviewRequests: {
                title: string;
            };
            toDosInReview: {
                title: string;
            };
            subordinated: {
                title: string;
            };
            subordinatedReviews: {
                title: string;
            };
            subordinatedRequestReviews: {
                title: string;
            };
            requests: {
                open: {
                    title: string;
                };
                approved: {
                    title: string;
                };
                expired: {
                    title: string;
                };
                declined: {
                    title: string;
                };
            };
            finishedToDos: {
                title: string;
                showMore: string;
                showLess: string;
            };
            dashboards: {
                title: string;
                showMore: string;
                showLess: string;
            };
        };
        subSidebar: {
            title: string;
        };
        overview: {
            showAll: string;
            showAllReviews: string;
            empty: string;
            resetFilter: string;
            archive: {
                title: string;
                description: string;
                empty: string;
            };
            title: {
                overview: string;
                pending: string;
                pendingWithGroup: string;
                finished: string;
            };
            requests: {
                title: string;
                pending: string;
                pendingWithGroup: string;
                edit: string;
                showAll: string;
            };
            approvedRequests: {
                title: string;
                showAll: string;
                show: string;
            };
            declinedRequests: {
                title: string;
                showAll: string;
                show: string;
            };
            expiredRequests: {
                title: string;
                showAll: string;
                show: string;
            };
            inReview: {
                title: string;
                description: string;
                empty: string;
                tooltip: {
                    title: string;
                };
            };
            requestsInReview: {
                title: string;
                description: string;
                empty: string;
                tooltip: {
                    title: string;
                };
            };
            userReview: {
                title: string;
                description: string;
                empty: string;
                actions: {
                    reviewer: {
                        button: string;
                        description: string;
                    };
                };
            };
            userReviewRequests: {
                title: string;
                description: string;
                empty: string;
                actions: {
                    reviewer: {
                        button: string;
                        description: string;
                    };
                };
            };
            otherToDos: {
                title: string;
                actions: string;
                description: string;
                empty: string;
            };
            otherReviews: {
                title: string;
                actions: string;
                description: string;
                empty: string;
            };
            otherReviewRequests: {
                title: string;
                actions: string;
                description: string;
                empty: string;
            };
            toDoGroup: {
                title: string;
            };
            requestGroup: {
                title: string;
            };
            toDo: {
                editor: {
                    title: string;
                    add: {
                        action: string;
                        dialog: {
                            title: string;
                            input: InputLabel;
                            noneSelected: string;
                            selectedEditors: string;
                            emptyResults: string;
                            actions: {
                                submit: string;
                                cancel: string;
                            };
                            success: string;
                            error: string;
                        };
                    };
                };
                start: string;
                search: IftaMessage;
                interval: Record<ToDoInterval, string>;
                saveAndContinueLater: {
                    button: string;
                    dialog: {
                        headline: string;
                        description: string;
                        confirm: string;
                        cancel: string;
                    };
                    success: string;
                    error: string;
                };
                info: {
                    completedTasks: string;
                    tasks: string;
                    completed: string;
                };
                noResults: string;
                none: string;
                until: string;
                pagination: {
                    description: string;
                };
                actions: {
                    view: {
                        button: string;
                    };
                    download: {
                        button: string;
                        buttonSelect: string;
                        buttonSingleSelect: string;
                        tableView: string;
                        defaultView: string;
                    };
                    reOpen: {
                        button: string;
                        dialog: {
                            title: string;
                            descriptions: string;
                            deadline: string;
                            submit: string;
                        };
                        notifications: {
                            success: string;
                            error: string;
                        };
                    };
                    review: {
                        show: string;
                        reOpen: string;
                    };
                    email: {
                        button: string;
                        buttonMobile: string;
                        buttonSingleSelect: string;
                        buttonSelect: string;
                        dialog: {
                            title: string;
                            subject: InputLabel;
                            text: InputLabel;
                            recipients: InputLabel;
                            defaultSubject: string;
                            defaultSubjectMultiple: string;
                            defaultGreeting: string;
                            attachment: string;
                            submit: string;
                            cancel: string;
                            success: string;
                            error: string;
                            search: {
                                loading: string;
                                noOptions: string;
                            };
                        };
                    };
                    open: string;
                    collapse: {
                        open: string;
                        close: string;
                    };
                    moreActions: string;
                    changeDeadline: {
                        button: string;
                        help: string;
                        dialog: {
                            title: string;
                            descriptions: string;
                            submit: string;
                        };
                        notifications: {
                            success: string;
                            error: string;
                        };
                    };
                    takeOverToDo: {
                        button: string;
                        help: string;
                    };
                };
            };
            review: {
                reviewer: {
                    title: string;
                    add: {
                        action: string;
                        dialog: {
                            title: string;
                            input: InputLabel;
                            noneSelected: string;
                            selectedEditors: string;
                            emptyResults: string;
                            actions: {
                                submit: string;
                                cancel: string;
                            };
                            success: string;
                            error: string;
                        };
                    };
                };
                actions: {
                    changeDeadline: {
                        button: string;
                        help: string;
                        dialog: {
                            title: string;
                            descriptions: string;
                            submit: string;
                        };
                        notifications: {
                            success: string;
                            error: string;
                        };
                    };
                    takeOverReview: {
                        button: string;
                        help: string;
                    };
                };
                approval: {
                    restrictedApprovalDate: string;
                };
            };
        };
        dialogs: {
            createToDoDialog: {
                title: string;
                submit: string;
                cancel: string;
                selectToDo: InputLabel;
                selectLocation: InputLabel;
                success: string;
                error: string;
                hint: {
                    description: string;
                    link: string;
                };
            };
        };
        topBar: {
            locations: {
                all: string;
                allLocations: string;
            };
            toDos: {
                button: string;
                all: string;
                allToDos: string;
            };
        };
        files: {
            preview: {
                notAvailable: string;
                fullscreen: string;
            };
        };
        edit: {
            files: {
                upload: {
                    headline: string;
                    progress: string;
                    preparing: string;
                    error: {
                        notification: string;
                        cancel: string;
                        retry: string;
                        uploadTooOld: string;
                    };
                    button: {
                        default: string;
                        more: string;
                        required: string;
                        multipleRequired: string;
                    };
                    dialog: {
                        delete: {
                            title: string;
                            content: string;
                            submit: string;
                            cancel: string;
                        };
                    };
                };
            };
            sync: {
                success: string;
                error: {
                    singular: string;
                    plural: string;
                };
                close: string;
            };
            text: {
                input: {
                    show: string;
                    hide: string;
                    placeholder: string;
                    remove: {
                        title: string;
                        description: string;
                        cancel: string;
                        submit: string;
                    };
                };
            };
            input: {
                placeholder: string;
                label: string;
            };
            questions: {
                boolean: {
                    default: {
                        true: string;
                        false: string;
                        notSpecified: string;
                    };
                    checkbox: {
                        active: string;
                        inactive: string;
                    };
                };
                numberInput: {
                    invalid: string;
                    invalidRange: {
                        lowerBound: string;
                        lowerBoundIncluded: string;
                        upperBound: string;
                        upperBoundIncluded: string;
                    };
                };
            };
            appendices: {
                actions: {
                    fullScreen: {
                        open: string;
                        show: string;
                        close: string;
                    };
                    download: string;
                };
            };
            actions: {
                next: {
                    desktop: string;
                    mobile: string;
                };
                resetTask: {
                    button: string;
                    dialog: {
                        title: string;
                        description: string;
                        submit: string;
                    };
                };
                start: string;
                view: string;
                previous: {
                    desktop: string;
                    mobile: string;
                };
                toggleTasks: {
                    show: string;
                    hide: string;
                };
                backToOverview: string;
                finish: {
                    button: {
                        text: string;
                        mobile: string;
                    };
                    description: string;
                    submit: string;
                    cancel: string;
                    success: string;
                    error: string;
                };
                review: {
                    description: string;
                    submit: string;
                    cancel: string;
                    success: string;
                    error: string;
                    title: string;
                    reviewer: string;
                    autoApprove: {
                        title: string;
                        description: string;
                        submit: string;
                        notifications: {
                            error: string;
                        };
                    };
                    autoDecline: {
                        title: string;
                        description: string;
                        submit: string;
                        notifications: {
                            error: string;
                        };
                    };
                };
                mails: {
                    trigger: string;
                };
                phone: {
                    trigger: string;
                };
                downloadFile: {
                    trigger: string;
                };
                link: {
                    open: string;
                };
            };
            state: {
                general: {
                    finished: string;
                    editing: string;
                    pending: string;
                    edited: string;
                    approved: string;
                    readOnly: string;
                    frozen: string;
                    optional: string;
                };
                tasks: {
                    pending: {
                        singular: string;
                        plural: string;
                    };
                    optional: string;
                };
            };
            previousToDos: {
                title: string;
                noPreviousAnswers: string;
            };
            reviews: {
                title: string;
            };
        };
        archive: {
            notEdited: string;
            files: {
                singular: string;
                plural: string;
                none: string;
            };
            table: {
                header: {
                    task: string;
                    answer: string;
                    remarks: string;
                    uploads: string;
                };
                export: {
                    page: string;
                };
            };
            topBar: {
                actions: {
                    button: string;
                };
            };
            view: Record<ArchiveVisualizationType, string>;
        };
        reviewRequest: {
            link: string;
        };
        review: {
            title: string;
            link: string;
            specification: {
                title: string;
            };
            declined: {
                reason: string;
                autoDeclined: string;
            };
            topBar: {
                progress: {
                    title: string;
                    tooltips: {
                        pending: {
                            singular: string;
                            plural: string;
                        };
                        disapproved: {
                            singular: string;
                            plural: string;
                        };
                        approved: {
                            singular: string;
                            plural: string;
                        };
                    };
                };
            };
            approval: {
                preview: string;
                title: string;
                task: string;
                approve: string;
                disapprove: string;
                zoom: {
                    description: string;
                    title: string;
                    on: string;
                    off: string;
                };
            };
            remarks: {
                title: string;
            };
            decline: {
                confirmationDialog: {
                    title: string;
                    description: string;
                    submit: string;
                    reason: InputLabel;
                    notifications: {
                        success: string;
                        error: string;
                    };
                };
            };
            finish: string;
            modal: {
                cancel: string;
                title: string;
                description: {
                    approved: string;
                    disapproved: string;
                };
                editors: string;
                deadline: {
                    description: string;
                    select: string;
                };
                timeRestriction: {
                    description: string;
                    descriptionOptional: string;
                    select: string;
                };
            };
            tasksToReview: {
                title: string;
            };
            reviewedTasks: {
                title: string;
                approved: {
                    title: string;
                    yes: string;
                    no: string;
                };
                reOpen: string;
            };
            finishedTasks: {
                title: string;
            };
            notifications: {
                success: string;
                error: string;
            };
            reOpen: {
                action: {
                    button: string;
                };
                modal: {
                    title: string;
                    description: string;
                    deadline: string;
                    submit: string;
                };
                notifications: {
                    success: string;
                    error: string;
                };
            };
        };
        search: {
            results: {
                searchString: string;
                locations: {
                    singular: string;
                    plural: string;
                };
                toDos: {
                    singular: string;
                    plural: string;
                };
            };
        };
    };
    reports: {
        timeScopes: {
            [ReportTimeScopeType.Day]: string;
            [ReportTimeScopeType.Week]: string;
            [ReportTimeScopeType.Month]: string;
            [ReportTimeScopeType.Total]: string;
            [ReportTimeScopeType.Custom]: string;
            [ReportTimeScopeType.Quarter]: string;
            [ReportTimeScopeType.Year]: string;
        };
        timeScopeDescription: {
            [ReportTimeScopeType.Day]: string;
            [ReportTimeScopeType.Week]: string;
            [ReportTimeScopeType.Month]: string;
            [ReportTimeScopeType.Total]: string;
            [ReportTimeScopeType.Custom]: string;
            [ReportTimeScopeType.Quarter]: string;
            [ReportTimeScopeType.Year]: string;
        };
        timeSpanDescription: {
            [ReportTimeSpanType.Day]: string;
            [ReportTimeSpanType.Week]: string;
            [ReportTimeSpanType.Month]: string;
            [ReportTimeSpanType.Total]: string;
            [ReportTimeSpanType.Custom]: string;
            [ReportTimeSpanType.Quarter]: string;
            [ReportTimeSpanType.Year]: string;
        },
        reportScope: {
            [ReportScopeType.Department]: string;
            [ReportScopeType.Location]: string;
            [ReportScopeType.Region]: string;
            [ReportScopeType.Central]: string;
        },
        reportScopeDescription: {
            [ReportScopeType.Department]: string;
            [ReportScopeType.Location]: string;
            [ReportScopeType.Region]: string;
            [ReportScopeType.Central]: string;
        },
        latest: {
            title: string;
        };
        actions: {
            download: string;
            fullscreen: string;
            closeDialog: string;
            detail: string;
        };
        topBar: {
            level: {
                title: string;
                all: string;
            };
            timeSpan: {
                title: string;
            };
            detail: {
                search: {
                    notFound: string;
                    results: string;
                    placeholder: string;
                };
            };
        };
        search: {
            title: string;
            noResults: string;
        };
        download: {
            success: {
                title: string;
                errorDescription: string;
                startDownload: string;
                description: string;
            };
            error: {
                title: string;
                description: string;
            };
            loginButton: string;
            archiveLink: string;
        };
        notFound: string;
    };
    landingPages: {
        empty: string;
        error: string;
        info: {
            createdAt: string;
            modifiedAt: string;
            type: string;
        };
        types: Record<LandingPageConfigType, string>;
        overview: {
            link: string;
            topBar: {
                hierarchyNodeSelection: {
                    empty: string;
                    selectNone: string;
                };
                search: {
                    placeholder: string;
                };
            };
            table: {
                header: {
                    hierarchyNode: string;
                    name: string;
                    type: string;
                    createdTimestamp: string;
                    modifiedTimestamp: string;
                    demo: string;
                    actions: string;
                };
            };
        };
        state: {
            type: Record<LandingPageReleaseStateType, string>;
            dialogs: {
                prerelease: {
                    title: string;
                    submit: string;
                    explanation: string;
                };
            };
        };
        preview: {
            title: string;
        };
        edit: {
            save: string;
            type: {
                dialog: {
                    confirmation: {
                        title: string;
                        description: string;
                        submit: string;
                    };
                };
            };
            cards: {
                stepper: string;
                preview: string;
            };
            topBar: {
                title: string;
            };
            links: {
                title: string;
                variants: Record<LandingPageLinkType, string>;
                explicit: {
                    input: InputLabel;
                    empty: string;
                    select: string;
                };
                feedback: {
                    title: string;
                };
            };
            steps: {
                step1: {
                    title: string;
                    submit: {
                        edit: string;
                        create: string;
                    };
                };
                step2: string;
                step3: string;
                stepRedirect: string;
            };
            popover: {
                apply: string;
            };
            theme: {
                color: {
                    title: string;
                };
                primaryColor: {
                    title: string;
                    input: InputLabel;
                };
                primaryContrastColor: {
                    title: string;
                    input: InputLabel;
                };
                secondaryColor: {
                    title: string;
                    input: InputLabel;
                };
                secondaryContrastColor: {
                    title: string;
                    input: InputLabel;
                };
                contentTextColor: {
                    title: string;
                    input: InputLabel;
                };
                customColor: {
                    title: string;
                    input: InputLabel;
                };
                background: {
                    title: string;
                };
            };
            language: {
                title: string;
                availableLanguages: string;
                select: {
                    theme: string;
                    content: string;
                };
            };
            browserTitle: {
                title: string;
                input: InputLabel;
            };
            qrCodeText: {
                title: string;
                input: InputLabel;
            };
            font: {
                select: string;
                customFont: string;
                predefinedFont: string;
                uploadFont: string;
                upload: {
                    headline: DragAreaTextElement;
                    subLine: DragAreaTextElement;
                    acceptedFileTypes: string;
                };
            };
            rows: {
                create: {
                    title: string;
                };
                remove: {
                    title: string;
                    description: string;
                    submit: string;
                };
                duplicate: {
                    title: string;
                };
                types: Record<LandingPageLayoutRowType, string>;
                spacing: {
                    title: string;
                    select: InputLabel;
                };
                image: {
                    configure: string;
                    height: {
                        title: string;
                        explanation: string;
                        input: InputLabel;
                    };
                };
                padding: {
                    title: string;
                    horizontal: string;
                    vertical: string;
                };
                button: {
                    border: {
                        title: string;
                        color: {
                            title: string;
                        };
                        width: {
                            title: string;
                            input: InputLabel;
                        };
                        radius: {
                            title: string;
                            input: InputLabel;
                        };
                    };
                    height: {
                        title: string;
                        input: InputLabel;
                    };
                    hover: {
                        type: Record<LandingPageLayoutTextButtonHoverType, string>;
                        percentage: InputLabel;
                    };
                    tabs: Record<LandingPageLayoutTextButtonConfigurationTab, string>;
                };
                text: {
                    textColor: string;
                    textSize: {
                        title: string;
                        variant: Record<LandingPageTextSizeType, string>;
                        custom: InputLabel;
                    };
                    textWeight: {
                        title: string;
                        variant: Record<LandingPageFontWeightClass, string>;
                    };
                    background: {
                        title: string;
                        type: string;
                        variant: {
                            none: string;
                            [LandingPageLayoutBackgroundType.BackgroundColor]: string;
                            [LandingPageLayoutBackgroundType.BackgroundImage]: string;
                        };
                        color: {
                            select: string;
                        };
                        image: {
                            select: string;
                            change: string;
                            preview: string;
                        };
                    };
                };
                circleButtons: {
                    title: string;
                    add: string;
                    remove: string;
                    empty: string;
                    type: {
                        select: string;
                        variant: Record<LandingPageIcon, string>;
                    };
                    link: {
                        title: string;
                    };
                    theme: {
                        select: string;
                        variant: Record<LandingPageIconThemeType, string>;
                        custom: {
                            backgroundColor: string;
                            textColor: string;
                        };
                    };
                };
                complexButton: {
                    content: {
                        add: string;
                        remove: string;
                        spacing: {
                            left: string;
                            right: string;
                        };
                        type: {
                            select: string;
                            variant: Record<LandingPageLayoutComplexButtonContentType, string>;
                        };
                        text: {
                            input: InputLabel;
                        };
                        hover: {
                            type: {
                                variant: Record<LandingPageLayoutComplexButtonHoverType, string>;
                            };
                        };
                    };
                };
                appStoreButtons: {
                    add: string;
                    remove: string;
                    empty: string;
                    appStore: Record<LandingPageAppStoreType, string>;
                    input: InputLabel;
                };
            };
            upload: {
                title: string;
                headline: DragAreaTextElement;
                subLine: DragAreaTextElement;
            };
            notifications: {
                save: {
                    success: string;
                    error: string;
                };
            };
        };
        create: {
            title: string;
            button: string;
            dialog: {
                title: string;
                submit: string;
                name: InputLabel;
                description: InputLabel;
                landingPageType: string;
                hierarchyNode: {
                    title: string;
                    description: string;
                };
            };
        };
        files: {
            images: {
                select: string;
                upload: string;
                existing: {
                    title: string;
                };
                localizedSelect: {
                    useForCurrentLocale: string;
                    useForAllLocale: string;
                };
            };
        };
        actions: {
            download: {
                title: {
                    singular: string;
                    plural: string;
                };
                fileType: string;
                color: {
                    title: string;
                    description: string;
                };
            };
            editLandingPage: string;
            clone: {
                title: {
                    singular: string;
                    plural: string;
                };
                dialog: {
                    submit: string;
                    explanation: {
                        singular: string;
                        plural: string;
                    };
                };
                notifications: {
                    success: {
                        singular: string;
                        plural: string;
                    };
                    error: {
                        singular: string;
                        plural: string;
                    };
                };
            };
        };
    };
}

export const messages: GlobalStrings<Messages> = {
    [Language.German]: {
        ...baseMessages[Language.German],
        ...DefaultLandingPageLocalization[Language.German],
        app: {
            title: 'Qualitize',
            offline: 'Sie sind zur Zeit offline.'
        },
        general: {
            close: 'Schließen',
            open: 'Öffnen',
            cancel: 'Abbrechen',
            select: 'Auswählen',
            save: 'Speichern',
            download: 'Herunterladen',
            delete: 'Löschen',
            next: 'Weiter',
            back: 'Zurück'
        },
        connection: {
            offline: 'Offline',
            online: 'Online',
            notifications: {
                online: 'Sie sind wieder online.',
                offline: 'Sie sind offline. Bitte überprüfen Sie Ihre Internetverbindung.',
                backendOffline: 'Sie sind offline. Wir versuchen die Verbindung wiederherzustellen.'
            }
        },
        languages: {
            at: 'Österreichisch',
            ba: 'Baschkirisch',
            be: 'Weißrussisch',
            bg: 'Bulgarisch',
            ch: 'Schweizerisch',
            cs: 'Tschechisch',
            de: 'Deutsch',
            da: 'Dänisch',
            fi: 'Finnisch',
            fr: 'Französisch',
            gb: 'Englisch',
            el: 'Griechisch',
            hr: 'Kroatisch',
            hu: 'Ungarisch',
            is: 'Isländisch',
            it: 'Italienisch',
            lt: 'Litauisch',
            lb: 'Luxemburgisch',
            lv: 'Lettisch',
            mk: 'Mazedonisch',
            nl: 'Niederländisch',
            no: 'Norwegisch',
            pl: 'Polnisch',
            ro: 'Rumänisch',
            ru: 'Russisch',
            sv: 'Schwedisch',
            sl: 'Slowenisch',
            sk: 'Slowakisch'
        },
        sort: {
            pageSize: 'Anzahl Zeilen',
            all: 'Alle',
            search: 'Suche',
            rowCount: '{0}-{1} von {2}'
        },
        forms: {
            noOptions: 'Keine Auswahlmöglichkeiten',
            input: {
                number: {
                    invalid: 'Bitte geben Sie eine gültige Zahl ein. Bitte geben Sie keine Tausender-Trennzeichen ein und verwenden für Dezimalzahlen "{0}" als Trennzeichen.'
                }
            }
        },
        legal: {
            legalNotice: {
                title: 'Impressum'
            },
            privacy: {
                title: 'Datenschutz'
            }
        },
        login: {
            slider: {
                welcome: {
                    title: 'Willkommen bei Qualitize!'
                }
            },
            title: 'Login bei Qualitize Online',
            caption: 'Jedes Kundenerlebnis zählt!',
            buttons: {
                continue: 'Weiter'
            },
            blocked: {
                title: 'Ihr Account wurde aufgrund zu vieler fehlgeschlagener Login-Versuche gesperrt.'
            },
            password: {
                title: 'Login via Passwort'
            },
            passwordLess: {
                title: 'Login via E-Mail',
                help: {
                    button: 'Mehr erfahren',
                    title: 'Login via E-Mail',
                    explanation: 'Nutzen Sie ab sofort unseren neuen Login über einen Link via E-Mail. <br />Nachdem Sie auf „E-Mail mit Login-Link anfordern“ geklickt haben erhalten Sie eine E-Mail, welche einen Link enthält, mit dem Sie sich einloggen können. Bitte öffnen Sie den Link in der E-Mail in dem gleichen Browser, in dem Sie den Link angefordert haben.',
                    howItWorks: {
                        title: 'Wie funktioniert der Login über einen Link?',
                        description: 'Login-Links sind sicher und funktionieren ohne Passwortvergabe. <br />Die Funktionsweise eines Login-Links ähnelt der „Passwort vergessen“-Funktion. Klicken Sie auf „Passwort vergessen“ erhalten Sie eine E-Mail mit einem Link, welcher einen sicheren, kurzlebigen Schlüssel beinhaltet, mit dem Sie sich ein neues Passwort erstellen können.<br />Login-Links nutzen die Funktionalität des sicheren, kurzlebigen Schlüssels für die Verifizierung des Benutzers und erlauben somit eine sichere Anmeldung in unserem System. Als weiterer Sicherheitsmechanismus ist der Login-Link nur in dem Browser gültig, in dem er auch angefordert wurde.'
                    },
                    close: 'Schließen',
                    doNotClose: 'Bitte schließen Sie dieses Browser-Fenster nicht und öffnen Sie den Ihnen zugesandten Link in diesem Browser.'
                },
                request: 'E-Mail mit Login-Link anfordern',
                success: {
                    message: 'Sie erhalten in Kürze eine E-Mail von uns an {0}. Bitte überprüfen Sie Ihr Postfach.',
                    submitLogin: 'Jetzt einloggen'
                },
                invalidate: {
                    success: 'Vielen Dank für Ihre Mitteilung. Wir haben Ihren Login-Link gesperrt.'
                },
                alreadyActiveToken: {
                    button: 'Login-Link erneut anfordern',
                    dialog: {
                        title: 'Login-Link erneut anfordern',
                        content: 'Möchten Sie wirklich einen neuen Login-Link anfordern? Der bereits angeforderte Login-Link wird dadurch ungültig und kann nicht mehr benutzt werden.',
                        submit: 'Anfordern'
                    }
                },
                backToLogin: 'Zurück zum Login',
                error: {
                    tokenExpired: 'Dieser Login-Link ist nicht mehr gültig.',
                    invalidBrowser: 'Aus Sicherheitsgründen ist dieser Login-Link nur in dem Browser gültig, in dem Sie den Link angefordert haben.',
                    tooManyTokens: 'Sie haben zu viele Login-Links, welche noch aktiv sind. Bitte überprüfen Sie Ihr E-Mail-Postfach, gegebenenfalls außerdem Ihren Spam-Ordner.',
                    alreadyTokenActive: 'Sie haben in diesem Browser bereits einen Login-Link angefordert. Bitte überprüfen Sie Ihr E-Mail-Postfach, gegebenenfalls außerdem Ihren Spam-Ordner.',
                    unknown: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut.'
                }
            },
            methods: {
                divider: 'oder'
            },
            loggedIn: {
                title: 'Sie sind bereits angemeldet. Wie möchten Sie fortfahren?',
                keepLoggedIn: 'Angemeldet bleiben',
                reLogin: 'Neu anmelden'
            },
            oidc: {
                error: 'Leider ist ein Fehler beim Login aufgetreten.',
                accessDeniedError: 'Der Login in Ihrem System ist fehlgeschlagen. Haben Sie eventuell keinen Zugriff?',
                backToLogin: 'Zurück zum Login'
            },
            inactivityLogout: {
                dialog: {
                    title: 'Logout aufgrund von Inaktivität',
                    description: 'Sie werden in {0}s aufgrund von Inaktivität ausgelogged.',
                    submit: 'Angemeldet bleiben',
                    cancel: 'Ausloggen'
                },
                menu: {
                    description: 'Sie werden in {0} aufgrund von Inaktivität ausgelogged.',
                    durationInMinutes: '{0}min',
                    durationInMinutesAndSeconds: '{0}min {1}s',
                    durationInSeconds: '{0}s',
                }
            }
        },
        passwordReset: {
            button: 'Speichern',
            title: 'Neues Passwort erstellen',
            description: 'Ihr neues Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
            success: 'Ihr Passwort wurde erfolgreich gespeichert. Sie können sich nun damit anmelden.',
            invalidate: {
                success: 'Vielen Dank für Ihre Mitteilung. Wir haben Ihren Link zum Zurücksetzen des Passworts gesperrt.'
            },
            error: {
                expired: 'Der Link zum Aktualisieren des Passworts ist abgelaufen.',
                invalidBrowser: 'Aus Sicherheitsgründen ist dieser Passwort Zurücksetzen Link nur in dem Browser gültig, in dem Sie den Link angefordert haben.'
            }
        },
        register: {
            button: 'Registrieren',
            title: 'Registrierung',
            subtitle: 'Der letzte Schritt vor der Anmeldung: Erstellen Sie Ihr Passwort.',
            description: 'Ihr Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.',
            success: 'Ihre Registrierung wurde erfolgreich abgeschlossen. Sie können sich nun mir Ihrem Passwort anmelden.',
            expired: 'Der Link zur Registrierung ist abgelaufen. Bitte kontaktieren Sie den Qualitize Kundenservice unter support@qualitize.de.'
        },
        user: {
            logout: 'Logout',
            hierarchy: {
                search: {
                    label: 'Hierarchie durchsuchen',
                    placeholder: 'Geben Sie hier einen Suchbegriff ein.'
                }
            },
            login: {
                title: 'Anmelden',
                success: 'Sie wurden erfolgreich eingeloggt',
                keepLoggedIn: 'Angemeldet bleiben?',
                errors: {
                    invalidInput: 'Benutzername oder Passwort falsch.',
                    missingInput: 'Bitte füllen Sie alle Felder aus.',
                    invalidMail: 'Bitte geben Sie eine valide E-Mail-Adresse an.'
                },
                forgot: {
                    title: 'Passwort vergessen?',
                    button: 'E-Mail anfordern',
                    caption: 'E-Mail zum Zurücksetzen anfordern',
                    description: 'Sie erhalten eine E-Mail mit einem Link, über den Sie ein neues Passwort erstellen können.',
                    toLogin: 'Zur Anmeldung',
                    error: 'Es ist ein Fehler aufgetreten.',
                    success: 'Sie erhalten in Kürze eine E-Mail mit einem Link, um ein neues Passwort zu erstellen.'
                }
            },
            username: {
                label: 'E-Mail',
                placeholder: 'E-Mail eingeben'
            },
            password: {
                label: 'Passwort',
                placeholder: 'Passwort eingeben',
            }
        },
        settings: {
            items: {
                video: 'Video',
                masterData: 'Stammdaten',
                accounts: 'User',
                terminals: 'Terminals'
            },
            account: {
                personalData: {
                    title: 'Allgemeine Informationen',
                    subLine: 'Name & E-Mail',
                    gender: {
                        label: {
                            label: 'Anrede',
                            placeholder: 'Anrede auswählen'
                        },
                        male: 'Herr',
                        female: 'Frau'
                    },
                    firstName: {
                        label: 'Vorname',
                        placeholder: 'Vornamen eingeben'
                    },
                    lastName: {
                        label: 'Nachname',
                        placeholder: 'Nachnamen eingeben'
                    },
                    email: {
                        label: 'E-Mail',
                        placeholder: 'E-Mail eingeben',
                    },
                    emailDomain: {
                        label: 'E-Mail-Endung',
                        placeholder: 'E-Mail-Endung auswählen',
                    },
                    phone: {
                        label: 'Telefon',
                        placeholder: 'Telefon eingeben'
                    },
                    submit: 'Speichern',
                    update: {
                        success: 'Ihre persönlichen Daten wurden geändert.',
                        error: 'Ihre persönlichen Daten konnten nicht geändert werden.'
                    }
                },
                password: {
                    title: 'Sicherheit',
                    subLine: 'Passwort',
                    old: {
                        label: 'Altes Passwort',
                        placeholder: 'Altes Passwort eingeben'
                    },
                    new: {
                        label: 'Neues Passwort',
                        placeholder: 'Neues Passwort eingeben'
                    },
                    confirm: {
                        label: 'Neues Passwort wiederholen',
                        placeholder: 'Neues Passwort wiederholen'
                    },
                    set: {
                        password: {
                            label: 'Passwort setzen',
                            placeholder: 'Geben Sie hier Ihr Passwort ein'
                        },
                        confirm: {
                            label: 'Passwort bestätigen',
                            placeholder: 'Geben Sie Ihr Passwort erneut ein'
                        }
                    },
                    submit: 'Passwort ändern',
                    update: {
                        success: 'Ihr Passwort wurde geändert.',
                        error: {
                            mismatch: 'Die Passwörter stimmen nicht überein.',
                            empty: 'Bitte füllen Sie alle Felder aus.',
                            same: 'Das neue Passwort darf nicht mit dem alten übereinstimmen.',
                            general: 'Das Passwort konnte nicht geändert werden.'
                        }
                    }
                }
            },
            videos: {
                title: 'Video Admin',
                subLine: 'Statistiken & Verwaltung',
                highlight: {
                    success: 'Das Video wurde aktualisiert.',
                    error: 'Das Video konnte nicht aktualisiert werden.'
                },
                statistics: {
                    headline: 'Admin',
                    overview: 'Übersicht',
                    thumbnail: 'Vorschau',
                    title: 'Titel',
                    authorCreator: 'Autor/Creator',
                    author: 'Autor',
                    creator: 'Creator',
                    reactions: 'Reactions',
                    views: 'Views',
                    created: 'hochgeladen',
                    updated: 'zuletzt bearbeitet',
                    dates: 'Datum',
                    actions: 'Aktion',
                    totalViews: 'Views insgesamt {0}'
                }
            }
        },
        menu: {
            user: {
                profile: 'Profil bearbeiten',
                password: 'Passwort ändern',
                setPassword: 'Passwort vergeben',
                logout: 'Logout'
            },
            feedback: {
                title: 'Feedback',
                reports: 'Auswertungen'
            },
            administration: {
                openingHours: 'Öffnungszeiten',
                legacyStickies: 'Sticky-Editor',
                legacyDepartments: 'Abteilungen',
                terminals: 'Terminals',
                qrCodes: 'QR-Codes',
                accounts: 'Accounts',
                accountProfiles: 'Profile',
                accountLoginAuditLog: 'Audit-Logs'
            },
            dashboard: 'Dashboard',
            operations: 'Operations',
            landingPages: 'Magic QRs',
            toDos: 'To Dos',
            videos: 'Learning',
            departments: 'Abteilungen',
            stickyEditor: 'Sticky-Editor',
            openingHours: 'Öffnungszeiten',
            settings: 'Verwaltung',
            customers: {
                select: {
                    label: 'Kunde auswählen',
                    placeholder: 'Kunde'
                }
            }
        },
        administration: {
            locationSelection: {
                button: 'Standort: {0}'
            },
            openingHours: {
                locationSelection: 'Standort auswählen',
                table: {
                    header: {
                        weekDay: 'Wochentag',
                        start: 'Start',
                        end: 'Ende',
                        dayOfRest: 'Ruhetag',
                        changeMultiple: 'Für {0} Tage ändern',
                        changeSingle: 'Für 1 Tag ändern',
                        changeAll: 'Für alle Tage ändern',
                    },
                    weekDays: {
                        [WeekDay.None]: '',
                        [WeekDay.Monday]: 'Montag',
                        [WeekDay.Tuesday]: 'Dienstag',
                        [WeekDay.Wednesday]: 'Mittwoch',
                        [WeekDay.Thursday]: 'Donnerstag',
                        [WeekDay.Friday]: 'Freitag',
                        [WeekDay.Saturday]: 'Samstag',
                        [WeekDay.Sunday]: 'Sonntag'
                    },
                    alwaysOpen: 'Immer geöffnet'
                },
                notifications: {
                    success: 'Ihre Öffnungszeiten wurden gespeichert.',
                    error: 'Ihre Öffnungszeiten konnten nicht gespeichert werden.',
                },
                dialog: {
                    title: 'Öffnungszeiten für mehrere Standorte speichern',
                    search: {
                        label: 'Standort suchen',
                        placeholder: 'Suchbegriff eingeben'
                    },
                    subTitle: 'Ausgewählte Öffnungszeiten'
                },
                actions: {
                    save: 'Speichern',
                    saveMultiple: 'Für {0} Standorte speichern',
                    saveSingle: 'Für 1 Standort speichern',
                    cancel: 'Abbrechen',
                    openDialog: {
                        desktop: 'Für weitere Standorte übernehmen',
                        mobile: 'Weitere Standorte'
                    }
                }
            },
            terminals: {
                state: {
                    all: 'Alle',
                    offline: 'Offline',
                    online: 'Online'
                },
                table: {
                    header: {
                        location: 'Standort',
                        terminalLocation: 'Aufstellungsort',
                        connectionMethod: 'Verbindung',
                        pinCode: 'PIN',
                        id: 'ID',
                        terminalState: 'Online-Status',
                        locationState: 'Status'
                    },
                    actions: {
                        support: 'Ticket erstellen',
                        supportStatus: 'Ticket anzeigen'
                    }
                },
                filter: {
                    showAllLocations: 'Alle',
                    onlineStates: 'Status: {0}',
                    noResults: 'Keine Einträge gefunden'
                },
                dialogs: {
                    incident: {
                        title: 'Support für Terminal {0} anfragen',
                        inputs: {
                            contactPerson: {
                                label: 'Ansprechpartner:in',
                                placeholder: 'Bitte geben Sie Ihren Namen ein'
                            },
                            contactEmail: {
                                label: 'E-Mail-Adresse',
                                placeholder: 'Bitte geben Sie Ihre E-Mail-Adresse ein'
                            },
                            contactPhone: {
                                label: 'Telefonnummer (optional)',
                                placeholder: 'Bitte geben Sie Ihre Telefonnummer ein'
                            },
                            comment: {
                                label: 'Beschreibung',
                                placeholder: 'Bitte beschreiben Sie hier Ihr Anliegen'
                            },
                            problems: {
                                label: 'Themen (optional)',
                                placeholder: 'Bitte wählen Sie ein Thema aus.'
                            }
                        },
                        attachments: {
                            title: 'Anhänge',
                            description: 'Hier können Sie Bilder oder Videos (bis zu 100MB) hochladen, welche Ihr Thema aufzeigen.',
                            upload: {
                                title: 'Hier klicken',
                                acceptedFileTypes: 'Dateitypen: .jpg, .png, .mp4, .mov',
                                illegalFileType: 'Der Dateityp wird nicht unterstützt.'
                            }
                        },
                        actions: {
                            submit: 'Absenden',
                            cancel: 'Abbrechen'
                        },
                        messages: {
                            success: 'Ihr Anliegen wurde an den Qualitize-Kundensupport weitergeleitet. Sie erhalten eine Kopie an {0}.',
                            error: 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
                        }
                    },
                    ticket: {
                        title: 'Status Ihres Tickets für {0}',
                        add: 'Neuer Ticket-Eintrag erstellen',
                        hide: 'Abbrechen',
                        problems: 'Themen',
                        attachments: 'Anhänge',
                        notifications: {
                            success: 'Ihre Nachricht wurde an den Qualitize-Kundensupport gesendet. Sie erhalten eine Kopie an {0}.',
                            error: 'Ihre Nachricht konnte nicht an uns gesendet werden. Bitte versuchen Sie es später erneut.'
                        }
                    }
                },
                notifications: {
                    online: 'Ihr Terminal {0} ist wieder online.'
                }
            },
            locations: {
                state: {
                    [LocationSetupStatus.NO_TABLET]: 'Kein Terminal vor Ort',
                    [LocationSetupStatus.SETUP]: 'Aufbau geplant',
                    [LocationSetupStatus.ACTIVE]: 'Aufgebaut',
                    [LocationSetupStatus.INACTIVE]: 'Nicht aktiv',
                    [LocationSetupStatus.TEMP_CLOSED]: 'Vor. geschlossen',
                    [LocationSetupStatus.TERMINATED]: 'Geschlossen',
                    [LocationSetupStatus.TEARDOWN]: 'Abgebaut'
                }
            },
            stickyEditor: {
                pageTitle: 'Sticky-Editor',
                zoomIn: 'Sticky vergrößern',
                zoomOut: 'Sticky verkleinern',
                stepper: {
                    design: 'Design festlegen',
                    timeframe: 'Zeitraum festlegen',
                    location: 'Standort auswählen',
                },
                designStep: {
                    description: 'Nutze die unterschiedlichen Auswahloptionen, um das Design individuell anzupassen. Die Farbe des Sticky und der Text der angezeigten Stickies kann individuell angepasst werden.',
                    colorInput: 'Wähle eine Farbe für das Sticky aus:',
                    textInputLabel: 'Sticky-Text eingeben (max. 70 Zeichen):',
                    textInputPlaceholder: 'Sticky-Text',
                    textInputDescription: 'Hier wird der Text festgelegt, der auf dem Sticky angezeigt werden soll.'
                },
                colors: {
                    yellow: 'Gelb',
                    pink: 'Rosa',
                    white: 'Weiß',
                },
                timeframeStep: {
                    description: 'In diesem Schritt wird die genaue Anzeigedauer des Sticky festgelegt. Falls sich das Enddatum kurzfristig ändert, kann das Sticky manuell deaktiviert werden.',
                    startDate: 'Startdatum festlegen:',
                    endDate: 'Enddatum festlegen:',
                    endDateLabel: 'Enddatum auswählen:',
                    checkboxLabel: 'Kein Enddatum festlegen',
                },
                locationStep: {
                    descriptionGeneral: 'Hier können die Standorte, an welchen das Sticky erscheinen soll, eingestellt werden. Bei mehreren Terminals pro Standort können diese jeweils seperat aktiviert werden. Durch Auswählen des entsprechenden Terminals wird dieses aktiviert.',
                    descriptionSpecific: 'Durch Klicken auf "Speichern" wird das Sticky für die unten aufgelisteten Terminals aktiviert:',
                    descriptionOneLocation: 'Hier können die Terminals, auf denen das Sticky erscheinen soll, eingestellt werden. Durch Auswählen des entsprechenden Terminals wird dieses aktiviert.',
                    selectAll: 'Alle Standorte auswählen',
                    searchPlaceholder: 'Standort suchen',
                    noLocationFound: 'Kein Standort gefunden'
                },
                archiveTitle: 'Sticky-Archiv',
                allLocations: 'Alle Standorte',
                undefinedEndDate: 'unbegrenzt',
                chipActive: 'Aktiv',
                chipInactive: 'Inaktiv',
                archiveItems : {
                    regionalManagedPlaceholder: 'Zentral oder regional verwaltet',
                    regionalManagedTooltip: 'Dieses Sticky wurde auf zentraler oder regionaler Ebene festgelegt.',
                    and: 'und',
                    remainingCount: '{0} weitere Standorte',
                    remainingLocationsTooltip: 'Der Sticky ist für die folgenden Standorte aktiv: {0}'
                },
                actionMenu: {
                    editText: 'Text anpassen',
                    deactivateSticky: 'Sticky deaktivieren',
                    reactivateSticky: 'Sticky reaktivieren',
                    deleteSticky: 'Sticky löschen',
                },
                popup: {
                    editTextTitle: 'Sticky bearbeiten',
                    submitChanges: 'Änderungen speichern',
                    saveMessage: 'Soll das Sticky jetzt gespeichert werden?',
                    deleteMessage: 'Soll das Sticky endgültig gelöscht werden?'
                },
                notifications: {
                    SaveSuccess: 'Das Sticky wurde gespeichert.',
                    SaveError: 'Das Sticky konnte nicht gespeichert werden.',
                    DeleteSuccess: 'Das Sticky wurde gelöscht.',
                    DeleteError: 'Das Sticky konnte nicht gelöscht werden.',
                    EditSuccess: 'Das Sticky wurde erfolgreich bearbeitet.',
                    EditError: 'Das Sticky konnte nicht aktualisiert werden.',
                },
            },
            accounts: {
                management: {
                    title: 'Account Management',
                    topBar: {
                        search: {
                            placeholder: 'Accounts durchsuchen'
                        },
                        stateFilter: {
                            active: 'Aktive Accounts',
                            temporarilyDisabled: 'Gesperrte Accounts',
                            all: 'Alle Accounts'
                        },
                        timeFilter: {
                            title: 'Zeitraum auswählen',
                            selectDate: 'Datum auswählen',
                            description: 'Sie können Nutzer nach Ihrem Erstellungsdatum suchen. Geben Sie hierfür einfach den gewünschten Zeitraum ein.',
                            createdFrom: 'Startzeitpunkt (optional)',
                            createdUntil: 'Endzeitpunkt (optional)',
                            resetDate: 'Datum zurücksetzen',
                            submit: 'Zeitraum auswählen',
                            submitMobile: 'Auswählen',
                            cancel: 'Ausgewählten Zeitraum zurücksetzen',
                            cancelMobile: 'Zurücksetzen',
                        },
                        export: {
                            button: 'Exportieren',
                            buttonSelection: 'Auswahl exportieren',
                            notifications: {
                                success: 'Der Download Ihres Exports wurde gestartet.',
                                error: 'Ihr Export konnte nicht erzeugt werden.'
                            }
                        }
                    },
                    state: {
                        [TemporarilyDisabledStateType.NotDisabled]: 'Aktiv',
                        [TemporarilyDisabledStateType.Disabled]: 'Gesperrt',
                    },
                    disabledReason: {
                        [TemporarilyDisabledReasonType.TooManyFailedPasswordLogins]: 'Passwort zu oft falsch eingegeben ({0} mal)',
                        [TemporarilyDisabledReasonType.ManuallyDisabled]: 'Manuell gesperrt',
                    },
                    reEnableDate: 'Automatisch wieder aktiv ab: {0}',
                    table: {
                        header: {
                            username: 'Benutzername',
                            authenticationConfiguration: 'Profil',
                            roleConfiguration: 'Rollen-Konfiguration',
                            loginMethodConfiguration: 'Login-Methoden',
                            securityRuleConfiguration: 'Sicherheitsregeln',
                            createdAt: 'Erstellt am',
                            disabledState: 'Status',
                            actions: 'Aktionen'
                        },
                        footer: {
                            rows: 'Zeige {0} von {1} Accounts'
                        },
                        loginMethods: {
                            title: 'Login-Methoden',
                            methods: {
                                [PublicLoginMethodType.Password]: 'Passwort',
                                [PublicLoginMethodType.LoginToken]: 'Login E-Mail',
                                [PublicLoginMethodType.OpenIdConnectAuthorizationCodeFlow]: 'OpenID Connect',
                            },
                            none: 'Keine Login-Methoden',
                            failureState: 'State: {0}'
                        },
                        securityRules: {
                            title: 'Sicherheitsregeln',
                            fromPreset: 'Aus Template: {0}',
                            none: 'Keine'
                        },
                        roles: {
                            title: 'Rollen',
                            explicit: 'Eigene Rollendefinition',
                            fromPresets: 'Aus Template: {0}',
                            none: 'Keine Rollen'
                        },
                        authenticationConfiguration: {
                            explicit: 'Kein Profil',
                            none: 'Kein Profil',
                            tooltip: 'Detailansicht zeigen',
                            dialog: {
                                title: 'Sicherheitseinstellungen für {0}'
                            }
                        },
                        actions: {
                            disable: {
                                title: 'Account temporär sperren',
                                mobileTitle: 'Temporär sperren',
                                description: 'Möchten Sie diese Nutzer temporär sperren? Die Nutzer haben anschließend keinen Zugriff mehr auf Qualitize Online, können von Ihnen aber jederzeit wieder aktiviert werden.',
                                submit: 'Account sperren',
                                notifications: {
                                    success: 'Die Nutzer wurden erfolgreich temporär gesperrt. Sie können diese jederzeit wieder entsperren.',
                                    error: 'Die Nutzer konnten nicht temporär gesperrt werden. Bitte versuchen Sie es später erneut.',
                                },
                                reason: {
                                    label: 'Beschreibung (Pflicht)',
                                    placeholder: 'Bitte geben Sie eine Beschreibung an.',
                                },
                                reEnableDescription: 'Soll der Account ab einem bestimmten Datum wieder aktiv sein?',
                                noReEnableDate: 'Datum auswählen',
                                resetReEnableDate: 'Datum zurücksetzen'
                            },
                            enable: {
                                title: 'Account entsperren',
                                mobileTitle: 'Entsperren',
                                description: 'Möchten Sie diese Nutzer entsperren? Die Nutzer haben anschließend wieder Zugriff auf Qualitize Online und können sich mit Ihren vorhanden Login-Methoden anmelden.',
                                submit: 'Account entsperren',
                                submitMobile: 'Entsperren',
                                notifications: {
                                    success: 'Die Nutzer wurden erfolgreich entsperrt und haben ab sofort wieder Zugriff auf Qualitize Online.',
                                    error: 'Die Nutzer konnten nicht entsperrt werden. Bitte versuchen Sie es später erneut.',
                                }
                            },
                            deactivate: {
                                title: 'Account dauerhaft deaktivieren',
                                mobileTitle: 'Dauerhaft deaktivieren',
                                description: 'Möchten Sie diese Nutzer deaktivieren? Die Nutzer haben anschließend keinen Zugriff mehr auf Qualitize Online und können von Ihnen auch nicht wieder aktiviert werden.',
                                submit: 'Account deaktivieren',
                                notifications: {
                                    success: 'Die Nutzer wurden erfolgreich dauerhaft deaktiviert und haben ab sofort keinen Zugriff auf Qualitize Online.',
                                    error: 'Die Nutzer konnten nicht dauerhaft deaktiviert werden. Bitte versuchen Sie es später erneut.',
                                }
                            }
                        }
                    },
                    passwordRule: {
                        [LoginPasswordRuleType.LowerCase]: 'Kleinbuchstaben (min. {0})',
                        [LoginPasswordRuleType.UpperCase]: 'Großbuchstaben (min. {0})',
                        [LoginPasswordRuleType.Digit]: 'Zahlen (min. {0})',
                        [LoginPasswordRuleType.History]: 'Keine bereits verwendeten Passwörter (berücksichtigt die letzten {0} Passwörter)',
                        [LoginPasswordRuleType.Length]: 'Anzahl Zeichen (min. {0}, max. {1})',
                        [LoginPasswordRuleType.Special]: 'Sonderzeichen (min. {0})',
                        [LoginPasswordRuleType.Username]: 'Darf Benutzernamen nicht enthalten',
                        [LoginPasswordRuleType.Whitespace]: 'Darf Leerzeichen enthalten',
                        [LoginPasswordRuleType.Dictionary]: 'Darf bestimmte Zeichenfolgen nicht beinhalten ({0})',
                    },
                    defaultPasswordRule: 'Qualitize Standard-Regeln: Min. 8 Zeichen, 1 Kleinbuchstabe, 1 Großbuchstabe, 1 Ziffer, 1 Sonderzeichen, keine Einträge aus der Liste der zu häufig verwendeten Passwörter, keine Benutzernamen, keine Passwortwiederverwendung.',
                    dictionaryPasswordRule: {
                        extendDefault: {
                            withCustomPasswords: 'Darf von Qualitize definierte und eigene Zeichenfolgen nicht beinhalten (Eigene Zeichenfolgen: {0})',
                            withoutCustomPasswords: 'Darf von Qualitize definierte Zeichenfolgen nicht beinhalten'
                        },
                        custom: {
                            withCustomPasswords: 'Darf eigens definierte Zeichenfolgen nicht beinhalten ({0})',
                            withoutCustomPasswords: 'Darf eigens definierte Zeichenfolgen nicht beinhalten'
                        }
                    },
                    profiles: {
                        title: 'Profile',
                        emptyState: 'Für Ihr Unternehmen sind noch keine Profile definiert.'
                    },
                    loginAudit: {
                        title: 'Audit-Logs',
                        emptyState: 'Für Ihr Unternehmen sind noch keine Audit Logs verfügbar.',
                        download: {
                            button: 'Als CSV herunterladen',
                            filename: '{0}_Qualitize_Online_Audit_Log'
                        },
                        topBar: {
                            timeSpan: {
                                type: {
                                    [AvailableLoginAuditTimeSpan.Last7Days]: 'Letzte 7 Tage',
                                    [AvailableLoginAuditTimeSpan.Last30Days]: 'Letzte 30 Tage',
                                    [AvailableLoginAuditTimeSpan.LastMonth]: 'Letzter Monat',
                                    [AvailableLoginAuditTimeSpan.Last3Months]: 'Letzte 3 Monate',
                                    [AvailableLoginAuditTimeSpan.Last6Months]: 'Letzte 6 Monate',
                                    [AvailableLoginAuditTimeSpan.LastYear]: 'Letztes Jahr',
                                    [AvailableLoginAuditTimeSpan.Custom]: 'Eigener Zeitraum',
                                }
                            }
                        },
                        table: {
                            header: {
                                timestamp: 'Zeitstempel',
                                username: 'Benutzername',
                                loginActivity: 'Aktivität',
                                loginMethod: 'Login-Methode',
                                reason: 'Grund'
                            },
                            footer: {
                                singular: '{0} Log-Eintrag',
                                plural: '{0} Log-Einträge',
                            },
                            loginActivity: {
                                type: {
                                    success: 'Erfolgreicher Login',
                                    failure: 'Fehlgeschlagener Login',
                                }
                            }
                        }
                    }
                }
            }
        },
        calendar: {
            week: {
                short: 'KW {0}'
            },
            time: {
                description: '{0} Uhr'
            }
        },
        icons: {
            description: 'Icons'
        },
        threshold: {
            description: 'Einfärbung'
        },
        switch: {
            active: 'An',
            inactive: 'Aus'
        },
        chart: {
            empty: 'Für Ihre Auswahl liegen keine Daten vor.',
            tooFewRespondents: 'Für Ihre Auswahl liegen zu wenig Daten vor (n<{0}).'
        },
        dashboard: {
            load: {
                active: 'Ihre Insights werden geladen',
                chart: {
                    error: 'Ihre Insights konnten nicht geladen werden.'
                }
            },
            period: {
                clock: 'Uhr',
                predefined: 'Vordefinierte Zeiträume',
                customDefined: 'Eigene Zeiträume',
                select: {
                    description: 'Zeitraum',
                    lastDay: 'Gestern ({0})',
                    quarter: 'Letztes Quartal (Q{0})',
                    week: 'Letzte Woche (KW {0})',
                    month: 'Letzter Monat ({0})',
                    yearToDate: 'Aktuelles Jahr ({0})',
                    lastYear: 'Letztes Jahr ({0})',
                    calendarWeek: 'Kalenderwochen',
                    total: 'Gesamt',
                    custom: 'Zeitraum',
                    today: 'Heute ({0})',
                    day: 'Tag'
                },
                comparison: {
                    title: 'Vergleichszeitraum',
                    description: 'vs. {0}',
                    none: 'Keinen',
                    automatic: 'Automatisch',
                    previousDay: '{0}',
                    previousWeek: 'KW {0}',
                    previousMonth: '{0}',
                    previousQuarter: 'Q{0}',
                    previousYear: '{0}',
                    previousTotal: 'Gesamt',
                    day: 'Vortag ({0})',
                    week: 'Vorwoche (KW {0})',
                    month: 'Vormonat ({0})',
                    quarter: 'Vorquartal (Q{0})',
                    year: 'Vorjahr ({0})',
                    yearToDate: 'Aktuelles Jahr ({0})',
                    custom: 'Zeitraum',
                    customDay: 'Tag',
                    total: 'Gesamt'
                }
            },
            element: {
                noValue: 'k.A.',
                numberOfRespondents: '(n={0}{1})',
                notApplicableCount: ', zzgl. k.A.={0}',
                question: 'Frage',
                actions: {
                    export: {
                        download: {
                            notifications: {
                                success: 'Ihr Export wurde erzeugt und der Download gestartet.',
                                error: 'Ihr Export konnte nicht erzeugt werden.'
                            },
                            started: 'Der Download Ihres Exports wurde gestartet.',
                            navigate: 'Zu Qualitize Online',
                            description: 'Sollte der Download nicht automatisch gestartet werden, klicken Sie einfach auf den Download Button.',
                            triggerDownload: 'Download'
                        },
                        mail: {
                            notifications: {
                                success: 'Ihr Export wird erzeugt und Ihnen per E-Mail an {0} zugesendet.',
                                error: 'Ihr Export konnte nicht erzeugt werden.'
                            }
                        }
                    }
                },
                table: {
                    expand: {
                        showAll: 'Alle anzeigen',
                        hideAll: 'Alle einklappen'
                    }
                }
            },
            config: {
                element: {
                    name: 'Name',
                    lastSync: 'Datenstand'
                },
                feedbackCollectionPoint: 'Geräte',
                questions: 'Fragen',
                chart: {
                    description: 'Auswertungstyp',
                    type: {
                        barChart: {
                            description: 'Säulendiagramm',
                            type: 'Typ',
                            horizontal: 'Horizontal',
                            stacked: 'Gestapelt'
                        },
                        lineChart: {
                            description: 'Liniendiagramm',
                            interpolation: 'Interpolation',
                            filter: {
                                periodGrouping: {
                                    name: 'Gruppierung',
                                    elements: {
                                        [PeriodGrouping.Day]: 'Tag',
                                        [PeriodGrouping.Month]: 'Monat',
                                        [PeriodGrouping.Year]: 'Jahr',
                                        [PeriodGrouping.Quarter]: 'Quartal',
                                        [PeriodGrouping.Week]: 'Kalenderwoche',
                                        [PeriodGrouping.HalfYear]: 'Halbjahr',
                                    }
                                }
                            }
                        },
                        gauge: {
                            description: 'Tachometer',
                            type: 'Typ',
                            customerIndex: 'Service-Index',
                            nps: 'NPS'
                        },
                        heatMap: {
                            description: 'HeatMap'
                        },
                        stat: {
                            description: 'Kennzahl',
                            type: 'Typ',
                            number: 'Anzahl',
                            average: 'Mittelwert',
                            percentage: 'TopBox',
                            nps: 'NPS'
                        },
                        text: {
                            description: 'Kommentare',
                            filter: {
                                rating: {
                                    name: 'Sentiment',
                                    elements: {
                                        [Sentiment.None]: 'Keins',
                                        [Sentiment.Negative]: 'Negativ',
                                        [Sentiment.Ambivalent]: 'Ambivalent',
                                        [Sentiment.Positive]: 'Positiv',
                                    }
                                },
                                category: {
                                    name: 'Kategorie'
                                },
                                search: {
                                    input: {
                                        label: 'Kommentare durchsuchen',
                                        placeholder: 'Suchbegriffe eingeben',
                                    }
                                }
                            },
                            detail: {
                                show: 'Details öffnen',
                                hide: 'Details schließen',
                                empty: 'Für Ihre Auswahl liegen keine Kommentare vor.',
                                showOriginal: 'Original ({0}) anzeigen',
                                hideOriginal: 'Übersetzung ({0}) anzeigen'
                            }
                        },
                        image: {
                            three60: {
                                preparing: '360° wird vorbereitet'
                            }
                        },
                        table: {
                            includeOnlyFinishedFeedback: 'Nicht ausgefüllte ausblenden',
                            empty: 'Für Ihre Auswahl liegen keine Daten vor.',
                            search: {
                                placeholder: 'Suche'
                            }
                        }
                    }
                }
            },
            settings: {
                filter: {
                    more: '{0} weitere',
                    active: 'Aktive Filter: {0}'
                },
                global: {
                    reload: {
                        desktop: 'Daten aktualisieren',
                        mobile: 'Aktualisieren',
                        last: 'Datenstand: {0} Uhr'
                    },
                    filter: {
                        placeholder: '{0} durchsuchen',
                        desktop: 'Filter anzeigen',
                        mobile: 'Filter',
                        apply: {
                            desktop: 'Filter anwenden',
                            mobile: 'Anwenden'
                        },
                        reset: 'Filter zurücksetzen',
                        tooltip: {
                            title: 'Ausgewählte Filter ({0}):',
                            numberOfAdditionalElements: '+ {0} weitere Elemente'
                        }
                    },
                    top: {
                        desktop: 'Filter anzeigen',
                        mobile: 'Filter',
                        apply: {
                            desktop: 'Filter schließen',
                            mobile: 'Schließen'
                        }
                    },
                    period: {
                        desktop: 'Zeitraum auswählen',
                        mobile: 'Zeitraum'
                    },
                    comparisonPeriod: {
                        desktop: 'Vergleichszeitraum auswählen',
                        mobile: 'Vergleichszeitraum'
                    },
                    live: 'Live-Daten'
                }
            }
        },
        feedback: {
            overview: {
                dashboards: {
                    headline: 'Meine Dashboards',
                    more: 'Weitere Dashboards',
                    open: 'Dashboard öffnen',
                    all: 'Alle Dashboards',
                    lastDays: {
                        singular: 'Gestern',
                        plural: 'Letzte {0} Tage'
                    }
                }
            },
            sidebar: {
                dashboards: {
                    more: 'Weitere Dashboards',
                    less: 'Weniger Dashboards'
                }
            },
            locationComparison: {
                evaluationType: 'Auswertungsart',
                map: {
                    headline: 'Zufriedenheitskarte',
                    detail: 'Interaktive Karte öffnen'
                },
                podium: {
                    headline: 'Podium'
                },
                rank: {
                    title: 'Ranking',
                    detail: {
                        open: 'Detailansicht öffnen'
                    },
                    table: {
                        header: {
                            rank: 'Rang',
                            location: 'Standort',
                            value: 'Wert',
                            difference: 'Differenz',
                            respondents: 'Befragte'
                        }
                    }
                },
                ranking: {
                    [RankingType.TopBox]: {
                        name: 'TopBox',
                        description: 'Prozentualer Anteil sehr positiver Smiley'
                    },
                    [RankingType.Top2Box]: {
                        name: 'Top2Box',
                        description: 'Prozentualer Anteil sehr positiver und positiver Smiley'
                    },
                    [RankingType.Top3Box]: {
                        name: 'Top3Box',
                        description: 'Prozentualer Anteil sehr positiver, positiver und neutraler Smiley'
                    },
                    [RankingType.Bottom2Box]: {
                        name: 'Bottom2Box',
                        description: 'Prozentualer Anteil sehr negativer und negativer Smiley'
                    },
                    [RankingType.Bottom3Box]: {
                        name: 'Bottom3Box',
                        description: 'Prozentualer Anteil sehr negativer, negativer und neutraler Smiley'
                    },
                    [RankingType.BottomBox]: {
                        name: 'BottomBox',
                        description: 'Prozentualer Anteil sehr negativer Smiley'
                    },
                    [RankingType.AvgGrade]: {
                        name: 'MW (Schulnote)',
                        description: 'Mittelwert umgerechnet als Schulnote'
                    },
                    [RankingType.Avg]: {
                        name: 'MW',
                        description: 'Mittelwert über alle Antworten'
                    },
                    [RankingType.Count]: {
                        name: 'Anzahl Befragte',
                        description: ''
                    },
                    [RankingType.NPS]: {
                        name: 'NPS',
                        description: 'Anteil Promotoren (Antworten 9-10) - Anteil Detraktoren (Antworten 0-6)'
                    },
                    [RankingType.PercentPromoters]: {
                        name: 'Anteil Promotoren',
                        description: 'Prozentualer Anteil Promotoren (Antworten 9-10)'
                    },
                    [RankingType.PercentDetractors]: {
                        name: 'Anteil Detraktoren',
                        description: 'Prozentualer Anteil Detraktoren (Antworten 0-6)'
                    },
                    [RankingType.ServiceIndex]: {
                        name: 'Service Index',
                        description: 'Service-Index wird errechnet über alle Service-Dimensionen, maximale Punktzahl: 100'
                    }
                },
                rankingOrder: {
                    elements: {
                        [RankingOrder.BestFirst]: 'Beste zuerst',
                        [RankingOrder.WorstFirst]: 'Schwächste zuerst',
                    },
                    title: 'Reihenfolge'
                },
                grouping: {
                    title: 'Gruppierung',
                    elements: {
                        [ComparisonGrouping.Location]: 'Standort',
                        [ComparisonGrouping.Region]: 'Region',
                        [ComparisonGrouping.DataSource]: 'Datenquelle'
                    }
                },
                calculation: {
                    base: 'Basis: {0}'
                },
                question: {
                    shortName: 'Frage: {0}'
                },
                period: {
                    description: 'Zeitraum: {0}',
                    comparison: ' vs. {0}'
                },
                promotedDemoted: {
                    promoted: {
                        title: 'Aufsteiger',
                        none: 'Für Ihre Auswahl liegen keine Aufsteiger vor'
                    },
                    demoted: {
                        title: 'Absteiger',
                        none: 'Für Ihre Auswahl liegen keine Absteiger vor'
                    }
                }
            }
        },
        videos: {
            title: 'Videos',
            categories: {
                headline: 'Kategorie',
                sidebar: {
                    headline: 'Übersicht',
                    expand: 'Alles anzeigen',
                    collapse: 'Weniger anzeigen',
                    allCategories: {
                        headline: 'Kategorien',
                        categories: 'Alle Videos'
                    },
                    playlists: {
                        headline: 'Playlists'
                    }
                },
                filter: {
                    apply: 'Filter anwenden',
                    delete: 'Filter löschen'
                }
            },
            dashboard: {
                title: 'Videos',
                welcome: {
                    description: 'Willkommen zurück, {0}!'
                },
                tags: {
                    highlights: 'Beliebte Themen'
                },
                search: {
                    headline: 'Suchergebnisse',
                    placeholder: 'Wonach suchen Sie?',
                },
                sections: {
                    mostSeen: 'Meistgesehene Videos',
                    latest: 'Neueste Videos',
                    popular: 'Beliebteste Videos'
                },
                categories: {
                    headline: 'Beliebte Kategorien'
                },
                wishes: {
                    headline: 'Neueste Wünsche',
                    button: 'Wunsch erfüllen',
                    all: 'Alle Wünsche'
                },
                allVideos: 'Alle Videos',
                empty: 'Leider konnten wir keine Videos finden.',
                back: 'Zurück',
                admin: {
                    settings: {
                        title: 'Einstellungen',
                        edit: 'Video bearbeiten',
                        deactivate: {
                            title: 'Video deaktivieren',
                            headline: 'Möchten Sie das Video deaktivieren?',
                            content: 'Wenn Sie dieses Video deaktivieren dann ist dieses für andere Nutzer nicht mehr sichtbar.',
                            cancel: 'Abbrechen',
                            submit: 'Deaktiveren',
                            success: 'Ihr Video wurde erfolgreich deaktiviert.',
                            error: 'Ihr Video konnte nicht deaktiviert werden. Bitte versuchen Sie es später erneut.'
                        },
                        activate: {
                            title: 'Video aktivieren',
                            headline: 'Möchten Sie das Video aktivieren?',
                            content: 'Wenn Sie das Video wieder aktivieren dann ist dieses für andere Nutzer sichtbar.',
                            cancel: 'Abbrechen',
                            submit: 'Aktivieren',
                            success: 'Ihr Video wurde erfolgreich aktiviert.',
                            error: 'Ihr Video konnte nicht aktiviert werden. Bitte versuchen Sie es später erneut.'
                        },
                        delete: {
                            title: 'Video löschen',
                            headline: 'Möchten Sie das Video löschen?',
                            content: 'Wenn Sie dieses Video löschen ist dieses unwiderruflich gelöscht.',
                            cancel: 'Abbrechen',
                            submit: 'Löschen',
                            success: 'Ihr Video wurde erfolgreich gelöscht.',
                            error: 'Ihr Video konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.'
                        },
                    }
                },
                upload: {
                    link: 'Video hochladen',
                    title: 'Video hochladen',
                    headline: 'Video hochladen',
                    caption: 'Video: Upload',
                    wishes: 'Wünsche auswählen (optional)',
                    cancel: 'Abbrechen',
                    dragAndDrop: {
                        title: 'Drag and Drop',
                        subTitle: 'Alternativ durchsuchen Sie Ihre Dateien ',
                        here: 'hier.',
                        illegalFileType: 'Dieser Dateityp wird nicht unterstützt',
                        illegalFileTypeDescription: 'Versuchen Sie es erneut oder klicken Sie '
                    },
                    dragOver: 'Jetzt einfach loslassen!',
                    tags: 'Tags',
                    categories: {
                        title: 'Kategorie',
                        noOption: 'Nicht gefunden',
                        create: ' erstellen'
                    },
                    create: ' erstellen',
                    video: {
                        title: 'Titel',
                        description: 'Beschreibung',
                        creator: 'Autor',
                        acceptedFileTypes: 'Wir akzeptieren .mp4, .mov.',
                        upload: {
                            active: 'Ihr Video wird hochgeladen',
                            preparing: {
                                title: 'Ihr Video wird vorbereitet',
                                subLine: 'Dies kann einen Moment dauern'
                            },
                            save: {
                                title: 'Video hochladen',
                                active: 'Ihr Video wird live gestellt',
                                success: 'Ihr Video wurde live gestellt',
                                error: 'Ihr Video konnte nicht live gestellt werden',
                                link: 'Zum Video'
                            }
                        },
                        edit: {
                            save: 'Video speichern',
                            cancel: 'Abbrechen',
                            success: 'Ihr Video wurde erfolgreich aktualisiert'
                        }
                    }
                },
                video: {
                    creator: 'Autor',
                    length: 'min',
                    category: 'Kategorie',
                    categories: 'Kategorien',
                    description: {
                        title: 'Beschreibung',
                        more: 'Weiterlesen …',
                        less: 'Weniger anzeigen'
                    },
                    backToDashboard: 'Zurück zur Übersicht',
                    deactivated: 'deaktiviert'
                },
                loading: {
                    title: 'Ihre Videos werden geladen'
                },
                highlights: {
                    title: 'Aktuelle Highlights',
                    author: 'von {0}'
                }
            },
            wishList: {
                link: 'Wunschliste',
                headline: 'Wunschliste',
                popular: 'Beliebteste Wünsche',
                latest: 'Neueste Wünsche',
                finished: 'Erfüllte Wünsche',
                wish: {
                    creator: 'gewünscht von {0}',
                    more: 'Mehr anzeigen',
                    less: 'Weniger anzeigen',
                    create: {
                        headline: 'Neuen Wunsch erstellen',
                        headlineMobile: 'Neuer Wunsch',
                        button: 'Wunsch erstellen',
                        title: {
                            label: 'Wie lautet dein Wunsch?',
                            placeholder: 'Beschreibe deinen Wunsch möglichst kurz und prägnant'
                        },
                        description: {
                            label: 'Beschreibe deinen Wunsch:',
                            placeholder: 'Beschreibe hier deinen Wunsch möglichst detailliert...'
                        },
                        explanation: 'Dir fehlt ein Video? Hier kannst du andere um Hilfe bitten. Jeder kann deinen Wunsch nach oben voten. Je höher dein Wunsch im Ranking steht, desto wahrscheinlicher nimmt sich jemand dem Thema an. Los geht\'s!',
                        submit: 'Wunsch absenden',
                        submitMobile: 'Wünschen',
                        cancel: 'Abbrechen',
                        success: 'Der Wunsch wurde erfolgreich erstellt.',
                        errors: {
                            missing: 'Bitte fülle alle Felder aus.',
                            server: 'Der Wunsch konnte nicht erstellt werden. Bitte versuchen Sie es später erneut.'
                        }
                    },
                    finished: {
                        link: 'Zum Video',
                        author: 'erfüllt von {0}',
                    }
                }
            },
            video: {
                views: 'Aufrufe',
                actions: {
                    create: 'Erstellen'
                },
                creator: 'Creator',
                player: {
                    settings: {
                        quality: 'Qualität',
                        speed: 'Speed'
                    }
                },
                reactions: {
                    reactor: {
                        mobile: {
                            single: 'Gefällt {0}',
                            singular: 'Gefällt {0} und einer weiteren Person',
                            plural: 'Gefällt {0} und {1} weiteren Personen',
                            empty: ''
                        },
                        heart: {
                            single: 'Geliebt von {0}',
                            singular: 'Geliebt von {0} und einer weiteren Person',
                            plural: 'Geliebt von {0} und {1} weiteren Personen',
                            empty: 'Schicke als erstes ein Herz!'
                        },
                        like: {
                            single: 'Geliked von {0}',
                            singular: 'Geliked von {0} und einer weiteren Person',
                            plural: 'Geliked von {0} und {1} weiteren Personen',
                            empty: 'Mach den ersten Like!'
                        },
                        clap: {
                            single: 'Applaudiert von {0}',
                            singular: 'Applaudiert von {0} und einer weiteren Person',
                            plural: 'Applaudiert von {0} und {1} weiteren Personen',
                            empty: 'Applaudiere als erstes!'
                        },
                        you: 'Dir'
                    }
                },
                comments: {
                    numberOfComments: 'Kommentare ({0})',
                    me: 'Ich',
                    delete: {
                        button: 'Löschen',
                        title: 'Löschen bestätigen',
                        content: 'Sind Sie sicher, dass Sie diesen Kommentar löschen möchten?',
                        submit: 'Löschen',
                        cancel: 'Abbrechen',
                        error: 'Der Kommentar konnte nicht gelöscht werden.',
                        success: 'Der Kommentar wurde gelöscht.',
                        deleted: 'Dieser Kommentar wurde {0} gelöscht.',
                        deletedByCreator: 'von seinem Autor',
                        deletedByAdmin: 'von einem Admin'
                    },
                    input: {
                        placeholder: 'Schreiben Sie hier Ihren Kommentar…',
                        submit: 'Kommentieren',
                        error: 'Ihr Kommentar konnte nicht gespeichert werden.'
                    },
                    children: {
                        show: 'Antworten anzeigen',
                        hide: 'Antworten verstecken'
                    },
                    answers: {
                        button: 'Antworten',
                        cancel: 'Abbrechen'
                    },
                    time: {
                        justNow: 'Gerade eben',
                        minutes: {
                            singular: 'vor einer Minute',
                            plural: 'vor {0} Minuten'
                        },
                        hours: {
                            singular: 'vor einer Stunde',
                            plural: 'vor {0} Stunden'
                        },
                        days: {
                            singular: 'vor einem Tag',
                            plural: 'vor {0} Tagen'
                        },
                        weeks: {
                            singular: 'vor einer Woche',
                            plural: 'vor {0} Wochen'
                        },
                        months: {
                            singular: 'vor einem Monat',
                            plural: 'vor {0} Monaten'
                        },
                        years: {
                            singular: 'vor einem Jahr',
                            plural: 'vor {0} Jahren'
                        }
                    }
                },
                highlight: {
                    remove: {
                        description: 'Highlight entfernen',
                        success: 'Das Video wurde aktualisiert.',
                        error: 'Das Video konnte nicht aktualisiert werden.'
                    },
                    add: {
                        description: 'Highlight setzen',
                        success: 'Das Video wurde gehighlighted.',
                        error: 'Das Video konnte nicht gehighlighted werden.'
                    }
                }
            },
            playlists: {
                manage: {
                    headline: 'Playlist bearbeiten',
                    title: {
                        label: 'Titel',
                        placeholder: 'Bitte geben Sie einen Titel für die Playlist an'
                    },
                    subtitle: {
                        label: 'Subtitel',
                        placeholder: 'Bitte geben Sie eine Überschrift für die Beschreibung an'
                    },
                    description: {
                        label: 'Beschreibung',
                        placeholder: 'Bitte geben Sie eine Beschreibung für die Playlist an'
                    },
                    visibility: {
                        label: 'Sichtbarkeit',
                        placeholder: 'Wer kann die Playlist sehen?'
                    },
                    curate: {
                        label: 'Hervorheben',
                        placeholder: 'Playlist auf der Startseite?'
                    },
                    image: {
                        headline: {
                            default: 'Titelbild hochladen',
                            active: 'Ihr Bild wird hochgeladen',
                            illegalFileType: 'Dieser Dateityp wird nicht unterstützt'
                        },
                        subLine: {
                            default: 'Alternativ durchsuchen Sie Ihre Dateien ',
                            active: 'Ihr Bild wird hochgeladen',
                            illegalFileType: 'Versuchen Sie es erneut oder klicken Sie '
                        },
                        acceptedFileTypes: 'Wir akzeptieren .jpg und .png'
                    },
                    tags: {
                        label: 'Tags',
                        placeholder: 'Tippen Sie hier Ihre Tags ein und bestätigen Sie mit Enter'
                    },
                    sections: {
                        add: 'Abschnitt hinzufügen',
                        title: {
                            label: 'Titel',
                            placeholder: 'Abschnitt benennen'
                        },
                        video: {
                            add: 'Video hinzufügen'
                        }
                    },
                    actions: {
                        delete: 'Playlist löschen',
                        back: 'Zurück zur Übersicht',
                        update: 'Playlist aktualisieren',
                        create: 'Playlist anlegen',
                    },
                    videos: {
                        manage: {
                            headline: 'Videos hinzufügen:',
                            description: 'Wählen Sie Videos aus die zu {0} hinzugefügt werden sollen.',
                            descriptionWithoutSectionTitle: 'Wählen Sie Videos aus die zum Abschnitt hinzugefügt werden sollen.',
                            preview: 'Vorschau',
                            title: 'Titel',
                            author: 'Autor',
                            date: 'Datum',
                            selected: '{0} Videos insgesamt ausgewählt',
                            cancel: 'Abbrechen und zurück',
                            submit: 'Ausgewählte Videos hinzufügen',
                            search: 'Suchbegriff eingeben'
                        }
                    },
                    update: {
                        error: 'Die Playlist konnte nicht aktualisiert werden.',
                        success: 'Die Playlist wurde erfolgreich aktualisiert.'
                    },
                    menu: {
                        edit: 'Playlist bearbeiten',
                        curate: 'Playlist Highlight',
                        uncurate: 'Playlist Standard',
                        delete: 'Playlist löschen',
                        deactivate: 'Playlist deaktivieren',
                        activate: 'Playlist aktivieren',
                    },
                    highlight: {
                        success: 'Die Playlist wurde gehighlighted.',
                        error: 'Das Playlist konnte nicht gehighlighted werden.'
                    },
                    unhighlight: {
                        success: 'Die Playlist wurde wieder auf Standard zurückgesetzt.',
                        error: 'Das Playlist konnte nicht wieder auf Standard zurückgesetzt werden.'
                    },
                    enable: {
                        success: 'Die Playlist wurde erfolgreich aktiviert.',
                        error: 'Das Playlist konnte nicht aktiviert werden.',
                        headline: 'Möchten Sie die Playlist aktivieren?',
                        content: 'Wenn Sie die Playlist wieder aktivieren dann ist diese für andere Nutzer sichtbar.',
                        cancel: 'Abbrechen',
                        submit: 'Aktivieren'
                    },
                    disable: {
                        success: 'Die Playlist wurde erfolgreich deaktiviert.',
                        error: 'Das Playlist konnte nicht deaktiviert werden.',
                        headline: 'Möchten Sie die Playlist deaktivieren?',
                        content: 'Wenn Sie diese Playlist deaktivieren dann ist diese für andere Nutzer nicht mehr sichtbar.',
                        cancel: 'Abbrechen',
                        submit: 'Deaktiveren'
                    },
                    delete: {
                        success: 'Die Playlist wurde erfolgreich gelöscht.',
                        error: 'Die Playlist konnte nicht gelöscht werden.',
                        headline: 'Möchten Sie die Playlist löschen?',
                        content: 'Wenn Sie diese Playlist löschen ist diese unwiderruflich gelöscht.',
                        cancel: 'Abbrechen',
                        submit: 'Löschen'
                    }
                },
                create: {
                    headline: 'Playlist anlegen',
                    error: 'Die Playlist konnte nicht erstellt werden.',
                    success: 'Die Playlist wurde erfolgreich erstellt.'
                },
                overview: {
                    headline: 'Playlists',
                    subLine: 'Für dich zusammengestellt',
                    numberOfVideos: '{0} Videos',
                    numberOfMinutes: '{0} min',
                },
                detail: {
                    title: 'Playlist: {0}',
                    playlist: 'Playlist',
                },
                highlights: {
                    title: 'Playlists - für Dich zusammengestellt',
                    mobileTitle: 'Playlists für Dich',
                    allPlaylists: 'Alle Playlists'
                }
            }
        },
        reports: {
            timeScopes: {
                [ReportTimeScopeType.Day]: '{0}',
                [ReportTimeScopeType.Week]: 'KW {0}',
                [ReportTimeScopeType.Month]: '{0}',
                [ReportTimeScopeType.Total]: '',
                [ReportTimeScopeType.Custom]: '{0}-{1}',
                [ReportTimeScopeType.Quarter]: 'Q{0}',
                [ReportTimeScopeType.Year]: '{0}',
            },
            timeScopeDescription: {
                [ReportTimeScopeType.Day]: 'Tag',
                [ReportTimeScopeType.Week]: 'Woche',
                [ReportTimeScopeType.Month]: 'Monat',
                [ReportTimeScopeType.Total]: 'Gesamt',
                [ReportTimeScopeType.Custom]: 'Sonderreporting',
                [ReportTimeScopeType.Quarter]: 'Quartal',
                [ReportTimeScopeType.Year]: 'Jahr'
            },
            timeSpanDescription: {
                [ReportTimeSpanType.Day]: 'Tagesreporting',
                [ReportTimeSpanType.Week]: 'Wochen-Update',
                [ReportTimeSpanType.Month]: 'Monatsauswertung',
                [ReportTimeSpanType.Total]: 'Gesamtauswertung',
                [ReportTimeSpanType.Custom]: 'Sonderauswertung',
                [ReportTimeSpanType.Quarter]: 'Quartalsauswertung',
                [ReportTimeSpanType.Year]: 'Jahresauswertung',
            },
            reportScope: {
                [ReportScopeType.Department]: '{0}',
                [ReportScopeType.Location]: '{0}',
                [ReportScopeType.Region]: '{0}',
                [ReportScopeType.Central]: '{0}',
            },
            reportScopeDescription: {
                [ReportScopeType.Department]: 'Abteilung',
                [ReportScopeType.Location]: 'Standort',
                [ReportScopeType.Region]: 'Region',
                [ReportScopeType.Central]: 'Zentrale',
            },
            latest: {
                title: 'Ihre letzten Auswertungen'
            },
            actions: {
                download: 'Download',
                fullscreen: 'Vollbild',
                closeDialog: 'Vollbild schließen',
                detail: 'Öffnen'
            },
            topBar: {
                level: {
                    title: 'Ebene',
                    all: 'Alle'
                },
                timeSpan: {
                    title: 'Zeitraum'
                },
                detail: {
                    search: {
                        notFound: 'Keine Resultate',
                        results: 'Ergebnis {0}/{1}',
                        placeholder: 'Auswertung durchsuchen'
                    }
                }
            },
            search: {
                title: 'Auswertungen - Suche',
                noResults: 'Für Ihre Auswahl wurden keine Auswertungen gefunden.'
            },
            download: {
                success: {
                    title: 'Ihr Download wurde gestartet.',
                    errorDescription: 'Sollte Ihr Download nicht gestartet worden sein, klicken Sie einfach den Download-Button.',
                    startDownload: 'Download',
                    description: 'Melden Sie sich in Ihren Qualitize Online Zugang ein und entdecken Sie noch mehr Möglichkeiten.'
                },
                error: {
                    title: 'Ihr Downloadlink ist leider abgelaufen.',
                    description: 'Sie finden alle Ihre Reportings im Archiv Ihres Online-Zugangs. Klicken Sie einfach auf den Button und loggen sich ein.',
                },
                loginButton: 'Jetzt anmelden',
                archiveLink: 'Reporting online einsehen'
            },
            notFound: 'Die Datei "{0}" konnte nicht geladen werden.'
        },
        operations: {
            sidebar: {
                overview: 'Übersicht',
                actions: {
                    addToDo: 'Neues To Do'
                },
                moreToDos: {
                    singular: '+{0} weiteres ToDo',
                    plural: '+{0} weitere ToDos'
                },
                nextToDos: {
                    title: 'Nächste To Dos',
                    showMore: 'Mehr To Dos anzeigen',
                    showLess: 'Weniger To Dos anzeigen'
                },
                reviewToDos: {
                    title: 'Meine zu überprüfenden To Dos'
                },
                reviewRequests: {
                    title: 'Meine zu überprüfenden Anträge'
                },
                toDosInReview: {
                    title: 'Meine To Dos in Prüfung'
                },
                subordinated: {
                    title: 'To Dos in meinem Team'
                },
                subordinatedReviews: {
                    title: 'Prüfungen in meinem Team'
                },
                subordinatedRequestReviews: {
                    title: 'Anträge in meinem Team'
                },
                requests: {
                    open: {
                        title: 'Freigabe-Anträge'
                    },
                    approved: {
                        title: 'Freigegebene Anträge'
                    },
                    expired: {
                        title: 'Abgelaufene Anträge'
                    },
                    declined: {
                        title: 'Abgelehnte Anträge'
                    }
                },
                finishedToDos: {
                    title: 'To Do Archiv',
                    showMore: 'Mehr To Dos anzeigen',
                    showLess: 'Weniger To Dos anzeigen'
                },
                dashboards: {
                    title: 'Dashboards',
                    showMore: 'Weitere anzeigen',
                    showLess: 'Weniger anzeigen'
                }
            },
            subSidebar: {
                title: 'Anhänge & Erläuterungen'
            },
            overview: {
                showAll: 'Zeige alle To Dos',
                showAllReviews: 'Zeige alle Prüfungen',
                empty: 'Es wurden keine To Dos für Ihre Suche gefunden. Bitte passen Sie Ihre Filter-Einstellungen an.',
                resetFilter: 'Filter zurücksetzen',
                archive: {
                    title: 'Zeige To Do Archiv',
                    empty: 'Es wurden keine To Dos für Ihre Suche {0} in Ihrem Archiv gefunden.',
                    description: 'Hier können Sie alle To Dos, welche bereits abgeschlossen wurden noch einmal einsehen, als PDF herunterladen oder direkt per E-Mail in Ihrem Unternehmen teilen.'
                },
                title: {
                    overview: 'Übersicht',
                    pending: 'Nächste To Dos',
                    pendingWithGroup: 'Weitere To Dos',
                    finished: 'Abgeschlossene To Dos'
                },
                requests: {
                    title: 'Meine Freigabe-Anträge',
                    pending: 'Freigabe-Anträge',
                    pendingWithGroup: 'Freigabe-Anträge',
                    edit: 'Antrag bearbeiten',
                    showAll: 'Alle Anträge anzeigen'
                },
                approvedRequests: {
                    title: 'Freigegebene Anträge',
                    showAll: 'Alle freigegebenen Anträge zeigen',
                    show: 'Antrag anzeigen'
                },
                declinedRequests: {
                    title: 'Abgelehnte Anträge',
                    showAll: 'Alle abgelehnten Anträge zeigen',
                    show: 'Antrag anzeigen'
                },
                expiredRequests: {
                    title: 'Abgelaufene Anträge',
                    showAll: 'Alle abgelaufenen Anträge zeigen',
                    show: 'Antrag anzeigen'
                },
                inReview: {
                    title: 'To Dos in Prüfung',
                    description: 'Hier sehen Sie Ihre To Dos, welche aktuell überprüft werden. Sobald die Prüfung abgeschlossen ist werden Sie per E-Mail benachrichtigt.',
                    empty: 'Es wurden keine To Dos für Ihre Suche {0} gefunden.',
                    tooltip: {
                        title: 'In Prüfung bei'
                    }
                },
                requestsInReview: {
                    title: 'Anträge in Prüfung',
                    description: 'Hier sehen Sie Ihre Antrage, welche aktuell überprüft werden. Sobald die Prüfung abgeschlossen ist werden Sie per E-Mail benachrichtigt.',
                    empty: 'Es wurden keine Anträge für Ihre Suche {0} gefunden.',
                    tooltip: {
                        title: 'In Prüfung bei'
                    }
                },
                userReview: {
                    title: 'To Dos zur Überprüfung',
                    description: 'Hier sehen Sie To Dos, welche von Ihnen überprüft werden müssen. Klicken Sie einfach auf ein To Do um mit der Prüfung zu starten.',
                    empty: 'Es wurden keine To Dos für Ihre Suche {0} gefunden.',
                    actions: {
                        reviewer: {
                            button: 'Prüfer hinzufügen',
                            description: 'Klicken Sie hier um einen weiteren Prüfer zu dieser Prüfung hinzuzufügen.'
                        }
                    }
                },
                userReviewRequests: {
                    title: 'Anträge zur Überprüfung',
                    description: 'Hier sehen Sie Anträge, welche von Ihnen überprüft werden müssen. Klicken Sie einfach auf einen Antrag um mit der Prüfung zu starten.',
                    empty: 'Es wurden keine Anträge für Ihre Suche {0} gefunden.',
                    actions: {
                        reviewer: {
                            button: 'Prüfer hinzufügen',
                            description: 'Klicken Sie hier um einen weiteren Prüfer zu dieser Prüfung hinzuzufügen.'
                        }
                    }
                },
                otherToDos: {
                    title: 'To Dos in meinem Team',
                    actions: 'Aktionen',
                    empty: 'Es wurden keine für Ihre Suche {0} gefunden.',
                    description: 'Hier sehen Sie To Dos, welche von Ihren Mitarbeitern aktuell bearbeitet werden. Sie können die Deadline anpassen, neue Bearbeiter hinzufügen oder selbst das To Do bearbeiten.'
                },
                otherReviews: {
                    title: 'Prüfungen in meinem Team',
                    actions: 'Aktionen',
                    empty: 'Es wurden keine Prüfungen für Ihre Suche {0} gefunden.',
                    description: 'Hier sehen Sie Prüfungen, welche von Ihren Mitarbeitern aktuell bearbeitet werden. Sie können die Deadline anpassen, neue Prüfer hinzufügen oder die Prüfung selbst bearbeiten.'
                },
                otherReviewRequests: {
                    title: 'Anträge in meinem Team',
                    actions: 'Aktionen',
                    empty: 'Es wurden keine Anträge für Ihre Suche {0} gefunden.',
                    description: 'Hier sehen Sie Anträge, welche von Ihren Mitarbeitern aktuell bearbeitet werden. Sie können die Deadline anpassen, neue Prüfer hinzufügen oder den Antrag selbst bearbeiten.'
                },
                toDoGroup: {
                    title: 'Meine To Dos: {0}'
                },
                requestGroup: {
                    title: 'Meine Anträge: {0}'
                },
                toDo: {
                    editor: {
                        title: 'Bearbeiter',
                        add: {
                            action: 'Bearbeiter hinzufügen',
                            dialog: {
                                title: 'Bearbeiter hinzufügen',
                                input: {
                                    label: 'Bearbeiter suchen',
                                    placeholder: 'E-Mail-Adresse eingeben'
                                },
                                noneSelected: 'Kein neuer Bearbeiter ausgewählt',
                                selectedEditors: 'Ausgewählte Bearbeiter',
                                emptyResults: 'Für Ihre Suche wurden keine Bearbeiter gefunden',
                                actions: {
                                    submit: 'Hinzufügen',
                                    cancel: 'Abbrechen'
                                },
                                success: 'Die Bearbeiter wurden erfolgreich aktualisiert',
                                error: 'Die Bearbeiter konnten nicht aktualisiert werden'
                            }
                        }
                    },
                    search: {
                        placeholder: 'Suchen',
                        label: 'To Dos durchsuchen'
                    },
                    interval: {
                        [ToDoInterval.Daily]: 'täglich',
                        [ToDoInterval.Weekly]: 'wöchentlich',
                        [ToDoInterval.Monthly]: 'monatlich',
                        [ToDoInterval.Yearly]: 'jährlich',
                        [ToDoInterval.ByMonthly]: '2 mal pro Monat',
                        [ToDoInterval.CustomDays]: 'benutzerdefiniert',
                        [ToDoInterval.OneTime]: 'einmalig',
                        [ToDoInterval.Every4Weeks]: '4-wöchentlich',
                        [ToDoInterval.Every6Weeks]: '6-wöchentlich',
                        [ToDoInterval.HalfYearly]: 'halbjährlich',
                        [ToDoInterval.Quarterly]: 'vierteljährlich',
                        [ToDoInterval.EveryTwoYears]: 'alle 2 Jahre',
                        [ToDoInterval.EveryTwoWeeks]: 'alle 2 Wochen',
                    },
                    saveAndContinueLater: {
                        button: 'Später fortfahren',
                        dialog: {
                            headline: 'To Do später fortfahren',
                            description: 'Alle Änderungen sind gespeichert. Wir senden Ihnen eine E-Mail mit einen Link, über welchen Sie später zu diesem To Do zurückkehren können.',
                            confirm: 'E-Mail senden',
                            cancel: 'Abbrechen'
                        },
                        success: 'Sie erhalten in Kürze eine E-Mail mit einem Link.',
                        error: 'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
                    },
                    start: 'To Do bearbeiten',
                    info: {
                        completedTasks: '{0} von {1} Tasks erledigt',
                        tasks: 'Tasks',
                        completed: 'erledigt'
                    },
                    noResults: 'Keine Suchergebnisse',
                    none: 'Sie haben aktuell keine offenen To Dos.',
                    until: '{0}, bis {1}',
                    pagination: {
                        description: '{0}-{1} von {2}'
                    },
                    actions: {
                        view: {
                            button: 'To Do anzeigen'
                        },
                        download: {
                            button: 'Download',
                            buttonSelect: '{0} To Dos herunterladen',
                            buttonSingleSelect: '{0} To Do herunterladen',
                            tableView: 'Tabellenansicht herunterladen',
                            defaultView: 'PDF inkl. Bilder herunterladen'
                        },
                        reOpen: {
                            button: 'Erneut zur Bearbeitung freischalten',
                            dialog: {
                                title: 'To Do zur Bearbeitung freischalten',
                                descriptions: 'Möchten Sie das To Do {0} wieder zur Bearbeitung freischalten?',
                                deadline: 'Bitte wählen Sie eine Deadline für die Bearbeitung aus:',
                                submit: 'Freischalten'
                            },
                            notifications: {
                                success: 'Das To Do wurde erneut zur Bearbeitung freigeschaltet.',
                                error: 'Das To Do konnte nicht wieder zur Bearbeitung freigeschaltet werden.'
                            }
                        },
                        review: {
                            show: 'Prüfung anzeigen',
                            reOpen: 'Prüfung wieder aufnehmen'
                        },
                        email: {
                            button: 'Per E-Mail versenden',
                            buttonMobile: 'E-Mail',
                            buttonSelect: '{0} To Dos per E-Mail versenden',
                            buttonSingleSelect: '{1} To Do per E-Mail versenden',
                            dialog: {
                                title: 'E-Mail versenden',
                                subject: {
                                    label: 'Betreff',
                                    placeholder: 'Qualitize Operations: Zusammenfassung'
                                },
                                text: {
                                    label: 'Nachricht',
                                    placeholder: 'Geben Sie hier Ihre Nachricht ein'
                                },
                                recipients: {
                                    label: 'Empfänger',
                                    placeholder: 'Geben Sie eine E-Mail-Adresse ein'
                                },
                                defaultSubject: 'Qualitize Operations: Zusammenfassung {0}',
                                defaultSubjectMultiple: 'Qualitize Operations: To Do Übersicht',
                                defaultGreeting: '\n\nViele Grüße, \n{0}',
                                attachment: 'Anhang',
                                submit: 'E-Mail senden',
                                cancel: 'Abbrechen',
                                success: 'Ihre E-Mail wurde erfolgreich gesendet.',
                                error: 'Ihre E-Mail konnte nicht gesendet werden.',
                                search: {
                                    loading: 'Lade Empfänger',
                                    noOptions: 'Keine Empfänger gefunden'
                                }
                            }
                        },
                        open: 'To Do anzeigen',
                        collapse: {
                            open: 'Alle To Dos anzeigen',
                            close: 'Weniger To Dos anzeigen'
                        },
                        moreActions: 'Weitere Aktionen',
                        changeDeadline: {
                            button: 'Deadline anpassen',
                            help: 'Hier können Sie die Deadline des To Dos auf einen Tag Ihrer Wahl verschieben.',
                            dialog: {
                                title: 'Deadline anpassen',
                                descriptions: 'Wählen Sie hier eine neue Deadline für das To Do aus.',
                                submit: 'Speichern'
                            },
                            notifications: {
                                success: 'Die Deadline wurde erfolgreich auf den {0} angepasst.',
                                error: 'Die Deadline konnte nicht auf den {0} angepasst werden.'
                            }
                        },
                        takeOverToDo: {
                            button: 'To Do übernehmen',
                            help: 'Klicken Sie hier, wenn Sie das To Do selbst bearbeiten möchten.'
                        }
                    }
                },
                review: {
                    reviewer: {
                        title: 'Prüfer',
                        add: {
                            action: 'Prüfer hinzufügen',
                            dialog: {
                                title: 'Prüfer hinzufügen',
                                input: {
                                    label: 'Prüfer suchen',
                                    placeholder: 'E-Mail-Adresse eingeben'
                                },
                                noneSelected: 'Kein neuer Prüfer ausgewählt',
                                selectedEditors: 'Ausgewählte Prüfer',
                                emptyResults: 'Für Ihre Suche wurden keine Prüfer gefunden',
                                actions: {
                                    submit: 'Hinzufügen',
                                    cancel: 'Abbrechen'
                                },
                                success: 'Die Prüfer wurden erfolgreich aktualisiert',
                                error: 'Die Prüfer konnten nicht aktualisiert werden'
                            }
                        }
                    },
                    actions: {
                        changeDeadline: {
                            button: 'Deadline anpassen',
                            help: 'Hier können Sie die Deadline der Prüfung auf einen Tag Ihrer Wahl verschieben.',
                            dialog: {
                                title: 'Deadline anpassen',
                                descriptions: 'Wählen Sie hier eine neue Deadline für die Prüfung aus.',
                                submit: 'Speichern'
                            },
                            notifications: {
                                success: 'Die Deadline wurde erfolgreich auf den {0} angepasst.',
                                error: 'Die Deadline konnte nicht auf den {0} angepasst werden.'
                            }
                        },
                        takeOverReview: {
                            button: 'Prüfung übernehmen',
                            help: 'Klicken Sie hier, wenn Sie die Prüfung selbst bearbeiten möchten.'
                        }
                    },
                    approval: {
                        restrictedApprovalDate: 'Freigabe gültig bis:'
                    }
                }
            },
            topBar: {
                locations: {
                    all: 'Alle',
                    allLocations: 'Alle Standorte'
                },
                toDos: {
                    button: 'To Do: {0}',
                    all: 'Alle',
                    allToDos: 'Alle To Dos'
                }
            },
            dialogs: {
                createToDoDialog: {
                    title: 'To Do erstellen',
                    submit: 'Erstellen',
                    cancel: 'Abbrechen',
                    selectToDo: {
                        label: 'To Do auswählen',
                        placeholder: 'Wählen Sie ein To Do aus'
                    },
                    selectLocation: {
                        label: 'Standort auswählen',
                        placeholder: 'Wählen Sie einen Standort aus'
                    },
                    success: 'Ihr To Do wurde erstellt.',
                    error: 'Ihr To Do konnte nicht erstellt werden, bitte versuchen Sie es später erneut.',
                    hint: {
                        description: 'Es besteht bereits ein ToDo für Ihre Auswahl.',
                        link: 'To Do jetzt bearbeiten'
                    }
                }
            },
            files: {
                preview: {
                    notAvailable: 'Keine Vorschau für die Datei "{0}" verfügbar. Klicken Sie hier um die Datei herunterzuladen.',
                    fullscreen: 'Vollbild'
                }
            },
            edit: {
                sync: {
                    success: 'Alle Änderungen gespeichert',
                    error: {
                        singular: '{0} Änderung nicht gespeichert',
                        plural: '{0} Änderungen nicht gespeichert',
                    },
                    close: 'Ihre Änderungen werden eventuell nicht gespeichert.'
                },
                files: {
                    upload: {
                        headline: 'Anhänge',
                        progress: 'Ihr Anhang wird hochgeladen',
                        preparing: 'Ihr Anhang wird vorbereitet',
                        error: {
                            notification: 'Ihr Anhang konnte nicht hochgeladen werden',
                            cancel: 'Anhang löschen',
                            retry: 'Erneut versuchen',
                            uploadTooOld: 'Die ausgewählte Datei darf nicht älter als {0} sein.'
                        },
                        button: {
                            default: 'Anhang auswählen',
                            more: 'Weitere auswählen',
                            required: '(Pflicht)',
                            multipleRequired: '({0} Pflicht)'
                        },
                        dialog: {
                            delete: {
                                title: 'Upload löschen',
                                content: 'Möchten Sie die hochgeladene Datei endgültig löschen?',
                                submit: 'Endgültig löschen',
                                cancel: 'Abbrechen'
                            }
                        }
                    }
                },
                text: {
                    input: {
                        show: 'Bemerkung hinzufügen',
                        hide: 'Bemerkung entfernen',
                        placeholder: 'Geben Sie hier Ihre Bemerkung ein',
                        remove: {
                            title: 'Bemerkung entfernen',
                            description: 'Möchten Sie die folgende Bemerkung wirklich entfernen?',
                            cancel: 'Abbrechen',
                            submit: 'Entfernen'
                        }
                    }
                },
                input: {
                    placeholder: 'Bitte hier eingeben',
                    label: 'Eingabe'
                },
                questions: {
                    boolean: {
                        default: {
                            true: 'Ok',
                            false: 'Nicht ok',
                            notSpecified: 'Keine Angabe'
                        },
                        checkbox: {
                            active: 'Bearbeitet',
                            inactive: 'Nicht bearbeitet'
                        }
                    },
                    numberInput: {
                        invalid: 'Bitte geben Sie eine gültige Zahl ein. Bitte geben Sie keine Tausender-Trennzeichen ein und verwenden für Dezimalzahlen "{0}" als Trennzeichen.',
                        invalidRange: {
                            lowerBound: 'Der eingegebene Wert muss größer als {0} sein.',
                            lowerBoundIncluded: 'Der eingegebene Wert muss größer oder gleich {0} sein.',
                            upperBound: 'Der eingegebene Wert muss kleiner als {0} sein.',
                            upperBoundIncluded: 'Der eingegebene Wert muss kleiner oder gleich {0} sein.',
                        }
                    }
                },
                appendices: {
                    actions: {
                        fullScreen: {
                            open: 'Vergrößern',
                            show: 'Anzeigen',
                            close: 'Schließen'
                        },
                        download: 'Download'
                    }
                },
                actions: {
                    next: {
                        desktop: 'Weiter ({0})',
                        mobile: 'Weiter'
                    },
                    resetTask: {
                        button: 'Task zurücksetzen',
                        dialog: {
                            title: 'Task "{0}" zurücksetzen',
                            description: 'Möchten Sie den Task wirklich zurücksetzen? Diese Aktion kann nicht rückgängig gemacht werden.',
                            submit: 'Task zurücksetzen'
                        }
                    },
                    start: 'To Do bearbeiten',
                    view: 'To Do anzeigen',
                    previous: {
                        desktop: 'Zurück ({0})',
                        mobile: 'Zurück'
                    },
                    toggleTasks: {
                        show: 'Alle Tasks ausklappen',
                        hide: 'Alle Tasks einklappen'
                    },
                    backToOverview: 'Zurück zur Übersicht',
                    finish: {
                        button: {
                            text: 'To Do abschließen',
                            mobile: 'Abschließen',
                        },
                        description: 'Möchten Sie das To Do abschließen? Es kann anschließend nicht mehr bearbeitet werden.',
                        submit: 'Abschließen',
                        cancel: 'Abbrechen',
                        success: 'Das To Do wurde erfolgreich abgeschlossen.',
                        error: 'Das To Do konnte nicht abgeschlossen werden.'
                    },
                    review: {
                        description: 'Möchten Sie das To Do zur Prüfung abschicken? Es kann anschließend nicht mehr bearbeitet werden.',
                        submit: 'Abschicken',
                        cancel: 'Abbrechen',
                        title: 'To Do zur Prüfung abschicken',
                        success: 'Das To Do wurde erfolgreich zur Prüfung abgeschickt.',
                        error: 'Das To Do konnte nicht zur Prüfung abgeschickt werden.',
                        reviewer: 'Das To Do wird an folgende Personen zur Prüfung verschickt:',
                        autoApprove: {
                            title: 'To Do abschließen',
                            description: 'Dieses To Do kann automatisch freigegeben werden. Klicken Sie auf "To Do abschließen" um das To Do abzuschließen',
                            submit: 'To Do abschließen',
                            notifications: {
                                error: 'Es ist ein Fehler aufgetreten.'
                            }
                        },
                        autoDecline: {
                            title: 'To Do abgelehnt',
                            description: 'Dieses To Do wurde automatisch abgelehnt. Klicken Sie auf "Weiter" um zur Übersichtsseite zu gelangen.',
                            submit: 'Weiter',
                            notifications: {
                                error: 'Es ist ein Fehler aufgetreten.'
                            }
                        }
                    },
                    mails: {
                        trigger: 'Mail versenden'
                    },
                    downloadFile: {
                        trigger: 'Datei herunterladen'
                    },
                    phone: {
                        trigger: '{0} anrufen'
                    },
                    link: {
                        open: 'Link öffnen'
                    }
                },
                state: {
                    general: {
                        finished: 'Abgeschlossen',
                        editing: 'In Bearbeitung',
                        pending: 'Ausstehend',
                        approved: 'Freigegeben',
                        edited: 'Bearbeitet',
                        readOnly: 'Lese-Ansicht',
                        frozen: 'Nicht mehr bearbeitbar',
                        optional: 'Optional'
                    },
                    tasks: {
                        pending: {
                            singular: '{0} ausstehender Task',
                            plural: '{0} ausstehende Tasks'
                        },
                        optional: '({0} optional)'
                    }
                },
                previousToDos: {
                    title: 'Letzte Antworten',
                    noPreviousAnswers: 'Keine Historie verfügbar.'
                },
                reviews: {
                    title: 'Anmerkungen von {0} ({1} Uhr)'
                }
            },
            archive: {
                notEdited: 'Nicht bearbeitet',
                files: {
                    singular: '{0} Upload',
                    plural: '{0} Uploads',
                    none: 'Keine Uploads vorhanden'
                },
                table: {
                    header: {
                        task: 'Aufgabe',
                        answer: 'Antwort',
                        remarks: 'Bemerkung',
                        uploads: 'Uploads'
                    },
                    export: {
                        page: 'Seite'
                    }
                },
                topBar: {
                    actions: {
                        button: 'Aktionen'
                    }
                },
                view: {
                    [ArchiveVisualizationType.Table]: 'Tabellenansicht',
                    [ArchiveVisualizationType.Grid]: 'Rasteransicht',
                    [ArchiveVisualizationType.Review]: 'Prüfungsansicht',
                    [ArchiveVisualizationType.Inline]: 'Inline-Ansicht',
                }
            },
            reviewRequest: {
                link: 'Antrag überprüfen'
            },
            review: {
                link: 'To Do überprüfen',
                title: 'Überprüfung {0}',
                specification: {
                    title: 'Vorgabe'
                },
                declined: {
                    reason: 'Grund für die Ablehnung',
                    autoDeclined: 'Vom System automatisch aufgrund von zentraler Vorgaben abgelehnt.'
                },
                topBar: {
                    progress: {
                        title: 'Fortschritt der Prüfung',
                        tooltips: {
                            pending: {
                                singular: '{0} ausstehende Prüfung',
                                plural: '{0} ausstehende Prüfungen'
                            },
                            disapproved: {
                                singular: '{0} nicht freigegebener Task',
                                plural: '{0}  nicht freigegebene Tasks'
                            },
                            approved: {
                                singular: '{0} freigegebener Task',
                                plural: '{0}  freigegebene Tasks'
                            }
                        }
                    }
                },
                approval: {
                    preview: 'Umsetzung',
                    title: 'Überprüfung',
                    task: 'Task:',
                    approve: 'Freigeben',
                    disapprove: 'Nicht freigeben',
                    zoom: {
                        description: 'Sie können mit Hilfe Ihrer Maus in ein Bild hineinzoomen. Fahren Sie hierfür einfach mit der Maus über das Bild und scrollen Sie. Klicken Sie auf "Aus" um das Zoomen zu deaktivieren.',
                        title: 'Zoom-Modus',
                        on: 'An',
                        off: 'Aus'
                    }
                },
                remarks: {
                    title: 'Anmerkungen'
                },
                decline: {
                    confirmationDialog: {
                        title: 'Antrag ablehnen und schließen',
                        description: 'Möchten Sie die Bearbeitung des Antrags ablehnen und den Antrag schließen? Er kann nachträglich nicht mehr bearbeitet werden.',
                        submit: 'Antrag ablehnen und schließen',
                        reason: {
                            label: 'Begründung',
                            placeholder: 'Bitte geben Sie eine Begründung ein.'
                        },
                        notifications: {
                            success: 'Der Antrag wurde erfolgreich abgelehnt. Die Bearbeiter wurden per E-Mail informiert.',
                            error: 'Der Antrag konnte nicht abgelehnt werden. Bitte überprüfen Sie Ihre Angaben.'
                        }
                    }
                },
                finish: 'Prüfung abschließen',
                tasksToReview: {
                    title: 'To Dos zur Überprüfung',
                },
                reviewedTasks: {
                    title: 'Bereits geprüfte Tasks',
                    approved: {
                        title: 'Freigegeben',
                        yes: 'Ja',
                        no: 'Nein'
                    },
                    reOpen: 'Erneut prüfen'
                },
                finishedTasks: {
                    title: 'Nicht zu überprüfende Tasks'
                },
                modal: {
                    cancel: 'Abbrechen',
                    title: 'Prüfung abschließen',
                    description: {
                        approved: 'Möchten Sie die Prüfung abschließen und freigeben?',
                        disapproved: 'Möchten Sie die Prüfung abschließen und zur Nachbesserung freigeben?'
                    },
                    editors: 'Folgende Bearbeiter werden über das Ergebnis Ihrer Prüfung per E-Mail informiert:',
                    deadline: {
                        description: 'Bitte geben Sie ein Datum an, bis zu welchem die Nachbesserung durchgeführt werden soll:',
                        select: 'Datum auswählen'
                    },
                    timeRestriction: {
                        description: 'Bitte geben Sie ein Datum an, bis zu welchem die Freigabe gilt:',
                        descriptionOptional: 'Möchten Sie ein Datum angeben bis zu welchem die Freigabe gilt (optional):',
                        select: 'Datum auswählen'
                    }
                },
                notifications: {
                    success: 'Die Prüfung wurde erfolgreich abgeschlossen.',
                    error: 'Die Prüfung konnte nicht abgeschlossen werden'
                },
                reOpen: {
                    action: {
                        button: 'Prüfung wieder aufnehmen'
                    },
                    modal: {
                        title: 'Prüfung wieder aufnehmen',
                        description: 'Möchten Sie die Prüfung wieder aufnehmen? Alle Prüfer können auf die Prüfung zugreifen und Ihre bisherige Prüfungen wieder bearbeiten.',
                        deadline: 'Bitte wählen Sie eine neue Deadline für die Prüfung aus:',
                        submit: 'Prüfung wieder aufnehmen'
                    },
                    notifications: {
                        success: 'Die Prüfung wurde erfolgreich wieder aufgenommen',
                        error: 'Die Prüfung konnte nicht wieder aufgenommen werden.'
                    }
                }
            },
            search: {
                results: {
                    searchString: 'Suchbegriff: {0}',
                    locations: {
                        singular: 'Standort: {0}',
                        plural: 'Standorte: {0}'
                    },
                    toDos: {
                        singular: 'To Do: {0}',
                        plural: 'To Dos: {0}'
                    }
                }
            }
        },
        landingPages: {
            empty: 'Für Sie stehen noch keine Magic QR-Codes zur Verfügung.',
            error: 'Ihre Magic QR-Codes konnten nicht geladen werden.',
            info: {
                createdAt: 'Erstellt am:',
                modifiedAt: 'Bearbeitet am:',
                type: 'Magic QR-Code Typ:'
            },
            types: {
                [LandingPageConfigType.Layout]: 'Landing Page',
                [LandingPageConfigType.Redirect]: 'Website-Redirect'
            },
            overview: {
                link: 'Magic QR-Code öffnen',
                topBar: {
                    hierarchyNodeSelection: {
                        empty: 'Ebene: Alle',
                        selectNone: 'Alle'
                    },
                    search: {
                        placeholder: 'Wonach suchen Sie?'
                    }
                },
                table: {
                    header: {
                        hierarchyNode: 'Ebene',
                        name: 'Name',
                        type: 'Magic QR-Code Typ',
                        createdTimestamp: 'Erstellungsdatum',
                        modifiedTimestamp: 'Zuletzt bearbeitet',
                        demo: '',
                        actions: 'Aktionen'
                    }
                }
            },
            state: {
                type: {
                    [LandingPageReleaseStateType.PreRelease]: 'In Vorbereitung',
                    [LandingPageReleaseStateType.Inactive]: 'Inaktiv',
                    [LandingPageReleaseStateType.Active]: 'Aktiv',
                    [LandingPageReleaseStateType.ServeHistoricVersion]: 'Alte Version in Verwendung',
                },
                dialogs: {
                    prerelease: {
                        title: 'Status-Änderung bestätigen',
                        submit: 'Status ändern',
                        explanation: 'Der Status des Magic QR-Codes wird von "In Vorbereitung" auf "{0}" gesetzt. Wenn Sie diesen Status ändern, können Sie anschließend nicht mehr den verknüpften Bereich ({1}) des Magic QR-Codes ändern.'
                    }
                }
            },
            preview: {
                title: 'Vorschau'
            },
            edit: {
                save: 'Änderungen speichern',
                type: {
                    dialog: {
                        confirmation: {
                            title: 'Typen ändern',
                            description: 'Möchten Sie den Typen wirklich ändern? Alle bisher bereits getätigte Konfiguration wird anschließend zurückgesetzt.',
                            submit: 'Typen ändern'
                        }
                    }
                },
                cards: {
                    stepper: 'Magic QR-Code konfigurieren',
                    preview: 'Vorschau'
                },
                topBar: {
                    title: 'Magic QR-Code {0}'
                },
                links: {
                    title: 'Verlinkung',
                    variants: {
                        [LandingPageLinkType.Explicit]: 'Eigene URL',
                        [LandingPageLinkType.Feedback]: 'Feedback',
                    },
                    explicit: {
                        input: {
                            label: 'Link',
                            placeholder: 'Bitte geben Sie einen Link ein'
                        },
                        empty: 'Für den ausgewählten Bereich stehen keine Umfragen zur Verfügung.',
                        select: 'Auswählen'
                    },
                    feedback: {
                        title: 'Umfrage'
                    }
                },
                language: {
                    title: 'Sprache',
                    availableLanguages: 'Verfügbare Sprachen',
                    select: {
                        theme: 'Für welche Sprache definieren Sie den Browser-Titel und den Hintergrund?',
                        content: 'Für welche Sprache erstellen Sie den Inhalt?'
                    }
                },
                steps: {
                    step1: {
                        title: 'Typen & Namen bestimmen',
                        submit: {
                            edit: 'Speichern und fortfahren',
                            create: 'Magic QR-Code erstellen'
                        }
                    },
                    step2: 'Farben & Schriftarten definieren',
                    step3: 'Inhalt erstellen',
                    stepRedirect: 'Redirect definieren'
                },
                popover: {
                    apply: 'Änderungen anwenden'
                },
                rows: {
                    create: {
                        title: 'Zeile hinzufügen'
                    },
                    remove: {
                        title: 'Zeile löschen',
                        description: 'Möchten Sie diese Zeile wirklich löschen?',
                        submit: 'Löschen'
                    },
                    duplicate: {
                        title: 'Zeile duplizieren'
                    },
                    types: {
                        [LandingPageLayoutRowType.Text]: 'Text',
                        [LandingPageLayoutRowType.Image]: 'Bild',
                        [LandingPageLayoutRowType.TextButton]: 'Text-Button',
                        [LandingPageLayoutRowType.AppStores]: 'App Store Verlinkung',
                        [LandingPageLayoutRowType.ComplexButton]: 'Button',
                        [LandingPageLayoutRowType.CircleButtons]: 'Social Buttons',
                    },
                    spacing: {
                        title: 'Abstand unten konfigurieren',
                        select: {
                            label: 'Abstand auswählen',
                            placeholder: 'Bitte wählen Sie einen Abstand aus.'
                        }
                    },
                    image: {
                        configure: 'Konfigurieren',
                        height: {
                            title: 'Höhe des Bildes in Pixel',
                            explanation: 'Geben Sie die Höhe des Bildes in Pixeln an. Wenn Sie keinen Wert eingeben, richtet sich das Bild anhand der verfügbaren Breite aus.',
                            input: {
                                label: 'Höhe in px',
                                placeholder: 'Geben Sie die Anzahl der Pixel an.'
                            }
                        }
                    },
                    padding: {
                        title: 'Innenabstand auswählen',
                        horizontal: 'Innenabstand links / rechts',
                        vertical: 'Innenabstand oben / unten',
                    },
                    button: {
                        border: {
                            title: 'Rahmen konfigurieren',
                            color: {
                                title: 'Rahmenfarbe'
                            },
                            width: {
                                title: 'Rahmenstärke',
                                input: {
                                    label: 'Rahmenstärke',
                                    placeholder: ''
                                }
                            },
                            radius: {
                                title: 'Rahmenradius',
                                input: {
                                    label: 'Rahmenradius',
                                    placeholder: ''
                                }
                            }
                        },
                        height: {
                            title: 'Buttonhöhe',
                            input: {
                                label: 'Buttonhöhe',
                                placeholder: ''
                            }
                        },
                        hover: {
                            type: {
                                [LandingPageLayoutTextButtonHoverType.None]: 'Kein',
                                [LandingPageLayoutTextButtonHoverType.Darken]: 'Abdunkeln',
                                [LandingPageLayoutTextButtonHoverType.Lighten]: 'Aufhellen',
                                [LandingPageLayoutTextButtonHoverType.InvertStyling]: 'Invertieren',
                                [LandingPageLayoutTextButtonHoverType.OverwriteStyling]: 'Eigener Stil',
                            },
                            percentage: {
                                label: 'Prozent (0-100%)',
                                placeholder: 'Bitte geben Sie die Prozentzahl ein.'
                            }
                        },
                        tabs: {
                            [LandingPageLayoutTextButtonConfigurationTab.DefaultState]: 'Standard',
                            [LandingPageLayoutTextButtonConfigurationTab.HoverState]: 'Mouseover',
                        }
                    },
                    text: {
                        textColor: 'Textfarbe',
                        textSize: {
                            title: 'Textgröße',
                            variant: {
                                [LandingPageTextSizeType.Small]: 'Klein',
                                [LandingPageTextSizeType.Body]: 'Normal',
                                [LandingPageTextSizeType.Subtitle]: 'Untertitel',
                                [LandingPageTextSizeType.Title]: 'Titel',
                                [LandingPageTextSizeType.Custom]: 'Eigene Größe',
                            },
                            custom: {
                                label: 'Schriftgröße',
                                placeholder: 'Bitte geben Sie die Schriftgröße ein.'
                            }
                        },
                        textWeight: {
                            title: 'Schriftstärke',
                            variant: {
                                [LandingPageFontWeightClass.Thin]: 'Fein (100)',
                                [LandingPageFontWeightClass.ExtraLight]: 'Extra leicht (200)',
                                [LandingPageFontWeightClass.Light]: 'Leicht (300)',
                                [LandingPageFontWeightClass.Regular]: 'Normal (400)',
                                [LandingPageFontWeightClass.Medium]: 'Medium (500)',
                                [LandingPageFontWeightClass.SemiBold]: 'Halb fett (600)',
                                [LandingPageFontWeightClass.Bold]: 'Fett (700)',
                                [LandingPageFontWeightClass.ExtraBold]: 'Extra fett (800)',
                                [LandingPageFontWeightClass.Black]: 'Dick (900)',
                                [LandingPageFontWeightClass.ExtraBlack]: 'Extra Dick (950)',
                            }
                        },
                        background: {
                            title: 'Hintergrund auswählen',
                            type: 'Hintergrund-Typ',
                            variant: {
                                none: 'Kein',
                                [LandingPageLayoutBackgroundType.BackgroundColor]: 'Farbe',
                                [LandingPageLayoutBackgroundType.BackgroundImage]: 'Bild',
                            },
                            color: {
                                select: 'Hintergrundfarbe'
                            },
                            image: {
                                select: 'Bild auswählen',
                                change: 'Bild ändern',
                                preview: 'Bildvorschau'
                            }
                        }
                    },
                    circleButtons: {
                        title: 'Social Buttons',
                        add: 'Button hinzufügen',
                        remove: 'Button entfernen',
                        empty: 'Keine Buttons definiert',
                        type: {
                            select: 'Icon auswählen',
                            variant: {
                                [LandingPageIcon.Add]: 'Hinzufügen',
                                [LandingPageIcon.AddCircle]: 'Hinzufügen (umrandet)',
                                [LandingPageIcon.AlternateEmail]: 'E-Mail (alternativ)',
                                [LandingPageIcon.ArrowBack]: 'Pfeil zurück',
                                [LandingPageIcon.ArrowDown]: 'Pfeil nach unten',
                                [LandingPageIcon.ArrowForward]: 'Pfeil vorwärts',
                                [LandingPageIcon.ArrowUp]: 'Pfeil nach oben',
                                [LandingPageIcon.AutoAwesome]: 'Sterne',
                                [LandingPageIcon.BarChart]: 'Balkendiagramm',
                                [LandingPageIcon.Bolt]: 'Blitz',
                                [LandingPageIcon.Bookmark]: 'Lesezeichen',
                                [LandingPageIcon.CalendarMonth]: 'Kalender (Monat)',
                                [LandingPageIcon.CalendarToday]: 'Kalender (heute)',
                                [LandingPageIcon.Camera]: 'Kamera',
                                [LandingPageIcon.Celebration]: 'Feier',
                                [LandingPageIcon.Chat]: 'Chat',
                                [LandingPageIcon.Check]: 'Haken',
                                [LandingPageIcon.CheckCircle]: 'Haken (umrundet)',
                                [LandingPageIcon.Checklist]: 'Checkliste',
                                [LandingPageIcon.Cloud]: 'Cloud',
                                [LandingPageIcon.Coffee]: 'Kaffee',
                                [LandingPageIcon.Comment]: 'Kommentar',
                                [LandingPageIcon.Computer]: 'Computer',
                                [LandingPageIcon.Cookie]: 'Cookie',
                                [LandingPageIcon.Create]: 'Erstellen',
                                [LandingPageIcon.CreditCard]: 'Kreditkarte',
                                [LandingPageIcon.Diamond]: 'Diamanten',
                                [LandingPageIcon.Download]: 'Herunterladen',
                                [LandingPageIcon.Email]: 'Email',
                                [LandingPageIcon.Event]: 'Veranstaltung',
                                [LandingPageIcon.Explore]: 'Kompass',
                                [LandingPageIcon.Facebook]: 'Facebook',
                                [LandingPageIcon.Favorite]: 'Favorit',
                                [LandingPageIcon.Fingerprint]: 'Fingerabdruck',
                                [LandingPageIcon.GoogleMaps]: 'Google Maps',
                                [LandingPageIcon.Help]: 'Hilfe',
                                [LandingPageIcon.Home]: 'Haus',
                                [LandingPageIcon.Instagram]: 'Instagram',
                                [LandingPageIcon.Language]: 'Sprache',
                                [LandingPageIcon.LinkedIn]: 'LinkedIn',
                                [LandingPageIcon.Lightbulb]: 'Glühbirne',
                                [LandingPageIcon.Link]: 'Link',
                                [LandingPageIcon.LocalOffer]: 'Preisschild',
                                [LandingPageIcon.MoreVert]: 'Menü',
                                [LandingPageIcon.Navigation]: 'Navigation',
                                [LandingPageIcon.NearMe]: 'In meiner Nähe',
                                [LandingPageIcon.NetworkWifi]: 'Netzwerk',
                                [LandingPageIcon.Phone]: 'Telefon',
                                [LandingPageIcon.Pinterest]: 'Pinterest',
                                [LandingPageIcon.Place]: 'Standort',
                                [LandingPageIcon.Podcasts]: 'Podcasts',
                                [LandingPageIcon.Public]: 'Welt',
                                [LandingPageIcon.QuestionMark]: 'Fragezeichen',
                                [LandingPageIcon.Restaurant]: 'Restaurant',
                                [LandingPageIcon.Search]: 'Suche',
                                [LandingPageIcon.Security]: 'Sicherheit',
                                [LandingPageIcon.Send]: 'Absenden',
                                [LandingPageIcon.ShoppingCart]: 'Einkaufswagen',
                                [LandingPageIcon.Snapchat]: 'Snapchat',
                                [LandingPageIcon.Spotify]: 'Spotify',
                                [LandingPageIcon.Star]: 'Stern',
                                [LandingPageIcon.Telegram]: 'Telegram',
                                [LandingPageIcon.ThumbsDown]: 'Daumen runter',
                                [LandingPageIcon.ThumbsUp]: 'Daumen hoch',
                                [LandingPageIcon.TikTok]: 'TikTok',
                                [LandingPageIcon.Threads]: 'Threads',
                                [LandingPageIcon.Vimeo]: 'Vimeo',
                                [LandingPageIcon.WhatsApp]: 'WhatsApp',
                                [LandingPageIcon.Whatshot]: 'Whatshot',
                                [LandingPageIcon.X]: 'X',
                                [LandingPageIcon.Xing]: 'Xing',
                                [LandingPageIcon.YouTube]: 'YouTube',
                            }
                        },
                        link: {
                            title: 'Verlinkung definieren'
                        },
                        theme: {
                            select: 'Theme auswählen',
                            variant: {
                                [LandingPageIconThemeType.Primary]: 'Primärfarbe',
                                [LandingPageIconThemeType.Secondary]: 'Sekundärfarbe',
                                [LandingPageIconThemeType.Custom]: 'Eigene Farben',
                            },
                            custom: {
                                backgroundColor: 'Hintergrundfarbe',
                                textColor: 'Icon-Farbe'
                            }
                        }
                    },
                    complexButton: {
                        content: {
                            add: 'Element hinzufügen',
                            remove: 'Element löschen',
                            spacing: {
                                left: 'Abstand links',
                                right: 'Abstand rechts'
                            },
                            type: {
                                select: 'Element-Typ',
                                variant: {
                                    [LandingPageLayoutComplexButtonContentType.TextContent]: 'Text',
                                    [LandingPageLayoutComplexButtonContentType.IconContent]: 'Icon',
                                    [LandingPageLayoutComplexButtonContentType.ImageContent]: 'Bild',
                                }
                            },
                            text: {
                                input: {
                                    placeholder: 'Bitte geben Sie den Text ein.',
                                    label: 'Button-Text'
                                }
                            },
                            hover: {
                                type: {
                                    variant: {
                                        [LandingPageLayoutComplexButtonHoverType.None]: 'Kein Effekt',
                                        [LandingPageLayoutComplexButtonHoverType.OverwriteContent]: 'Inhalt überschreiben',
                                    }
                                }
                            }
                        }
                    },
                    appStoreButtons: {
                        empty: 'App Stores konfigurieren',
                        remove: 'App Store entfernen',
                        add: 'App Store hinzufügen',
                        appStore: {
                            [LandingPageAppStoreType.AppleAppStore]: 'Apple App Store',
                            [LandingPageAppStoreType.PlayStore]: 'Google Play Store',
                        },
                        input: {
                            label: 'App Store URL',
                            placeholder: 'App Store URL eingeben'
                        }
                    }
                },
                theme: {
                    color: {
                        title: 'Farben & Hintergrund definieren'
                    },
                    primaryColor: {
                        title: 'Hauptfarbe',
                        input: {
                            label: 'Hauptfarbe',
                            placeholder: 'Farbcode hier eingeben (Hex-Code) oder mit Hilfe des Color-Pickers auswählen.'
                        }
                    },
                    primaryContrastColor: {
                        title: 'Haupt-Kontrastfarbe',
                        input: {
                            label: 'Haupt-Kontrastfarbe',
                            placeholder: 'Farbcode hier eingeben (Hex-Code) oder mit Hilfe des Color-Pickers auswählen.'
                        }
                    },
                    secondaryColor: {
                        title: 'Sekundärfarbe',
                        input: {
                            label: 'Sekundärfarbe',
                            placeholder: 'Farbcode hier eingeben (Hex-Code) oder mit Hilfe des Color-Pickers auswählen.'
                        }
                    },
                    secondaryContrastColor: {
                        title: 'Sekundäre Kontrastfarbe',
                        input: {
                            label: 'Sekundär-Kontrastfarbe',
                            placeholder: 'Farbcode hier eingeben (Hex-Code) oder mit Hilfe des Color-Pickers auswählen.'
                        }
                    },
                    contentTextColor: {
                        title: 'Textfarbe',
                        input: {
                            label: 'Textfarbe',
                            placeholder: 'Farbcode hier eingeben (Hex-Code) oder mit Hilfe des Color-Pickers auswählen.'
                        }
                    },
                    customColor: {
                        title: 'Eigene Farbe',
                        input: {
                            label: 'Eigene Farbe',
                            placeholder: 'Farbcode hier eingeben (Hex-Code) oder mit Hilfe des Color-Pickers auswählen.'
                        }
                    },
                    background: {
                        title: 'Hintergrund definieren'
                    }
                },
                browserTitle: {
                    title: 'Browser-Titel definieren (optional)',
                    input: {
                        label: 'Browser-Titel',
                        placeholder: 'Bitte geben Sie einen Titel ein.'
                    }
                },
                qrCodeText: {
                    title: 'Text unter QR-Code (optional)',
                    input: {
                        label: 'Text unter QR-Code',
                        placeholder: 'Bitte geben Sie einen Text ein.'
                    }
                },
                font: {
                    select: 'Schriftart auswählen',
                    customFont: 'Eigene Schriftarten',
                    predefinedFont: 'Vordefinierte Schriftarten',
                    uploadFont: 'Neue Schriftart hochladen',
                    upload: {
                        headline: {
                            default: 'Schriftart hochladen',
                            active: 'Upload läuft',
                            illegalFileType: 'Dieser Dateityp wird nicht unterstützt.',
                        },
                        subLine: {
                            default: 'Alternativ durchsuchen Sie Ihre Dateien ',
                            active: 'Ihre Schriftart wird hochgeladen',
                            illegalFileType: 'Versuchen Sie es erneut oder klicken Sie '
                        },
                        acceptedFileTypes: 'Wir akzeptieren .ttf'
                    }
                },
                upload: {
                    title: 'Dateien hochladen',
                    headline: {
                        default: 'Dateien hochladen',
                        active: 'Upload läuft',
                        illegalFileType: 'Dieser Dateityp wird nicht unterstützt.'
                    },
                    subLine: {
                        default: 'Alternativ durchsuchen Sie Ihre Dateien ',
                        active: 'Ihre Datei wird hochgeladen',
                        illegalFileType: 'Versuchen Sie es erneut oder klicken Sie '
                    }
                },
                notifications: {
                    save: {
                        success: 'Der Magic QR-Code wurde erfolgreich gespeichert.',
                        error: 'Der Magic QR-Code konnte nicht gespeichert werden.'
                    }
                }
            },
            files: {
                images: {
                    select: 'Bild auswählen',
                    upload: 'Neues Bild hinzufügen',
                    existing: {
                        title: 'Verfügbare Bilder'
                    },
                    localizedSelect: {
                        useForCurrentLocale: 'Verwende für ausgewählte Sprache',
                        useForAllLocale: 'Verwende für alle Sprachen',
                    }
                }
            },
            create: {
                title: 'Magic QR-Code erstellen',
                button: 'Magic QR-Code erstellen',
                dialog: {
                    title: 'Magic QR-Code erstellen',
                    submit: 'Erstellen',
                    name: {
                        placeholder: 'Bitte geben Sie einen Namen an.',
                        label: 'Name'
                    },
                    description: {
                        placeholder: 'Bitte geben Sie eine Beschreibung an.',
                        label: 'Beschreibung (optional)'
                    },
                    landingPageType: 'Typ',
                    hierarchyNode: {
                        title: 'Bereich',
                        description: 'Ein Magic QR-Code kann in Ihrem Unternehmen verschiedenen Hierarchie-Ebenen zugeordnet sein. Sollten Sie den Magic QR-Code in einer Filiale von Ihnen platzieren, wählen Sie hier einfach diese Filiale aus. Ebenso können Sie Magic QR-Codes mit Regionen oder Ihrer Zentrale verknüpfen.'
                    }
                }
            },
            actions: {
                download: {
                    title: {
                        singular: 'QR-Code herunterladen',
                        plural: 'QR-Codes herunterladen',
                    },
                    fileType: 'Datei-Typen auswählen',
                    color: {
                        title: 'QR-Code Farbe auswählen',
                        description: 'Um eine maximale Kompatibilität mit allen Geräten zu erreichen, verwenden Sie immer einen dunklen QR-Code auf einem hellen Hintergrund. Nicht alle Geräte sind in der Lage, einen hellen QR-Code auf dunklem Hintergrund zu scannen.'
                    }
                },
                editLandingPage: 'Bearbeiten',
                clone: {
                    title: {
                        singular: 'Magic QR-Code duplizieren',
                        plural: 'Magic QR-Codes duplizieren'
                    },
                    dialog: {
                        submit: 'Duplizieren',
                        explanation: {
                            singular: 'Möchten Sie den Magic QR-Code {0} Duplizieren?',
                            plural: 'Möchten Sie die Magic QR-Codes {0} Duplizieren?',
                        }
                    },
                    notifications: {
                        success: {
                            singular: 'Der Magic QR-Code {0} wurde erfolgreich kopiert.',
                            plural: 'Die Magic QR-Codes {0} wurden erfolgreich kopiert.',
                        },
                        error: {
                            singular: 'Der Magic QR-Code {0} konnte nicht kopiert werden.',
                            plural: 'Die Magic QR-Codes {0} konnten nicht kopiert werden.',
                        }
                    }
                }
            }
        }
    },
    [Language.English]: {
        ...baseMessages[Language.English],
        ...DefaultLandingPageLocalization[Language.English],
        app: {
            title: 'Qualitize',
            offline: 'You are offline.'
        },
        general: {
            close: 'Close',
            open: 'Open',
            cancel: 'Cancel',
            select: 'Select',
            save: 'Save',
            download: 'Download',
            delete: 'Delete',
            next: 'Next',
            back: 'Back'
        },
        connection: {
            offline: 'Offline',
            online: 'Online',
            notifications: {
                online: 'You are online again.',
                offline: 'You are offline. Please check your internet connection.',
                backendOffline: 'You are offline. We are trying to reconnect.'
            }
        },
        languages: {
            at: 'Austrian',
            ba: 'Bashkir',
            be: 'Belarusian',
            bg: 'Bulgarian',
            ch: 'Swiss',
            cs: 'Czech',
            de: 'German',
            da: 'Danish',
            fi: 'Finnish',
            fr: 'French',
            gb: 'English',
            el: 'Greek',
            hr: 'Croatian',
            hu: 'Hungarian',
            is: 'Icelandic',
            it: 'Italian',
            lt: 'Lithuanian',
            lb: 'Luxembourgian',
            lv: 'Latvian',
            mk: 'Macedonian',
            nl: 'Dutch',
            no: 'Norwegian',
            pl: 'Polish',
            ro: 'Romanian',
            ru: 'Russian',
            sv: 'Swedish',
            sl: 'Slovenian',
            sk: 'Slovak'
        },
        sort: {
            pageSize: 'Number of rows',
            all: 'all',
            search: 'Search',
            rowCount: '{0}-{1} to {2}'
        },
        forms: {
            noOptions: 'No options',
            input: {
                number: {
                    invalid: 'Please enter a valid number. Please do not enter thousands separators and use “{0}” as a separator for decimal numbers.'
                }
            }
        },
        legal: {
            legalNotice: {
                title: 'Legal notice'
            },
            privacy: {
                title: 'Privacy'
            }
        },
        login: {
            slider: {
                welcome: {
                    title: 'Welcome to Qualitize!'
                }
            },
            title: 'Log in to Qualitize',
            caption: 'Every customer experience counts!',
            buttons: {
                continue: 'Continue'
            },
            blocked: {
                title: 'Your account has been blocked due to too many failed login attempts.'
            },
            password: {
                title: 'Login with password'
            },
            passwordLess: {
                title: 'Login via e-mail',
                help: {
                    title: 'Login via e-mail',
                    button: 'Learn more',
                    explanation: 'From now on, you can use our password-free login. <br />After clicking on "Request e-mail with login link", you will receive an e-mail containing a link with which you can log in. Please open the link in the email in the same browser in which you requested the link.',
                    howItWorks: {
                        title: 'How does the login via a link work?',
                        description: 'Login links are secure and work without password assignment. <br />The functionality of a login link is similar to the "Forgot password?" button. If you click on "Forgot your password?" you will receive an email with a link that contains a secure, short-lived key that you can use to create a new password. <br /> Login links use the functionality of the secure, short-lived key to verify the user, thus allowing a secure login to our system. <br />As an additional security mechanism, the login link is valid only in the browser in which it was requested.'
                    },
                    close: 'Close',
                    doNotClose: 'Please do not close this browser window and open the link sent to you in this browser.'
                },
                request: 'Request e-mail with login link',
                success: {
                    message: 'You will receive an email from us shortly at {0}. Please check your inbox.',
                    submitLogin: 'Login now'
                },
                invalidate: {
                    success: 'Thank you for your message. We have blocked your login link.'
                },
                alreadyActiveToken: {
                    button: 'Request login link again',
                    dialog: {
                        title: 'Request login link again',
                        content: 'Do you really want to request a new login link? The login link you have already requested will become invalid and can no longer be used.',
                        submit: 'Request'
                    }
                },
                backToLogin: 'Back to login',
                error: {
                    tokenExpired: 'This login link is no longer valid.',
                    invalidBrowser: 'For security reasons, this login link is only valid in the browser with which you requested the link.',
                    tooManyTokens: 'You have too many login links which are still active. Please check your email inbox, if necessary also your spam folder.',
                    alreadyTokenActive: 'You have already requested a login link in this browser. Please check your e-mail inbox and, if necessary, your spam folder.',
                    unknown: 'An error occurred, please try again later.'
                }
            },
            methods: {
                divider: 'or'
            },
            loggedIn: {
                title: 'You are already logged in. How would you like to continue?',
                keepLoggedIn: 'Stay logged in',
                reLogin: 'New log in'
            },
            oidc: {
                error: 'Unfortunately, an error occurred during login.',
                accessDeniedError: 'The login to your system has failed. Do you possibly have no access?',
                backToLogin: 'back to login'
            },
            inactivityLogout: {
                dialog: {
                    title: 'Logout due to inactivity',
                    description: 'You will be logged out in {0}s due to inactivity.',
                    submit: 'Stay logged in',
                    cancel: 'Logout'
                },
                menu: {
                    description: 'You will be logged out in {0} due to inactivity.',
                    durationInMinutes: '{0}min',
                    durationInMinutesAndSeconds: '{0}min {1}s',
                    durationInSeconds: '{0}s',
                }
            }
        },
        passwordReset: {
            button: 'Save',
            title: 'Create new password',
            description: 'Your new password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number and one special character.',
            success: 'Your password has been successfully saved. You can now log in with it.',
            invalidate: {
                success: 'Thank you for your message. We have blocked your link for password recovering.'
            },
            error: {
                expired: 'The link to update the password has expired.',
                invalidBrowser: 'For security reasons, this password reset link is only valid in the browser with which you requested the link.'
            }
        },
        register: {
            button: 'Register',
            title: 'Registration',
            subtitle: 'The last step before logging in: create your password.',
            description: 'Your new password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number and one special character.',
            success: 'Your registration has been completed successfully. You can now log in with your password.',
            expired: 'The link to register has expired. Please contact the Qualitize support via support@qualitize.de.'
        },
        user: {
            logout: 'Logout',
            hierarchy: {
                search: {
                    label: 'Search hierarchy',
                    placeholder: 'Please enter your search term here.'
                }
            },
            login: {
                title: 'Log in',
                success: 'You have been successfully logged in.',
                keepLoggedIn: 'Stay signed in?',
                errors: {
                    invalidInput: 'Username or password incorrect.',
                    missingInput: 'Please fill in all fields.',
                    invalidMail: 'Please enter a valid e-mail address.'
                },
                forgot: {
                    title: 'Forgot password?',
                    button: 'Request email',
                    caption: 'Request reset email',
                    description: 'You will receive an email with a link to create a new password.',
                    toLogin: 'Go to login',
                    error: 'An error occurred.',
                    success: 'You will receive an email with a link to create a new password.'
                }
            },
            username: {
                label: 'Email',
                placeholder: 'Enter your Email'
            },
            password: {
                label: 'Password',
                placeholder: 'Enter your password',
            }
        },
        settings: {
            items: {
                video: 'Video',
                masterData: 'Master data',
                accounts: 'User',
                terminals: 'Terminals'
            },
            account: {
                personalData: {
                    title: 'General information',
                    subLine: 'Name & Email',
                    gender: {
                        label: {
                            label: 'Title',
                            placeholder: 'Select title'
                        },
                        male: 'Male',
                        female: 'Female'
                    },
                    firstName: {
                        label: 'First name',
                        placeholder: 'Enter your first name'
                    },
                    lastName: {
                        label: 'Last name',
                        placeholder: 'Enter your last name'
                    },
                    email: {
                        label: 'Email',
                        placeholder: 'Enter your email',
                    },
                    emailDomain: {
                        label: 'Email domain',
                        placeholder: 'Select your email domain',
                    },
                    phone: {
                        label: 'Phone',
                        placeholder: 'Enter your phone'
                    },
                    submit: 'Save',
                    update: {
                        success: 'Your personal data has been changed.',
                        error: 'Your personal data could not be changed.'
                    }
                },
                password: {
                    title: 'Security',
                    subLine: 'Password',
                    old: {
                        label: 'Old password',
                        placeholder: 'Enter your old password'
                    },
                    new: {
                        label: 'New password',
                        placeholder: 'Enter your new password'
                    },
                    confirm: {
                        label: 'Repeat new password',
                        placeholder: 'Repeat your new password'
                    },
                    set: {
                        password: {
                            label: 'Set password',
                            placeholder: 'Enter your password'
                        },
                        confirm: {
                            label: 'Confirm password',
                            placeholder: 'Please repeat your password'
                        }
                    },
                    submit: 'Change password',
                    update: {
                        success: 'Your password has been changed.',
                        error: {
                            mismatch: 'The passwords do not match.',
                            empty: 'Please fill in all fields.',
                            same: 'The new password must not be the same as the old one.',
                            general: 'The password could not be changed.'
                        }
                    }
                }
            },
            videos: {
                title: 'Video Admin',
                subLine: 'Statistics & administration',
                highlight: {
                    success: 'The video was updated.',
                    error: 'The video could not be updated.'
                },
                statistics: {
                    headline: 'Admin',
                    overview: 'Overview',
                    thumbnail: 'Thumbnail',
                    title: 'Title',
                    authorCreator: 'Author/Creator',
                    author: 'Author',
                    creator: 'Creator',
                    reactions: 'Reactions',
                    views: 'Views',
                    created: 'uploaded',
                    updated: 'last edited',
                    dates: 'Date',
                    actions: 'Action',
                    totalViews: 'Total views {0}'
                }
            }
        },
        menu: {
            user: {
                profile: 'Edit profile',
                password: 'Change password',
                setPassword: 'Set password',
                logout: 'Logout'
            },
            feedback: {
                title: 'Feedback',
                reports: 'Reports'
            },
            administration: {
                openingHours: 'Opening hours',
                legacyStickies: 'Sticky-Editor',
                legacyDepartments: 'Departments',
                terminals: 'Terminals',
                qrCodes: 'QR-Codes',
                accounts: 'Accounts',
                accountProfiles: 'Profiles',
                accountLoginAuditLog: 'Audit logs'
            },
            dashboard: 'Dashboard',
            operations: 'Operations',
            landingPages: 'Magic QRs',
            toDos: 'To Dos',
            videos: 'Learning',
            departments: 'Departments',
            stickyEditor: 'Sticky-Editor',
            openingHours: 'Opening hours',
            settings: 'Administration',
            customers: {
                select: {
                    label: 'Select customer',
                    placeholder: 'Customer'
                }
            }
        },
        administration: {
            locationSelection: {
                button: 'Location: {0}'
            },
            openingHours: {
                locationSelection: 'Select location',
                table: {
                    header: {
                        weekDay: 'Weekday',
                        start: 'Start',
                        end: 'End',
                        dayOfRest: 'Day of rest',
                        changeMultiple: 'Change for {0} days',
                        changeSingle: 'Change for 1 day',
                        changeAll: 'Change for all days',
                    },
                    weekDays: {
                        [WeekDay.None]: '',
                        [WeekDay.Monday]: 'Monday',
                        [WeekDay.Tuesday]: 'Tuesday',
                        [WeekDay.Wednesday]: 'Wednesday',
                        [WeekDay.Thursday]: 'Thursday',
                        [WeekDay.Friday]: 'Friday',
                        [WeekDay.Saturday]: 'Saturday',
                        [WeekDay.Sunday]: 'Sunday'
                    },
                    alwaysOpen: 'Always open'
                },
                notifications: {
                    success: 'The opening hours have been updated.',
                    error: 'The opening hours could not be updated. Please try again later.'
                },
                dialog: {
                    title: 'Save opening hours for multiple locations',
                    search: {
                        label: 'Search locations',
                        placeholder: 'Enter search'
                    },
                    subTitle: 'Selected opening hours'
                },
                actions: {
                    save: 'Save',
                    saveMultiple: 'Save for {0} locations',
                    saveSingle: 'Save for 1 location',
                    cancel: 'Cancel',
                    openDialog: {
                        desktop: 'Transfer to more locations',
                        mobile: 'More locations'
                    }
                }
            },
            terminals: {
                state: {
                    all: 'All',
                    offline: 'Offline',
                    online: 'Online'
                },
                table: {
                    header: {
                        location: 'Location',
                        terminalLocation: 'Terminal location',
                        connectionMethod: 'Connection method',
                        pinCode: 'PIN-Code',
                        id: 'ID',
                        terminalState: 'Online state',
                        locationState: 'State'
                    },
                    actions: {
                        support: 'Create ticket',
                        supportStatus: 'Show ticket'
                    }
                },
                filter: {
                    showAllLocations: 'All locations',
                    onlineStates: 'State: {0}',
                    noResults: 'No entries have been found.'
                },
                dialogs: {
                    incident: {
                        title: 'Request support for Terminal {0}',
                        inputs: {
                            contactPerson: {
                                label: 'Contact person',
                                placeholder: 'Please enter your name'
                            },
                            contactEmail: {
                                label: 'Email',
                                placeholder: 'Please enter your email'
                            },
                            contactPhone: {
                                label: 'Phone (optional)',
                                placeholder: 'Please enter your phone'
                            },
                            comment: {
                                label: 'Description',
                                placeholder: 'Please enter a description of your request'
                            },
                            problems: {
                                label: 'Topic (optional)',
                                placeholder: 'Please select an topic.'
                            }
                        },
                        attachments: {
                            title: 'Attachments (optional)',
                            description: 'You can upload images or videos which illustrate your issue.',
                            upload: {
                                title: 'Click here',
                                acceptedFileTypes: 'File types: .jpg, .png, .mp4, .mov',
                                illegalFileType: 'The file type is not supported.'
                            },
                        },
                        actions: {
                            submit: 'Send',
                            cancel: 'Cancel'
                        },
                        messages: {
                            success: 'Your request has been forwarded to Qualitize customer support',
                            error: 'Unfortunately, an error has occurred. Please try again later.'
                        }
                    },
                    ticket: {
                        title: 'Status of your ticket for {0}',
                        add: 'Create new ticket entry',
                        hide: 'Cancel',
                        problems: 'Topics',
                        attachments: 'Attachments',
                        notifications: {
                            success: 'Your message has been sent to Qualitize Customer Support. You will receive a copy at {0}.',
                            error: 'Your message could not be sent to us. Please try again later.'
                        }
                    }
                },
                notifications: {
                    online: 'Your terminal {0} is back online.'
                }
            },
            locations: {
                state: {
                    [LocationSetupStatus.NO_TABLET]: 'No terminal',
                    [LocationSetupStatus.SETUP]: 'Setup planned',
                    [LocationSetupStatus.ACTIVE]: 'Installed',
                    [LocationSetupStatus.INACTIVE]: 'Inactive',
                    [LocationSetupStatus.TEMP_CLOSED]: 'Temp. closed',
                    [LocationSetupStatus.TERMINATED]: 'Closed',
                    [LocationSetupStatus.TEARDOWN]: 'Dismounted'
                }
            },
            stickyEditor: {
                pageTitle: 'Sticky-Editor',
                zoomIn: 'Maximise Sticky',
                zoomOut: 'Minimise Sticky',
                stepper: {
                    design: 'Choose design',
                    timeframe: 'Select timeframe',
                    location: 'Select location',
                },
                designStep: {
                    description: 'Use the different selection options to customize the design to your wishes. The color of the sticky and the text displayed on the sticky can be customized.',
                    colorInput: 'Select a color for the Sticky:',
                    textInputLabel: 'Insert Sticky-Text (max. 70 characters):',
                    textInputPlaceholder: 'Sticky-Text',
                    textInputDescription: 'The text to be displayed on the sticky is defined here.'
                },
                colors: {
                    yellow: 'Yellow',
                    pink: 'Rosa',
                    white: 'White',
                },
                timeframeStep: {
                    description: 'The exact display duration of the sticky is defined in this step. If the end date changes at short notice, the sticky can be deactivated manually.',
                    startDate: 'Set a start date:',
                    endDate: 'Set an end date:',
                    endDateLabel: 'Select an end date:',
                    checkboxLabel: 'No end date',
                },
                locationStep: {
                    descriptionGeneral: 'Here you can set at which locations the sticky should appear. If there are several terminals per location, you can activate them individually. Simply click on the specific terminal to activate it.',
                    descriptionSpecific: 'Click on "Save" to activate the sticky for the terminal listed below:',
                    descriptionOneLocation: 'The terminals on which the sticky is to appear can be set here. By selecting the corresponding terminal and clicking on "save" the Sticky will be activated.',
                    selectAll: 'Select all locations',
                    searchPlaceholder: 'Search for location',
                    noLocationFound: 'No location found'
                },
                archiveTitle: 'Sticky-Archive',
                allLocations: 'All Locations',
                undefinedEndDate: 'unlimited',
                chipActive: 'Active',
                chipInactive: 'Inactive',
                archiveItems : {
                    regionalManagedPlaceholder: 'Centrally or regional managed',
                    regionalManagedTooltip: 'This sticky was defined at a central or regional level.',
                    and: 'and',
                    remainingCount: '{0} more locations',
                    remainingLocationsTooltip: 'The sticky is activated for the following locations: {0}'
                },
                actionMenu: {
                    editText: 'Edit Text',
                    deactivateSticky: 'Deactivate Sticky',
                    reactivateSticky: 'Reactivate Sticky',
                    deleteSticky: 'Delete Sticky',
                },
                popup: {
                    editTextTitle: 'Edit Sticky',
                    submitChanges: 'Save changes',
                    saveMessage: 'Do you want to save the sticky now?',
                    deleteMessage: 'Should the sticky be deleted permanently?'
                },
                notifications: {
                    SaveSuccess: 'The sticky has been saved.',
                    SaveError: 'The sticky could not be saved. Please try again later.',
                    DeleteSuccess: 'The sticky has been deleted.',
                    DeleteError: 'The sticky could not be deleted. Please try again later.',
                    EditSuccess: 'The sticky has been updated.',
                    EditError: 'The sticky could not be updated. Please try again later.',
                },
            },
            accounts: {
                management: {
                    title: 'Account Management',
                    topBar: {
                        search: {
                            placeholder: 'Search accounts'
                        },
                        stateFilter: {
                            active: 'Active accounts',
                            temporarilyDisabled: 'Blocked accounts',
                            all: 'All accounts'
                        },
                        timeFilter: {
                            title: 'Select time period',
                            selectDate: 'Select date',
                            description: 'You can search for users by their creation date. Simply enter the desired time period.',
                            createdFrom: 'Starting time (optional)',
                            createdUntil: 'Ending time (optional)',
                            resetDate: 'Reset date',
                            submit: 'Set time period',
                            submitMobile: 'Set period',
                            cancel: 'Reset selected time period',
                            cancelMobile: 'Reset'
                        },
                        export: {
                            button: 'Export',
                            buttonSelection: 'Export selection',
                            notifications: {
                                success: 'The download of your export has been started.',
                                error: 'Your export could not be created.',
                            }
                        }
                    },
                    state: {
                        [TemporarilyDisabledStateType.NotDisabled]: 'Active',
                        [TemporarilyDisabledStateType.Disabled]: 'Blocked',
                    },
                    disabledReason: {
                        [TemporarilyDisabledReasonType.TooManyFailedPasswordLogins]: 'Failed password logins ({0} times)',
                        [TemporarilyDisabledReasonType.ManuallyDisabled]: 'Manually blocked',
                    },
                    reEnableDate: 'Automatically unblocked at: {0}',
                    table: {
                        header: {
                            username: 'Username',
                            authenticationConfiguration: 'Profile',
                            roleConfiguration: 'Role configuration',
                            loginMethodConfiguration: 'Login methods',
                            securityRuleConfiguration: 'Security rules',
                            createdAt: 'Created at',
                            disabledState: 'State',
                            actions: 'Actions'
                        },
                        footer: {
                            rows: 'Showing {0} of {1} accounts'
                        },
                        loginMethods: {
                            title: 'Login methods',
                            methods: {
                                [PublicLoginMethodType.Password]: 'Password',
                                [PublicLoginMethodType.LoginToken]: 'Login E-Mail',
                                [PublicLoginMethodType.OpenIdConnectAuthorizationCodeFlow]: 'OpenID Connect',
                            },
                            none: 'No login methods',
                            failureState: 'State: {0}'
                        },
                        securityRules: {
                            title: 'Security rules',
                            fromPreset: 'From preset: {0}',
                            none: 'None'
                        },
                        roles: {
                            title: 'Roles',
                            explicit: 'Custom role definition',
                            fromPresets: 'From preset: {0}',
                            none: 'None'
                        },
                        authenticationConfiguration: {
                            explicit: 'No profile',
                            none: 'No profile',
                            tooltip: 'Show detail view',
                            dialog: {
                                title: 'Security settings for {0}'
                            }
                        },
                        actions: {
                            disable: {
                                title: 'Block user temporarily',
                                mobileTitle: 'Block temporarily',
                                description: 'Would you like to temporarily block these users? The users will then no longer have access to Qualitize Online, but you can reactivate them at any time.',
                                submit: 'Block user',
                                notifications: {
                                    success: 'The users have been successfully blocked temporarily. You can unblock them again at any time.',
                                    error: 'The users could not be temporarily blocked. Please try again later.',
                                },
                                reason: {
                                    label: 'Explanation (mandatory)',
                                    placeholder: 'Please enter an explanation.',
                                },
                                reEnableDescription: 'Should the account be unblocked again from a certain date?',
                                noReEnableDate: 'Select date',
                                resetReEnableDate: 'Reset date'
                            },
                            enable: {
                                title: 'Unblock user',
                                mobileTitle: 'Unblock',
                                description: 'Would you like to unblock these users? The users will then have access to Qualitize Online again and can log in using their existing login methods.',
                                submit: 'Unlock user',
                                submitMobile: 'Unlock',
                                notifications: {
                                    success: 'Users have been successfully unblocked and can now access Qualitize Online again.',
                                    error: 'The users could not be unblocked. Please try again later.',
                                }
                            },
                            deactivate: {
                                title: 'Deactivate user permanently',
                                mobileTitle: 'Deactivate permanently',
                                description: 'Would you like to deactivate these users? The users will then no longer have access to Qualitize Online and cannot be reactivated by you.',
                                submit: 'Deactivate user',
                                notifications: {
                                    success: 'The users were successfully deactivated permanently and have no access to Qualitize Online with immediate effect.',
                                    error: 'The users could not be deactivated permanently. Please try again later.',
                                }
                            }
                        }
                    },
                    passwordRule: {
                        [LoginPasswordRuleType.LowerCase]: 'Lower case characters (min. {0})',
                        [LoginPasswordRuleType.UpperCase]: 'Upper case characters (min. {0})',
                        [LoginPasswordRuleType.Digit]: 'Digits (min. {0})',
                        [LoginPasswordRuleType.History]: 'No previously used passwords (takes into account the last {0} passwords)',
                        [LoginPasswordRuleType.Length]: 'Number of characters (min. {0}, max. {1})',
                        [LoginPasswordRuleType.Special]: 'Special characters (min. {0})',
                        [LoginPasswordRuleType.Username]: 'Must not contain user name',
                        [LoginPasswordRuleType.Whitespace]: 'May contain spaces',
                        [LoginPasswordRuleType.Dictionary]: 'Must not contain certain character strings ({0})',
                    },
                    defaultPasswordRule: 'Qualitize default rules: Min. 8 characters, 1 lowercase letter, 1 uppercase letter, 1 digit, 1 special character, don\'t include entries from list of too common passwords, don\'t include username, don\'t allow password reuse.',
                    dictionaryPasswordRule: {
                        extendDefault: {
                            withCustomPasswords: 'Must not contain strings defined by Qualitize and own strings (Own strings: {0})',
                            withoutCustomPasswords: 'Must not contain strings defined by Qualitize'
                        },
                        custom: {
                            withCustomPasswords: 'Must not contain specially defined character strings ({0})',
                            withoutCustomPasswords: 'Must not contain specially defined character strings'
                        }
                    },
                    profiles: {
                        title: 'Profiles',
                        emptyState: 'No profiles have yet been defined for your company.'
                    },
                    loginAudit: {
                        title: 'Audit Logs',
                        emptyState: 'No audit logs are available for your company.',
                        download: {
                            button: 'Export as CSV',
                            filename: '{0}_Qualitize_Online_Audit_Log'
                        },
                        topBar: {
                            timeSpan: {
                                type: {
                                    [AvailableLoginAuditTimeSpan.Last7Days]: 'Last 7 days',
                                    [AvailableLoginAuditTimeSpan.Last30Days]: 'Last 30 days',
                                    [AvailableLoginAuditTimeSpan.LastMonth]: 'Last month',
                                    [AvailableLoginAuditTimeSpan.Last3Months]: 'Last 3 months',
                                    [AvailableLoginAuditTimeSpan.Last6Months]: 'Last 6 months',
                                    [AvailableLoginAuditTimeSpan.LastYear]: 'Last year',
                                    [AvailableLoginAuditTimeSpan.Custom]: 'Custom',
                                }
                            }
                        },
                        table: {
                            header: {
                                timestamp: 'Timestamp',
                                username: 'Username',
                                loginActivity: 'Activity',
                                loginMethod: 'Login method',
                                reason: 'Reason'
                            },
                            footer: {
                                singular: '{0} log entry',
                                plural: '{0} log entries',
                            },
                            loginActivity: {
                                type: {
                                    success: 'Successful login',
                                    failure: 'Failed login',
                                }
                            }
                        }
                    }
                }
            }
        },
        calendar: {
            week: {
                short: '{0}'
            },
            time: {
                description: '{0}'
            }
        },
        icons: {
            description: 'Icons'
        },
        threshold: {
            description: 'Coloring'
        },
        switch: {
            active: 'Active',
            inactive: 'Inactive'
        },
        chart: {
            empty: 'No data is available for your selection.',
            tooFewRespondents: 'There are too few respondents for your selection (n<{0}).',
        },
        dashboard: {
            load: {
                active: 'Your insights are loaded',
                chart: {
                    error: 'Your insights could not be loaded.'
                }
            },
            period: {
                clock: '',
                predefined: 'Predefined periods',
                customDefined: 'Custom periods',
                select: {
                    description: 'Select period',
                    lastDay: 'Yesterday ({0})',
                    quarter: 'Last quarter (Q{0})',
                    week: 'Last week ({0})',
                    month: 'Last month ({0})',
                    yearToDate: 'Year to date ({0})',
                    lastYear: 'Last year ({0})',
                    calendarWeek: 'Calendar weeks',
                    total: 'Total',
                    custom: 'Custom period',
                    today: 'Today ({0})',
                    day: 'Custom day'
                },
                comparison: {
                    title: 'Comparison period',
                    description: 'in comparison {0}',
                    none: 'None',
                    automatic: 'Automatic',
                    previousDay: 'to {0}',
                    previousWeek: 'to calendar week {0}',
                    previousMonth: 'to {0}',
                    previousQuarter: 'to Q{0}',
                    previousYear: 'to {0}',
                    previousTotal: 'to total',
                    day: 'Previous day ({0})',
                    week: 'Previous week ({0})',
                    month: 'Previous month ({0})',
                    quarter: 'Previous quarter (Q{0})',
                    year: 'Previous year ({0})',
                    yearToDate: 'year to date ({0})',
                    custom: 'Custom period',
                    customDay: 'Custom day',
                    total: 'Total'
                }
            },
            element: {
                noValue: 'n.a.',
                numberOfRespondents: '(n = {0}{1})',
                notApplicableCount: ', n/a = {0}',
                question: 'Question',
                actions: {
                    export: {
                        download: {
                            notifications: {
                                success: 'Your export has been created and the download has started.',
                                error: 'Your export could not be created.'
                            },
                            started: 'The download of your export has been started.',
                            navigate: 'Go to Qualitize Online',
                            description: 'If the download does not start automatically, simply click on the download button.',
                            triggerDownload: 'Download'
                        },
                        mail: {
                            notifications: {
                                success: 'Your export will be generated and sent to your e-mail {0}.',
                                error: 'Your export could not be created.'
                            }
                        }
                    }
                },
                table: {
                    expand: {
                        showAll: 'Show all',
                        hideAll: 'Hide all'
                    }
                }
            },
            config: {
                element: {
                    name: 'Name',
                    lastSync: 'Last sync'
                },
                feedbackCollectionPoint: 'Devices',
                questions: 'Questions',
                chart: {
                    description: 'Auswertungstyp',
                    type: {
                        barChart: {
                            description: 'Säulendiagramm',
                            type: 'Typ',
                            horizontal: 'Horizontal',
                            stacked: 'Gestapelt'
                        },
                        lineChart: {
                            description: 'Line chart',
                            interpolation: 'Interpolation',
                            filter: {
                                periodGrouping: {
                                    name: 'Grouping',
                                    elements: {
                                        [PeriodGrouping.Day]: 'Day',
                                        [PeriodGrouping.Month]: 'Month',
                                        [PeriodGrouping.Year]: 'Year',
                                        [PeriodGrouping.Quarter]: 'Quarter',
                                        [PeriodGrouping.Week]: 'Week',
                                        [PeriodGrouping.HalfYear]: 'Half year',
                                    }
                                }
                            }

                        },
                        gauge: {
                            description: 'Tachometer',
                            type: 'Typ',
                            customerIndex: 'Service-Index',
                            nps: 'NPS'
                        },
                        heatMap: {
                            description: 'HeatMap'
                        },
                        stat: {
                            description: 'Kennzahl',
                            type: 'Typ',
                            number: 'Anzahl',
                            average: 'Mittelwert',
                            percentage: 'TopBox',
                            nps: 'NPS'
                        },
                        text: {
                            description: 'Comments',
                            filter: {
                                rating: {
                                    name: 'Sentiment',
                                    elements: {
                                        [Sentiment.None]: 'None',
                                        [Sentiment.Negative]: 'Negative',
                                        [Sentiment.Ambivalent]: 'Ambivalent',
                                        [Sentiment.Positive]: 'Positive'
                                    }
                                },
                                category: {
                                    name: 'Category'
                                },
                                search: {
                                    input: {
                                        label: 'Search comments',
                                        placeholder: 'Enter search terms',
                                    }
                                }
                            },
                            detail: {
                                show: 'Show details',
                                hide: 'Close details',
                                empty: 'There are no comments for your selection.',
                                showOriginal: 'Show original ({0})',
                                hideOriginal: 'Show translation ({0})'
                            }
                        },
                        image: {
                            three60: {
                                preparing: '360° is prepared'
                            }
                        },
                        table: {
                            includeOnlyFinishedFeedback: 'Hide unfinished',
                            empty: 'No data is available for your selection.',
                            search: {
                                placeholder: 'Search'
                            }
                        }
                    }
                }
            },
            settings: {
                filter: {
                    more: '{0} more',
                    active: 'Active filter: {0}'
                },
                global: {
                    reload: {
                        desktop: 'Refresh data',
                        mobile: 'refresh',
                        last: 'Last sync: {0}'
                    },
                    filter: {
                        placeholder: 'Search {0}',
                        desktop: 'Show filter',
                        mobile: 'Filter',
                        tooltip: {
                            title: 'Selected filter ({0}):',
                            numberOfAdditionalElements: '+ {0} additional elements'
                        },
                        apply: {
                            desktop: 'Apply filter',
                            mobile: 'Apply'
                        },
                        reset: 'Reset filter'
                    },
                    top: {
                        desktop: 'Show filter',
                        mobile: 'Filter',
                        apply: {
                            desktop: 'Close filter',
                            mobile: 'Close'
                        }
                    },
                    period: {
                        desktop: 'Select period',
                        mobile: 'Period'
                    },
                    comparisonPeriod: {
                        desktop: 'Select comparison period',
                        mobile: 'Comparison period'
                    },
                    live: 'Live data'
                }
            }
        },
        feedback: {
            overview: {
                dashboards: {
                    headline: 'My dashboards',
                    more: 'More dashboards',
                    open: 'Open dashboard',
                    all: 'All dashboards',
                    lastDays: {
                        singular: 'Yesterday',
                        plural: 'Last {0} days'
                    }
                }
            },
            sidebar: {
                dashboards: {
                    more: 'More dashboards',
                    less: 'Less dashboards'
                }
            },
            locationComparison: {
                evaluationType: 'Evaluation type',
                map: {
                    headline: 'Satisfaction map',
                    detail: 'Open interactive map'
                },
                podium: {
                    headline: 'Podium'
                },
                rank: {
                    title: 'Ranking',
                    detail: {
                        open: 'Open details'
                    },
                    table: {
                        header: {
                            rank: 'Rank',
                            location: 'Location',
                            value: 'Value',
                            difference: 'Difference',
                            respondents: 'Respondents'
                        }
                    }
                },
                ranking: {
                    [RankingType.TopBox]: {
                        name: 'TopBox',
                        description: 'Percentage of very positive smiley'
                    },
                    [RankingType.Top2Box]: {
                        name: 'Top2Box',
                        description: 'Percentage of very positive and positive smiley'
                    },
                    [RankingType.Top3Box]: {
                        name: 'Top3Box',
                        description: 'Percentage of very positive, positive and neutral smiley'
                    },
                    [RankingType.Bottom3Box]: {
                        name: 'Bottom3Box',
                        description: 'Percentage of very negative, negative and neutral smiley'
                    },
                    [RankingType.Bottom2Box]: {
                        name: 'Bottom2Box',
                        description: 'Percentage of very negative and negative smiley'
                    },
                    [RankingType.BottomBox]: {
                        name: 'BottomBox',
                        description: 'Percentage of very negative smiley'
                    },
                    [RankingType.AvgGrade]: {
                        name: 'Grade',
                        description: 'Mean value converted as school grade'
                    },
                    [RankingType.Avg]: {
                        name: 'Mean value',
                        description: 'Mean value over all given answers'
                    },
                    [RankingType.Count]: {
                        name: 'Count',
                        description: ''
                    },
                    [RankingType.NPS]: {
                        name: 'NPS',
                        description: 'Share of promoters (answers 9-10) - share of detractors (answers 0-6)'
                    },
                    [RankingType.PercentPromoters]: {
                        name: 'Share of promoters',
                        description: 'Percentage of promoters (answers 9-10)'
                    },
                    [RankingType.PercentDetractors]: {
                        name: 'Share of detractors',
                        description: 'Percentage of detractors (answers 0-6)'
                    },
                    [RankingType.ServiceIndex]: {
                        name: 'Service Index',
                        description: 'Service index is calculated across all service dimensions, maximum score: 100'
                    }
                },
                rankingOrder: {
                    elements: {
                        [RankingOrder.BestFirst]: 'Best first',
                        [RankingOrder.WorstFirst]: 'Worst first',
                    },
                    title: 'Order'
                },
                grouping: {
                    title: 'Grouping',
                    elements: {
                        [ComparisonGrouping.Location]: 'Location',
                        [ComparisonGrouping.Region]: 'Region',
                        [ComparisonGrouping.DataSource]: 'Data source'
                    }
                },
                calculation: {
                    base: 'Basis: {0}'
                },
                question: {
                    shortName: 'Question: {0}'
                },
                period: {
                    description: 'Period: {0}',
                    comparison: ' vs. {0}'
                },
                promotedDemoted: {
                    promoted: {
                        title: 'Ascenders',
                        none: 'There are no ascenders for your selection'
                    },
                    demoted: {
                        title: 'Relegations',
                        none: 'There are no relegations for your selection'
                    }
                }
            }
        },
        videos: {
            title: 'Videos',
            categories: {
                headline: 'Category',
                sidebar: {
                    headline: 'Overview',
                    expand: 'Show all',
                    collapse: 'Show less',
                    allCategories: {
                        headline: 'Categories',
                        categories: 'All videos'
                    },
                    playlists: {
                        headline: 'Playlists'
                    }
                },
                filter: {
                    apply: 'Apply filter',
                    delete: 'Delete filter'
                }
            },
            dashboard: {
                title: 'Videos',
                welcome: {
                    description: 'Welcome back, {0}!'
                },
                tags: {
                    highlights: 'Popular topics'
                },
                search: {
                    headline: 'Search results',
                    placeholder: 'What are you looking for?',
                },
                sections: {
                    mostSeen: 'Most seen videos',
                    latest: 'Latest videos',
                    popular: 'Most popular videos'
                },
                categories: {
                    headline: 'Popular categories'
                },
                wishes: {
                    headline: 'Latest wishes',
                    button: 'Fulfill wish',
                    all: 'All wishes'
                },
                allVideos: 'All videos',
                empty: 'Unfortunately, we could not find any videos.',
                back: 'Back',
                admin: {
                    settings: {
                        title: 'Settings',
                        edit: 'Edit video',
                        deactivate: {
                            title: 'Deactivate video',
                            headline: 'Do you want to deactivate the video?',
                            content: 'If you deactivate this video then it will not be visible for other users.',
                            cancel: 'Cancel',
                            submit: 'Deactivate',
                            success: 'Your video has been successfully deactivated.',
                            error: 'Your video could not be deactivated. Please try again later.'
                        },
                        activate: {
                            title: 'Activate video',
                            headline: 'Do you want to activate the video?',
                            content: 'If you reactivate the video then it will be visible to other users.',
                            cancel: 'Cancel',
                            submit: 'Activate',
                            success: 'Your video has been successfully activated.',
                            error: 'Your video could not be activated. Please try again later.'
                        },
                        delete: {
                            title: 'Delete video',
                            headline: 'Do you want to delete the video?',
                            content: 'If you delete this video it is irrevocably deleted.',
                            cancel: 'Cancel',
                            submit: 'Delete',
                            success: 'Your video has been successfully deleted.',
                            error: 'Your video could not be deleted. Please try again later.'
                        },
                    }
                },
                upload: {
                    link: 'Upload video',
                    title: 'Upload video',
                    headline: 'Upload video',
                    caption: 'Video: Upload',
                    wishes: 'Select wishes (optional)',
                    cancel: 'Cancel',
                    dragAndDrop: {
                        title: 'Drag and Drop',
                        subTitle: 'Alternatively browse your files ',
                        here: 'here.',
                        illegalFileType: 'This file type is not supported.',
                        illegalFileTypeDescription: 'Try again or click '
                    },
                    dragOver: 'Now simply drop!',
                    tags: 'Tags',
                    categories: {
                        title: 'Category',
                        noOption: 'Not found',
                        create: ' create'
                    },
                    create: ' create',
                    video: {
                        title: 'Title',
                        description: 'Description',
                        creator: 'Creator',
                        acceptedFileTypes: 'We accept .mp4, .mov.',
                        upload: {
                            active: 'Your video is uploaded',
                            preparing: {
                                title: 'Your video is prepared',
                                subLine: 'This may take a moment'
                            },
                            save: {
                                title: 'Upload video',
                                active: 'Your video will be placed live',
                                success: 'Your video went live',
                                error: 'Your video could not be placed live',
                                link: 'Go to video'
                            }
                        },
                        edit: {
                            save: 'Save video',
                            cancel: 'Cancel',
                            success: 'Your video was successfully updated'
                        }
                    }
                },
                video: {
                    creator: 'Creator',
                    length: 'min',
                    category: 'Category',
                    categories: 'Categories',
                    description: {
                        title: 'Description',
                        more: 'More …',
                        less: 'Show less'
                    },
                    backToDashboard: 'Back to overview',
                    deactivated: 'deactivated'
                },
                loading: {
                    title: 'Your videos are loaded'
                },
                highlights: {
                    title: 'Current highlights',
                    author: 'of {0}'
                }
            },
            wishList: {
                link: 'Wish list',
                headline: 'Wish list',
                popular: 'Most popular wishes',
                latest: 'Latest wishes',
                finished: 'Fulfilled wishes',
                wish: {
                    creator: 'requested by {0}',
                    more: 'Show more',
                    less: 'Show less',
                    create: {
                        headline: 'Create new wish',
                        headlineMobile: 'New wish',
                        button: 'Create wish',
                        title: {
                            label: 'What is your wish?',
                            placeholder: 'Describe your wish as briefly and concisely as possible'
                        },
                        description: {
                            label: 'Describe your wish:',
                            placeholder: 'Describe your wish here in as much detail as possible....'
                        },
                        explanation: 'Missing a video? Here you can ask others for help. Anyone can vote your wish up. The higher your wish is in the ranking, the more likely someone will take up the topic. Let\'s go!',
                        submit: 'Submit wish',
                        submitMobile: 'Wish',
                        cancel: 'Cancel',
                        success: 'The wish was created successfully.',
                        errors: {
                            missing: 'Please fill in all fields.',
                            server: 'The wish could not be created. Please try again later.'
                        }
                    },
                    finished: {
                        link: 'Got to wish',
                        author: 'fulfilled by {0}',
                    }
                }
            },
            video: {
                views: 'Views',
                creator: 'Creator',
                actions: {
                    create: 'Create'
                },
                player: {
                    settings: {
                        quality: 'Quality',
                        speed: 'Speed'
                    }
                },
                reactions: {
                    reactor: {
                        mobile: {
                            single: 'Liked by {0}',
                            singular: 'Liked by {0} and one more locationComparison',
                            plural: 'Liked by {0} and {1} more users',
                            empty: ''
                        },
                        heart: {
                            single: 'Loved by {0}',
                            singular: 'Loved by {0} and one more locationComparison',
                            plural: 'Loved by {0} and {1} more users',
                            empty: 'Be the first one to send a heart!'
                        },
                        like: {
                            single: 'Liked by {0}',
                            singular: 'Liked by {0} and one more locationComparison',
                            plural: 'Liked by {0} and {1} more users',
                            empty: 'Be the first to like!'
                        },
                        clap: {
                            single: 'Applauded by {0}',
                            singular: 'Applauded by {0} and one more locationComparison',
                            plural: 'Applauded by {0} and {1} one more users',
                            empty: 'Be the first to applaud!'
                        },
                        you: 'you'
                    }
                },
                comments: {
                    numberOfComments: 'Comments ({0})',
                    me: 'me',
                    delete: {
                        button: 'Delete',
                        title: 'Confirm deleting',
                        content: 'Are you sure you want to delete this comment?',
                        submit: 'Delete',
                        cancel: 'Cancel',
                        error: 'The comment could not be deleted.',
                        success: 'The comment was deleted.',
                        deleted: 'This comment has been deleted {0}.',
                        deletedByCreator: 'by the creator',
                        deletedByAdmin: 'by an admin'
                    },
                    input: {
                        placeholder: 'Write your comment here…',
                        submit: 'Comment',
                        error: 'Your comment could not be saved.'
                    },
                    children: {
                        show: 'Show replies',
                        hide: 'Hide replies'
                    },
                    answers: {
                        button: 'Reply',
                        cancel: 'Cancel'
                    },
                    time: {
                        justNow: 'Just now',
                        minutes: {
                            singular: 'one minute ago',
                            plural: '{0} minutes ago'
                        },
                        hours: {
                            singular: 'one hour ago',
                            plural: '{0} hours ago'
                        },
                        days: {
                            singular: 'one day ago',
                            plural: '{0} days ago'
                        },
                        weeks: {
                            singular: 'one week ago',
                            plural: '{0} weeks ago'
                        },
                        months: {
                            singular: 'one month ago',
                            plural: '{0} months ago'
                        },
                        years: {
                            singular: 'one year ago',
                            plural: '{0} years ago'
                        }
                    }
                },
                highlight: {
                    remove: {
                        description: 'Unhighlight',
                        success: 'The video was updated.',
                        error: 'The video could not be updated.'
                    },
                    add: {
                        description: 'Highlight',
                        success: 'The video was highlighted',
                        error: 'The video could not be highlighted.'
                    }
                }
            },
            playlists: {
                manage: {
                    headline: 'Edit playlist',
                    title: {
                        label: 'Title',
                        placeholder: 'Please enter a title for the playlist'
                    },
                    subtitle: {
                        label: 'Subtitle',
                        placeholder: 'Please provide a heading for the description.'
                    },
                    description: {
                        label: 'Description',
                        placeholder: 'Please provide a description for the playlist'
                    },
                    visibility: {
                        label: 'Visibility',
                        placeholder: 'Who can see the playlist?'
                    },
                    curate: {
                        label: 'Curate',
                        placeholder: 'Playlist on the home page?'
                    },
                    image: {
                        headline: {
                            default: 'Upload title image',
                            active: 'Your image is uploaded',
                            illegalFileType: 'This file type is not supported'
                        },
                        subLine: {
                            default: 'Alternatively browse your files ',
                            active: 'Your image is uploaded',
                            illegalFileType: 'Try again or click '
                        },
                        acceptedFileTypes: 'We accept .jpg and .png'
                    },
                    tags: {
                        label: 'Tags',
                        placeholder: 'Type your tags here and confirm with Enter'
                    },
                    sections: {
                        add: 'Add section',
                        title: {
                            label: 'Title',
                            placeholder: 'Name section'
                        },
                        video: {
                            add: 'Add video'
                        }
                    },
                    actions: {
                        delete: 'Delete playlist',
                        back: 'Back to overview',
                        update: 'Update playlist',
                        create: 'Create playlist',
                    },
                    videos: {
                        manage: {
                            headline: 'Add videos:',
                            description: 'Select videos to be added to {0}.',
                            descriptionWithoutSectionTitle: 'Select videos to be added to the section.',
                            preview: 'Preview',
                            title: 'Title',
                            author: 'Creator',
                            date: 'Date',
                            selected: '{0} total videos selected',
                            cancel: 'Cancel and back',
                            submit: 'Add selected videos',
                            search: 'Enter search term'
                        }
                    },
                    update: {
                        error: 'The playlist could not be updated.',
                        success: 'The playlist was updated.'
                    },
                    menu: {
                        edit: 'Edit playlist',
                        curate: 'Highlight playlist',
                        uncurate: 'Unhighlight playlist',
                        delete: 'Delete playlist',
                        deactivate: 'Deactivate playlist',
                        activate: 'Activate playlist',
                    },
                    highlight: {
                        success: 'The playlist was highlighted.',
                        error: 'The playlist could not be highlighted.'
                    },
                    unhighlight: {
                        success: 'The playlist was unhighlighted.',
                        error: 'The playlist could not be unhighlighted.'
                    },
                    enable: {
                        success: 'The playlist was successfully activated.',
                        error: 'The playlist could not be activated.',
                        headline: 'Do you want to activate the playlist?',
                        content: 'If you activate the playlist then it will be visible for other users.',
                        cancel: 'Cancel',
                        submit: 'Activate'
                    },
                    disable: {
                        success: 'The playlist was successfully deactivated.',
                        error: 'The playlist could not be deactivated.',
                        headline: 'Do you want to activate the playlist?',
                        content: 'If you deactivate this playlist then it will not be visible for other users.',
                        cancel: 'Cancel',
                        submit: 'Deactivate'
                    },
                    delete: {
                        success: 'The playlist was successfully deleted.',
                        error: 'The playlist could not be deleted.',
                        headline: 'Do you want to delete the playlist?',
                        content: 'If you delete this playlist it is irrevocably deleted.',
                        cancel: 'Cancel',
                        submit: 'Delete'
                    }
                },
                create: {
                    headline: 'Create playlist',
                    error: 'The playlist could not be created.',
                    success: 'The playlist was successfully created.'
                },
                overview: {
                    headline: 'Playlists',
                    subLine: 'Specifically for you',
                    numberOfVideos: '{0} videos',
                    numberOfMinutes: '{0} min',
                },
                detail: {
                    title: 'Playlist: {0}',
                    playlist: 'Playlist',
                },
                highlights: {
                    title: 'Playlists - created for you',
                    mobileTitle: 'Playlists for you',
                    allPlaylists: 'All playlists'
                }
            }
        },
        operations: {
            sidebar: {
                overview: 'Overview',
                actions: {
                    addToDo: 'Create to do'
                },
                moreToDos: {
                    singular: '+{0} more ToDo',
                    plural: '+{0} more ToDos'
                },
                nextToDos: {
                    title: 'Next To Dos',
                    showMore: 'Show more to dos',
                    showLess: 'Show less to dos'
                },
                reviewToDos: {
                    title: 'My To Dos to review'
                },
                reviewRequests: {
                    title: 'My requests to review'
                },
                toDosInReview: {
                    title: 'My To Dos in review'
                },
                subordinated: {
                    title: 'To Dos in my team'
                },
                subordinatedReviews: {
                    title: 'Reviews in my team'
                },
                subordinatedRequestReviews: {
                    title: 'Requests in my team'
                },
                requests: {
                    open: {
                        title: 'Approval requests'
                    },
                    approved: {
                        title: 'Approved requests'
                    },
                    expired: {
                        title: 'Expired requests'
                    },
                    declined: {
                        title: 'Declined requests'
                    }
                },
                finishedToDos: {
                    title: 'To Do archive',
                    showMore: 'Show more to dos',
                    showLess: 'Show less to dos'
                },
                dashboards: {
                    title: 'Dashboards',
                    showMore: 'Show more',
                    showLess: 'Show less'
                }
            },
            subSidebar: {
                title: 'Attachments & explanations'
            },
            overview: {
                showAll: 'Show all To Dos',
                showAllReviews: 'Show all reviews',
                empty: 'No To Dos found for your search. Please adjust your filter settings.',
                resetFilter: 'Reset filter',
                archive: {
                    title: 'Show To Do archive',
                    description: 'Here you can see all To Dos that have already been completed, download them as PDFs or share them directly via email within your company.',
                    empty: 'No To Dos found for your search {0}.',
                },
                title: {
                    overview: 'Overview',
                    pending: 'Next To Dos',
                    pendingWithGroup: 'More To Dos',
                    finished: 'Finished To Dos'
                },
                requests: {
                    title: 'My approval requests',
                    pending: 'Approval requests',
                    pendingWithGroup: 'Approval requests',
                    edit: 'Edit request',
                    showAll: 'Show all requests'
                },
                approvedRequests: {
                    title: 'Approved requests',
                    showAll: 'Show all approved requests',
                    show: 'Show request'
                },
                declinedRequests: {
                    title: 'Declined requests',
                    showAll: 'Show all declined requests',
                    show: 'Show request'
                },
                expiredRequests: {
                    title: 'Expired requests',
                    showAll: 'Show all expired requests',
                    show: 'Show request'
                },
                inReview: {
                    title: 'To Dos in review',
                    description: 'Here you can see your To Dos, which are currently being reviewed. As soon as the review is completed, you will be notified by e-mail.',
                    empty: 'No To Dos found for your search {0}.',
                    tooltip: {
                        title: 'Reviewed by'
                    }
                },
                requestsInReview: {
                    title: 'Requests in review',
                    description: 'Here you can see your requests, which are currently being reviewed. As soon as the review is completed, you will be notified by e-mail.',
                    empty: 'No requests found for your search {0}.',
                    tooltip: {
                        title: 'Reviewed by'
                    }
                },
                userReview: {
                    title: 'To Dos for review',
                    description: 'Here you can see To Dos which need to be reviewd by you. Just click on a To Do to start with the review.',
                    empty: 'No To Dos found for your search {0}.',
                    actions: {
                        reviewer: {
                            button: 'Add reviewer',
                            description: 'Click here to add another reviewer to this review.'
                        }
                    }
                },
                userReviewRequests: {
                    title: 'Requests for review',
                    description: 'Here you can see requests which need to be reviewd by you. Just click on a request to start with the review.',
                    empty: 'No requests found for your search {0}.',
                    actions: {
                        reviewer: {
                            button: 'Add reviewer',
                            description: 'Click here to add another reviewer to this review.'
                        }
                    }
                },
                otherToDos: {
                    title: 'To Dos in my team',
                    actions: 'Actions',
                    description: 'Here you can see to dos that are currently being edited by your employees. You can adjust the deadline, add new editors or edit the to do by yourself.',
                    empty: 'No To Dos found for your search {0}.',
                },
                otherReviews: {
                    title: 'Reviews in my team',
                    actions: 'Actions',
                    description: 'Here you can see reviews that are currently being done by your employees. You can adjust the deadline, add new reviewers or review the to do by yourself.',
                    empty: 'No To Dos found for your search {0}.',
                },
                otherReviewRequests: {
                    title: 'Requests in my team',
                    actions: 'Actions',
                    description: 'Here you can see requests that are currently being done by your employees. You can adjust the deadline, add new reviewers or review the request by yourself.',
                    empty: 'No requests found for your search {0}.',
                },
                toDoGroup: {
                    title: 'My To Dos: {0}'
                },
                requestGroup: {
                    title: 'My requests: {0}'
                },
                toDo: {
                    editor: {
                        title: 'Editors',
                        add: {
                            action: 'Add editor',
                            dialog: {
                                title: 'Add editor',
                                input: {
                                    label: 'Search editors',
                                    placeholder: 'Enter email'
                                },
                                noneSelected: 'No new editor selected',
                                selectedEditors: 'Selected editors',
                                emptyResults: 'No users were found for your search',
                                actions: {
                                    submit: 'Add',
                                    cancel: 'Cancel'
                                },
                                success: 'The editors have been saved.',
                                error: 'The editors could not be saved.'
                            }
                        }
                    },
                    search: {
                        placeholder: 'Search',
                        label: 'Search to dos'
                    },
                    interval: {
                        [ToDoInterval.Daily]: 'daily',
                        [ToDoInterval.Weekly]: 'weekly',
                        [ToDoInterval.Monthly]: 'monthly',
                        [ToDoInterval.Yearly]: 'yearly',
                        [ToDoInterval.ByMonthly]: 'by-monthly',
                        [ToDoInterval.CustomDays]: 'custom',
                        [ToDoInterval.OneTime]: 'one time',
                        [ToDoInterval.Every4Weeks]: 'every 4 weeks',
                        [ToDoInterval.Every6Weeks]: 'every 5 weeks',
                        [ToDoInterval.HalfYearly]: 'half yearly',
                        [ToDoInterval.Quarterly]: 'quarterly',
                        [ToDoInterval.EveryTwoYears]: 'every 2 years',
                        [ToDoInterval.EveryTwoWeeks]: 'every 2 weeks',
                    },
                    saveAndContinueLater: {
                        button: 'Continue later',
                        dialog: {
                            headline: 'Continue To Do later',
                            description: 'All changes are saved. We will send you an email with a link that will allow you to return to this To Do later.',
                            confirm: 'Send email',
                            cancel: 'Cancel'
                        },
                        success: 'Your To Do was successfully saved. You will receive an email shortly.',
                        error: 'Unfortunately an error occurred. Please try again later.'
                    },
                    start: 'Start To Do',
                    info: {
                        completedTasks: '{0} of {1} tasks completed',
                        tasks: 'tasks',
                        completed: 'completed'
                    },
                    noResults: 'No results',
                    none: 'There are no open to dos.',
                    until: '{0}, until {1}',
                    pagination: {
                        description: '{0}-{1} of {2}'
                    },
                    actions: {
                        view: {
                            button: 'Open To Do'
                        },
                        download: {
                            button: 'Download',
                            buttonSelect: 'Download {0} to dos',
                            buttonSingleSelect: 'Download {0} to do',
                            tableView: 'Download table view',
                            defaultView: 'Download PDF with images'
                        },
                        reOpen: {
                            button: 'Enable To Do for editing again',
                            dialog: {
                                title: 'Enable To Do for editing again',
                                descriptions: 'Do you want to enable the To Do {0} for editing again? First select a new deadline and then click on Enable.',
                                deadline: 'Please select a new deadline for the To Do:',
                                submit: 'Enable'
                            },
                            notifications: {
                                success: 'The To Do has been enabled for editing again.',
                                error: 'The To Do could not be enabled for editing again.'
                            }
                        },
                        review: {
                            show: 'Show review',
                            reOpen: 'Resume review'
                        },
                        email: {
                            button: 'Share via email',
                            buttonMobile: 'E-Mail',
                            buttonSelect: 'Share {0} to dos via email',
                            buttonSingleSelect: 'Share {0} to do via email',
                            dialog: {
                                title: 'Send email',
                                subject: {
                                    label: 'Subject',
                                    placeholder: 'Qualitize Operations: Summary'
                                },
                                text: {
                                    label: 'Message',
                                    placeholder: 'Enter your message here'
                                },
                                recipients: {
                                    label: 'Recipients',
                                    placeholder: 'Enter an email'
                                },
                                defaultSubject: 'Qualitize Operations: Summary {0}',
                                defaultSubjectMultiple: 'Qualitize Operations - To Do overview',
                                defaultGreeting: '\n\nBest regards, \n{0}',
                                attachment: 'Attachment',
                                submit: 'Send email',
                                cancel: 'Cancel',
                                success: 'Your email has been sent successfully.',
                                error: 'Your email could not be sent.',
                                search: {
                                    loading: 'Loading recipients',
                                    noOptions: 'No recipients found'
                                }
                            }
                        },
                        open: 'Open To Do',
                        collapse: {
                            open: 'Show all to dos',
                            close: 'Show less to dos'
                        },
                        moreActions: 'More actions',
                        changeDeadline: {
                            button: 'Move deadline',
                            help: 'Here you can move the deadline of the to do to a day of your choice.',
                            dialog: {
                                title: 'Move deadline',
                                descriptions: 'Select a new deadline for the to do here.',
                                submit: 'Save'
                            },
                            notifications: {
                                success: 'The deadline was successfully moved to {0}.',
                                error: 'The deadline could not be moved to {0}.'
                            }
                        },
                        takeOverToDo: {
                            button: 'Take over to do',
                            help: 'Click here if you want to edit the to do by yourself.'
                        }
                    }
                },
                review: {
                    reviewer: {
                        title: 'Reviewer',
                        add: {
                            action: 'Add reviewer',
                            dialog: {
                                title: 'Add reviewer',
                                input: {
                                    label: 'Search reviewer',
                                    placeholder: 'Enter email'
                                },
                                noneSelected: 'No new reviewer selected',
                                selectedEditors: 'Selected reviewer',
                                emptyResults: 'No users were found for your search',
                                actions: {
                                    submit: 'Add',
                                    cancel: 'Cancel'
                                },
                                success: 'The reviewer have been saved.',
                                error: 'The reviewer could not be saved.'
                            }
                        }
                    },
                    actions: {
                        changeDeadline: {
                            button: 'Move deadline',
                            help: 'Here you can move the deadline of the review to a day of your choice.',
                            dialog: {
                                title: 'Move deadline',
                                descriptions: 'Select a new deadline for the review here.',
                                submit: 'Save'
                            },
                            notifications: {
                                success: 'The deadline was successfully moved to {0}.',
                                error: 'The deadline could not be moved to {0}.'
                            }
                        },
                        takeOverReview: {
                            button: 'Take over review',
                            help: 'Click here if you want to edit the review by yourself.'
                        }
                    },
                    approval: {
                        restrictedApprovalDate: 'Approval valid until:'
                    }
                }
            },
            dialogs: {
                createToDoDialog: {
                    title: 'Create To Do',
                    submit: 'Create',
                    cancel: 'Cancel',
                    selectToDo: {
                        label: 'Select To Do',
                        placeholder: 'Please select a To Do'
                    },
                    selectLocation: {
                        label: 'Select location',
                        placeholder: 'Please select a location'
                    },
                    success: 'Your To Do has been created successfully.',
                    error: 'An error occurred, please try again later.',
                    hint: {
                        description: 'There is already a To Do for your selection.',
                        link: 'Edit To Do'
                    }
                }
            },
            topBar: {
                locations: {
                    all: 'All',
                    allLocations: 'All locations'
                },
                toDos: {
                    button: 'To Do: {0}',
                    all: 'All',
                    allToDos: 'All To Dos'
                }
            },
            files: {
                preview: {
                    notAvailable: 'No preview for file "{0}" available. Click here to download file.',
                    fullscreen: 'Fullscreen'
                }
            },
            edit: {
                sync: {
                    success: 'All changes saved',
                    error: {
                        singular: '{0} change not saved',
                        plural: '{0} changes not saved',
                    },
                    close: 'Your changes might not be saved.'
                },
                files: {
                    upload: {
                        headline: 'Attachments',
                        progress: 'Uploading your attachment',
                        preparing: 'Preparing your attachment',
                        error: {
                            notification: 'Your attachment could not be uploaded.',
                            cancel: 'Delete attachment',
                            retry: 'Retry upload',
                            uploadTooOld: 'The selected attachment must not be older than {0}.'
                        },
                        button: {
                            default: 'Select attachment',
                            more: 'Select another',
                            required: '(required)',
                            multipleRequired: '({0} required)'
                        },
                        dialog: {
                            delete: {
                                title: 'Delete upload',
                                content: 'Do you want to permanently delete the uploaded file?',
                                submit: 'Delete permanently',
                                cancel: 'Cancel'
                            }
                        }
                    }
                },
                text: {
                    input: {
                        show: 'Add comment',
                        hide: 'Remove comment',
                        placeholder: 'Enter your comment here',
                        remove: {
                            title: 'Remove comment',
                            description: 'Do you really want to remove the following comment?',
                            cancel: 'Cancel',
                            submit: 'Remove'
                        }
                    }
                },
                input: {
                    placeholder: 'Please enter here',
                    label: 'Text input'
                },
                questions: {
                    boolean: {
                        default: {
                            true: 'Ok',
                            false: 'Not ok',
                            notSpecified: 'Not specified'
                        },
                        checkbox: {
                            active: 'finished',
                            inactive: 'to do'
                        }
                    },
                    numberInput: {
                        invalid: 'Please enter a valid number.',
                        invalidRange: {
                            lowerBound: 'The value entered must be greater than {0}.',
                            lowerBoundIncluded: 'The value entered must be greater than or equal to {0}.',
                            upperBound: 'The value entered must be less than than {0}.',
                            upperBoundIncluded: 'The value entered must be less than or equal to {0}.',
                        }
                    }
                },
                appendices: {
                    actions: {
                        fullScreen: {
                            open: 'Fullscreen',
                            show: 'Show',
                            close: 'Close'
                        },
                        download: 'Download'
                    }
                },
                actions: {
                    next: {
                        desktop: 'Next ({0})',
                        mobile: 'Next'
                    },
                    start: 'Edit To Do',
                    view: 'View To Do',
                    resetTask: {
                        button: 'Reset task',
                        dialog: {
                            title: 'Reset task "{0}"',
                            description: 'Do you really want to reset the task? This action cannot be undone.',
                            submit: 'Reset task',
                        }
                    },
                    previous: {
                        desktop: 'Back ({0})',
                        mobile: 'Back'
                    },
                    toggleTasks: {
                        show: 'Show all tasks',
                        hide: 'Hide all tasks'
                    },
                    backToOverview: 'Back to overview',
                    finish: {
                        button: {
                            text: 'Finish To Do',
                            mobile: 'Finish',
                        },
                        description: 'Do you want to finish the To Do? It cannot be edited afterwards.',
                        submit: 'Finish',
                        cancel: 'Cancel',
                        success: 'The To Do was successfully completed.',
                        error: 'The To Do could not be completed.'
                    },
                    review: {
                        description: 'Would you like to send the To Do for review? It cannot be edited afterwards.',
                        submit: 'Send',
                        cancel: 'Cancel',
                        title: 'Send To Do for review',
                        success: 'The To Do has been successfully sent for review.',
                        error: 'The To Do could not be sent for review.',
                        reviewer: 'The To Do will be sent to the following for review:',
                        autoApprove: {
                            title: 'Complete To Do',
                            description: 'This To Do can be approved automatically. Click on "Complete To Do" to complete the To Do.',
                            submit: 'Complete To Do',
                            notifications: {
                                error: 'An error occurred.'
                            }
                        },
                        autoDecline: {
                            title: 'To Do declined',
                            description: 'This To Do was automatically declined. Click on "Continue" to go to the overview page.',
                            submit: 'Continue',
                            notifications: {
                                error: 'An error occurred.'
                            }
                        }
                    },
                    mails: {
                        trigger: 'Send mail'
                    },
                    downloadFile: {
                        trigger: 'Download file'
                    },
                    phone: {
                        trigger: 'Call {0}'
                    },
                    link: {
                        open: 'Open link'
                    }
                },
                state: {
                    general: {
                        finished: 'Finished',
                        editing: 'In progress',
                        pending: 'Pending',
                        approved: 'Approved',
                        edited: 'Edited',
                        readOnly: 'Read-Only View',
                        frozen: 'No longer editable',
                        optional: 'Optional'
                    },
                    tasks: {
                        pending: {
                            singular: '{0} pending Task',
                            plural: '{0} pending Tasks'
                        },
                        optional: '({0} optional)'
                    }
                },
                previousToDos: {
                    title: 'Latest answers',
                    noPreviousAnswers: 'No history available.'
                },
                reviews: {
                    title: 'Remarks by {0} ({1})',
                }
            },
            archive: {
                notEdited: 'Task was not edited.',
                files: {
                    singular: '{0} Upload',
                    plural: '{0} Uploads',
                    none: 'No uploads'
                },
                table: {
                    header: {
                        task: 'Task',
                        answer: 'Answer',
                        remarks: 'Remark',
                        uploads: 'Uploads'
                    },
                    export: {
                        page: 'Page'
                    }
                },
                topBar: {
                    actions: {
                        button: 'Actions'
                    }
                },
                view: {
                    [ArchiveVisualizationType.Table]: 'Table view',
                    [ArchiveVisualizationType.Grid]: 'Grid view',
                    [ArchiveVisualizationType.Review]: 'Review view',
                    [ArchiveVisualizationType.Inline]: 'Inline view',
                }
            },
            reviewRequest: {
                link: 'Review request'
            },
            review: {
                title: 'To Do Review {0}',
                link: 'Review to do',
                specification: {
                    title: 'Specification'
                },
                declined: {
                    reason: 'Reason for declining',

                    autoDeclined: 'Automatically declined by the system due to central specifications.'
                },
                topBar: {
                    progress: {
                        title: 'Review progress',
                        tooltips: {
                            pending: {
                                singular: '{0} pending review',
                                plural: '{0} pending reviews'
                            },
                            disapproved: {
                                singular: '{0} disapproved Task',
                                plural: '{0}  disapproved Tasks'
                            },
                            approved: {
                                singular: '{0} approved Task',
                                plural: '{0}  approved Tasks'
                            }
                        }
                    }
                },
                approval: {
                    preview: 'Implementation',
                    title: 'Review',
                    approve: 'Approve',
                    task: 'Task:',
                    disapprove: 'Disapprove',
                    zoom: {
                        description: 'You can zoom into an image with the help of your mouse. To do this, simply move the mouse over the image and scroll. Click "Off" to disable zooming.',
                        title: 'Zoom-Mode',
                        on: 'On',
                        off: 'Off'
                    }
                },
                remarks: {
                    title: 'Remark'
                },
                decline: {
                    confirmationDialog: {
                        title: 'Decline and finish To Do',
                        description: 'Would you like to decline editing the To Do and close the To Do? It can no longer be edited afterwards.',
                        submit: 'Decline and finish To Do',
                        reason: {
                            label: 'Reason',
                            placeholder: 'Please enter a reason.'
                        },
                        notifications: {
                            success: 'The To Do was successfully declined. The editor were informed by e-mail.',
                            error: 'The To Do could not be declined. Please check your details.'
                        }
                    }
                },
                finish: 'Finish review',
                tasksToReview: {
                    title: 'Tasks to review',
                },
                reviewedTasks: {
                    title: 'Reviewed tasks',
                    approved: {
                        title: 'Approved',
                        yes: 'Yes',
                        no: 'No'
                    },
                    reOpen: 'Redo review'
                },
                finishedTasks: {
                    title: 'Other tasks'
                },
                modal: {
                    cancel: 'Cancel',
                    title: 'Finish review',
                    description: {
                        approved: 'Would you like to complete the review and approve?',
                        disapproved: 'Would you like to complete the review and release for rework?'
                    },
                    editors: 'The following editors will be notified of your review:',
                    deadline: {
                        description: 'Please select a new deadline:',
                        select: 'Select date'
                    },
                    timeRestriction: {
                        description: 'Please enter a date until which the approval is valid:',
                        descriptionOptional: 'Would you like to specify a date until which the approval is valid (optional):',
                        select: 'Select date'
                    }
                },
                notifications: {
                    success: 'The review was successfully completed.',
                    error: 'The review could not be completed.'
                },
                reOpen: {
                    action: {
                        button: 'Resume review'
                    },
                    modal: {
                        title: 'Resume review',
                        description: 'Do you want to resume the review? All reviewer will have access to the review again and edit their reviews.',
                        deadline: 'Please select a new deadline for the review:',
                        submit: 'Resume review'
                    },
                    notifications: {
                        success: 'The review was successfully resumed and can be edited again.',
                        error: 'The review could not be resumed.'
                    }
                }
            },
            search: {
                results: {
                    searchString: 'Search: {0}',
                    locations: {
                        singular: 'Location: {0}',
                        plural: 'Locations: {0}'
                    },
                    toDos: {
                        singular: 'To Do: {0}',
                        plural: 'To Dos: {0}'
                    }
                }
            }
        },
        reports: {
            timeScopes: {
                [ReportTimeScopeType.Day]: '{0}',
                [ReportTimeScopeType.Week]: 'Week {0}',
                [ReportTimeScopeType.Month]: '{0}',
                [ReportTimeScopeType.Total]: '',
                [ReportTimeScopeType.Custom]: '{0}-{1}',
                [ReportTimeScopeType.Quarter]: 'Q{0}',
                [ReportTimeScopeType.Year]: '{0}',
            },
            timeScopeDescription: {
                [ReportTimeScopeType.Day]: 'Day',
                [ReportTimeScopeType.Week]: 'Week',
                [ReportTimeScopeType.Month]: 'Month',
                [ReportTimeScopeType.Total]: 'Total',
                [ReportTimeScopeType.Custom]: 'Custom',
                [ReportTimeScopeType.Quarter]: 'Quarter',
                [ReportTimeScopeType.Year]: 'Year'
            },
            timeSpanDescription: {
                [ReportTimeSpanType.Day]: 'Daily report',
                [ReportTimeSpanType.Week]: 'Weekly update',
                [ReportTimeSpanType.Month]: 'Monthly report',
                [ReportTimeSpanType.Total]: 'Total report',
                [ReportTimeSpanType.Custom]: 'Custom report',
                [ReportTimeSpanType.Quarter]: 'Quarter report',
                [ReportTimeSpanType.Year]: 'Year report',
            },
            reportScope: {
                [ReportScopeType.Department]: '{0}',
                [ReportScopeType.Location]: '{0}',
                [ReportScopeType.Region]: '{0}',
                [ReportScopeType.Central]: '{0}',
            },
            reportScopeDescription: {
                [ReportScopeType.Department]: 'Department',
                [ReportScopeType.Location]: 'Location',
                [ReportScopeType.Region]: 'Region',
                [ReportScopeType.Central]: 'Headquarters',
            },
            latest: {
                title: 'Your latest reports'
            },
            actions: {
                download: 'Download',
                fullscreen: 'Fullscreen',
                closeDialog: 'Close fullscreen',
                detail: 'Open'
            },
            topBar: {
                level: {
                    title: 'Level',
                    all: 'All'
                },
                timeSpan: {
                    title: 'Timespan'
                },
                detail: {
                    search: {
                        notFound: 'No results',
                        results: 'Result {0}/{1}',
                        placeholder: 'Search report'
                    }
                }
            },
            search: {
                title: 'Reports - Search',
                noResults: 'No reports have been found for your selection.'
            },
            download: {
                success: {
                    title: 'Your download has been started',
                    errorDescription: 'If your download has not been started yet, click the download button below.',
                    startDownload: 'Download',
                    description: 'Log in to your Qualitize Online account and discover even more possibilities.'
                },
                error: {
                    title: 'Sorry, your download link has expired.',
                    description: 'You can find all your reports in the archive of your Qualitize Online account. Just click on the button and log in.',
                },
                loginButton: 'Login now',
                archiveLink: 'See report online'
            },
            notFound: 'Your file "{0}" could not be loaded.'
        },
        landingPages: {
            empty: 'There are no Magic QR codes available for you yet.',
            error: 'Your Magic QR codes could not be loaded.',
            info: {
                createdAt: 'Created at:',
                modifiedAt: 'Updated at:',
                type: 'Magic QR-Code Type:'
            },
            types: {
                [LandingPageConfigType.Layout]: 'Landing Page',
                [LandingPageConfigType.Redirect]: 'Website-Redirect'
            },
            overview: {
                link: 'Open Magic QR-Code',
                topBar: {
                    hierarchyNodeSelection: {
                        empty: 'Level: All',
                        selectNone: 'All'
                    },
                    search: {
                        placeholder: 'What are you searching for?'
                    }
                },
                table: {
                    header: {
                        hierarchyNode: 'Level',
                        name: 'Name',
                        type: 'Magic QR-Code Type',
                        createdTimestamp: 'Created at',
                        modifiedTimestamp: 'Modified at',
                        demo: '',
                        actions: 'Actions'
                    }
                }
            },
            state: {
                type: {
                    [LandingPageReleaseStateType.PreRelease]: 'In preparation',
                    [LandingPageReleaseStateType.Inactive]: 'Inactive',
                    [LandingPageReleaseStateType.Active]: 'Active',
                    [LandingPageReleaseStateType.ServeHistoricVersion]: 'Serving historic version',
                },
                dialogs: {
                    prerelease: {
                        title: 'Confirm status change',
                        submit: 'Change status',
                        explanation: 'The status of the Magic QR Code is set from "In preparation" to "{0}". If you change this status, you will no longer be able to change the linked area ({1}) of the Magic QR Code.'
                    }
                }
            },
            preview: {
                title: 'Preview'
            },
            edit: {
                save: 'Save changes',
                type: {
                    dialog: {
                        confirmation: {
                            title: 'Change type',
                            description: 'Do you really want to change the type? All previous configurations will then be reset.',
                            submit: 'Change type'
                        }
                    }
                },
                cards: {
                    stepper: 'Configure Magic QR-Code',
                    preview: 'Preview'
                },
                topBar: {
                    title: 'Magic QR-Code {0}'
                },
                language: {
                    title: 'Language',
                    availableLanguages: 'Available languages',
                    select: {
                        theme: 'For which language do you define the browser title and the background?',
                        content: 'For which language do you create the content?'
                    }
                },
                links: {
                    title: 'Link',
                    variants: {
                        [LandingPageLinkType.Explicit]: 'Custom URL',
                        [LandingPageLinkType.Feedback]: 'Feedback-Link',
                    },
                    explicit: {
                        input: {
                            label: 'Link',
                            placeholder: 'Please enter the link.'
                        },
                        empty: 'There are no available survey links for the selected area.',
                        select: 'Select'
                    },
                    feedback: {
                        title: 'Survey'
                    }
                },
                popover: {
                    apply: 'Apply changes'
                },
                steps: {
                    step1: {
                        title: 'Define type & name',
                        submit: {
                            edit: 'Save and continue',
                            create: 'Create Magic QR-Code'
                        }
                    },
                    step2: 'Define colors & fonts',
                    step3: 'Create content',
                    stepRedirect: 'Define redirect'
                },
                rows: {
                    create: {
                        title: 'Add row'
                    },
                    remove: {
                        title: 'Remove row',
                        description: 'Do you really want to remove this row?',
                        submit: 'Remove'
                    },
                    duplicate: {
                        title: 'Duplicate row'
                    },
                    types: {
                        [LandingPageLayoutRowType.Text]: 'Text',
                        [LandingPageLayoutRowType.Image]: 'Image',
                        [LandingPageLayoutRowType.TextButton]: 'Text button',
                        [LandingPageLayoutRowType.AppStores]: 'App Store link',
                        [LandingPageLayoutRowType.ComplexButton]: 'Button',
                        [LandingPageLayoutRowType.CircleButtons]: 'Social buttons',
                    },
                    spacing: {
                        title: 'Configure spacing bottom',
                        select: {
                            label: 'Select spacing',
                            placeholder: 'Please select the spacing.'
                        }
                    },
                    image: {
                        configure: 'Configure',
                        height: {
                            title: 'Height of the image in pixel',
                            explanation: 'Enter the height of the image in pixels. If you do not enter a value, the image is aligned based on the available width.',
                            input: {
                                label: 'Height in px',
                                placeholder: 'Enter the number of pixels.'
                            }
                        }
                    },
                    padding: {
                        title: 'Select padding',
                        horizontal: 'Padding left / right',
                        vertical: 'Padding top / bottom',
                    },
                    button: {
                        border: {
                            title: 'Configure border',
                            color: {
                                title: 'Border color'
                            },
                            width: {
                                title: 'Border width',
                                input: {
                                    label: 'Border width',
                                    placeholder: ''
                                }
                            },
                            radius: {
                                title: 'Border radius',
                                input: {
                                    label: 'Border radius',
                                    placeholder: ''
                                }
                            }
                        },
                        height: {
                            title: 'Button height',
                            input: {
                                label: 'Button height',
                                placeholder: ''
                            }
                        },
                        hover: {
                            type: {
                                [LandingPageLayoutTextButtonHoverType.None]: 'None',
                                [LandingPageLayoutTextButtonHoverType.Darken]: 'Darken',
                                [LandingPageLayoutTextButtonHoverType.Lighten]: 'Lighten',
                                [LandingPageLayoutTextButtonHoverType.InvertStyling]: 'Invert',
                                [LandingPageLayoutTextButtonHoverType.OverwriteStyling]: 'Custom style',
                            },
                            percentage: {
                                label: 'Percentage (0-100%)',
                                placeholder: 'Please enter the percentage.'
                            }
                        },
                        tabs: {
                            [LandingPageLayoutTextButtonConfigurationTab.HoverState]: 'Hover state',
                            [LandingPageLayoutTextButtonConfigurationTab.DefaultState]: 'Default state',
                        }
                    },
                    text: {
                        textColor: 'Text color',
                        textSize: {
                            title: 'Text size',
                            variant: {
                                [LandingPageTextSizeType.Small]: 'Small',
                                [LandingPageTextSizeType.Body]: 'Normal',
                                [LandingPageTextSizeType.Subtitle]: 'Subtitle',
                                [LandingPageTextSizeType.Title]: 'Title',
                                [LandingPageTextSizeType.Custom]: 'Custom size',
                            },
                            custom: {
                                label: 'Text size',
                                placeholder: 'Please enter the text size.'
                            }
                        },
                        textWeight: {
                            title: 'Text weight',
                            variant: {
                                [LandingPageFontWeightClass.Thin]: 'Thin (100)',
                                [LandingPageFontWeightClass.ExtraLight]: 'Extra light (200)',
                                [LandingPageFontWeightClass.Light]: 'Light (300)',
                                [LandingPageFontWeightClass.Regular]: 'Normal (400)',
                                [LandingPageFontWeightClass.Medium]: 'Medium (500)',
                                [LandingPageFontWeightClass.SemiBold]: 'Semi bold (600)',
                                [LandingPageFontWeightClass.Bold]: 'Bold (700)',
                                [LandingPageFontWeightClass.ExtraBold]: 'Extra bold (800)',
                                [LandingPageFontWeightClass.Black]: 'Black (900)',
                                [LandingPageFontWeightClass.ExtraBlack]: 'Extra Black (950)',
                            }
                        },
                        background: {
                            title: 'Select background',
                            type: 'Background type',
                            variant: {
                                none: 'None',
                                [LandingPageLayoutBackgroundType.BackgroundColor]: 'Background color',
                                [LandingPageLayoutBackgroundType.BackgroundImage]: 'Image',
                            },
                            color: {
                                select: 'Select color'
                            },
                            image: {
                                select: 'Select image',
                                change: 'Change image',
                                preview: 'Image preview'
                            }
                        }
                    },
                    circleButtons: {
                        title: 'Social buttons',
                        add: 'Add button',
                        remove: 'Remove button',
                        empty: 'No buttons defined yet.',
                        type: {
                            select: 'Select icon',
                            variant: {
                                [LandingPageIcon.Add]: 'Add',
                                [LandingPageIcon.AddCircle]: 'Add (circled)',
                                [LandingPageIcon.AlternateEmail]: 'Email (alternate)',
                                [LandingPageIcon.ArrowBack]: 'Arrow back',
                                [LandingPageIcon.ArrowDown]: 'Arrow down',
                                [LandingPageIcon.ArrowForward]: 'Arrow forward',
                                [LandingPageIcon.ArrowUp]: 'Arrow up',
                                [LandingPageIcon.AutoAwesome]: 'Stars',
                                [LandingPageIcon.BarChart]: 'Bar chart',
                                [LandingPageIcon.Bolt]: 'Lightning',
                                [LandingPageIcon.Bookmark]: 'Bookmark',
                                [LandingPageIcon.CalendarMonth]: 'Calendar (month)',
                                [LandingPageIcon.CalendarToday]: 'Calendar (today)',
                                [LandingPageIcon.Camera]: 'Camera',
                                [LandingPageIcon.Celebration]: 'Celebration',
                                [LandingPageIcon.Chat]: 'Chat',
                                [LandingPageIcon.Check]: 'Check',
                                [LandingPageIcon.CheckCircle]: 'Check (circle)',
                                [LandingPageIcon.Checklist]: 'Checklist',
                                [LandingPageIcon.Cloud]: 'Cloud',
                                [LandingPageIcon.Coffee]: 'Coffee',
                                [LandingPageIcon.Comment]: 'Comment',
                                [LandingPageIcon.Computer]: 'Computer',
                                [LandingPageIcon.Cookie]: 'Cookie',
                                [LandingPageIcon.Create]: 'Create',
                                [LandingPageIcon.CreditCard]: 'Credit card',
                                [LandingPageIcon.Diamond]: 'Diamond',
                                [LandingPageIcon.Download]: 'Download',
                                [LandingPageIcon.Email]: 'Email',
                                [LandingPageIcon.Event]: 'Event',
                                [LandingPageIcon.Explore]: 'Compass',
                                [LandingPageIcon.Facebook]: 'Facebook',
                                [LandingPageIcon.Favorite]: 'Favorite',
                                [LandingPageIcon.Fingerprint]: 'Fingerprint',
                                [LandingPageIcon.GoogleMaps]: 'Google Maps',
                                [LandingPageIcon.Help]: 'Help',
                                [LandingPageIcon.Home]: 'Home',
                                [LandingPageIcon.Instagram]: 'Instagram',
                                [LandingPageIcon.Language]: 'Language',
                                [LandingPageIcon.LinkedIn]: 'LinkedIn',
                                [LandingPageIcon.Lightbulb]: 'Lightbulb',
                                [LandingPageIcon.Link]: 'Link',
                                [LandingPageIcon.LocalOffer]: 'Tag',
                                [LandingPageIcon.MoreVert]: 'Menu',
                                [LandingPageIcon.Navigation]: 'Navigation',
                                [LandingPageIcon.NearMe]: 'Near me',
                                [LandingPageIcon.NetworkWifi]: 'Network',
                                [LandingPageIcon.Phone]: 'Phone',
                                [LandingPageIcon.Pinterest]: 'Pinterest',
                                [LandingPageIcon.Place]: 'Location',
                                [LandingPageIcon.Podcasts]: 'Podcasts',
                                [LandingPageIcon.Public]: 'World',
                                [LandingPageIcon.QuestionMark]: 'Question mark',
                                [LandingPageIcon.Restaurant]: 'Restaurant',
                                [LandingPageIcon.Search]: 'Search',
                                [LandingPageIcon.Security]: 'Security',
                                [LandingPageIcon.Send]: 'Submit',
                                [LandingPageIcon.ShoppingCart]: 'Shopping cart',
                                [LandingPageIcon.Snapchat]: 'Snapchat',
                                [LandingPageIcon.Spotify]: 'Spotify',
                                [LandingPageIcon.Star]: 'Star',
                                [LandingPageIcon.Telegram]: 'Telegram',
                                [LandingPageIcon.ThumbsDown]: 'Thumbs down',
                                [LandingPageIcon.ThumbsUp]: 'Thumbs up',
                                [LandingPageIcon.TikTok]: 'TikTok',
                                [LandingPageIcon.Threads]: 'Threads',
                                [LandingPageIcon.Vimeo]: 'Vimeo',
                                [LandingPageIcon.WhatsApp]: 'WhatsApp',
                                [LandingPageIcon.Whatshot]: 'Whatshot',
                                [LandingPageIcon.X]: 'X',
                                [LandingPageIcon.Xing]: 'Xing',
                                [LandingPageIcon.YouTube]: 'YouTube',
                            }
                        },
                        link: {
                            title: 'Define link'
                        },
                        theme: {
                            select: 'Select theme',
                            variant: {
                                [LandingPageIconThemeType.Primary]: 'Primary',
                                [LandingPageIconThemeType.Secondary]: 'Secondary',
                                [LandingPageIconThemeType.Custom]: 'Custom colors',
                            },
                            custom: {
                                backgroundColor: 'Background color',
                                textColor: 'Icon color'
                            }
                        }
                    },
                    complexButton: {
                        content: {
                            add: 'Add element',
                            remove: 'Remove element',
                            spacing: {
                                left: 'Spacing left',
                                right: 'Spacing right'
                            },
                            type: {
                                select: 'Content type',
                                variant: {
                                    [LandingPageLayoutComplexButtonContentType.TextContent]: 'Text',
                                    [LandingPageLayoutComplexButtonContentType.IconContent]: 'Icon',
                                    [LandingPageLayoutComplexButtonContentType.ImageContent]: 'Image',
                                }
                            },
                            text: {
                                input: {
                                    placeholder: 'Please enter the button text.',
                                    label: 'Button text'
                                }
                            },
                            hover: {
                                type: {
                                    variant: {
                                        [LandingPageLayoutComplexButtonHoverType.None]: 'None',
                                        [LandingPageLayoutComplexButtonHoverType.OverwriteContent]: 'Overwrite content',
                                    }
                                }
                            }
                        }
                    },
                    appStoreButtons: {
                        empty: 'Configure App Stores',
                        add: 'Add App Store',
                        remove: 'Remove App Store',
                        appStore: {
                            [LandingPageAppStoreType.AppleAppStore]: 'Apple App Store',
                            [LandingPageAppStoreType.PlayStore]: 'Google Play Store',
                        },
                        input: {
                            label: 'App Store URL',
                            placeholder: 'Enter App Store URL eingeben'
                        },
                    }
                },
                theme: {
                    color: {
                        title: 'Define colors & background'
                    },
                    primaryColor: {
                        title: 'Primary color',
                        input: {
                            label: 'Primary color',
                            placeholder: 'Enter the color code here (hex code) or select it using the color picker.'
                        }
                    },
                    primaryContrastColor: {
                        title: 'Primary contrast color',
                        input: {
                            label: 'Primary contrast color',
                            placeholder: 'Enter the color code here (hex code) or select it using the color picker.'
                        }
                    },
                    secondaryColor: {
                        title: 'Secondary color',
                        input: {
                            label: 'Secondary color',
                            placeholder: 'Enter the color code here (hex code) or select it using the color picker.'
                        }
                    },
                    secondaryContrastColor: {
                        title: 'Secondary contrast color',
                        input: {
                            label: 'Secondary contrast color',
                            placeholder: 'Enter the color code here (hex code) or select it using the color picker.'
                        }
                    },
                    contentTextColor: {
                        title: 'Text color',
                        input: {
                            label: 'Text color',
                            placeholder: 'Enter the color code here (hex code) or select it using the color picker.'
                        }
                    },
                    customColor: {
                        title: 'Custom color',
                        input: {
                            label: 'Custom color',
                            placeholder: 'Enter the color code here (hex code) or select it using the color picker.'
                        }
                    },
                    background: {
                        title: 'Define background'
                    }
                },
                browserTitle: {
                    title: 'Define browser title (optional)',
                    input: {
                        label: 'Browser title',
                        placeholder: 'Please enter a title.'
                    }
                },
                qrCodeText: {
                    title: 'Text below QR-Code (optional)',
                    input: {
                        label: 'Text below QR-Code',
                        placeholder: 'Please enter a text.'
                    }
                },
                font: {
                    select: 'Select font',
                    customFont: 'Custom font',
                    predefinedFont: 'Predefined fonts',
                    uploadFont: 'Upload new font',
                    upload: {
                        headline: {
                            default: 'Upload font',
                            active: 'Upload',
                            illegalFileType: 'This file type is not supported.',
                        },
                        subLine: {
                            default: 'Alternatively browse your files ',
                            active: 'Your font is uploaded',
                            illegalFileType: 'Try again or click '
                        },
                        acceptedFileTypes: 'We accept .ttf'
                    }
                },
                upload: {
                    title: 'Upload files',
                    headline: {
                        default: 'Upload files',
                        active: 'Upload',
                        illegalFileType: 'This file type is not supported.',
                    },
                    subLine: {
                        default: 'Alternatively browse your files ',
                        active: 'Your file is uploaded.',
                        illegalFileType: 'Try again or click '
                    }
                },
                notifications: {
                    save: {
                        success: 'The Magic QR-Code has been successfully saved.',
                        error: 'The Magic QR-Code could not be saved.'
                    }
                }
            },
            files: {
                images: {
                    select: 'Select image',
                    upload: 'Add new image',
                    existing: {
                        title: 'Available images'
                    },
                    localizedSelect: {
                        useForCurrentLocale: 'Use for selected language',
                        useForAllLocale: 'Use for all languages',
                    }
                }
            },
            create: {
                title: 'Create Magic QR-Code',
                button: 'Create Magic QR-Code',
                dialog: {
                    title: 'Create Magic QR-Code',
                    submit: 'Create',
                    name: {
                        placeholder: 'Please enter a name.',
                        label: 'Name'
                    },
                    description: {
                        placeholder: 'Please enter a description.',
                        label: 'Description (optional)'
                    },
                    landingPageType: 'Type',
                    hierarchyNode: {
                        title: 'Area',
                        description: 'Ein A Magic QR Code can be assigned to different hierarchy levels in your company. If you want to place the Magic QR Code in one of your branches, simply select this branch here. You can also link Magic QR Codes to regions or your head office.',
                    }
                }
            },
            actions: {
                download: {
                    title: {
                        singular: 'Download QR-Code',
                        plural: 'Download QR-Codes',
                    },
                    fileType: 'Select file types',
                    color: {
                        title: 'Select QR-Code color',
                        description: 'To reach maximum compatibility with all devices, always use a dark QR code on a light background. Not all devices are able to scan a light QR code on a dark background'
                    }
                },
                editLandingPage: 'Edit',
                clone: {
                    title: {
                        singular: 'Duplicate Magic QR-Code',
                        plural: 'Duplicate Magic QR-Codes'
                    },
                    dialog: {
                        submit: 'Duplicate',
                        explanation: {
                            singular: 'Do you want to duplicate the Magic QR-Code {0}?',
                            plural: 'Do you want to duplicate the Magic QR-Codes {0}?',
                        }
                    },
                    notifications: {
                        success: {
                            singular: 'The Magic QR-Code {0} was copied successfully.',
                            plural: 'The Magic QR-Codes {0} was copied successfully.',
                        },
                        error: {
                            singular: 'The Magic QR-Code {0} could not be duplicated.',
                            plural: 'The Magic QR-Codes {0} could not be duplicated.',
                        }
                    }
                }
            }
        }
    }
}