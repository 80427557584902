import {ApplicationState} from '../redux/reducer/application/types';
import {ConnectionState} from '../redux/reducer/connection/types';
import {DashboardState} from '../redux/reducer/dashboard/types';
import {UserState} from '../redux/reducer/user/types';
import {VideoState} from '../redux/reducer/videos/types';
import {AdminState} from '../redux/reducer/admin/types';
import {BaseEnvironmentVariables, FetchStatus} from '@software/reactcommons';
import {OperationsState} from '../redux/reducer/operations/types';
import {AdministrationState} from '../redux/reducer/administration/types';
import {ReportState} from '../redux/reducer/reports/types';
import {LandingPageState} from '../redux/reducer/landingPages/types';
import {CompiledMessage} from '@software/reactcommons/dist/localization/message/message.types';
import {LoginState} from '../redux/reducer/login/types';

/***************************************************************
 * Table pagination types.
 **************************************************************/

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC'
}

export interface OrderRequest {
    key: string;
    order: SortOrder;
}


export interface SearchRequest {
    offset?: number;
    limit?: number;
    searchText?: string;
    sorting?: string;
}

export interface ServiceSearchResponse<Entity> {
    totalCount: number;
    entities: Entity[];
}

export interface PaginationState<T> {
    visible: string[];
    page: number;
    pageSize: number;
    elements: Record<string, T>;
    numberOfElements: number;
    fetchStatus: FetchStatus;
    orderBy: OrderRequest[];
    search: string;
}

/***************************************************************
 * Global component prop types
 **************************************************************/

export interface EnvironmentVariables extends BaseEnvironmentVariables {
    REACT_APP_DATABASE?: string;
    REACT_APP_AUTHENTICATION_SERVICE_URL?: string;
    REACT_APP_VIDEO_SERVICE_URL?: string;
    REACT_APP_QUALITIZE_ONLINE_URL?: string;
    REACT_APP_BACKEND_BASE_ROUTE?: string;
    REACT_APP_PROTOCOL?: string;
    REACT_APP_VIDEO_SERVICE_WEB_SOCKET_URL?: string;
    REACT_APP_VIDEO_BACKEND?: string;
    REACT_APP_VIDEO_HIGHLIGHT_SLIDER_DURATION_IN_S?: string;
    REACT_APP_IMAGE_BACKEND?: string;
    REACT_APP_OPERATIONS_IMAGE_URL?: string;
    REACT_APP_OPERATIONS_DOWNLOAD_IMAGE_URL?: string;
    REACT_APP_NUMBER_OF_REFRESH_TOKEN_RETRIES?: string;
    REACT_APP_TOKEN_EXPIRY_THRESHOLD_IN_S?: string;
    REACT_APP_MAX_NUMBER_OF_VIDEOS_IN_VIDEO_DASHBOARD?: string;
    REACT_APP_TERMINAL_SERVICE_URL?: string;
    PUBLIC_URL?: string;
    REACT_APP_QUALITIZE_ONLINE_WEB_SOCKET_URL?: string;
    REACT_APP_REPORT_DOWNLOAD_BASE_PATH?: string;
    REACT_APP_CHART_SERVICE_BASE_URL?: string;
    REACT_APP_MUI_LICENSE_KEY?: string;
    REACT_APP_OPERATIONS_SYNC_TIMEOUT_IN_MS?: string;
    REACT_APP_QUALITIZE_ONLINE_SERVICE_URL?: string;
    REACT_APP_REDIRECT_PATH_PARAMETER_NAME?: string;
    REACT_APP_DEEP_LINK_TOKEN_PARAMETER_NAME?: string;
    REACT_APP_LOGIN_TOKEN_PARAMETER_NAME?: string;
    REACT_APP_LANGUAGE_COOKIE_NAME?: string;
    REACT_APP_PASSWORD_RESET_TOKEN_PARAMETER_NAME?: string;
    REACT_APP_INVALIDATE_TOKEN_PARAMETER_NAME?: string;
    REACT_APP_MAXIMUM_NUMBER_OF_TEXT_SEARCH_HISTORY_ENTRIES?: string;
    REACT_APP_LANDING_PAGE_SERVICE_URL?: string;
    REACT_APP_MASTER_DATA_SERVICE_URL?: string;
    REACT_APP_LANDING_PAGE_CDN_URL?: string;
    REACT_APP_LANDING_PAGE_HOST_URL?: string;
}

export interface Dimension {
    width: number;
    height: number;
}

export interface IdParams {
    id: number;
}

export interface OpaqueIdProps {
    opaqueId: string;
}

export interface OpaqueIdsProps {
    opaqueIds: string[];
}



export interface MdsLocale {
    locale: string;
    name: CompiledMessage;
}

/***************************************************************
 * Global redux types
 **************************************************************/

export interface AppState {
    application: ApplicationState;
    connection: ConnectionState;
    dashboard: DashboardState;
    user: UserState;
    videos: VideoState;
    admin: AdminState;
    operations: OperationsState;
    administration: AdministrationState;
    reports: ReportState;
    landingPages: LandingPageState;
    login: LoginState;
}
