import {
    call,
    put,
    select,
    delay,
    takeEvery,
    takeLatest, take, cancelled,
} from 'redux-saga/effects'
import {Route} from '../../api/Api';
import {
    SearchResponse,
    Video,
    VideoComment,
    VideoWishVote,
    VideoCategoryResponse,
    VideoTagsResponse,
    DashboardResponse,
    VideoPlaylist,
} from '../reducer/videos/types';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    VideoInfoPayload,
    SubmitVideoCommentActionPayload,
    DeleteVideoCommentActionPayload,
    VideoReactionActionPayload,
    CreateVideoWishPayload,
    UpvoteWishPayload,
    SetEnabledVideoHighlightSlider,
    RecordView,
    SearchPayload,
    AddVideoPlaylistImagePayload,
    VideoPlaylistImageUploadResponse,
    setUploadVideoPlaylistTitleImageProgress,
    LoadVideoPlaylistsResponse,
    RecordPlaylistView,
    loadVideoCategoriesAction,
    loadVideoTagsAction,
    loadVideoMostViewedAction,
    loadVideoLatestAction,
    loadVideoMostExcitingAction,
    searchVideosAction,
    setVideoReactionAction,
    loadVideoCommentsAction,
    submitVideoCommentAction,
    deleteVideoCommentAction,
    loadVideoHighlightsAction,
    createVideoWishAction,
    upvoteWishAction,
    uploadVideoPlaylistTitleImageAction,
    updateVideoPlaylistAction,
    loadVideoPlaylistsAction,
    loadVideoPlaylistHighlightsAction,
    loadVideoActionAndSaga,
    highlightVideoAction,
    highlightVideoPlaylistAction,
    loadVideoWishesAction,
    enableVideoPlaylistAction,
    deleteVideoPlaylistAction, VideoPlaylistActionPayload, HighlightVideoPayload
} from '../reducer/videos/actions';
import {AppState, SortOrder} from '../../types/Types';
import {replaceLink} from '../../helper/Helper';
import {MaxNumberOfVideosInVideoDashboard, VideoHighlightSliderDurationInS} from '../../constants/Constants';
import {LoginUser} from '../reducer/user/types';
import {StatusCodes} from 'http-status-codes';
import {EventChannel} from 'redux-saga';
import {
    deleteRequest,
    putRequest,
    getRequest,
    postRequest,
    logger,
    AuthenticationError,
    spawnSagas, Request, RequestResponse, UploadEventChannel, createUploadFileChannel
} from '@software/reactcommons';
import {CancelAllSagasViaForcedTabLogoutActionKey, jwtSelector, logoutAction} from '@software/reactcommons-security';
import {setActiveVideoHighlightSliderItem} from '../reducer/videos/videos';
import {DateTime} from 'luxon';

/******************************************************************************************
 * Selectors from app state
 ******************************************************************************************/

export const userSelector = (state: AppState) => state.user.info;

export const videoDashboardFetchStatusSelector = (state: AppState) => state.videos.videos.dashboardFetchStatus;

export const searchSelector = (state: AppState) => ({
    search: state.videos.search.text,
    jwt: state.user.info.jwt,
    tags: state.videos.search.selectedTags,
    category: state.videos.selectedCategory
});

export const tagSelector = (state: AppState) => ({
    jwt: state.user.info.jwt,
    tagIDs: state.videos.search.selectedTags,
    category: state.videos.selectedCategory
});

export const userAndVideoSelector = (id: string) => (state: AppState): { user: LoginUser, video?: Video } => ({
    user: state.user.info,
    video: state.videos.videos.elements[id]
});

export const highlightSelector = (state: AppState) => state.videos.highlights;

/******************************************************************************************
 * Generators and sagas
 ******************************************************************************************/

export function* checkAPIError(e: Error) {
    if (e instanceof AuthenticationError) {
        yield put(logoutAction.action());
    }
}

export function* searchVideos(payload: SearchPayload): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt, tags, category} = yield select(searchSelector);
        if (jwt) {
            yield put(searchVideosAction.startAction(payload));
            request = postRequest(Route.Search, {
                body: {
                    fullTextQuery: payload.search?.trim() ? payload.search.trim() : undefined,
                    tagIDs: tags.length ? tags : undefined,
                    categoryIDs: category > 0 ? [category] : undefined,
                    limit: payload.limit,
                    offset: payload.offset,
                    sortedBy: 'AGE',
                    sortOrder: SortOrder.DESC
                },
                jwt
            });
            const {response}: RequestResponse<SearchResponse> = yield call(request.request);
            yield put(searchVideosAction.successAction({result: response, lazy: payload.lazy}));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(searchVideosAction.errorAction({
            ...payload,
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* searchVideosByTextSaga(): Generator<any, any, any> {
    // Use take latest to automatically cancel previous search video generator
    yield takeLatest('videos/updateSearchText', function* (action: PayloadAction<string>) {
        // Wait 500ms since the user could update the search text
        yield delay(500);
        yield call(searchVideos, {
            search: action.payload
        });
    });
}

export function* searchVideosDelayedSaga(action: PayloadAction<SearchPayload>) {
    if (action.payload.isTextSearch) {
        yield delay(500);
    }
    yield call(searchVideos, action.payload);
}

export function* searchVideosSaga(): Generator<any, any, any> {
    yield takeLatest(searchVideosAction.actionKey, searchVideosDelayedSaga);
}

export function* setVideoReactionSaga() {
    yield takeEvery(setVideoReactionAction.actionKey, setVideoReaction);
}

export function* setVideoReaction(action: PayloadAction<VideoReactionActionPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {
            user,
            video
        }: { user: LoginUser, video: Video } = yield select(userAndVideoSelector(action.payload.videoID));
        if (user.jwt && video) {
            yield put(setVideoReactionAction.startAction(action.payload));
            const body = {
                reactionTypeID: action.payload.reactionTypeID,
                actionTimestamp: DateTime.now().toUTC().valueOf()
            }
            if (action.payload.active) {
                request = putRequest(Route.SetReaction(action.payload.videoID), {body, jwt: user.jwt});
            } else {
                request = deleteRequest(Route.SetReaction(action.payload.videoID), {body, jwt: user.jwt});
            }
            yield call(request.request);
            yield put(setVideoReactionAction.successAction({
                ...action.payload,
                user: {
                    id: user.videoUserID || -1,
                    firstName: user.firstName || '',
                    lastName: user.lastName || ''
                }
            }));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(setVideoReactionAction.errorAction({
            ...action.payload,
            message: e.message
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadVideoCommentsSaga() {
    yield takeEvery(loadVideoCommentsAction.actionKey, loadVideoComments);
}

export function* loadVideoComments(action: PayloadAction<VideoInfoPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {
            user,
            video
        }: { user: LoginUser, video: Video } = yield select(userAndVideoSelector(action.payload.videoID));
        if (user.jwt && video) {
            yield put(loadVideoCommentsAction.startAction(action.payload));
            request = getRequest(Route.GetComments(action.payload.videoID), {jwt: user.jwt});
            const {response}: RequestResponse<VideoComment[]> = yield call(request.request);
            yield put(loadVideoCommentsAction.successAction({
                id: action.payload.videoID,
                comments: response
            }));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoCommentsAction.errorAction({
            ...action.payload,
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* postVideoCommentSaga() {
    yield takeEvery(submitVideoCommentAction.actionKey, postVideoComment);
}

export function* postVideoComment(action: PayloadAction<SubmitVideoCommentActionPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {user, video}: { user: LoginUser, video: Video } = yield select(userAndVideoSelector(action.payload.id));
        if (user.jwt && video) {
            yield put(submitVideoCommentAction.startAction(action.payload));
            request = postRequest(Route.PostComment(video.videoID), {
                body: {
                    parentID: action.payload.parentID,
                    comment: action.payload.text
                },
                jwt: user.jwt
            })
            const {response}: RequestResponse<VideoComment> = yield call(request.request);
            yield put(submitVideoCommentAction.successAction({
                id: action.payload.id,
                comment: response
            }));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(submitVideoCommentAction.errorAction({
            ...action.payload,
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}


export function* deleteVideoCommentSaga() {
    yield takeEvery(deleteVideoCommentAction.actionKey, deleteVideoComment);
}

export function* deleteVideoComment(action: PayloadAction<DeleteVideoCommentActionPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {user, video}: { user: LoginUser, video: Video } = yield select(userAndVideoSelector(action.payload.id));
        if (user.jwt && video) {
            yield put(deleteVideoCommentAction.startAction(action.payload));
            request = deleteRequest(Route.DeleteComment(video.videoID, action.payload.commentID), {jwt: user.jwt});
            yield call(request.request);
            yield put(deleteVideoCommentAction.successAction({
                id: action.payload.id,
                commentID: action.payload.commentID,
                deletedBy: {firstName: user.firstName || '', id: user.videoUserID || -1, lastName: user.lastName || ''},
                deletedTimestamp: DateTime.now().toUTC().valueOf()
            }));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(deleteVideoCommentAction.errorAction({...action.payload, message: e.toString()}));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadHighlightsSaga() {
    yield takeLatest(loadVideoHighlightsAction.actionKey, loadHighlights)
}

export function* loadHighlights(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt} = yield select(userSelector);
        if (jwt.trim()) {
            yield put(loadVideoHighlightsAction.startAction({}));
            request = getRequest(Route.LoadHighlights, {jwt});
            yield put(loadVideoHighlightsAction.successAction((yield call(request.request)).response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoHighlightsAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}


export function* loadWishesSaga() {
    yield takeLatest(loadVideoWishesAction.actionKey, loadWishes);
}

export function* loadWishes(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt} = yield select(userSelector);
        if (jwt.trim()) {
            request = getRequest(Route.Wishes, {jwt});
            yield put(loadVideoWishesAction.startAction({}));
            yield put(loadVideoWishesAction.successAction((yield call(request.request)).response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoWishesAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* createVideoWishSaga() {
    yield takeLatest(createVideoWishAction.actionKey, createVideoWish)
}

export function* createVideoWish(action: PayloadAction<CreateVideoWishPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt} = yield select(userSelector);
        if (jwt.trim()) {
            yield put(createVideoWishAction.startAction(action.payload));
            request = postRequest(Route.Wishes, {
                body: {
                    title: action.payload.title,
                    description: replaceLink(action.payload.description)
                },
                jwt
            })
            const {response} = yield call(request.request);
            yield put(createVideoWishAction.successAction(response))
        }
    } catch (e: any) {
        logger.error(e);
        yield put(createVideoWishAction.errorAction({
            ...action.payload,
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* upvoteWishSaga() {
    yield takeLatest(upvoteWishAction.actionKey, upvoteWish);
}

export function* upvoteWish(action: PayloadAction<UpvoteWishPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt = yield select(jwtSelector);
        if (jwt.trim()) {
            yield put(upvoteWishAction.startAction(action.payload));
            let response: VideoWishVote;
            if (action.payload.vote) {
                request = postRequest(Route.UpvoteWish(action.payload.id), {jwt});
                response = (yield call(request.request)).response;
            } else {
                // If the vote is delete, then userVote in payload is not undefined
                response = (action.payload.userVote as VideoWishVote);
                request = deleteRequest(Route.UpvoteWish(action.payload.id), {jwt});
                yield call(request.request);
            }

            yield put(upvoteWishAction.successAction({
                id: action.payload.id,
                voted: action.payload.vote,
                vote: response
            }));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(upvoteWishAction.errorAction({
            ...action.payload,
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* videoHighlightSliderSaga() {
    yield takeLatest(SetEnabledVideoHighlightSlider, videoHighlightSlider)
}


export function* videoHighlightSlider(action: PayloadAction<boolean>): Generator<any, any, any> {
    if (action.payload) {
        yield delay(VideoHighlightSliderDurationInS * 1000);
        const {activeItem, videos} = yield select(highlightSelector);
        yield put(setActiveVideoHighlightSliderItem((activeItem + 1) % videos.length));
        yield call(videoHighlightSlider, action);
    }
}

export function* recordViewSaga() {
    yield takeEvery(RecordView, recordView);
}

export function* recordView(action: PayloadAction<string>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        if (jwt.trim()) {
            request = postRequest(Route.RecordView(action.payload), {jwt});
            yield call(request.request);
        }
    } catch (e: any) {
        logger.error(e);
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadCategories(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt}: { jwt: string} = yield select(userSelector);
        if (jwt.trim()) {
            yield put(loadVideoCategoriesAction.startAction({}));
            request = getRequest(Route.LoadVideoCategories, {jwt});
            const {response}: RequestResponse<VideoCategoryResponse> = yield call(request.request);
            yield put(loadVideoCategoriesAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoCategoriesAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadCategoriesSaga() {
    yield takeEvery(loadVideoCategoriesAction.actionKey, loadCategories);
}

export function* loadTags(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt}: { jwt: string } = yield select(userSelector);
        if (jwt.trim()) {
            yield put(loadVideoTagsAction.startAction({}));
            request = getRequest(Route.LoadVideoTags, {jwt});
            const {response}: RequestResponse<VideoTagsResponse> = yield call(request.request);
            yield put(loadVideoTagsAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoTagsAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadTagsSaga() {
    yield takeEvery(loadVideoTagsAction.actionKey, loadTags);
}

export function* loadMostViewed(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt}: { jwt: string } = yield select(userSelector);
        if (jwt.trim()) {
            yield put(loadVideoMostViewedAction.startAction({}));
            request = postRequest(Route.LoadVideoMostViewed({
                limit: MaxNumberOfVideosInVideoDashboard,
                offset: 0
            }), {jwt});
            const {response}: RequestResponse<DashboardResponse> = yield call(request.request);
            yield put(loadVideoMostViewedAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoMostViewedAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadMostViewedSaga() {
    yield takeEvery(loadVideoMostViewedAction.actionKey, loadMostViewed);
}

export function* loadLatest(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt}: { jwt: string } = yield select(userSelector);
        if (jwt.trim()) {
            yield put(loadVideoLatestAction.startAction({}));
            request = postRequest(Route.LoadVideoLatest({
                limit: MaxNumberOfVideosInVideoDashboard,
                offset: 0
            }), {jwt});
            const {response}: RequestResponse<DashboardResponse> = yield call(request.request);
            yield put(loadVideoLatestAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoLatestAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadLatestSaga() {
    yield takeEvery(loadVideoLatestAction.actionKey, loadLatest);
}

export function* loadMostExciting(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt} = yield select(userSelector);
        if (jwt.trim()) {
            yield put(loadVideoMostExcitingAction.startAction({}));
            request = postRequest(Route.LoadVideoMostExciting({
                    limit: MaxNumberOfVideosInVideoDashboard,
                    offset: 0
                }),
                {jwt});
            const {response}: RequestResponse<DashboardResponse> = yield call(request.request);
            yield put(loadVideoMostExcitingAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoMostExcitingAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadMostExcitingSaga() {
    yield takeEvery(loadVideoMostExcitingAction.actionKey, loadMostExciting);
}

export function* uploadPlaylistImage(action: PayloadAction<AddVideoPlaylistImagePayload>): Generator<any, any, any> {
    try {
        const {jwt} = yield select(userSelector);
        if (jwt && action.payload) {
            yield put(uploadVideoPlaylistTitleImageAction.startAction(action.payload));
            let file = action.payload.file;
            if (file) {
                // Only upload file if type is valid
                const channel: EventChannel<UploadEventChannel<VideoPlaylistImageUploadResponse>> = yield call(createUploadFileChannel, Route.UploadPlaylistImage, file, jwt);
                while (true) {
                    const {
                        progress = 0,
                        err,
                        success,
                        response
                    } = yield take<UploadEventChannel<VideoPlaylistImageUploadResponse>>(channel);
                    if (err) {
                        yield put(uploadVideoPlaylistTitleImageAction.errorAction({
                            ...action.payload,
                            message: err.toString()
                        }));
                    }
                    if (success) {
                        if (response) {
                            yield put(uploadVideoPlaylistTitleImageAction.successAction({
                                response,
                                playlistId: action.payload.playlistId
                            }));
                        }
                        break;
                    }
                    yield put(setUploadVideoPlaylistTitleImageProgress({
                        progress,
                        playlistId: action.payload.playlistId
                    }));
                }
            }
        }
    } catch (e: any) {
        logger.error(e);
        yield put(uploadVideoPlaylistTitleImageAction.errorAction({
            ...action.payload,
            message: e.toString()
        }));
        if (Number(e.message) === StatusCodes.UNAUTHORIZED) {
            yield put(logoutAction.action());
        }
    }
}

export function* uploadPlaylistImageSaga() {
    yield takeLatest(uploadVideoPlaylistTitleImageAction.actionKey, uploadPlaylistImage);
}

export enum VideoPlaylistVisibility {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
    UNLISTED = 'UNLISTED'
}

export interface PlaylistUpdateBody {
    playlistId?: string;
    previewImageFileName?: string;
    title: string;
    subTitle: string;
    description: string;
    enabled: boolean;
    visibility?: VideoPlaylistVisibility;
    newTags: string[];
    tagIds: number[];
    sections: VideoPlaylistUpdateSection[];
}

export interface VideoPlaylistUpdateSectionVideo {
    id?: number;
    videoId?: string;
    position: number;
}

export interface VideoPlaylistUpdateSection {
    id?: number;
    title?: string;
    position: number;
    sectionVideos: VideoPlaylistUpdateSectionVideo[];
}

export function* updateVideoPlaylist(action: PayloadAction<{ playlistId: string }>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        let {
            jwt,
            playlist
        }: { jwt: string, playlist: VideoPlaylist | undefined } = yield select((state: AppState) => ({
            ...state.user.info,
            playlist: state.videos.playlists.elements[action.payload.playlistId]
        }))
        if (playlist && jwt.trim()) {
            yield put(updateVideoPlaylistAction.startAction(action.payload));
            // Convert playlist into body for request
            const body: PlaylistUpdateBody = {
                playlistId: action.payload.playlistId !== '-1' ? action.payload.playlistId : undefined,
                // If file name is not present in preview image, try to get it from path
                previewImageFileName: playlist.previewImages[0]?.fileName || playlist.previewImages[0]?.path?.split(/(\\|\/)/g).pop(),
                title: playlist.title,
                subTitle: playlist.subTitle,
                description: playlist.description,
                enabled: true,
                visibility: VideoPlaylistVisibility.PUBLIC,
                newTags: playlist.tags.filter(it => it.id === -1).map(it => it.name),
                tagIds: playlist.tags.filter(it => it.id > -1).map(it => it.id),
                sections: playlist.sections.map((it) => ({
                    id: it.id > -1 ? it.id : undefined,
                    title: it.title?.trim(),
                    position: it.position,
                    sectionVideos: it.videos.map(video => ({
                        id: video.id,
                        videoId: video.videoId,
                        position: video.position
                    }))
                }))
            }
            request = (playlist.id === -1 ? postRequest : putRequest)(Route.UpdateVideoPlaylist, {body, jwt});
            // Create a new playlist if id is minus 1
            const {response}: RequestResponse<VideoPlaylist> = yield call(request.request);
            yield put(updateVideoPlaylistAction.successAction({response, playlistId: action.payload.playlistId}));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(updateVideoPlaylistAction.errorAction({
            ...action.payload,
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* updateVideoPlaylistSaga() {
    yield takeLatest(updateVideoPlaylistAction.actionKey, updateVideoPlaylist);
}

export function* loadVideoPlaylists(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt} = yield select(userSelector);
        if (jwt.length) {
            yield put(loadVideoPlaylistsAction.startAction({}));
            // TODO No parameter passed to route?
            request = getRequest(Route.GetVideoPlaylists(), {jwt});
            const {response}: RequestResponse<LoadVideoPlaylistsResponse> = yield call(request.request);
            yield put(loadVideoPlaylistsAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoPlaylistsAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadVideoPlaylistsSaga() {
    yield takeLatest(loadVideoPlaylistsAction.actionKey, loadVideoPlaylists);
}

export function* loadPlaylistHighlights(): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt} = yield select(userSelector);
        if (jwt.length) {
            yield put(loadVideoPlaylistHighlightsAction.startAction({}));
            request = getRequest(Route.GetVideoPlaylistHighlights, {jwt});
            const {response}: RequestResponse<VideoPlaylist[]> = yield call(request.request);
            yield put(loadVideoPlaylistHighlightsAction.successAction(response));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(loadVideoPlaylistHighlightsAction.errorAction({
            message: e.toString()
        }));
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}


export function* loadPlaylistHighlightsSaga() {
    yield takeEvery(loadVideoPlaylistHighlightsAction.actionKey, loadPlaylistHighlights);
}

export function* recordPlaylistView(action: PayloadAction<string>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt = yield select(jwtSelector);
        if (jwt.length) {
            request = postRequest(Route.RecordPlaylistView, {
                body: {playlistId: action.payload},
                jwt
            })
            yield call(request.request);
        }
    } catch (e: any) {
        logger.error(e);
        yield call(checkAPIError, e);
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* highlightVideoSaga() {
    yield takeLatest(highlightVideoAction.actionKey, function* ({payload}: PayloadAction<HighlightVideoPayload>) {
        const jwt: string = yield select(jwtSelector);
        let request: Request<any> | undefined = undefined;
        try {
            yield put(highlightVideoAction.startAction(payload));
            request = (payload.highlight ? postRequest : deleteRequest)(Route.HighlightVideo(payload.videoID), {
                jwt
            });
            yield call(request.request);
            yield put(highlightVideoAction.successAction(payload));
        } catch (e: any) {
            logger.error(e);
            yield put(highlightVideoAction.errorAction({
                ...payload,
                message: e.message
            }));
        } finally {
            if (yield cancelled()) {
                request?.abort();
            }
        }
    });
}

export function* recordPlaylistViewSaga() {
    yield takeEvery(RecordPlaylistView, recordPlaylistView);
}


export function* enableVideoPlayListSaga() {
    yield takeLatest(enableVideoPlaylistAction.actionKey, function* ({payload}: PayloadAction<VideoPlaylistActionPayload>) {
        const jwt: string = yield select(jwtSelector);
        let request: Request<any> | undefined = undefined;
        try {
            yield put(enableVideoPlaylistAction.startAction(payload));
            request = postRequest(Route.EnableVideoPlaylist(payload.playlistId, payload.value), {
                jwt
            });
            yield call(request.request);
            yield put(enableVideoPlaylistAction.successAction(payload));
        } catch (e: any) {
            logger.error(e);
            yield put(enableVideoPlaylistAction.errorAction({
                ...payload,
                message: e.message
            }));
        } finally {
            if (yield cancelled()) {
                request?.abort();
            }
        }
    });
}

export function* highlightVideoPlayListSaga() {
    yield takeLatest(highlightVideoPlaylistAction.actionKey, function* ({payload}: PayloadAction<VideoPlaylistActionPayload>) {
        const jwt: string = yield select(jwtSelector);
        let request: Request<any> | undefined = undefined;
        try {
            yield put(highlightVideoPlaylistAction.startAction(payload));
            request = (payload.value ? postRequest : deleteRequest)(Route.HighlightVideoPlaylist(payload.playlistId), {
                jwt
            });
            yield call(request.request);
            yield put(highlightVideoPlaylistAction.successAction(payload));
        } catch (e: any) {
            logger.error(e);
            yield put(highlightVideoPlaylistAction.errorAction({
                ...payload,
                message: e.message
            }));
        } finally {
            if (yield cancelled()) {
                request?.abort();
            }
        }
    });
}


export function* deleteVideoPlayListSaga() {
    yield takeLatest(deleteVideoPlaylistAction.actionKey, function* ({payload}: PayloadAction<{ playlistId: string }>) {
        const jwt: string = yield select(jwtSelector);
        let request: Request<any> | undefined = undefined;
        try {
            yield put(deleteVideoPlaylistAction.startAction(payload));
            request = deleteRequest(payload.playlistId, {jwt});
            yield call(request.request);
            yield put(deleteVideoPlaylistAction.successAction(payload));
        } catch (e: any) {
            logger.error(e);
            yield put(deleteVideoPlaylistAction.errorAction({
                ...payload,
                message: e.message
            }));
        } finally {
            if (yield cancelled()) {
                request?.abort();
            }
        }
    });
}

function* VideoSaga(): Generator<any, any, any> {
    yield call(spawnSagas([
        {generator: loadVideoActionAndSaga.saga},
        {generator: searchVideosSaga},
        {generator: setVideoReactionSaga},
        {generator: loadVideoCommentsSaga},
        {generator: postVideoCommentSaga},
        {generator: deleteVideoCommentSaga},
        {generator: loadHighlightsSaga},
        {generator: highlightVideoSaga},
        {generator: loadWishesSaga},
        {generator: createVideoWishSaga},
        {generator: upvoteWishSaga},
        {generator: videoHighlightSliderSaga},
        {generator: recordViewSaga},
        {generator: loadCategoriesSaga},
        {generator: loadTagsSaga},
        {generator: loadMostViewedSaga},
        {generator: loadLatestSaga},
        {generator: loadMostExcitingSaga},
        {generator: searchVideosByTextSaga},
        {generator: uploadPlaylistImageSaga},
        {generator: updateVideoPlaylistSaga},
        {generator: loadVideoPlaylistsSaga},
        {generator: highlightVideoPlayListSaga},
        {generator: enableVideoPlayListSaga},
        {generator: deleteVideoPlayListSaga},
        {generator: loadPlaylistHighlightsSaga},
        {generator: recordPlaylistViewSaga},
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}

export default VideoSaga;
