import {call, CallEffect, cancel, delay, fork, put, select, takeEvery} from 'redux-saga/effects';
import {getRequest, HttpError, logger, setShowAppLoader, SidebarSaga, spawnSagas} from '@software/reactcommons';
import {CancelAllSagasViaForcedTabLogoutActionKey, jwtSelector, logoutAction} from '@software/reactcommons-security';
import {Task} from 'redux-saga';
import {setOnlineState} from '../reducer/connection/connection';
import {OfflineReason, OnlineState} from '../reducer/connection/types';
import {StatusCodes} from 'http-status-codes';
import {AppState} from '../../types/Types';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    DeepLink,
    loadDeepLinkActionAndSaga,
    LoadDeepLinkPayload, LoadLocaleNameActionAndSaga,
    LoadLocalesActionAndSaga
} from '../reducer/application/actions';
import {loadAvailableLoginMethodsActionAndSaga, requestLoginTokenActionAndSaga} from '../reducer/user/actions';
import {ClientLoginMethodType} from '../reducer/user/types';
import {setLoginMethods} from '../reducer/user/user';
import {setUsername} from '../reducer/login/login';

export function* checkOnlineStatus(url: string): Generator<any, any, any> {
    try {
        while (true) {
            const jwt: string = yield select(jwtSelector);
            const currentState = yield select((state: AppState) => state.connection.online[url]?.state);
            let onlineState = currentState;
            let offlineReason;
            if (jwt) {
                try {
                    const request = getRequest(url, {jwt, withRetry: false});
                    yield call(request.request);
                    // If request does not throw any error, the app is online
                    onlineState = OnlineState.Online;
                } catch (e) {
                    // Only tell store about the state if the
                    if (currentState !== OnlineState.Offline) {
                        logger.error('Lost connection to backend!', e);
                        offlineReason = OfflineReason.NetworkError;
                        if (e instanceof HttpError && e.statusCode && [StatusCodes.BAD_GATEWAY, StatusCodes.NOT_FOUND].includes(e.statusCode)) {
                            offlineReason = OfflineReason.BackendNotReachable;
                        }
                    }
                    onlineState = OnlineState.Offline;
                }
                if (currentState !== onlineState) {
                    yield put(setOnlineState({url, state: onlineState, offlineReason}));
                }
            }
            // Check more often if the app is currently offline
            yield delay(onlineState === OnlineState.Offline ? 1000 : 10000);
        }
    } catch (e) {
        logger.error('Error during online status check!', e)
    }
}

export const checkOnlineStatusTask: Record<string, Task<any>> = {};

export function* enableCheckOnlineStatus(action: PayloadAction<string>) {
    if (checkOnlineStatusTask[action.payload]) {
        yield cancel(checkOnlineStatusTask[action.payload]!);
    }
    checkOnlineStatusTask[action.payload] = yield fork(checkOnlineStatus, action.payload);
}

export function* enableCheckOnlineStatusSaga() {
    yield takeEvery('application/enableOnlineCheck', enableCheckOnlineStatus);
}

export function* fetchDeepLinkTokenSuccessSaga(payload: LoadDeepLinkPayload, deepLink: DeepLink): Generator<any, any, any> {
    if (payload.triggerAutoActions && deepLink.username) {
        const {deepLinkToken, redirect} = yield select((state: AppState) => state.application);
        // Store the username in the login reducer to store it in web database
        yield put(setUsername(deepLink.username));

        // Automatic actions are applied, ensure the app loader is visible
        yield put(setShowAppLoader(true));
        if (!deepLink.autoTriggerLoginTokenMail) {
            // TODO Online trigger if not logged in => only edit this if backend endpoint is available to check whether
            // password login is available
            yield put(loadAvailableLoginMethodsActionAndSaga.action({
                username: deepLink.username,
                deepLinkToken,
                redirectPath: redirect
            }));
        } else {
            // No need to fetch available login methods since deep link enforces to trigger the login token action
            // TODO Also fetch login methods because it is not assured that user has the login token login method
            yield put(setLoginMethods([{
                    type: ClientLoginMethodType.LoginToken,
                    priority: true
                }]
            ));
            // Only trigger login token email if user is not already logged in
            if (!(yield select(jwtSelector))) {
                yield put(requestLoginTokenActionAndSaga.action({
                    username: deepLink.username,
                    deepLinkToken: payload.token,
                    staySignedIn: false
                }));
            }
            yield call(hideAppLoader);
        }
    } else {
        yield call(hideAppLoader);
    }
}

export function* hideAppLoader() {
    yield put(setShowAppLoader(false));
}

export default function* ApplicationSaga(): Generator<CallEffect<void>> {
    yield call(spawnSagas([
        {generator: SidebarSaga},
        {generator: enableCheckOnlineStatusSaga},
        {generator: loadDeepLinkActionAndSaga.saga},
        {generator: LoadLocalesActionAndSaga.saga},
        {generator: LoadLocaleNameActionAndSaga.saga},
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}