import {call, CallEffect, select, takeEvery} from 'redux-saga/effects';
import {
    loadIncidentProblemsActionAndSaga,
    loadOpeningHoursForLocationActionAndSaga,
    loadTerminalsActionAndSaga,
    saveOpeningHoursForLocationsActionAndSaga,
    UploadIncidentFilesPayload,
    uploadIncidentFilesAction,
    deleteIncidentUploadFileActionAndSaga,
    createIncidentActionAndSaga,
    loadQrCodesActionAndSaga,
    UploadIncidentFilesSuccessPayload,
    SetIncidentFileUploadProgressPayload,
    loadAllStickiesActionAndSaga,
    editStickyActionAndSaga,
    deleteStickyActionAndSaga,
    createNewStickyActionAndSaga,
    loadAccountsActionAndSaga,
    deactivateAccountsActionAndSaga,
    disableAccountsActionAndSaga,
    enableAccountsActionAndSaga,
    loadAccountProfilesActionAndSaga,
    exportAccountsActionAndSaga, loadAccountLoginAuditLogsActionAndSaga
} from '../reducer/administration/actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {Route} from '../../api/Api';
import {CancelAllSagasViaForcedTabLogoutActionKey, jwtSelector, logoutAction} from '@software/reactcommons-security';
import {
    spawnSagas,
    uploadFileSaga,
    UploadFileSagaParameter
} from '@software/reactcommons';
import {setIncidentFileUploadProgress} from '../reducer/administration/administration';

export function* uploadIncidentFile(action: PayloadAction<UploadIncidentFilesPayload>) {
    const jwt: string = yield select(jwtSelector);
    const parameters: UploadFileSagaParameter<UploadIncidentFilesPayload, UploadIncidentFilesSuccessPayload, UploadIncidentFilesSuccessPayload, SetIncidentFileUploadProgressPayload> = {
        route: Route.Administration.UploadIncidentFile(action.payload.terminalId),
        jwt,
        fileUploadSuccessActionCreator: ({response, file}) => {
            return uploadIncidentFilesAction.successAction({...response, id: file.id})
        },
        errorActionCreator: params => {
            return uploadIncidentFilesAction.errorAction(params);
        },
        progressActionCreator: ({progress, file}) => {
            return setIncidentFileUploadProgress({
                progress: progress,
                id: file.id
            });
        },
        action
    }
    yield call(uploadFileSaga<UploadIncidentFilesPayload, UploadIncidentFilesSuccessPayload, UploadIncidentFilesSuccessPayload, SetIncidentFileUploadProgressPayload>, parameters);
}


export function* uploadIncidentFilesSaga() {
    yield takeEvery(uploadIncidentFilesAction.actionKey, uploadIncidentFile);
}

export default function* AdministrationSaga(): Generator<CallEffect<void>> {
    yield call(spawnSagas([
        {generator: loadOpeningHoursForLocationActionAndSaga.saga},
        {generator: saveOpeningHoursForLocationsActionAndSaga.saga},
        {generator: loadTerminalsActionAndSaga.saga},
        {generator: loadQrCodesActionAndSaga.saga},
        {generator: loadIncidentProblemsActionAndSaga.saga},
        {generator: deleteIncidentUploadFileActionAndSaga.saga},
        {generator: createIncidentActionAndSaga.saga},
        {generator: loadAllStickiesActionAndSaga.saga},
        {generator: createNewStickyActionAndSaga.saga},
        {generator: editStickyActionAndSaga.saga},
        {generator: deleteStickyActionAndSaga.saga},
        {generator: loadAccountsActionAndSaga.saga},
        {generator: deactivateAccountsActionAndSaga.saga},
        {generator: disableAccountsActionAndSaga.saga},
        {generator: enableAccountsActionAndSaga.saga},
        {generator: loadAccountProfilesActionAndSaga.saga},
        {generator: loadAccountLoginAuditLogsActionAndSaga.saga},
        {generator: exportAccountsActionAndSaga.saga},
        {generator: uploadIncidentFilesSaga},
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}