import {ChartTextElement} from '../../redux/reducer/dashboard/types';
import {Callback, ChartConfiguration, Point, TextObject, WordCloudConfiguration} from '@software/chartlibrary';
import {GridColDef, GridRowProps} from '@mui/x-data-grid-premium';
import {RankingType} from '../../components/sites/feedback/dashboard/elements/types';


export enum BarChartType {
    Horizontal,
    Stacked
}

export enum GaugeType {
    CUSTOMER_INDEX,
    NPS
}

export enum StatType {
    NUMBER,
    ANSWER_PERCENTAGE,
    ANSWER_AVERAGE,
    ANSWER_NPS
}


export enum MediaType {
    IMAGE,
    IMAGE_360,
    VIDEO
}

export enum StatSubChartType {
    NUMBER,
    ANSWER_PERCENTAGE,
    ANSWER_AVERAGE,
    ANSWER_NPS
}

/********************************************************************************************************************
 * Images
 ********************************************************************************************************************/

export enum PhotoType {
    Original = 'Original',
    HD = 'HD',
    Export = 'Export',
    Preview = 'Preview',
    Thumbnail = 'Thumbail'
}

export interface ImagePath {
    type: PhotoType;
    path: string;
    width: number;
    height: number;
}

export enum AttachmentType {
    Image = 'image',
    File = 'file',
    Pdf = 'pdf',
    Video = 'video'
}

export interface Attachment {
    type: AttachmentType;
    id: number;
    source: string;
    creator: string;
    name?: string;
}

export interface AttachmentVideo extends Attachment {
    type: AttachmentType.Video;
}

export interface AttachmentPdf extends Attachment {
    type: AttachmentType.Pdf;
}

export interface AttachmentImage extends Attachment {
    type: AttachmentType.Image;
    paths: ImagePath[];
    ratio: number;
    is360?: boolean;
    width?: number;
    height?: number;
}

export interface QuestionAnswerAttachment {
    attachment: Attachment;
    question?: string;
    answer?: string;
    texts?: Object[];
    location?: string;
    timestamp: number;
}


export interface QuestionAttachment {
    attachments: Attachment[];
    timestamp: number;
    question: string;
    answer: string;
    creator: string;
    location: string;
}

export interface ChartExplanation {
    description: string;
}

/********************************************************************************************************
 * Table chart
 ********************************************************************************************************/

export enum TableColumnType {
    TEXT = 'TEXT',
    COMBINED = 'COMBINED'
}

export interface EnhancedTableColumn extends TableColumn {
    key: string;
    dataKey: string;
    title?: string;
    width: number;
}

export enum ColumnContentType {
    TEXT = 'TEXT',
    ICON = 'ICON',
    FILES = 'FILES'
}

export interface TableColumn {
    text: TextObject[];
    center?: boolean;
    description?: string;
    type: TableColumnType;
    backgroundColor?: string;
    color?: string;
    outDated: boolean;
    dataDate: number;
    groupColumn: boolean;
    rowSpan: number;
    ignore: boolean;
    value: number;
    threshold?: number;
    explanations?: string[];
    contents: TableColumnContent[];
    frozen?: boolean;
    width?: number;
    headerColumn?: boolean;
}


export enum TableIcon {
    CHECK,
    CROSS,
    CIRCLE,
    EMPTY_SQUARE
}

export interface TableColumnContent {
    type: ColumnContentType;
    text?: TextObject[];
    files?: Attachment[];
    icon: TableIcon;
    creator: string;
    inline: boolean;
}

export interface Threshold {
    min?: number;
    max?: number;
    color?: string;
    background?: string;
}

export enum TableType {
    THRESHOLD_BASED = 'THRESHOLD_BASED',
    ICON_BASED = 'ICON_BASED'
}

export interface UnflattenedTableRow extends TableRow {
    children?: UnflattenedTableRow[];
    index: number;
    lastSubTotalRow?: boolean;
    lastRow?: boolean;
    firstRow: boolean;
}

export enum TableRowType {
    Header = 'Header',
    Group = 'Group',
    Leaf = 'Leaf'
}

export interface TableRow {
    id: string;
    parentID?: string;
    type: TableRowType;
    title: string;
    columns: TableColumn[];
    totalRow?: boolean;
    headerRow?: boolean;
    path?: string[];
    randomizedId?: boolean;
}

export interface Table {
    type: TableType;
    header: TableRow[];
    rows: TableRow[];
    footer?: TableRow;
    expandColumnKey: string;
    filteredRows?: TableRow[];
}

export enum LineChartType {
    LINEAR,
    CURVED
}

export interface Line {
    points: Point[];
    stroke?: number;
    color?: string;
    style?: Object;
    callbacks?: Callback[];
    connectPoints?: boolean;
    name?: string;
    dataPoints: Point[];
    element: any
}

/******************************************************************************************************************
 * Chart configurations
 ******************************************************************************************************************/

export interface DashboardChartConfiguration extends ChartConfiguration {
    explanations?: ChartExplanation[];
}

export enum Sentiment {
    None = 'None',
    Positive = 'Positive',
    Negative = 'Negative',
    Ambivalent = 'Ambivalent'
}

export interface TextChartConfiguration extends DashboardChartConfiguration {
    elements?: ChartTextElement[];
    showTranslation?: boolean;
    numberOfTotalElements: number;
}

export interface TableChartConfiguration extends DashboardChartConfiguration {
    table: Table;
}

export interface FileChartConfiguration extends DashboardChartConfiguration {
    images: QuestionAttachment[];
}

export interface WordCloudChartConfiguration extends DashboardChartConfiguration, WordCloudConfiguration {
    useTranslations: boolean;
}

export enum StatComparisonType {
    ABSOLUTE = 'ABSOLUTE',
    RELATIVE = 'RELATIVE'
}

export interface StatElementChartConfiguration extends DashboardChartConfiguration {
    value: number;
    comparison?: number;
    comparisonType?: StatComparisonType;
    comparisonAppendix?: string;
    notApplicableCount?: number;
    color?: string;
    compareColor?: string;
}

export interface TopLevelGridRowProps extends GridRowProps {
    entries: Record<string, any>;
}

export type TopLevelGridColumn = GridColDef<any, any, any> & {
    valueAppendix?: string;
}

export interface TopLevelConfiguration extends ChartConfiguration {
    rows: TopLevelGridRowProps[];
    columns: TopLevelGridColumn[];
}

export enum DataSourceComparisonGroupingType {
    Location = 'Location',
    DataSource = 'DataSource',
    Region = 'Region'
}

export interface ComparisonValue {
    value: number;
    color: string;
    difference: number;
}

export interface ValueForRankingType {
    value?: number;
    rankingType: RankingType;
    color: string;
    comparisonValue?: ComparisonValue;
}

export interface DataSourceComparisonCompareElement {
    id: number;
    name: string;
    latitude?: number;
    longitude?: number;
    groupingType: DataSourceComparisonGroupingType;
    n: number;
    values: ValueForRankingType[];
}

export interface DataSourceComparisonChartConfiguration extends ChartConfiguration {
    compareElements: DataSourceComparisonCompareElement[];
}

export interface DataSourceComparisonMapChartConfiguration extends DataSourceComparisonChartConfiguration {
    mapIsoCodes?: string[];
}

export interface RawValueCount {
    value: number;
    count: number;
}

export interface QuestionComparisonCompareElement {
    questionId: number;

    question: string;

    questionShortName: string;

    n: number;

    values: ValueForRankingType[];

    rawValues: RawValueCount[];
}

export interface QuestionComparisonChartConfiguration extends ChartConfiguration {
    compareElements: QuestionComparisonCompareElement[];
}