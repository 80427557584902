import {call, CallEffect, put, select, take, takeEvery} from 'redux-saga/effects';
import {createUploadFileChannel, spawnSagas, UploadEventChannel} from '@software/reactcommons';
import {CancelAllSagasViaForcedTabLogoutActionKey, jwtSelector, logoutAction} from '@software/reactcommons-security';
import {
    CloneLandingPageActionAndSaga,
    CreateLandingPageActionAndSaga,
    DownloadLandingPageQRCodeActionAndSaga,
    LandingPageCdnFileDTO,
    LoadLandingPageActionAndSaga,
    LoadLandingPageCdnFileActionAndSaga,
    LoadLandingPageCdnFilesActionAndSaga,
    LoadLandingPagePreviewActionAndSaga,
    LoadLandingPagesActionAndSaga,
    UpdateLandingPageActionAndSaga,
    uploadLandingPageFilesAction,
    UploadLandingPageFilesPayload
} from '../reducer/landingPages/actions';
import {PayloadAction} from '@reduxjs/toolkit';
import {EventChannel} from 'redux-saga';
import {Route} from '../../api/Api';
import {setUploadLandingPageFilesProgress} from '../reducer/landingPages/landingPages';

export function* uploadLandingPageFiles({payload}: PayloadAction<UploadLandingPageFilesPayload>): Generator<any, any, any> {
    if (Boolean(payload.files.length)) {
        const jwt: string = yield select(jwtSelector);
        const numberOfFiles = payload.files.length;
        let totalProgress = 0;
        for (const file of payload.files) {
            const channel: EventChannel<UploadEventChannel<LandingPageCdnFileDTO>> = yield call(createUploadFileChannel, Route.LandingPage.PostFileUpload, file, jwt, true);
            yield put(uploadLandingPageFilesAction.startAction(payload));
            while (true) {
                const {
                    progress = 0,
                    err,
                    success,
                    response
                } = yield take<UploadEventChannel<LandingPageCdnFileDTO>>(channel);
                if (err) {
                    yield put(uploadLandingPageFilesAction.errorAction({
                        ...payload,
                        // Put file on which the error occurred into the payload
                        files: [file],
                        message: err.message
                    }));
                    break;
                }
                if (success) {
                    if (response) {
                        yield put(uploadLandingPageFilesAction.successAction(response));
                    }
                    break;
                }
                totalProgress += progress / numberOfFiles;
                yield put(setUploadLandingPageFilesProgress(totalProgress));
            }
        }
    }
}

export function* UploadLandingPageFileSaga() {
    yield takeEvery(uploadLandingPageFilesAction.actionKey, uploadLandingPageFiles);
}

function* LandingPageSaga(): Generator<CallEffect<void>> {
    yield call(spawnSagas([
        {generator: LoadLandingPagesActionAndSaga.saga},
        {generator: LoadLandingPageActionAndSaga.saga},
        {generator: CreateLandingPageActionAndSaga.saga},
        {generator: DownloadLandingPageQRCodeActionAndSaga.saga},
        {generator: UpdateLandingPageActionAndSaga.saga},
        {generator: LoadLandingPageCdnFilesActionAndSaga.saga},
        {generator: LoadLandingPageCdnFileActionAndSaga.saga},
        {generator: LoadLandingPagePreviewActionAndSaga.saga},
        {generator: CloneLandingPageActionAndSaga.saga},
        {generator: UploadLandingPageFileSaga}
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}

export default LandingPageSaga;