import {createSelector} from '@reduxjs/toolkit';
import {AppState} from '../../../types/Types';
import {denormalize} from '@software/reactcommons';
import {
    ClientPostAuthenticationInactivityLogoutSecurityRule,
    ClientPostAuthenticationSecurityType
} from '@software/reactcommons-security';
import {Duration} from 'luxon';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';

export const userLocationSelector = (state: AppState) => state.user.locations;
export const userLocationSortedArraySelector = (state: AppState) => state.user.locationArray;
export const userRegionSelector = (state: AppState) => state.user.regions;

export const userFeedbackLocationsSelector = createSelector(
    userLocationSelector,
    (locations) => denormalize(locations).filter(it => it.hasDataSources).sort((r1, r2) => r1.name.localeCompare(r2.name))
);

export const useUserFeedbackLocationsSortedByActiveState = () => {
    const locations = useSelector(userLocationSelector);

    return useMemo(() => {
        return denormalize(locations).filter(it => it.hasDataSources).sort((l1, l2) => l1.active && !l2.active ? -1 : (l2.active && !l1.active ? 1 : l1.name.localeCompare(l2.name)));
    }, [locations]);
}

export const userFeedbackRegionSelector = createSelector(
    userRegionSelector,
    (regions) => denormalize(regions).filter(it => it.hasDataSources).sort((r1, r2) => r1.name.localeCompare(r2.name))
);

export const userFeedbackLocationIdSelector = createSelector(
    userLocationSelector,
    (locations) => denormalize(locations).map(it => it.id)
);

export const userHeadquartersSelector = (state: AppState) => state.user.headquarters;

export const inactivityLogoutDurationInMsSelector = (state: AppState) => {
    const securityDelay: string | undefined = ((state.user.info.postAuthenticationRules?.find(it => it.type === ClientPostAuthenticationSecurityType.InactivityLogout)) as ClientPostAuthenticationInactivityLogoutSecurityRule | undefined)?.duration;
    if (securityDelay) {
        // Convert the delay from duration string to milliseconds
        const duration = Duration.fromISO(securityDelay);
        if (duration.isValid) {
            return duration.toMillis();
        }
    }
    return 0;
}