import {
    call,
    CallEffect,
    cancel,
    cancelled,
    delay,
    fork,
    put,
    select,
    take,
    takeEvery,
    takeLatest
} from 'redux-saga/effects';
import {
    CheckPeriodicToDoSyncResponse,
    createManualToDoActionAndSaga,
    deleteOperationsFileActionAndSaga,
    DeleteOperationsFilePayload,
    DownloadToDoActionPayload,
    downloadToDosAction,
    finishToDoActionAndSaga,
    loadAvailableToDosActionAndSaga,
    loadFinishedToDosAction,
    loadManualToDosActionAndSaga,
    loadOpenToDosAction,
    loadOperationsEditorsAction,
    loadToDoActionAndSaga,
    reloadToDoActionAndSaga,
    saveOperationsEditorsAction,
    SaveOperationsEditorsPayload,
    sendContinueEmailActionAndSaga,
    sendOperationsToDoEmailAction,
    SendOperationsToEmailPayload,
    setOperationsTaskAnswer,
    SetOperationsQuestionAnswerPayload,
    syncOperationsToDoAction,
    SyncOperationsToDoResponse,
    uploadOperationsFilesAction,
    UploadOperationsFilesPayload,
    loadReviewToDosAction,
    loadUserReviewToDosAction,
    loadArchiveToDoActionAndSaga,
    loadToDoReviewActionAndSaga,
    saveToDoReviewRemarkAction,
    finishToDoReviewActionAndSaga,
    SaveToDoReviewRemarkBody,
    loadSubordinatedToDosAction,
    changeToDoDeadlineActionAndSaga,
    saveOperationsToDoReviewerActionAndSaga,
    loadSubordinatedUserReviewToDosAction,
    changeReviewDeadlineActionAndSaga,
    reOpenToDoActionAndSaga,
    loadFinishedToDoReviewActionAndSaga,
    reOpenReviewActionAndSaga,
    loadOpenToDosForToDoDefinitionAndLocationActionAndSaga,
    loadPreviousToDosForToDoActionAndSaga,
    declineToDoReviewActionAndSaga,
    preFinishToDoActionAndSaga,
    finishAutoApproveToDoActionAndSaga,
    finishAutoDeclineToDoActionAndSaga,
    loadRequestsToDosAction,
    loadApprovedRequestsAction,
    loadDeclinedRequestsAction,
    loadUserReviewRequestsAction,
    loadSubordinatedUserReviewRequestsAction,
    loadReviewRequestsAction,
    loadExpiredRequestsAction
} from '../../reducer/operations/actions';
import {
    AuthenticationError,
    createUploadFileChannel, deleteRequest, FetchAction,
    FetchStatus,
    getRequest,
    logger,
    notUndefined,
    postRequest,
    Request,
    RequestResponse,
    spawnSagas, UploadEventChannel
} from '@software/reactcommons';
import {CheckOnlineStateRoutes, Route} from '../../../api/Api';
import {AppState} from '../../../types/Types';
import {PayloadAction} from '@reduxjs/toolkit';
import {
    AnswerAttachment,
    Editor,
    EditToDo,
    OperationsStatePart,
    PreFinishToDoResult,
    PreFinishToDoResultType,
    ReviewersPreFinishResult,
    ReviewToDoRemark,
    SyncToDoAnswerType,
    TaskAnswer,
    ToDoDTO,
    ToDoTaskType
} from '../../reducer/operations/types';
import {saveAs} from 'file-saver';
import {ApiListResponse} from '../../reducer/administration/actions';
import {CancelAllSagasViaForcedTabLogoutActionKey, jwtSelector, logoutAction} from '@software/reactcommons-security';
import {DateTime} from 'luxon';
import {EventChannel, Task} from 'redux-saga';
import {
    deleteOperationsFileFromAnswer,
    setOperationsFileUploadProgress,
    setToDoLastUpdatedTimestamp, setToDoReviewers
} from '../../reducer/operations/operations';
import {OperationsSyncTimeout} from '../../../constants/Constants';
import {OnlineState} from '../../reducer/connection/types';
import {toDoSearchSelector} from './helper';
import {manualRefreshToken} from '@software/reactcommons-security/dist/redux/saga/BaseUserSaga';

export function* loadToDos(statePart: OperationsStatePart, route: string, action: FetchAction<any, ApiListResponse<ToDoDTO>, 'UNKNOWN'>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt, page, pageSize, search, toDoIds, locationIds} = yield select(toDoSearchSelector(statePart));
        yield put(action.startAction({}));
        yield delay(500);
        request = postRequest(route, {
            body: {
                page, pageSize, search, toDoIds, locationIds
            },
            jwt
        });
        const {response}: RequestResponse<ApiListResponse<ToDoDTO>> = yield call(request.request);
        yield put(action.successAction(response));
    } catch (e: any) {
        logger.error(e);
        yield put(action.errorAction({
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* searchOperationsToDosSaga() {
    yield takeLatest([loadOpenToDosAction.actionKey, 'operations/setOperationsPendingToDosSearch', 'operations/setOperationsPendingToDosPage',
        'operations/setOperationsPendingToDosLocationIds', 'operations/setOperationsPendingToDosToDoIds'], loadToDos, 'overview', Route.Operations.LoadOperationsToDos, loadOpenToDosAction);
}

export function* searchFinishedOperationsToDosSaga() {
    yield takeLatest([loadFinishedToDosAction.actionKey, 'operations/setOperationsFinishedToDosSearch', 'operations/setOperationsFinishedToDosPageAndPageSize',
        'operations/setOperationsFinishedToDosLocationIds', 'operations/setOperationsFinishedToDosToDoIds'], loadToDos, 'finishedToDos', Route.Operations.LoadOperationsFinishedToDos, loadFinishedToDosAction);
}

export function* searchReviewOperationsToDosSaga() {
    yield takeLatest([loadReviewToDosAction.actionKey, 'operations/setOperationsReviewToDosSearch', 'operations/setOperationsReviewToDosPage',
        'operations/setOperationsReviewToDosLocationIds', 'operations/setOperationsReviewToDosToDoIds'], loadToDos, 'reviewToDos', Route.Operations.LoadOperationsReviewToDos, loadReviewToDosAction);
}

export function* searchReviewOperationsRequestsSaga() {
    yield takeLatest([loadReviewRequestsAction.actionKey, 'operations/setOperationsReviewRequestsSearch', 'operations/setOperationsReviewRequestsPageAndPageSize',
        'operations/setOperationsReviewRequestsLocationIds', 'operations/setOperationsReviewRequestsToDoIds'], loadToDos, 'reviewRequests', Route.Operations.LoadOperationsReviewRequestsToDos, loadReviewRequestsAction);
}

export function* searchUserReviewOperationsToDosSaga() {
    yield takeLatest([loadUserReviewToDosAction.actionKey, 'operations/setOperationsUserReviewToDosSearch', 'operations/setOperationsUserReviewToDosPageAndPageSize',
        'operations/setOperationsUserReviewToDosLocationIds', 'operations/setOperationsUserReviewToDosToDoIds'], loadToDos, 'userReviewToDos', Route.Operations.LoadOperationsUserReviewToDos, loadUserReviewToDosAction);
}

export function* searchUserReviewOperationsRequestsSaga() {
    yield takeLatest([loadUserReviewRequestsAction.actionKey, 'operations/setOperationsUserReviewRequestsSearch', 'operations/setOperationsUserReviewRequestsPageAndPageSize',
        'operations/setOperationsUserReviewRequestsLocationIds', 'operations/setOperationsUserReviewRequestsToDoIds'], loadToDos, 'userReviewRequests', Route.Operations.LoadOperationsUserReviewRequests, loadUserReviewRequestsAction);
}

export function* searchSubordinatedOperationsToDosSaga() {
    yield takeLatest([loadSubordinatedToDosAction.actionKey, 'operations/setOperationsSubordinatedToDosSearch', 'operations/setOperationsSubordinatedToDosPageAndPageSize',
        'operations/setOperationsSubordinatedToDosLocationIds', 'operations/setOperationsSubordinatedToDosToDoIds'], loadToDos, 'subordinatedToDos', Route.Operations.LoadSubordinatedToDos, loadSubordinatedToDosAction);
}

export function* searchSubordinatedUserReviewOperationsToDosSaga() {
    yield takeLatest([loadSubordinatedUserReviewToDosAction.actionKey, 'operations/setOperationsSubordinatedUserReviewToDosSearch', 'operations/setOperationsSubordinatedUserReviewToDosPageAndPageSize',
        'operations/setOperationsSubordinatedUserReviewToDosLocationIds', 'operations/setOperationsSubordinatedUserReviewToDosToDoIds'], loadToDos, 'subordinatedUserReviewToDos', Route.Operations.LoadSubordinatedUserReviewToDos, loadSubordinatedUserReviewToDosAction);
}

export function* searchSubordinatedUserReviewOperationsRequestsSaga() {
    yield takeLatest([loadSubordinatedUserReviewRequestsAction.actionKey, 'operations/setOperationsSubordinatedUserReviewRequestsSearch', 'operations/setOperationsSubordinatedUserReviewRequestsPageAndPageSize',
        'operations/setOperationsSubordinatedUserReviewRequestsLocationIds', 'operations/setOperationsSubordinatedUserReviewRequestsToDoIds'], loadToDos, 'subordinatedUserReviewRequests', Route.Operations.LoadSubordinatedUserReviewRequests, loadSubordinatedUserReviewRequestsAction);
}

export function* searchRequestsOperationsToDosSaga() {
    yield takeLatest([loadRequestsToDosAction.actionKey, 'operations/setOperationsRequestsToDosSearch', 'operations/setOperationsRequestsPage',
        'operations/setOperationsRequestsLocationIds', 'operations/setOperationsRequestsToDoIds'], loadToDos, 'requestOverview', Route.Operations.LoadOperationsRequests, loadRequestsToDosAction);
}

export function* searchApprovedRequestsOperationsToDosSaga() {
    yield takeLatest([loadApprovedRequestsAction.actionKey, 'operations/setOperationsApprovedRequestsToDosSearch', 'operations/setOperationsApprovedRequestsPageAndPageSize',
        'operations/setOperationsApprovedRequestsLocationIds', 'operations/setOperationsApprovedRequestsToDoIds'], loadToDos, 'approvedRequests', Route.Operations.LoadOperationsApprovedRequests, loadApprovedRequestsAction);
}

export function* searchDeclinedRequestsOperationsToDosSaga() {
    yield takeLatest([loadDeclinedRequestsAction.actionKey, 'operations/setOperationsDeclinedRequestsToDosSearch', 'operations/setOperationsDeclinedRequestsPageAndPageSize',
        'operations/setOperationsDeclinedRequestsLocationIds', 'operations/setOperationsDeclinedRequestsToDoIds'], loadToDos, 'declinedRequests', Route.Operations.LoadOperationsDeclinedRequests, loadDeclinedRequestsAction);
}

export function* searchExpiredRequestsOperationsToDosSaga() {
    yield takeLatest([loadExpiredRequestsAction.actionKey, 'operations/setOperationsExpiredRequestsToDosSearch', 'operations/setOperationsExpiredRequestsPageAndPageSize',
        'operations/setOperationsExpiredRequestsLocationIds', 'operations/setOperationsExpiredRequestsToDoIds'], loadToDos, 'expiredRequests', Route.Operations.LoadOperationsExpiredRequests, loadExpiredRequestsAction);
}

export function* loadOperationsEditors(action: PayloadAction<{ search: string }>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt = yield select(jwtSelector);
        yield put(loadOperationsEditorsAction.startAction(action.payload));
        yield delay(500);
        request = getRequest(Route.Operations.LoadOperationsEditors(action.payload.search), {jwt})
        const {response}: RequestResponse<Editor[]> = yield call(request.request);
        yield put(loadOperationsEditorsAction.successAction(response));
    } catch (e: any) {
        logger.error(e);
        yield put(loadOperationsEditorsAction.errorAction({
            ...action.payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* loadOperationsEditorsSaga() {
    yield takeLatest(loadOperationsEditorsAction.actionKey, loadOperationsEditors)
}

export function* saveOperationsEditors(action: PayloadAction<SaveOperationsEditorsPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const {jwt, emails} = yield select((state: AppState) => ({
            jwt: state.user.info.jwt,
            emails: action.payload.userIds.map(it => state.operations.editorSearch.elements[it]?.email).filter(notUndefined)
        }));
        yield put(saveOperationsEditorsAction.startAction(action.payload));
        request = postRequest(Route.Operations.SaveOperationsEditors, {
            body: {
                toDoId: action.payload.toDoId,
                userIds: action.payload.userIds
            }, jwt
        });
        const {response}: RequestResponse<{ success: boolean }> = yield call(request.request);
        if (response.success) {
            yield put(saveOperationsEditorsAction.successAction({
                emails,
                toDoId: action.payload.toDoId,
                statePart: action.payload.statePart
            }));
        } else {
            yield put(saveOperationsEditorsAction.errorAction({
                ...action.payload,
                message: ''
            }));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(saveOperationsEditorsAction.errorAction({
            ...action.payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* saveOperationsEditorsSaga() {
    yield takeEvery(saveOperationsEditorsAction.actionKey, saveOperationsEditors);
}

export function* downloadToDos({payload}: PayloadAction<DownloadToDoActionPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        if (Boolean(payload.toDos.length)) {
            const jwt = yield select(jwtSelector);
            yield put(downloadToDosAction.startAction(payload));
            request = postRequest(Route.Operations.DownloadOperationsToDos, {
                body: payload.toDos.map(it => ({id: it.id})),
                jwt
            });
            const {response}: RequestResponse<Blob> = yield call(request.request);
            let fileName = payload.toDos.length > 1 ? 'export.zip' : `${payload.toDos[0].name.replace(/\//g, '_').replace(/ /g, '_')}.pdf`;
            saveAs(response, `${DateTime.now().toFormat('yyyyMMdd')}-${fileName}`);
            yield put(downloadToDosAction.successAction(payload));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(downloadToDosAction.errorAction({
            ...payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* downloadToDosSaga() {
    yield takeEvery(downloadToDosAction.actionKey, downloadToDos);
}

export function* sendOperationsToDoEmail(action: PayloadAction<SendOperationsToEmailPayload>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        if (Boolean(action.payload.ids.length) && Boolean(action.payload.recipients.length)) {
            const jwt = yield select(jwtSelector);
            yield put(sendOperationsToDoEmailAction.startAction(action.payload));
            request = postRequest(Route.Operations.SendOperationsToDoEmail, {body: action.payload, jwt});
            yield call(request.request);
            yield put(sendOperationsToDoEmailAction.successAction(action.payload.ids));
        }
    } catch (e: any) {
        logger.error(e);
        yield put(sendOperationsToDoEmailAction.errorAction({
            ...action.payload,
            message: e.message
        }));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* sendOperationsToDoEmailSaga() {
    yield takeEvery(sendOperationsToDoEmailAction.actionKey, sendOperationsToDoEmail);
}

export function* uploadOperationsFile({payload}: PayloadAction<UploadOperationsFilesPayload>): Generator<any, any, any> {
    if (Boolean(payload.files.length)) {
        const jwt: string = yield select(jwtSelector);
        for (const file of payload.files) {
            const channel: EventChannel<UploadEventChannel<AnswerAttachment>> = yield call(createUploadFileChannel, Route.Operations.UploadFile(payload.toDoId, payload.questionId), file.file, jwt);
            yield put(uploadOperationsFilesAction.startAction(payload));
            while (true) {
                const {
                    progress = 0,
                    err,
                    success,
                    response
                } = yield take<UploadEventChannel<AnswerAttachment>>(channel);
                if (err) {
                    yield put(uploadOperationsFilesAction.errorAction({
                        ...payload,
                        // Put file on which the error occurred into the payload
                        files: [file],
                        message: err.message
                    }));
                    break;
                }
                if (success) {
                    if (response) {
                        yield put(uploadOperationsFilesAction.successAction({
                            file: response,
                            fileId: file.id,
                            toDoId: payload.toDoId,
                            questionId: payload.questionId,
                            uploadedFile: file.file
                        }));
                    }
                    break;
                }
                yield put(setOperationsFileUploadProgress({
                    progress,
                    fileId: file.id,
                    toDoId: payload.toDoId,
                    questionId: payload.questionId
                }));
            }
        }
    }
}

export function* uploadOperationsFileSaga() {
    yield takeEvery(uploadOperationsFilesAction.actionKey, uploadOperationsFile);
}


export function* resetToDoTaskFetchStatus(toDoId: number, taskIds: number[]): Generator<any, any, any> {
    if (Boolean(taskIds.length)) {
        yield delay(1000);
        for (let it of taskIds) {
            const fetchStatus: FetchStatus | undefined = yield select((state: AppState) => state.operations.editToDos.elements[toDoId]?.taskFetchStatus[it]);
            // Only reset fetch status if no error occurred!
            if (fetchStatus !== FetchStatus.Error) {
                yield put(syncOperationsToDoAction.resetAction({
                    toDoId,
                    questionId: it,
                    timestamp: DateTime.now().toMillis()
                }));
            }
        }
    }
}

let numberOfReSyncRetries: Record<number, Record<number, number>> = {};

export function* syncOperationsToDo(toDoId: number, questionId: number, questionType: ToDoTaskType, answer?: TaskAnswer): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    const startTimestamp = DateTime.now().toMillis();
    if (!numberOfReSyncRetries[toDoId]) {
        numberOfReSyncRetries[toDoId] = {};
    }
    if (!numberOfReSyncRetries[toDoId]![questionId]) {
        numberOfReSyncRetries[toDoId]![questionId] = 0;
    }
    try {
        const jwt: string = yield select(jwtSelector);
        yield put(syncOperationsToDoAction.startAction({
            toDoId,
            questionId,
            timestamp: startTimestamp
        }));

        if (answer) {
            const postAnswer = {...answer};
            delete postAnswer.external;
            request = postRequest(Route.Operations.SaveOperationsToDo, {
                body: {
                    type: SyncToDoAnswerType.Single,
                    toDoId: toDoId,
                    questionType,
                    answer: postAnswer
                },
                jwt
            });
        } else {
            request = deleteRequest(Route.Operations.DeleteOperationsToDoAnswer, {
                body: {
                    toDoId,
                    taskId: questionId
                },
                jwt
            });
        }
        const {response}: RequestResponse<SyncOperationsToDoResponse> = yield call(request.request);

        if (Boolean(response?.tasks.length)) {
            for (let it of response.tasks) {
                yield put(syncOperationsToDoAction.successAction({
                    toDoId,
                    questionId: it.questionId,
                    complete: it.complete,
                    timestamp: startTimestamp,
                    serverTimestamp: it.serverTimestamp
                }));
            }
            yield put(setToDoLastUpdatedTimestamp({
                toDoId,
                timestamp: response.timestamp
            }));

        } else {
            yield put(syncOperationsToDoAction.errorAction({
                toDoId,
                questionId,
                timestamp: startTimestamp,
                message: ''
            }));
        }

        numberOfReSyncRetries[toDoId]![questionId] = 0;
    } catch (e: any) {
        logger.error('Could not sync operations to do!', e);
        yield put(syncOperationsToDoAction.errorAction({
            toDoId,
            questionId,
            timestamp: startTimestamp,
            message: e.message
        }));

        // Try to refresh the token
        if (e instanceof AuthenticationError && numberOfReSyncRetries[toDoId]![questionId]! < 5) {
            numberOfReSyncRetries[toDoId]![questionId]!++;
            yield call(manualRefreshToken, Route.RefreshToken);
        }

    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export let syncCallStack: { taskId: number; toDoId: number; effect: CallEffect }[] = [];
export let syncing = false;

export function* startOperationsToDoSyncing(action: PayloadAction<SetOperationsQuestionAnswerPayload<TaskAnswer>>) {
    // Filter all sync calls which are currently in the stack for this task because it has been overwritten by the user
    syncCallStack = syncCallStack.filter(it => it.taskId !== action.payload.questionId);
    syncCallStack.push({
        toDoId: action.payload.toDoId,
        taskId: action.payload.questionId,
        effect: call(syncOperationsToDo, action.payload.toDoId, action.payload.questionId, action.payload.questionType, action.payload.answer)
    });
    if (!syncing) {
        // Start syncing because no other sync is currently active
        yield call(applyOperationsSyncCalls);
    }
}

export function* syncOperationsToDoSaga() {
    yield takeEvery('operations/setOperationsTaskAnswer', startOperationsToDoSyncing);
}

export function* applyOperationsSyncCalls() {
    // Lock the syncing
    syncing = true;
    while (syncCallStack.length) {
        const element = syncCallStack.shift()!;
        // Get the first element of the stack and sync it
        yield element.effect;
        // Wait 1s and reset the fetch status in a separate fork
        yield fork(resetToDoTaskFetchStatus, element.toDoId, [element.taskId]);
    }
    // Release the lock because no active syncing
    syncing = false;
}

export function* deleteOperationsFileSuccess(action: PayloadAction<DeleteOperationsFilePayload>): Generator<any, any, any> {
    // Delete the file from the operations answer
    yield put(deleteOperationsFileFromAnswer(action.payload));

    // Sync the answer with the backend
    // Get the current answer from the backend
    const {answer, questionType} = yield select((state: AppState) => {
        const answer = state.operations.editToDos.elements[action.payload.toDoId]!.answers.find(it => it.questionID === action.payload.questionId);
        const questionType = state.operations.editToDos.elements[action.payload.toDoId]!.questions.find(it => it.id === action.payload.questionId)?.type;
        return {
            answer,
            questionType
        }
    });
    // Sync the answer with the backend
    yield put(setOperationsTaskAnswer()({
        answer,
        toDoId: action.payload.toDoId,
        questionId: action.payload.questionId,
        questionType
    }));
}

export function* deleteOperationsFileSuccessSaga() {
    yield takeEvery(deleteOperationsFileActionAndSaga.successKey, deleteOperationsFileSuccess)
}

let numberOfPeriodicRetriesByToDo: Record<number, number> = {};

export function* periodicToDoSync(toDoId: number): Generator<any, any, any> {
    try {
        while (true) {
            try {
                if (!numberOfPeriodicRetriesByToDo[toDoId]) {
                    numberOfPeriodicRetriesByToDo[toDoId] = 0;
                }
                const online = yield select((state: AppState) => state.connection.online[CheckOnlineStateRoutes.QualitizeOnline]?.state === OnlineState.Online);
                // Execute periodic sync only if backend can be reached.
                if (online) {
                    const jwt: string = yield select(jwtSelector);
                    const request = getRequest(Route.Operations.GetToDoLastUpdateTimestamp(toDoId), {
                        jwt
                    });
                    const {response}: RequestResponse<CheckPeriodicToDoSyncResponse> = yield call(request.request);
                    const lastUpdatedInState: number = yield select((state: AppState) => state.operations.editToDos.elements[toDoId]?.lastUpdateTimestamp || 0);
                    if (response.lastUpdatedTimestamp > lastUpdatedInState) {
                        // Change from outside, load changes from backend
                        yield put(reloadToDoActionAndSaga.action({id: toDoId}));
                        yield put(setToDoLastUpdatedTimestamp({
                            toDoId,
                            timestamp: DateTime.now().toMillis()
                        }));
                    }
                }
                numberOfPeriodicRetriesByToDo[toDoId] = 0;
            } catch (e) {
                logger.error(e);
                // Try to refresh the token
                if (e instanceof AuthenticationError && numberOfPeriodicRetriesByToDo[toDoId]! < 5) {
                    numberOfPeriodicRetriesByToDo[toDoId]!++;
                    yield call(manualRefreshToken, Route.RefreshToken);
                }
            }
            // Wait the defined timeout duration before next sync.
            yield delay(OperationsSyncTimeout);
        }
    } catch (e) {
        logger.error('Error during periodic to do sync!', e);
    } finally {
        if (yield cancelled()) {
            logger.info('Periodic to do sync was cancelled');
        }
    }
}

// Dictionary which stores for a to-do (the key) the current periodic to do syncing.
export const enabledPeriodicToDoSync: Record<number, Task> = {};

export function* enablePeriodicToDoSync(action: PayloadAction<number>) {
    // Check if any existing syncing sagas are currently running, if so, cancel it
    if (enabledPeriodicToDoSync[action.payload]) {
        yield cancel(enabledPeriodicToDoSync[action.payload]!);
    }
    enabledPeriodicToDoSync[action.payload] = yield fork(periodicToDoSync, action.payload);
}

export function* enablePeriodicToDoSyncSaga() {
    yield takeEvery('operations/enablePeriodicToDoSync', enablePeriodicToDoSync);
}

export function* disablePeriodicToDoSync(action: PayloadAction<number>) {
    // Check if any syncing sagas are running for the given to do id, if so, cancel it and remove the reference
    if (enabledPeriodicToDoSync[action.payload]) {
        yield cancel(enabledPeriodicToDoSync[action.payload]!);
        delete enabledPeriodicToDoSync[action.payload];
    }
}

export function* disablePeriodicToDoSyncSaga() {
    yield takeEvery('operations/disablePeriodicToDoSync', disablePeriodicToDoSync);
}


export function* reSyncOperationsTaskOnOnlineState(action: PayloadAction<number>): Generator<any, any, any> {
    const toDo: EditToDo | undefined = yield select((state: AppState) => state.operations.editToDos.elements[action.payload]);
    if (toDo) {
        const tasksToReSync = Object.keys(toDo.taskFetchStatus).filter(it => toDo.taskFetchStatus[it] === FetchStatus.Error).map(it => toDo.answers.find(answer => answer.questionID === Number(it))).filter(notUndefined);
        for (const it of tasksToReSync) {
            const toDoTaskType = toDo.questions.find(question => question.id === it.questionID)?.type;
            if (toDoTaskType) {
                yield call(syncOperationsToDo, toDo.id, it.questionID, toDoTaskType, it);
            }
        }
    }
}

export function* reSyncOperationsTaskOnOnlineStateSaga() {
    yield takeEvery('operations/reSyncToDoTasks', reSyncOperationsTaskOnOnlineState);
}


export let reviewSyncCallStack: { taskId: number; toDoId: number; effect: CallEffect }[] = [];
export let isReviewSyncing = false;

export function* startOperationsToDoReviewSyncing(action: PayloadAction<SaveToDoReviewRemarkBody>) {
    // Filter all sync calls which are currently in the stack for this task because it has been overwritten by the user
    reviewSyncCallStack = reviewSyncCallStack.filter(it => it.toDoId === action.payload.toDoId && it.taskId !== action.payload.remark.taskId);
    reviewSyncCallStack.push({
        toDoId: action.payload.toDoId,
        taskId: action.payload.remark.taskId,
        effect: call(syncOperationsToDoReview, action)
    });
    if (!isReviewSyncing) {
        // Start syncing because no other sync is currently active
        yield call(applyOperationsReviewSyncCalls);
    }
}

export function* syncOperationsToDoReviewSaga() {
    yield takeEvery('operations/setOperationsReviewRemark', startOperationsToDoReviewSyncing);
}

export function* applyOperationsReviewSyncCalls() {
    // Lock the syncing
    isReviewSyncing = true;
    while (reviewSyncCallStack.length) {
        const element = reviewSyncCallStack.shift()!;
        // Get the first element of the stack and sync it
        yield element.effect;
    }
    // Release the lock because no active syncing
    isReviewSyncing = false;
}

export function* syncOperationsToDoReview(action: PayloadAction<SaveToDoReviewRemarkBody>): Generator<any, any, any> {
    let request: Request<any> | undefined = undefined;
    try {
        const jwt: string = yield select(jwtSelector);
        let body = {...action.payload};
        // Check if payload has no id assigned, but in the meantime the remark has already been posted in the backend and an id has been assigned
        if (!action.payload.remark.id) {
            const existingId = yield select((state: AppState) => {
                return state.operations.reviews.elements[action.payload.toDoId]?.remarks.find(it => it.taskId === action.payload.remark.taskId)?.id
            });
            if (existingId) {
                body.remark.id = existingId;
            }
        }
        yield put(saveToDoReviewRemarkAction.startAction(action.payload));
        request = postRequest(Route.Operations.SaveToDoReviewRemark, {
            body,
            jwt
        });
        const {response}: RequestResponse<ReviewToDoRemark> = yield call(request.request);
        yield put(saveToDoReviewRemarkAction.successAction(response));

    } catch (e: any) {
        logger.error('Could not sync operations to do!', e);
        yield put(saveToDoReviewRemarkAction.errorAction({...action.payload, message: e.message}));
    } finally {
        if (yield cancelled()) {
            request?.abort();
        }
    }
}

export function* handlePreFinishResults(toDoId: number, results: PreFinishToDoResult[] = []) {
    for (const result of results) {
        switch (result.type) {
            case PreFinishToDoResultType.Reviewer:
                // Set to do reviewers
                yield put(setToDoReviewers({id: toDoId, reviewers: (result as ReviewersPreFinishResult).reviewers}));
                break;
        }
    }
}


function* OperationsSaga(): Generator<CallEffect<void>> {
    yield call(spawnSagas([
        {generator: searchOperationsToDosSaga},
        {generator: loadOperationsEditorsSaga},
        {generator: saveOperationsEditorsSaga},
        {generator: searchFinishedOperationsToDosSaga},
        {generator: searchReviewOperationsToDosSaga},
        {generator: searchReviewOperationsRequestsSaga},
        {generator: searchUserReviewOperationsToDosSaga},
        {generator: searchUserReviewOperationsRequestsSaga},
        {generator: searchSubordinatedOperationsToDosSaga},
        {generator: searchSubordinatedUserReviewOperationsToDosSaga},
        {generator: searchSubordinatedUserReviewOperationsRequestsSaga},
        {generator: searchRequestsOperationsToDosSaga},
        {generator: searchApprovedRequestsOperationsToDosSaga},
        {generator: searchDeclinedRequestsOperationsToDosSaga},
        {generator: searchExpiredRequestsOperationsToDosSaga},
        {generator: downloadToDosSaga},
        {generator: sendOperationsToDoEmailSaga},
        {generator: loadManualToDosActionAndSaga.saga},
        {generator: loadAvailableToDosActionAndSaga.saga},
        {generator: createManualToDoActionAndSaga.saga},
        {generator: sendContinueEmailActionAndSaga.saga},
        {generator: loadToDoActionAndSaga.saga},
        {generator: reloadToDoActionAndSaga.saga},
        {generator: uploadOperationsFileSaga},
        {generator: syncOperationsToDoSaga},
        {generator: deleteOperationsFileActionAndSaga.saga},
        {generator: deleteOperationsFileSuccessSaga},
        {generator: finishToDoActionAndSaga.saga},
        {generator: loadArchiveToDoActionAndSaga.saga},
        {generator: loadToDoReviewActionAndSaga.saga},
        {generator: loadFinishedToDoReviewActionAndSaga.saga},
        {generator: reOpenReviewActionAndSaga.saga},
        {generator: saveToDoReviewRemarkAction.saga},
        {generator: finishToDoReviewActionAndSaga.saga},
        {generator: changeToDoDeadlineActionAndSaga.saga},
        {generator: saveOperationsToDoReviewerActionAndSaga.saga},
        {generator: changeReviewDeadlineActionAndSaga.saga},
        {generator: reOpenToDoActionAndSaga.saga},
        {generator: loadOpenToDosForToDoDefinitionAndLocationActionAndSaga.saga},
        {generator: loadPreviousToDosForToDoActionAndSaga.saga},
        {generator: declineToDoReviewActionAndSaga.saga},
        {generator: preFinishToDoActionAndSaga.saga},
        {generator: finishAutoApproveToDoActionAndSaga.saga},
        {generator: finishAutoDeclineToDoActionAndSaga.saga},
        {generator: enablePeriodicToDoSyncSaga},
        {generator: disablePeriodicToDoSyncSaga},
        {generator: reSyncOperationsTaskOnOnlineStateSaga},
        {generator: syncOperationsToDoReviewSaga},
    ], [logoutAction.actionKey, CancelAllSagasViaForcedTabLogoutActionKey]));
}

export default OperationsSaga;