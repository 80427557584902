import {useEffect, useMemo, useRef, useState} from 'react';
import {broadcastChannel, FetchStatus, Language, setLanguage, setShowAppLoader} from '@software/reactcommons';
import {useCookies} from 'react-cookie';
import {useDispatch, useSelector} from 'react-redux';
import {
    EnableUserLogoutOnInactivityActionKey,
    getLanguageFromCookie,
    RegisterUserActivityActionKey,
    RegisterUserActivityInOtherTabActionKey
} from '../redux/reducer/user/actions';
import {setDeepLinkToken, setRedirect} from '../redux/reducer/application/application';
import {loadDeepLinkActionAndSaga} from '../redux/reducer/application/actions';
import Routes from '../constants/routes';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {AppState} from '../types/Types';
import {
    DeepLinkTokenParameterName,
    LanguageCookieName,
    LoginTokenParameterName,
    RedirectPathParameterName
} from '../constants/Constants';
import {createAction} from '@reduxjs/toolkit';
import {ClientPostAuthenticationSecurityType, setForcedLogout} from '@software/reactcommons-security';
import {inactivityLogoutDurationInMsSelector} from '../redux/reducer/user/selectors';
import {resetUserState} from '../redux/reducer/user/user';
import {throttle} from 'lodash';

/**
 * Custom hook which reacts on setup load failure. If failure on loading happens, redirect to the login page
 * and reset the user state.
 */
export const useRedirectOnSetupFailure = () => {

    const fetchStatus = useSelector((state: AppState) => state.user.fetchStatus.availableSites);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (fetchStatus === FetchStatus.Error) {
            // Reset the load available sites fetch status
            dispatch(resetUserState());
            navigate(Routes.Login.Base(''));
            dispatch(setShowAppLoader(false));
        }
    }, [navigate, fetchStatus, dispatch]);

}


/**
 * Hook which reads the current selected language value from the cookie and sets the
 * app language according to the cookie value.
 * If no cookie value is set, the language of the browser is used, if that is not defined, english is used
 */
export const useLanguageFromCookie = () => {

    const [cookies] = useCookies([LanguageCookieName]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLanguageFromCookie());
        let usedLanguage = cookies.PLAY_LANG || Language.German;
        if (!cookies.PLAY_LANG) {
            // @ts-ignore
            usedLanguage = (navigator?.language || navigator?.languages?.[0] || navigator?.userLanguage || Language.English).substring(0, 2);
        }
        // Check if language is supported, otherwise set default language to german.
        if (![Language.English, Language.German].includes(usedLanguage)) {
            usedLanguage = Language.English;
        }
        dispatch(setLanguage(usedLanguage));
    }, [cookies.PLAY_LANG, dispatch]);

    return;
}

/**
 * Hook which reads a redirect from a get parameter and puts the redirect into the store
 */
export const useParameterRedirect = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch();

    const {parameterRedirect} = useMemo(() => ({
        parameterRedirect: params.get(RedirectPathParameterName)
    }), [params]);

    useEffect(() => {
        dispatch(setRedirect(parameterRedirect || undefined));
    }, [parameterRedirect, dispatch]);

    return;
}

export const useForcedLogoutRedirect = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const forcedLogout = useSelector((state: AppState) => state.user.forcedLogout);

    useEffect(() => {
        // If logout was forced (e.g. by logout in another tab, redirect to the login page.
        if (forcedLogout) {
            navigate(Routes.Login.Base(''));
            dispatch(setForcedLogout(false));
        }
    }, [forcedLogout, navigate, dispatch]);
}

export const useIsBlockedRedirect = () => {
    const navigate = useNavigate();

    const isBlocked = useSelector((state: AppState) => state.user.isBlocked);

    useEffect(() => {
        if (isBlocked) {
            navigate(Routes.Login.Base(Routes.Login.Disabled));
        }
    }, [isBlocked, navigate]);

}

/**
 * Hook which reacts on changes in the redirect property of the application state
 * and executes the redirect.
 */
export const useRedirect = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const redirect = useSelector((state: AppState) => state.application.redirect);
    const hasAvailableSites = useSelector((state: AppState) => state.user.fetchStatus.availableSites === FetchStatus.Success);
    const loggedIn = useSelector((state: AppState) => Boolean(state.user.info.jwt));

    useEffect(() => {
        // Check if user is logged in and its available sites are loaded
        if (redirect && loggedIn && hasAvailableSites) {
            // If a redirect exists in the state, execute the redirect and reset it
            dispatch(setRedirect(undefined));
            navigate(redirect);
        }
    }, [redirect, navigate, dispatch, loggedIn, hasAvailableSites]);
}

export const useInactivityLogout = () => {

    const dispatch = useDispatch();
    const enableInactivityLogout = useSelector((state: AppState) => Boolean(state.user.info.postAuthenticationRules?.some(it => it.type === ClientPostAuthenticationSecurityType.InactivityLogout)));

    useEffect(() => {
        if (enableInactivityLogout) {
            dispatch(createAction(EnableUserLogoutOnInactivityActionKey)());
            const handler = throttle(() => {
                dispatch(createAction(RegisterUserActivityActionKey)());
                // Inform all other active tabs about the activity
                broadcastChannel.postMessage(JSON.stringify(createAction(RegisterUserActivityInOtherTabActionKey)()));
            }, 1000);
            // Call the handler initially
            handler();
            const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'click'];
            events.forEach(it => document.addEventListener(it, handler, true));

            return () => {
                // Deregister event handler
                events.forEach(it => document.removeEventListener(it, handler, true));
            }
        }
    }, [dispatch, enableInactivityLogout]);
}

/**
 * Hook which reads a deep link token from the get parameter, stores it in the state
 * and triggers the loading of the deep link information from the backend.
 */
export const useDeepLinkToken = () => {

    const dispatch = useDispatch();
    const [params] = useSearchParams();
    const {pathname} = useLocation();

    // Use memo object to avoid unnecessary re-triggering of deep link actions
    const {deepLinkToken, loginToken} = useMemo(() => ({
        deepLinkToken: params.get(DeepLinkTokenParameterName)?.trim(),
        loginToken: params.get(LoginTokenParameterName)?.trim() || '',
    }), [params]);

    const refreshFetchStatus = useSelector((state: AppState) => state.user.fetchStatus.refreshToken);

    useEffect(() => {
        if (deepLinkToken && [FetchStatus.Success, FetchStatus.Error].includes(refreshFetchStatus)) {
            // Store deep link token in state since it has to be reused if user request a login token
            dispatch(setDeepLinkToken(deepLinkToken));
            dispatch(loadDeepLinkActionAndSaga.action({
                token: deepLinkToken,
                // Only trigger auto actions of the deep link token if we are within the login area and there is no login token
                // already defined via get parameter
                triggerAutoActions: pathname.startsWith(Routes.Login.Base('')) && !Boolean(loginToken.length)
            }));
        }
    }, [deepLinkToken, loginToken, dispatch, pathname, refreshFetchStatus]);

    return;
}

export const useLoginLinkRequestRedirect = (username: string, staySignedIn: boolean) => {

    const fetchStatus = useSelector((state: AppState) => state.user.fetchStatus.requestLoginToken);

    const navigate = useNavigate();

    useEffect(() => {
        if (fetchStatus === FetchStatus.Success) {
            navigate(Routes.Login.Base(Routes.Login.RequestTokenSuccess));
        } else if (fetchStatus === FetchStatus.Error) {
            // Error occurred during requesting, e.g. too many active tokens for user
            navigate(`${Routes.Login.Base(Routes.Login.RequestTokenError)}?username=${encodeURIComponent(username)}&staySignedIn=${encodeURIComponent(String(staySignedIn))}`);
        }
    }, [navigate, fetchStatus, username, staySignedIn]);

}

export const useInactivityLogoutDurationInSeconds = (referenceUserActivityTimestamp: number, enabled: boolean) => {

    const durationInMillis = useSelector(inactivityLogoutDurationInMsSelector);
    const [secondsToLogout, setSecondsToLogout] = useState(0);
    const interval = useRef<number | null>(null);

    useEffect(() => {
        if (durationInMillis > 0) {
            if (enabled) {
                // If there was already an interval started, clear it
                if (interval.current) {
                    window.clearInterval(interval.current);
                }
                // On change, set the seconds directly and start a new interval
                setSecondsToLogout(Math.round(((referenceUserActivityTimestamp + durationInMillis) - new Date().getTime()) / 1000));
                interval.current = window.setInterval(() => {
                    setSecondsToLogout(Math.round(((referenceUserActivityTimestamp + durationInMillis) - new Date().getTime()) / 1000));
                }, 1000);
            } else {
                // If inactivity was disabled, clear the interval
                if (interval.current) {
                    window.clearInterval(interval.current);
                }
            }
        }
        return () => {
            if (interval.current) {
                window.clearInterval(interval.current);
            }
        }
    }, [enabled, durationInMillis, referenceUserActivityTimestamp]);

    return secondsToLogout;
}