import {PasswordResetTokenParameterName, QualitizeOnlineUrl} from './Constants';

export const LegacyIFrameRoutes = {
    Stickies: `${QualitizeOnlineUrl}/embedded/stickies`,
    Departments: `${QualitizeOnlineUrl}/embedded/departments`,
}

export type RouteType = string | ((path: string | number) => string);

const Routes = {
    FeedbackBase: (path: string | number) => `/feedback${path}`,
    Dashboard: (path: string | number) => `/dashboards/${path}`,
    AdministrationBase: (path: string | number) => `/administration${path}`,
    OperationsBase: (path: string | number) => `/operations${path}`,
    LandingPageBase: (path: string | number) => `/landingPages${path}`,
    Logout: '/logout',
    Login: {
        Base: (path: string | number) => `/login${path}`,
        Token: '/token',
        RequestTokenSuccess: '/token/request',
        RequestTokenError: '/token/request/error',
        ValidateTokenError: '/token/validate/error',
        OIDCCallback: '/oidc/callback',
        Disabled: '/disabled',
        Password: {
            Base: (path: string | number) => `/password${path}`,
            Recover: {
                Base: (path: string | number) => `/recover${path}`,
                Success: '/success'
            },
            Reset: {
                Base: (path: string | number) => `/reset${path}`,
                Invalidate: (param?: string) => `/invalidate${param ? `?${PasswordResetTokenParameterName}=${param}` : ''}`,
                Error: '/error',
                Success: '/success'
            }
        },
        Register: {
            Base: (path: string | number) => `/register${path}`,
            Error: '/error',
            Success: '/success'
        }
    },
    VideoDashboard: '/videos',
    Videos: {
        Base: (path: string | number) => `/videos${path}`,
        Video: (id: string | number) => `/video/${id}`,
        VideoCategory: (id: string | number) => `/category/${id}`,
        Upload: '/upload',
        Playlist: (id: string | number, videoId?: number | string) => `/playlist/${id}${videoId ? `/${videoId}` : ''}`,
        Playlists: '/playlists',
        CreatePlaylist: '/playlist/create',
        EditPlaylist: (id: string | number) => `/playlist/edit/${id}`,
        WishList: (showDialog: (string | number) = '') => `/wishList${Boolean(showDialog) ? `/${showDialog}` : ''}`,
    },
    Operations: {
        ToDo: {
            Base: (id: string | number, path: string | number) => `/${id}${path}`,
            Overview: '/overview',
            Category: (id: number | string) => `/category/${id}`
        },
        ArchiveToDo: (id: number | string) => `/archive/${id}`,
        FinishedToDos: '/todos/completed',
        OpenToDos: '/todos/open',
        Subordinated: '/todos/subordinated',
        Review: {
            Base: (path: string | number) => `/reviews${path}`,
            ToDo: (id: number | string) => `/toDo/${id}`,
            Requests: '/requests',
            FinishedToDo: (id: number | string) => `/finished/toDo/${id}`,
            Subordinated: '/subordinated',
            SubordinatedRequests: '/subordinated/requests',
        },
        Requests: {
            Base: (path: string | number) => `/requests${path}`,
            Open: '/open',
            Approved: '/approved',
            Declined: '/declined',
            Expired: '/expired',
        }
    },
    Administration: {
        OpeningHours: '/openingHours',
        Terminals: (id: number | string) => `/terminals/${id}`,
        QrCodes: (id: number | string) => `/qrCodes/${id}`,
        VideoAdmin: '/videos/admin',
        AccountManagement: {
            Base: (path: number | string) => `/accounts${path}`,
            Profiles: '/profiles',
            LoginAudit: '/audit',
        }
    },
    Feedback: {
        Reports: {
            Base: (path: number | string) => `/reports${path}`,
            Filter: '/filter',
            Detail: (id: string | number) => `/detail/${id}`,
            Download: (id: string | number) => `/download/${id}`
        }
    },
    Download: {
        Base: (path: number | string) => `/download${path}`,
        DashboardElementExport: (opaqueId: string) => `/dashboard/export/${opaqueId}`
    },
    Legacy: {
        StickyEditor: '/stickies',
        Departments: '/departments'
    },
    Settings: {
        Base: (path: number | string) => `/settings${path}`,
        Account: '/settings/account',
        Password: '/settings/password'
    },
    LandingPage: {
        Edit: (id: number | string, step?: number | string) => `/edit/${id}${step ? `/${step}` : ''}`,
        Create: '/create',
    },
    Base: '/'
}

export default Routes;