import {createAction} from '@reduxjs/toolkit';
import {
    AccountResponse,
    Customer,
    Location,
    UserInfoResponse,
    Region,
    Headquarters,
    ClientLoginMethod,
    ClientLoginMethodType,
    HierarchyFeedbackCollectionPoint,
    OIDCLoginError,
    UserAccessDataSource
} from './types';
import {
    AuthenticationResponse,
    createAuthenticatedSagaFetchAction, fetchAuthenticateError, fetchAuthenticateSuccess,
    Gender, JWT, preparedSelector,
    SecurityErrorCode, setUserPrepared,
    ClientPreAuthenticationSecurityRule
} from '@software/reactcommons-security';
import {
    BaseErrorCode,
    createBasicSagaFetchAction,
    createFetchAction,
    getRequest, logger,
    postRequest
} from '@software/reactcommons';
import {Route} from '../../../api/Api';
import {hideAppLoader} from '../../sagas/ApplicationSaga';
import {postLoginErrorGenerator, postLoginGenerator, setPasswordUpdateErrorGenerator} from '../../sagas/UserSaga';
import {HierarchyNodeWithChildren} from './hierarchyNodeTypes';
import {AppState, IdParams} from '../../../types/Types';
import {DateTime} from 'luxon';
import {jwtDecode} from 'jwt-decode';
import {call, fork, put, select} from 'redux-saga/effects';
import {setDeepLinkToken, setRedirect} from '../application/application';
import {loadDeepLinkActionAndSaga} from '../application/actions';
import {DeepLinkTokenHeaderName, RedirectPathHeaderName} from '../../../constants/Constants';
import {startRefreshTokenSaga} from '@software/reactcommons-security/dist/redux/saga/BaseUserSaga';


/***************************************************************
 * Redux actions types
 **************************************************************/

export const UpdatePersonalDataActionKey = 'user/updatePersonalData';

export const UpdatePasswordActionKey = 'user/updatePassword';

export const LoadAvailableSitesActionKey = 'user/loadAvailableSites';

export const GetLanguageFromCookieActionKey = 'user/getLanguageFromCookie';

export const RegisterUserActivityActionKey = 'user/registerUserActivity';
export const RegisterUserActivityInOtherTabActionKey = 'user/registerUserActivityInOtherTab';

export const EnableUserLogoutOnInactivityActionKey = 'user/enableUserLogoutOnInactivityActionKey';

/***************************************************************
 * Redux action payloads
 **************************************************************/

export interface RequestPasswordMailPayload {
    username: string;
}

export interface UpdatePersonalDataActionPayload {
    gender: Gender;
    firstName: string
    lastName: string;
    email: string;
    phone: string;
}

export interface UpdatePasswordActionPayload {
    oldPassword: string;
    password: string;
    passwordConfirm: string;
}

export interface SetPasswordActionPayload {
    emailToken: string;
    password: string;
    passwordConfirm: string;
}


/***************************************************************
 * Redux action creator
 **************************************************************/

export const updatePersonalData = createAction<UpdatePersonalDataActionPayload>(UpdatePersonalDataActionKey);
export const updatePassword = createAction<UpdatePasswordActionPayload>(UpdatePasswordActionKey);

export const requestPasswordMailActionAndSaga = createBasicSagaFetchAction<RequestPasswordMailPayload, void, never>({
    actionGroup: 'user',
    actionName: 'requestPasswordMail',
    networkCall: (selectedLanguage, requestBody) => {
        return postRequest(Route.Authenticate.RequestPasswordReset, {
            selectedLanguage,
            body: requestBody
        })
    }
});

export const resetPasswordActionAndSaga = createBasicSagaFetchAction<SetPasswordActionPayload, void, never>({
    actionGroup: 'user',
    actionName: 'resetPassword',
    networkCall: (selectedLanguage, requestBody) => postRequest(Route.Authenticate.ResetPassword, {
        selectedLanguage,
        body: requestBody
    }),
    errorGenerator: [setPasswordUpdateErrorGenerator]
});

export const loadAccountActionAndSaga = createAuthenticatedSagaFetchAction<{}, AccountResponse, SecurityErrorCode>({
    actionGroup: 'user',
    actionName: 'loadAccount',
    networkCall: (jwt, selectedLanguage) => getRequest(Route.LoadAccount, {
        jwt,
        selectedLanguage
    })
});

export const loadUserInfoActionAndSaga = createAuthenticatedSagaFetchAction<{}, UserInfoResponse, SecurityErrorCode>({
    actionGroup: 'user',
    actionName: 'loadUserInfo',
    networkCall: (jwt, selectedLanguage) => getRequest(Route.LoadUserInfo, {
        jwt,
        selectedLanguage
    })
});

export const validateResetPasswordTokenActionAndSaga = createBasicSagaFetchAction<{
    emailToken: string
}, void, BaseErrorCode>({
    actionGroup: 'user',
    actionName: 'validateResetPasswordToken',
    networkCall: (selectedLanguage, requestBody) => postRequest(Route.Authenticate.ValidateResetPasswordLink, {body: requestBody}),
    successGenerator: [hideAppLoader],
    errorGenerator: [hideAppLoader]
});

export const invalidateResetPasswordTokenActionAndSaga = createBasicSagaFetchAction<EmailTokenPayload, void, never>({
    actionGroup: 'user',
    actionName: 'invalidateResetPasswordToken',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.InvalidateResetPasswordToken, {body, selectedLanguage})
    },
    successGenerator: [hideAppLoader],
    errorGenerator: [hideAppLoader]
});

export const getLanguageFromCookie = createAction(GetLanguageFromCookieActionKey);

export const loadCustomerListActionAndSaga = createAuthenticatedSagaFetchAction<void, Customer[], never>({
    actionGroup: 'user',
    actionName: 'loadCustomerList',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.User.LoadCustomerList, {jwt, selectedLanguage});
    }
});

export interface SetSelectedCustomerIdPayload {
    id: number;
}

export const setSelectedCustomerIdAction = createFetchAction<SetSelectedCustomerIdPayload, SetSelectedCustomerIdPayload, never>('user', 'setSelectedCustomerId');

export const loadSelectedCustomerIdActionAndSaga = createAuthenticatedSagaFetchAction<void, Customer, never>({
    actionGroup: 'user',
    actionName: 'loadSelectedCustomer',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.User.LoadSelectedCustomer, {jwt, selectedLanguage});
    }
});

export interface UserAccess {
    locations: Location[];
    regions: Region[];
    headquarters: Headquarters;
    dataSources: UserAccessDataSource[];
}

export const loadUserAccessActionAndSaga = createAuthenticatedSagaFetchAction<void, UserAccess, never>({
    actionGroup: 'user',
    actionName: 'loadLocationList',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.User.LoadUserAccess, {jwt, selectedLanguage});
    }
});


export interface RequestPasswordLessLoginTokenPayload {
    username: string;
    staySignedIn?: boolean;
    redirectPath?: string;
    deepLinkToken?: string;
    forceCreate?: boolean;
}

export const requestLoginTokenActionAndSaga = createBasicSagaFetchAction<RequestPasswordLessLoginTokenPayload, void, never>({
    actionGroup: 'user',
    actionName: 'requestPasswordLessLoginToken',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.RequestLoginToken, {body, selectedLanguage})
    },
    successGenerator: [hideAppLoader],
    errorGenerator: [hideAppLoader]
});


export interface EmailTokenPayload {
    emailToken: string;
}

export const validateLoginTokenAction = createFetchAction<EmailTokenPayload, void, never>('user', 'validateLoginToken');
export const authenticateViaLoginTokenAction = createFetchAction<EmailTokenPayload, void, never>('user', 'authenticateViaLoginToken');

export const invalidatePasswordLessLoginTokenActionAndSaga = createBasicSagaFetchAction<EmailTokenPayload, void, never>({
    actionGroup: 'user',
    actionName: 'invalidatePasswordLessLoginToken',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.InvalidateLoginToken, {body, selectedLanguage})
    }
});

export interface LoadAvailableLoginMethodsSuccessPayload {
    methods: ClientLoginMethod[];
    securityRules: ClientPreAuthenticationSecurityRule[];
}

export interface LoadAvailableLoginMethodsBody {
    username: string;
    redirectPath?: string;
    deepLinkToken?: string;
}

export const loadAvailableLoginMethodsActionAndSaga = createBasicSagaFetchAction<LoadAvailableLoginMethodsBody, LoadAvailableLoginMethodsSuccessPayload, never>({
    actionGroup: 'user',
    actionName: 'getAvailableLoginMethods',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.LoadLoginMethods, {body, selectedLanguage})
    },
    successGenerator: [hideAppLoader],
    errorGenerator: [hideAppLoader]
});


export const setPasswordViaRegistrationTokenActionAndSaga = createBasicSagaFetchAction<SetPasswordActionPayload, void, never>({
    actionGroup: 'user',
    actionName: 'setPasswordViaRegistrationToken',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.Registration.SubmitPassword, {body, selectedLanguage});
    },
    successGenerator: [hideAppLoader],
    errorGenerator: [hideAppLoader, setPasswordUpdateErrorGenerator]
});

export const validateRegistrationTokenActionAndSaga = createBasicSagaFetchAction<EmailTokenPayload, void, never>({
    actionGroup: 'user',
    actionName: 'validateRegistrationToken',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.Registration.Validate, {body, selectedLanguage});
    },
    successGenerator: [hideAppLoader],
    errorGenerator: [hideAppLoader]
});

export interface GetEmailViaLoginTokenResponse {
    email: string;
}

export const getEmailViaLoginTokenActionAndSaga = createBasicSagaFetchAction<EmailTokenPayload, GetEmailViaLoginTokenResponse, never>({
    actionGroup: 'user',
    actionName: 'getEmailViaLoginToken',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.GetEmailViaLoginToken, {body, selectedLanguage});
    },
    successGenerator: [function* (_, response) {
        // Get deep link and redirect from state
        const {redirect, deepLinkToken} = yield select((state: AppState) => state.application);
        // If email is successfully loaded, load the login methods for this user
        yield put(loadAvailableLoginMethodsActionAndSaga.action({
            username: response.email,
            deepLinkToken,
            redirectPath: redirect
        }));
    }, hideAppLoader],
    errorGenerator: [hideAppLoader]
});

export interface LoadPersonAccountMethodResponse {
    loginMethod: {
        type: ClientLoginMethodType;
    },
    priority: boolean;
}

export interface LoadPersonAccountResponse {
    loginMethods: LoadPersonAccountMethodResponse[];
    hasPassword: boolean;
}

export const loadPersonAccountActionAndSaga = createAuthenticatedSagaFetchAction<void, LoadPersonAccountResponse, never>({
    actionGroup: 'user',
    actionName: 'loadPersonAccount',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.Authenticate.LoadPersonAccount, {jwt, selectedLanguage})
    },
    successGenerator: [hideAppLoader],
    errorGenerator: [hideAppLoader]
});

export interface LoadDataSourceGroupHierarchyResponse {
    hierarchies: HierarchyNodeWithChildren[];
}


export const LoadResolvedHierarchyNodeHierarchyActionAndSaga = createAuthenticatedSagaFetchAction<void, LoadDataSourceGroupHierarchyResponse, SecurityErrorCode>({
    actionGroup: 'user',
    actionName: 'loadResolvedHierarchyNodeHierarchy',
    networkCall: (jwt, selectedLanguage) => {
        return getRequest(Route.User.GetResolvedHierarchyNodeHierarchy, {
            jwt, selectedLanguage
        });
    }
});

export interface LoadHierarchyNodeFeedbackCollectionPointsResponse {
    feedbackCollectionPoints: HierarchyFeedbackCollectionPoint[];
}

export const LoadHierarchyNodeFeedbackCollectionPointsActionAndSaga = createAuthenticatedSagaFetchAction<IdParams, LoadHierarchyNodeFeedbackCollectionPointsResponse, SecurityErrorCode>({
    actionGroup: 'landingPages',
    actionName: 'loadHierarchyNodeFeedbackCollectionPoints',
    networkCall: (jwt, selectedLanguage, params) => {
        return getRequest(Route.User.GetHierarchyNodeFeedbackCollectionPoints(params!.id), {
            jwt, selectedLanguage
        });
    }
});

export interface ValidateOIDCLoginRequestBody {
    code: string;
    state: string;
    username: string;
    staySignedIn: boolean;

}

export const ValidateOIDCLoginActionAndSaga = createBasicSagaFetchAction<ValidateOIDCLoginRequestBody, AuthenticationResponse, never>({
    actionGroup: 'user',
    actionName: 'validateOIDCLogin',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.OIDC.Validate, {body, selectedLanguage});
    },
    successGenerator: [function* (request, response, responseHeaders): Generator<any, any, any> {
        try {
            // Check if response has a redirect path defined in the header
            const redirectPath = responseHeaders.get(RedirectPathHeaderName);
            if (redirectPath) {
                yield put(setRedirect(redirectPath || undefined));
            }
            const expiresAt = DateTime.now().valueOf() + response.expiresInSeconds * 1000;
            const decoded: JWT = jwtDecode(response.accessToken);
            yield put(fetchAuthenticateSuccess({
                ...response,
                roles: decoded.roles,
                internal: decoded.accountType === 'internal',
                expiresAt
            }));
            yield call(postLoginGenerator);

            // Check if headers contain deep link token
            const deepLinkToken = responseHeaders.get(DeepLinkTokenHeaderName);
            if (deepLinkToken) {
                // Trigger deep link token
                yield put(setDeepLinkToken(deepLinkToken));
                yield put(loadDeepLinkActionAndSaga.action({
                    token: deepLinkToken,
                    // Set trigger auto actions to true since we are in login seitz
                    triggerAutoActions: true
                }))
            }

            yield fork(startRefreshTokenSaga, Route.RefreshToken);
        } catch (e: any) {
            logger.error(e);
            yield call(postLoginErrorGenerator, e);
        } finally {
            const prepared = yield select(preparedSelector);
            if (!prepared) {
                yield put(setUserPrepared());
            }
        }
    }],
    errorGenerator: [function* () {
        yield put(fetchAuthenticateError());
        const prepared = yield select(preparedSelector);
        if (!prepared) {
            yield put(setUserPrepared());
        }
    }]
});

export interface InvalidateOIDCAuthorizationCodeRequest {
    state: string;
    error: OIDCLoginError;
    errorDescription?: string;
}

export const InvalidateOIDCAuthorizationCodeActionAndSaga = createBasicSagaFetchAction<InvalidateOIDCAuthorizationCodeRequest, void, never>({
    actionGroup: 'user',
    actionName: 'invalidateOIDCAuthorizationCode',
    networkCall: (selectedLanguage, body) => {
        return postRequest(Route.Authenticate.OIDC.InvalidateAuthorizationCode, {body, selectedLanguage});
    }
});