import {LinkRegex} from '../constants/Constants';
import {FetchStatus, Language, LocalizedCompiledMessage} from '@software/reactcommons';


export const LanguageToDateFormat = {
    [Language.German]: {
        HOUR: 'HH:mm',
        DAY_WITH_HOUR: 'DD.MM.YYYY HH:mm',
        DAY: 'DD.MM.YYYY',
        WEEK: 'ww/GGGG',
        MONTH: 'MMMM yyyy'
    },
    [Language.English]: {
        HOUR: 'hh:mm A',
        DAY_WITH_HOUR: 'MM/DD/YYYY hh:mm A',
        DAY: 'MM/DD/YYYY',
        WEEK: 'ww/GGGG',
        MONTH: 'MMM yyyy'
    }
}

export const replaceLink = (text: string): string => {
    return text?.replace(LinkRegex, url => {
        return `[${url.trim()}](${!(url.trim().startsWith('http') || url.trim().startsWith('ftp') || url.startsWith('file')) ? `https://${url}` : url})`;
    }) || '';
}

export const removeHTMLTags = (text: string): string => text?.replace(/<[^>]*>/ig, '') || '';

export const getLocalizedString = (message: LocalizedCompiledMessage, locale: Language) => {
    return message.localizations.find(it => it.locale === locale)?.text || ''
}

export const convertFetchStatusArrayToSingleFetchStatus = (fetchStatus: FetchStatus[]) => {
    if (fetchStatus.length > 0) {
        if (fetchStatus.some(it => it === FetchStatus.Active)) {
            return FetchStatus.Active;
        }
        if (fetchStatus.every(it => it === FetchStatus.Success)) {
            return FetchStatus.Success;
        }
        if (fetchStatus.some(it => it === FetchStatus.Error)) {
            return FetchStatus.Error;
        }
    }
    return FetchStatus.Default;
}