// Polyfill window.CustomEVENT
(function () {
    if (typeof window.CustomEvent === 'function') return false; //If not IE

    function CustomEvent(event: string, params: { bubbles: boolean, cancelable: boolean, detail: any }) {
        params = params || {bubbles: false, cancelable: false, detail: undefined};
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    // @ts-ignore
    window.CustomEvent = CustomEvent;
})();

if (typeof HTMLElement !== 'undefined' && !
    HTMLElement.prototype.insertAdjacentElement) {
    HTMLElement.prototype.insertAdjacentElement = function (where, parsedNode) {
        let element = null;
        switch (where) {
            case 'beforebegin':
                element = this.parentNode?.insertBefore(parsedNode, this) || null
                break;
            case 'afterbegin':
                element = this.insertBefore(parsedNode, this.firstChild);
                break;
            case 'beforeend':
                element = this.appendChild(parsedNode);
                break;
            case 'afterend':
                if (this.nextSibling) {
                    element = this.parentNode?.insertBefore(parsedNode, this.nextSibling) || null;
                } else {
                    element = this.parentNode?.appendChild(parsedNode) || null;
                }
                break;
        }
        return element;
    }

    HTMLElement.prototype.insertAdjacentHTML = function (where, htmlStr) {
        const r = this.ownerDocument.createRange();
        r.setStartBefore(this);
        const parsedHTML = r.createContextualFragment(htmlStr) as any;
        this.insertAdjacentElement(where, parsedHTML)
    }


    HTMLElement.prototype.insertAdjacentText = function (where, txtStr) {
        const parsedText = document.createTextNode(txtStr) as any;
        this.insertAdjacentElement(where, parsedText);
    }
}
export {};