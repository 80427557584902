import {SearchResponse, Video, VideoState,} from './types';
import {FetchStatus, normalize} from '@software/reactcommons';
import {
    deleteVideoAction,
    loadVideoActionAndSaga,
    loadVideoCategoriesAction,
    loadVideoLatestAction,
    loadVideoMostExcitingAction,
    loadVideoMostViewedAction,
    loadVideoTagsAction,
    searchVideosAction,
    setVideoEnabledAction, toggleVideoEdit,
    videoCreated,
    videoDeleted,
    videoDisabled,
    videoEnabled,
    videoPathCreated,
    videoViewed
} from './actions';
import {
    createSlice,
    Draft,
    PayloadAction
} from '@reduxjs/toolkit';
import {markdownConverter} from '../../../constants/Constants';
import {LoginUser, Role} from '../user/types';
import {extraPlaylistReducers, playlistReducers} from './playlists';
import {extraWishReducers, wishReducers} from './wishes';
import {commentsReducers, extraCommentsReducers} from './comments';
import {extraReactionReducers, reactionReducers} from './reactions';
import {extraUploadReducers, uploadReducers} from './upload';
import {extraHighlightReducers, highlightReducers} from './highlights';
import {adminReducers, extraAdminReducers} from './admin';
import {setSelectedCustomerIdAction} from '../user/actions';

export const canAddVideos = (user: LoginUser): boolean => user?.jwt?.length > 0 && user.roles.some(it => [Role.VideoCreator, Role.VideoAdmin, Role.QualitizeAdmin].includes(it));
export const canAddPlaylists = (user: LoginUser): boolean => user?.jwt?.length > 0 && user.roles.some(it => [Role.VideoPlaylistCreator, Role.VideoAdmin, Role.QualitizeAdmin].includes(it));

export const canEditVideo = (user: LoginUser, video?: Video) => Boolean(user?.jwt) && Boolean(video?.creator) && ((video?.creator.id === user.videoUserID && user.roles.includes(Role.VideoCreator)) || user.roles.some(it => [Role.VideoAdmin, Role.QualitizeAdmin].includes(it)));

export const initialVideoState: VideoState = {
    videos: {
        fetchStatus: FetchStatus.Default,
        dashboardFetchStatus: FetchStatus.Default,
        elements: {},
        count: 0
    },
    latest: {
        fetchStatus: FetchStatus.Default,
        videos: []
    },
    mostViewed: {
        fetchStatus: FetchStatus.Default,
        videos: []
    },
    highlights: {
        fetchStatus: FetchStatus.Default,
        videos: [],
        activeItem: -1
    },
    popular: {
        fetchStatus: FetchStatus.Default,
        videos: []
    },
    tags: {
        fetchStatus: FetchStatus.Default,
        elements: {}
    },
    tagTypes: {
        fetchStatus: FetchStatus.Default,
        elements: {}
    },
    categories: {
        fetchStatus: FetchStatus.Default,
        elements: {}
    },
    tagsByCategories: {},
    selectedTagsByCategory: {},
    selectedCategory: -2,
    search: {
        text: '',
        elements: [],
        expandBar: false,
        fetchStatus: FetchStatus.Default,
        showSearch: false,
        selectedTags: [],
        textSearch: false,
        count: 0
    },
    videosByCategory: {},
    tagsByTagType: {},
    upload: {
        title: '',
        author: '',
        description: '',
        categories: [],
        tags: [],
        createdTags: [],
        selectedTags: [],
        selectedTagsByTagType: {},
        fetchStatus: FetchStatus.Default,
        saveFetchStatus: FetchStatus.Default,
        progress: 0,
        wishIds: []
    },
    statistics: {
        videos: {
            elements: {},
            page: 0,
            pageSize: 25,
            visible: [],
            numberOfElements: 0,
            fetchStatus: FetchStatus.Default,
            orderBy: [],
            search: ''
        },
        totalViews: 0,
        fetchStatus: {
            deleteFetchStatus: FetchStatus.Default,
            enabledFetchStatus: FetchStatus.Default,
            highlightFetchStatus: FetchStatus.Default,
        }
    },
    wishes: {
        fetchStatus: FetchStatus.Default,
        createFetchStatus: FetchStatus.Default,
        elements: {},
        latest: [],
        popular: [],
        finished: []
    },
    playlists: {
        fetchStatus: FetchStatus.Default,
        createFetchStatus: FetchStatus.Default,
        highlightFetchStatus: FetchStatus.Default,
        elements: {},
        originalElements: {},
        highlights: [],
        count: 0
    }
};

const mergeVideosByCategory = (state: Draft<VideoState>, videos: Video[]) => {
    videos.forEach((video: Video) => {
        video.categories.forEach((category) => {
            state.videosByCategory[category.id] = state.videosByCategory[category.id] || [];
            if (!state.videosByCategory[category.id]!.includes(video.videoID)) {
                state.videosByCategory[category.id]!.push(video.videoID);
            }
        });
    });
};

const setStateFromSearch = (state: VideoState, response: SearchResponse, lazy = false) => {
    response.videos.forEach((video: Video) => {
        state.videos.elements[video.videoID] = {
            ...state.videos.elements[video.videoID],
            ...video,
            description: markdownConverter.makeHtml(video.description)
        };
    });
    let searchElements = response.videos.map((video: Video) => video.videoID);
    if (lazy) {
        searchElements = [...state.search.elements, ...searchElements];
    }
    state.videos.fetchStatus = FetchStatus.Success;
    state.videos.dashboardFetchStatus = FetchStatus.Success;
    state.videos.count = state.videos.count > 0 ? state.videos.count : response.videoCount;
    state.search.elements = searchElements;
    state.search.showSearch = false;
    state.search.count = response.videoCount;
    // Add the videos to the categores
    mergeVideosByCategory(state, response.videos);
};

export const mergeVideoIntoState = (state: Draft<VideoState>, video: Video) => {
    state.videos.fetchStatus = FetchStatus.Success;
    state.videos.elements[video.videoID] = {
        ...video,
        ...(state.videos.elements[video.videoID] || {}),
        description: markdownConverter.makeHtml(video.description),
        enabledFetchStatus: FetchStatus.Default,
        deleteFetchStatus: FetchStatus.Default,
        commentFetchStatus: FetchStatus.Default,
        submitCommentFetchStatus: FetchStatus.Default,
        highlightFetchStatus: FetchStatus.Default,
        enabled: video.enabled
    };
    // Merge the video into the category
    mergeVideosByCategory(state, [video]);
};

const deleteVideoFromState = (state: VideoState, video: Video, deleted: boolean = true) => {
    video.tags.forEach(it => {
        const tag = state.tags.elements[it.id];
        if (tag) {
            tag.count--;
        }
    });
    state.videos.count--;
    video.categories.forEach(it => {
        const indexOfVideo = state.videosByCategory[it.id]?.indexOf(video.videoID || '-1');
        if (indexOfVideo && indexOfVideo > -1) {
            state.videosByCategory[it.id] = [...state.videosByCategory[it.id]!].splice(indexOfVideo, 1);
        }
        const category = state.categories.elements[it.id];
        if (category) {
            category.count--;
        }
    });
    // Finally remove the video from the state
    // Check if video is deleted, if so, delete it from state, otherwise set enabled to false
    if (deleted) {
        // Delete the video also from the dashboard.
        state.latest.videos = state.latest.videos.filter(it => it !== video.videoID);
        state.mostViewed.videos = state.mostViewed.videos.filter(it => video.videoID);
        delete state.videos.elements[video.videoID];
    } else if (state.videos.elements[video.videoID]) {
        state.videos.elements[video.videoID]!.enabled = false;
    }
}

export const mergeNewVideoIntoState = (state: VideoState, video: Video, created: boolean) => {
    if (!state.videos.elements[video.videoID]) {
        mergeVideoIntoState(state, video);
        state.tags.elements = {...normalize(video.tags), ...state.tags.elements};
        state.videos.count++;
        // Only add video to latest video if it has been created, re-enabled videos will not be merged
        if (created && !state.latest.videos.includes(video.videoID)) {
            state.latest.videos.push(video.videoID);
        }
        // Merge the categories of the video in the state by adding the video to the category and updating
        // the count of the video
        video.categories.forEach(it => {
            if (!state.videosByCategory[it.id]?.includes(video.videoID)) {
                state.videosByCategory[it.id] = [...state.videosByCategory[it.id]!, video.videoID];
            }
            const category = state.categories.elements[it.id];
            if (category) {
                category.count++;
            }
        });
        video.tags.forEach(it => {
            if (state.tags.elements[it.id]) {
                state.tags.elements[it.id]!.count++;
            }
        });
    }
}

const setDeleteVideoFetchStatus = (state: Draft<VideoState>, videoId: string, fetchStatus: FetchStatus) => {
    const video = state.videos.elements[videoId];
    if (video) {
        video.deleteFetchStatus = fetchStatus;
    }
    if (state.statistics.videos.elements[videoId]) {
        state.statistics.fetchStatus.deleteFetchStatus = fetchStatus;
    }
}

const setEnableVideoFetchStatus = (state: VideoState, videoID: string, fetchStatus: FetchStatus) => {
    const video = state.videos.elements[videoID];
    if (video) {
        video.enabledFetchStatus = fetchStatus;
    }
    if (state.statistics.videos.elements[videoID]) {
        state.statistics.fetchStatus.enabledFetchStatus = fetchStatus;
    }
};

const reducers = {
    resetVideoDashboardSettings: (state: VideoState) => {
        state.selectedCategory = -2;
        state.search.showSearch = false;
        state.search.selectedTags = [];
    },
    setSelectedCategory: (state: VideoState, action: PayloadAction<number>) => {
        state.selectedCategory = action.payload;
        state.search.expandBar = false;
    },
    updateSearchText: (state: VideoState, action: PayloadAction<string>) => {
        state.selectedCategory = -2;
        state.selectedTagsByCategory = {};
        state.search.text = action.payload;
        state.search.showSearch = Boolean(action.payload.trim());
        state.search.fetchStatus = FetchStatus.Active;
        state.search.textSearch = true;
        state.search.selectedTags = [];
        state.search.elements = [];
    },
    setExpandSearchBar: (state: VideoState, action: PayloadAction<boolean>) => {
        state.search.expandBar = action.payload;
        state.search.textSearch = !action.payload ? false : state.search.textSearch;
    },
    deleteVideoFromStateAction: (state: VideoState, action: PayloadAction<string>) => {
        const video = state.videos.elements[action.payload];
        if (video) {
            deleteVideoFromState(state, video);
        }
        // Delete video from statistic state
        state.statistics.videos.visible = state.statistics.videos.visible.filter(it => it !== action.payload);
        state.statistics.videos.numberOfElements--;
        delete state.statistics.videos.elements[action.payload];
    },
    toggleSelectedTag: (state: VideoState, action: PayloadAction<number>) => {
        if (state.search.selectedTags.includes(action.payload)) {
            state.search.selectedTags = state.search.selectedTags.filter(it => it !== action.payload);
        } else {
            state.search.selectedTags.push(action.payload);
        }
        state.search.textSearch = false;
        state.videos.fetchStatus = FetchStatus.Active;
    },
    deleteSelectedTags: (state: VideoState) => {
        state.search.selectedTags = [];
        state.search.textSearch = false;
        state.videos.fetchStatus = FetchStatus.Active;
    },
    updateVideoSuccess: (state: VideoState, action: PayloadAction<Video>) => {
        mergeVideoIntoState(state, action.payload);
    },
    resetVideoSearchText: (state: VideoState) => {
        state.search.text = '';
    }
}

const videos = createSlice({
    name: 'videos',
    initialState: initialVideoState,
    reducers: {
        ...adminReducers,
        ...commentsReducers,
        ...highlightReducers,
        ...playlistReducers,
        ...reactionReducers,
        ...uploadReducers,
        ...wishReducers,
        ...reducers
    },
    extraReducers: builder => extraWishReducers(extraUploadReducers(extraReactionReducers(extraPlaylistReducers(extraHighlightReducers(extraCommentsReducers(extraAdminReducers(builder)))))))
        .addCase(loadVideoActionAndSaga.startAction, state => {
            state.videos.fetchStatus = FetchStatus.Active;
        }).addCase(loadVideoActionAndSaga.successAction, (state, action) => {
            mergeVideoIntoState(state, action.payload)
        }).addCase(loadVideoActionAndSaga.errorAction, state => {
            state.videos.fetchStatus = FetchStatus.Error;
        }).addCase(deleteVideoAction.startAction, (state, action) => {
            setDeleteVideoFetchStatus(state, action.payload.videoID, FetchStatus.Active);
        }).addCase(deleteVideoAction.successAction, (state, action) => {
            setDeleteVideoFetchStatus(state, action.payload, FetchStatus.Success);
        }).addCase(deleteVideoAction.errorAction, (state, action) => {
            setDeleteVideoFetchStatus(state, action.payload.videoID, FetchStatus.Error);
        }).addCase(deleteVideoAction.resetAction, (state, action) => {
            setDeleteVideoFetchStatus(state, action.payload, FetchStatus.Default);
        }).addCase(setVideoEnabledAction.startAction, (state, action) => {
            setEnableVideoFetchStatus(state, action.payload.videoID, FetchStatus.Active);
        }).addCase(setVideoEnabledAction.successAction, (state, action) => {
            const video = state.videos.elements[action.payload.id];
            if (video) {
                video.enabledFetchStatus = FetchStatus.Success;
                video.enabled = action.payload.enabled;
            }
            const element = state.statistics.videos.elements[action.payload.id];
            if (element) {
                element.enabled = action.payload.enabled;
                state.statistics.fetchStatus.enabledFetchStatus = FetchStatus.Success;
            }
        }).addCase(setVideoEnabledAction.errorAction, (state, action) => {
            setEnableVideoFetchStatus(state, action.payload.videoID, FetchStatus.Error);
        }).addCase(setVideoEnabledAction.resetAction, (state, action) => {
            setEnableVideoFetchStatus(state, action.payload, FetchStatus.Default);
        }).addCase(searchVideosAction.startAction, state => {
            state.search.showSearch = true;
            state.search.fetchStatus = FetchStatus.Active;
        }).addCase(searchVideosAction.successAction, (state, action) => {
            setStateFromSearch(state, action.payload.result, action.payload.lazy);
            state.search.showSearch = true;
            state.search.fetchStatus = FetchStatus.Success;
        }).addCase(videoCreated, (state, action) => {
            mergeNewVideoIntoState(state, action.payload.videoDetail, true);
        }).addCase(videoDeleted, (state, action) => {
            const video = state.videos.elements[action.payload.videoID];
            if (video?.deleteFetchStatus !== FetchStatus.Active) {
                deleteVideoFromState(state, video!);
            }
        }).addCase(videoDisabled, (state, action) => {
            let video = state.videos.elements[action.payload.videoID];
            if (video?.deleteFetchStatus !== FetchStatus.Active) {
                // Delete the video completely from the state if the user is not the creator of the video or a video admin,
                // otherwise disable it only
                deleteVideoFromState(state, video!, !(video!.creatorID === action.payload.user.info.id || canEditVideo(action.payload.user.info, video)));
            }
        }).addCase(videoEnabled, (state, action) => {
            mergeNewVideoIntoState(state, action.payload.videoDetail, false);
        }).addCase(videoPathCreated, (state, action) => {
            const video = state.videos.elements[action.payload.videoID];
            if (video) {
                video.videoPaths = [action.payload.videoPath, ...(video.videoPaths || [])];
            }
        }).addCase(videoViewed, (state, action) => {
            let video = state.videos.elements[action.payload.videoID];
            if (video) {
                video.views++;
            }
        }).addCase(loadVideoCategoriesAction.startAction, (state) => {
            state.categories.fetchStatus = FetchStatus.Active;
        }).addCase(loadVideoCategoriesAction.errorAction, state => {
            state.categories.fetchStatus = FetchStatus.Error;
        }).addCase(loadVideoCategoriesAction.successAction, (state, action) => {
            state.categories.fetchStatus = FetchStatus.Success;
            state.categories.elements = {
                ...state.categories.elements, ...normalize(action.payload.categories.map(category => ({
                    ...category.category,
                    count: category.count
                })))
            };
            state.videos.count = action.payload.totalCount;
        }).addCase(loadVideoTagsAction.startAction, state => {
            state.tags.fetchStatus = FetchStatus.Active;
        }).addCase(loadVideoTagsAction.errorAction, state => {
            state.tags.fetchStatus = FetchStatus.Error;
        }).addCase(loadVideoTagsAction.successAction, (state, action) => {
            const tagTypes = {...state.tagTypes};
            tagTypes.elements = {...tagTypes.elements};
            action.payload.tagsWithCounts.filter((tag) => tag.tag.type !== undefined).forEach((tag) => {
                if (tag.tag.type && !state.tagTypes.elements[tag.tag.type.id]) {
                    state.tagTypes.elements[tag.tag.type.id] = tag.tag.type;
                }
            });
            state.tags.elements = {
                ...state.tags.elements,
                ...normalize(action.payload.tagsWithCounts.map(tag => ({
                    ...tag.tag,
                    count: tag.count
                })))
            };
        }).addCase(loadVideoMostViewedAction.startAction, state => {
            state.mostViewed.fetchStatus = FetchStatus.Active;
        }).addCase(loadVideoMostViewedAction.errorAction, state => {
            state.mostViewed.fetchStatus = FetchStatus.Error;
        }).addCase(loadVideoMostViewedAction.successAction, (state, action) => {
            state.mostViewed.fetchStatus = FetchStatus.Success;
            state.mostViewed.videos = action.payload.videos.map(it => it.videoID);
            state.videos.elements = {...normalize(action.payload.videos, undefined, 'videoID'), ...state.videos.elements};
        }).addCase(loadVideoLatestAction.startAction, (state) => {
            state.latest.fetchStatus = FetchStatus.Active;
        }).addCase(loadVideoLatestAction.errorAction, (state) => {
            state.latest.fetchStatus = FetchStatus.Error;
        }).addCase(loadVideoLatestAction.successAction, (state, action) => {
            state.latest.fetchStatus = FetchStatus.Success;
            state.latest.videos = action.payload.videos.map(it => it.videoID);
            action.payload.videos.forEach(it => {
                state.videos.elements[it.videoID] = {
                    ...(state.videos.elements[it.videoID] || {}),
                    ...it
                }
            });
        }).addCase(loadVideoMostExcitingAction.startAction, state => {
            state.popular.fetchStatus = FetchStatus.Active;
        }).addCase(loadVideoMostExcitingAction.errorAction, state => {
            state.popular.fetchStatus = FetchStatus.Error;
        }).addCase(loadVideoMostExcitingAction.successAction, (state, action) => {
            state.popular.fetchStatus = FetchStatus.Success;
            state.popular.videos = action.payload.videos.map(it => it.videoID);
            state.videos.elements = {...normalize(action.payload.videos, undefined, 'videoID'), ...state.videos.elements};
        }).addCase(toggleVideoEdit, (state, action) => {
            const video = state.videos.elements[action.payload.id];
            if (video) {
                video.edit = !video.edit;
            }
        }).addCase(setSelectedCustomerIdAction.startAction, () => {
            return {...initialVideoState};
        })
});

export const {
    deleteVideoFromStateAction,
    deleteSelectedTags,
    toggleSelectedTag,
    setSelectedCategory,
    updateVideoSuccess,
    updateSearchText,
    addVideoPlaylistSection,
    addVideosToPlaylistSection,
    createEmptyVideoPlaylist,
    removeVideoFromPlaylistSection,
    removeVideoPlaylistImage,
    removeVideoPlaylist,
    removeVideoPlaylistSection,
    setActiveVideoPlaylistVideo,
    removeAdminVideoColumnSortOrder,
    setVideoPlaylistSectionTitle,
    resetVideoPlaylistFetchStatus,
    resetVideoPlaylistEdit,
    resetVideoDashboardSettings,
    setAdminVideoColumnSortOrder,
    setAdminVideoPage,
    setAdminVideoPageSize,
    setAdminVideoSearchText,
    setSectionVideos,
    updateVideoPlaylistSectionPosition,
    setUploadVideoPlaylistTitleImageProgress,
    setUploadVideoCategories,
    setUploadVideoProgress,
    setUploadVideoTags,
    setUploadVideoTagsForTagType,
    setUploadVideoWishes,
    setActiveVideoHighlightSliderItem,
    setExpandSearchBar,
    resetUpload,
    resetVideoSearchText
} = videos.actions;

export default videos.reducer;