import {Messages} from '../../localization/localization';
import Routes from '../../constants/routes';
import {LocalizedStrings} from 'react-localization';
import {MenuElement, Smiley, Play, PlayActive, Settings, Dashboard} from '@software/reactcommons';
import {resetVideoDashboardSettings} from '../../redux/reducer/videos/videos';
import {AutoAwesomeOutlined} from '@mui/icons-material';


export const getMenuElementForRoute = (route: string, messages: LocalizedStrings<Messages>): MenuElement => {
    const routesToIcon = {
        [Routes.FeedbackBase('')]: {
            element: Smiley,
            description: messages.menu.feedback.title
        },
        [Routes.VideoDashboard as string]: {
            element: Play,
            activeElement: PlayActive,
            description: messages.menu.videos,
            // On click on the icon, the selected category should be unset
            actions: [resetVideoDashboardSettings()]
        },
        [Routes.AdministrationBase('')]: {
            element: Settings,
            description: messages.menu.settings
        },
        [Routes.OperationsBase('')]: {
            element: Dashboard,
            description: messages.menu.operations
        },
        [Routes.LandingPageBase('')]: {
            element: AutoAwesomeOutlined,
            description: messages.menu.landingPages
        }
    }
    return routesToIcon[route];
}
